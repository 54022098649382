<template>
  <svg
    width="119"
    height="119"
    viewBox="0 0 119 119"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.1848 16.7419L101.962 72.5192L72.4906 101.991L16.7133 46.2134L46.1848 16.7419Z"
      fill="currentColor"
    />
    <path
      d="M1.77542 23.7155L23.5415 1.64071C25.7026 -0.52046 29.0988 -0.52046 31.1056 1.48634C31.1056 1.48634 31.1055 1.48634 31.2599 1.64071L40.8308 11.2116L11.3463 40.8505L1.77542 31.2796C-0.385751 29.1184 -0.385751 25.7223 1.77542 23.7155Z"
      fill="currentColor"
    />
    <path
      d="M106.284 78.9799L118.479 111.706C119.559 114.485 118.17 117.572 115.391 118.653C114.156 119.116 112.767 119.116 111.532 118.653L78.8058 106.303L106.284 78.9799Z"
      fill="currentColor"
    />
  </svg>
</template>
