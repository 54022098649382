
import { defineComponent, onMounted, reactive } from "vue";
import Login from "./Login.vue";
import ForgotPassword from "./ForgotPassword.vue";

export default defineComponent({
  components: { ForgotPassword, Login },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
    });

    const forgotPassword = reactive({
      show: false,
      email: "",
    });

    return {
      forgotPassword,
    };
  },
});
