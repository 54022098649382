
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetProject, projectService } from "@/services";
import { useRoute, useRouter } from "vue-router";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import ProjectInformation from "./information/Main.vue";
import ProjectTimeline from "./timeline/Main.vue";
import ProjectDocuments from "./documents/Main.vue";
import ProductSubmissions from "@/views/projects/view/submissions/Main.vue";
import ProjectActionButton from "@/views/projects/view/ActionButton.vue";
import ProjectBallots from "@/views/projects/view/Ballots.vue";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetProject>;
}

export default defineComponent({
  name: "ViewProject",
  components: {
    ProjectBallots,
    ProjectActionButton,
    ProductSubmissions,
    ProjectTimeline,
    ProjectInformation,
    ProjectDocuments,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const activeTab = ref<string>("info");
    const isActiveEditableInformation = ref<boolean>(false);
    const { fetchRecord } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: projectService.show,
      errorCallback() {
        router.go(-1);
      },
    }) as FetchRecord;

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `VIEW PROJECT (#${id.value})`;
      return `${data.value.reference}`;
    });
    const disabledActionButtons = computed<boolean>(
      () => isActiveEditableInformation.value
    );

    watch(id, () => onFetch(), { flush: "post" });
    onMounted(() => {
      onFetch();
    });

    const onFetch = () => fetch(id.value).finally();

    return {
      id,
      loading,
      headerTitle,
      project: data,
      activeTab,
      disabledActionButtons,
      isActiveEditableInformation,
      onFetch,
    };
  },
});
