<template>
  <div class="accordion__pane py-4">
    <CreateOrUpdateSubmissionModal
      v-model:list="list.data"
      @update:add-item="() => list.meta.total++"
      :project-id="projectId"
      :committee-id="$_.get(project, 'committee_id')"
      ref="refCreateOrUpdateModal"
      v-if="$_.get(project, 'abilities.can_update', false)"
    />
    <a
      href="javascript:;"
      class="accordion__pane__toggle relative font-medium text-2xl text-gray-600 block pr-16 whitespace-nowrap overflow-hidden overflow-ellipsis"
      @click="() => $emit('update:active-tab', 'submissions')"
    >
      Submissions ({{ $_.get(list, "meta.total", 0) }})
      <Tippy
        tag="button"
        content="Add Submission Document"
        :options="{ placement: 'bottom' }"
        class="button p-0 absolute right-4"
        @click.stop.prevent="() => addSubmission()"
        v-if="$_.get(project, 'abilities.can_update', false)"
      >
        <FilePlusIcon class="w-8 h-8" />
      </Tippy>
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Title</th>
                <th class="whitespace-nowrap">Documents</th>
                <th class="whitespace-nowrap text-center">Created At</th>
                <th
                  class="whitespace-nowrap text-right"
                  v-if="$_.get(project, 'abilities.can_update', false)"
                >
                  Functions
                </th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="
                  deleteProcessing && transactionIds.indexOf(row.id) > -1
                "
                :waiting="transactionIds[0] !== row.id"
              >
                <td class="">
                  <div>{{ $_.get(row, "text", "") }}</div>
                </td>
                <td>
                  <router-link
                    v-for="file in $_.get(row, 'files', [])"
                    :key="file"
                    :to="{
                      name: 'documents',
                      params: { uuid: $_.get(file, 'uuid', '') },
                    }"
                    target="_blank"
                    class="block text-blue-500 hover:text-blue-600"
                    >{{ $_.get(file, "title", "") }}</router-link
                  >
                </td>
                <td class="whitespace-nowrap text-center w-0">
                  {{ $h.formatDate(row.created_at, "DD MMMM YYYY") }}
                </td>
                <td
                  class="whitespace-nowrap text-right w-0"
                  v-if="$_.get(project, 'abilities.can_update', false)"
                >
                  <Tippy
                    tag="button"
                    content="Edit"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => openEditSubmissionModal(row)"
                  >
                    <Edit2Icon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => triggerDelete([row.id])"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-lg text-gray-500">
        No submissions found for this project!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { submissionService, GetProject } from "@/services";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import CreateOrUpdateSubmissionModal from "@/views/projects/view/submissions/CreateOrUpdateModal.vue";

export default defineComponent({
  name: "ProductSubmissions",
  components: {
    CreateOrUpdateSubmissionModal,
    Pagination,
    TransactionStatement,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props) {
    const router = useRouter();
    const refCreateOrUpdateModal = ref();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "submission",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "text",
            "files",
            "committee_id",
            "project_id",
            "secretary_id",
            "created_at",
          ],
          "@where": {
            project_id: props.projectId,
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: submissionService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const goToView = (uuid: any) => {
      if (uuid) {
        router.push({ name: "documents", params: { uuid } });
      }
    };

    const addSubmission = () => {
      cash("#submission-modal").modal("show");
    };

    const openEditSubmissionModal = (submission: Record<string, any>) => {
      if (refCreateOrUpdateModal.value)
        refCreateOrUpdateModal.value.openEditModal(submission);
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      refCreateOrUpdateModal,
      loading,
      list,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      changePage,
      changePerPage,
      addSubmission,
      goToView,
      openEditSubmissionModal,
    };
  },
});
</script>
