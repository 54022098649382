<template>
  <div class="divide-y">
    <div class="accordion__pane active pb-4">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
        :class="{
          'text-red-500': validationErrors.get(
            'committee_id',
            'wg_id',
            'reference',
            'edition_no',
            'price',
            'visible_on_website',
            'time_frame',
            'stage'
          ),
        }"
      >
        Identity
      </a>
      <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
        <div class="grid grid-cols-12 gap-4">
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('committee_id')"
          >
            <template v-slot:label>
              <label class="block mb-1">Committee</label>
            </template>
            <Select2Input
              v-model="editableFormData.committee_id"
              :validation-errors="validationErrors.get('committee_id')"
              :options="committeeSelect2Options"
              @update:selected-item="onChangeCommittee"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('wg_id')"
          >
            <template v-slot:label>
              <label class="block mb-1">Working Group</label>
            </template>
            <Select2Input
              v-model="editableFormData.wg_id"
              :validation-errors="validationErrors.get('wg_id')"
              :options="workingGroupSelect2Options"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('stage')"
            v-if="!projectId"
          >
            <template v-slot:label>
              <label class="block mb-1">Start Stage</label>
            </template>
            <VueSelect
              v-model="editableFormData.stage"
              :validation-errors="validationErrors.get('stage')"
            >
              <option
                v-for="stage in $store.state.project.initialTypes"
                :key="`stage-${stage.value}`"
                :value="stage.value"
              >
                ({{ stage.value }}) {{ stage.text }}
              </option>
            </VueSelect>
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('project_no')"
          >
            <template v-slot:label>
              <label class="block mb-1">Project No</label>
            </template>
            <VueInput
              v-model="editableFormData.project_no"
              :validation-errors="validationErrors.get('project_no')"
            />
            <div
              class="mt-2 text-xs text-gray-500 text-gray-500"
              v-if="state.nextProjectNo"
            >
              Next available number will be <b>{{ state.nextProjectNo }}</b>
            </div>
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('part_no')"
          >
            <template v-slot:label>
              <label class="block mb-1">Part No</label>
            </template>
            <VueInput
              v-model="editableFormData.part_no"
              :validation-errors="validationErrors.get('part_no')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('reference')"
          >
            <template v-slot:label>
              <label class="block mb-1">Reference</label>
            </template>
            <VueInput
              :value="reference"
              readonly
              :validation-errors="validationErrors.get('reference')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('reference_suffix')"
          >
            <template v-slot:label>
              <label class="block mb-1">Reference Suffix</label>
            </template>
            <VueInput
              v-model="editableFormData.reference_suffix"
              :validation-errors="validationErrors.get('reference_suffix')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('edition_no')"
          >
            <template v-slot:label>
              <label class="block mb-1">Edition No</label>
            </template>
            <VueInput
              v-model="editableFormData.edition_no"
              :validation-errors="validationErrors.get('edition_no')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('price')"
          >
            <template v-slot:label>
              <label class="block mb-1">Price</label>
            </template>
            <VueInput
              v-model="editableFormData.price"
              :validation-errors="validationErrors.get('price')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('time_frame')"
          >
            <template v-slot:label>
              <label class="block mb-1">Time Frame</label>
            </template>
            <VueSelect
              v-model="editableFormData.time_frame"
              :validation-errors="validationErrors.get('time_frame')"
            >
              <option value="12">12</option>
              <option value="18">18</option>
              <option value="24">24</option>
              <option value="36">36</option>
              <option value="48">48</option>
            </VueSelect>
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-6"
            :validation-errors="validationErrors.get('visible_on_website')"
          >
            <template v-slot:label>
              <label class="block mb-1">Visible On Site</label>
            </template>
            <VueSelect
              v-model="editableFormData.visible_on_website"
              :validation-errors="validationErrors.get('visible_on_website')"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </VueSelect>
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('title_en')"
          >
            <template v-slot:label>
              <label class="block mb-1">English Title</label>
            </template>
            <VueInput
              v-model="editableFormData.title_en"
              :validation-errors="validationErrors.get('title_en')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('description_en')"
          >
            <template v-slot:label>
              <label class="block mb-1">English Description</label>
            </template>
            <VueTiptap
              v-model="editableFormData.description_en"
              :validation-errors="validationErrors.get('description_en')"
            />
          </VueInputGroup>
        </div>
      </div>
    </div>
    <div class="accordion__pane py-4">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
        :class="{
          'text-red-500': validationErrors.get('title_fr', 'description_fr'),
        }"
      >
        French
      </a>
      <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
        <div class="grid grid-cols-12 gap-4">
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('title_fr')"
          >
            <template v-slot:label>
              <label class="block mb-1">Title</label>
            </template>
            <VueInput
              v-model="editableFormData.title_fr"
              :validation-errors="validationErrors.get('title_fr')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('description_fr')"
          >
            <template v-slot:label>
              <label class="block mb-1">Description</label>
            </template>
            <VueTiptap
              v-model="editableFormData.description_fr"
              :validation-errors="validationErrors.get('description_fr')"
            />
          </VueInputGroup>
        </div>
      </div>
    </div>
    <div class="accordion__pane py-4">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
        :class="{
          'text-red-500': validationErrors.get('title_ar', 'description_ar'),
        }"
      >
        Arabic
      </a>
      <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
        <div class="grid grid-cols-12 gap-4">
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('title_ar')"
          >
            <template v-slot:label>
              <label class="block mb-1">Title</label>
            </template>
            <VueInput
              v-model="editableFormData.title_ar"
              :validation-errors="validationErrors.get('title_ar')"
            />
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full"
            :validation-errors="validationErrors.get('description_ar')"
          >
            <template v-slot:label>
              <label class="block mb-1">Description</label>
            </template>
            <VueTiptap
              v-model="editableFormData.description_ar"
              :validation-errors="validationErrors.get('description_ar')"
            />
          </VueInputGroup>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { PostProject, projectService } from "@/services";
import { ValidationErrors } from "@/utils/form";
import { Select2Options } from "@/global-components/select2";
import { useStore } from "@/store";
import VueTiptap from "@/components/tiptap/Main.vue";

export default defineComponent({
  name: "ProjectEditInformation",
  components: { VueTiptap },
  props: {
    projectId: { default: () => null },
    formData: { type: Object as () => PostProject, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const editableFormData = reactive(toRefs(props.formData));
    const state = reactive<Record<string, any>>({
      selectedCommittee: null,
      nextProjectNo: "",
    });
    const stageAbbr = computed<string>(() => {
      const index = _.findIndex(store.state.project.initialTypes, {
        value: editableFormData.stage,
      });
      return _.get(store.state.project.initialTypes, `${index}.abbr`, "");
    });
    const reference = computed<string>(() => {
      let val = `OIC/SMIIC ${stageAbbr.value} ${editableFormData.project_no}`;
      if (editableFormData.part_no) val += `-${editableFormData.part_no}`;
      if (editableFormData.reference_suffix)
        val += `${editableFormData.reference_suffix}`;
      return val;
    });
    const onChangeCommittee = (committee: any) => {
      state.selectedCommittee = committee;
      editableFormData.wg_id = "";
    };
    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
            level: "level",
            type: "type",
          },
          "@where": {
            level: [1, 2],
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              "can_create_project"
            ),
          },
          "@whereNot": {
            type: ["WG"],
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });
    const workingGroupSelect2Options = computed<Select2Options>(() => {
      const selectedCommitteeLevel = _.get(
        state.selectedCommittee,
        "level.level"
      );
      const where: any = {
        level_1_type: _.get(state.selectedCommittee, "level.level_1.type"),
        level_1_number: _.get(state.selectedCommittee, "level.level_1.number"),
      };
      if (selectedCommitteeLevel === 1) {
        _.set(where, "level", 2);
        _.set(where, "level_2_type", "WG");
      } else if (selectedCommitteeLevel === 2) {
        _.set(where, "level", 3);
        _.set(
          where,
          "level_2_type",
          _.get(state.selectedCommittee, "level.level_2.type")
        );
        _.set(
          where,
          "level_2_number",
          _.get(state.selectedCommittee, "level.level_2.number")
        );
        _.set(where, "level_3_type", "WG");
      }
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
            "@where": where,
          },
        },
        templateSelection: (obj: any): string => {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    const fetchNextNumber = () => {
      projectService.nextProjectNumber().then((result) => {
        state.nextProjectNo = result.project_no;
        if (state.nextProjectNo)
          editableFormData.project_no = result.project_no;
      });
    };

    onMounted(() => {
      fetchNextNumber();
    });

    return {
      state,
      reference,
      editableFormData,
      committeeSelect2Options,
      workingGroupSelect2Options,
      onChangeCommittee,
    };
  },
});
</script>
