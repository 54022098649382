
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee, GetProject } from "@/services";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "ProjectBallots",
  components: { Pagination },
  props: {
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props) {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const committee = computed<GetCommittee | null>(() =>
      _.get(props.project, "committee", null)
    );
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "ballot",
        config: {
          // "@paginate": 5,
          "@page": 1,
          "@select": [
            "id",
            "committee_id",
            "ballot_type",
            "reference",
            "english_title",
            "status",
            "end_date",
          ],
          "@where": {
            project_id: _.get(props, "project.id", props.projectId),
            committee_id: _.get(
              committee.value,
              "id",
              props.project.committee_id
            ),
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      committee,
      changePage,
      changePerPage,
    };
  },
});
