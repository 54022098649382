
import { computed, defineComponent, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { feedbackService, GetDocument, PostFeedback } from "@/services";
import { DropzoneOptions } from "dropzone";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { useStore } from "vuex";
import Dropzone from "@/global-components/dropzone/Main.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostFeedback;
}

export default defineComponent({
  name: "CreateFeedback",
  components: { Dropzone },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const dropzoneRef = ref<HTMLElement>();
    provide("bind[dropzoneRef]", (el: HTMLElement) => {
      dropzoneRef.value = el;
    });

    const uploading = ref<boolean>(false);
    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT FEEDBACK (#${id.value})`;
      return "CREATE FEEDBACK";
    });

    const { createOrUpdate } = useGeneralActions();

    const {
      processing,
      formData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: feedbackService.store,
      successCallback() {
        router.go(-1);
      },
      params: {
        status: null,
        text: "",
        files: [],
      },
    }) as CreateOrUpdate;

    const committeeId = computed<any>(
      () => store.getters["committee/smiicCommittee"]?.id || null
    );
    const dropzoneOptions = computed<DropzoneOptions>(() => {
      return {
        params: {
          committee_id: committeeId.value,
          type: 103,
        },
      };
    });

    const successUploadFile = (document: GetDocument) => {
      formData.files.push({
        id: document.id,
        uuid: document.uuid,
        title: document.title,
        n_number: document.n_number,
        file_name: document.file_name,
        file_size: document.file_size,
      });
    };

    const removeUploadedFile = (uuid: string) => {
      const fileIndex = _.findIndex(formData.files, { uuid });
      if (fileIndex > -1) formData.files.splice(fileIndex, 1);
    };

    const onSubmit = () => {
      if (uploading.value) return;
      submit(id.value);
    };

    return {
      uploading,
      committeeId,
      headerTitle,
      processing,
      formData,
      validationErrors,
      dropzoneOptions,
      successUploadFile,
      removeUploadedFile,
      onSubmit,
    };
  },
});
