
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { ballotService, GetBallotResult, PostBallot } from "@/services";
import BallotEditInformation from "@/views/ballots/create/Information.vue";
import BallotQuestions from "@/views/ballots/view/questions/Main.vue";
import { useStore } from "@/store";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { helper } from "@/utils/helper";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostBallot;
}

export default defineComponent({
  name: "BallotCreate",
  components: { BallotQuestions, BallotEditInformation },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isActiveEditableQuestion = ref<boolean>(false);
    const id = computed(() => route.params.id);

    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: ballotService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const ballotType = computed(() => data.value.type || route.params.type);

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: ballotService.store,
      updateApiMethod: ballotService.update,
      successCallback(result: GetBallotResult) {
        if (result.kind === "ok") {
          if (!id.value) {
            router.replace({
              name: "ballots.view",
              params: {
                id: result.data.id,
              },
            });
          } else {
            router.go(-1);
          }
        }
      },
      params: {
        project_id: _.get(route.query, "project_id", "") || "",
        stage_id: "",
        committee_id: _.get(route.query, "committee_id", "") || "",
        version: "",
        ballot_type: ballotType.value,
        reference: "",
        english_title: "",
        note: "",
        start_date: helper.formatDate(new Date(), "YYYY-MM-DD"),
        end_date: "",
        files: [],
        questions: [],
      },
    }) as CreateOrUpdate;

    const changeBallot = (val: any) => {
      setFormData(val);
    };

    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT BALLOT (#${id.value})`;
      return `CREATE BALLOT (${ballotType.value})`;
    });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
      formData.questions = _.cloneDeep(
        store.getters["ballot/getStageQuestions"](ballotType.value)
      );
      if (ballotType.value === "SMIIC") {
        setFormData({
          committee_id: _.get(
            store.getters["committee/smiicCommittee"],
            "id",
            null
          ),
        });
      }
    });

    return {
      isActiveEditableQuestion,
      id,
      ballotType,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit,
      changeBallot,
    };
  },
});
