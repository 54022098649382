
import { defineComponent, reactive, ref } from "vue";
import { authService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  components: {},
  setup(props) {
    const loading = ref<boolean>(false);
    const formData = reactive({
      email: props.email,
    });

    const validationErrors = ref(new ValidationErrors());

    const submit = () => {
      validationErrors.value.clear();
      loading.value = true;
      authService.forgotPassword(formData)
        .then((result) => {
          if (result.kind === "ok") {
            ToastNotify({
              text: "The link to reset the password has been sent by email.",
              className: "success",
            });
          } else {
            if (result.kind === "validation")
              validationErrors.value.record(result.fields);
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      loading,
      formData,
      validationErrors,
      submit,
    };
  },
});
