import {
  BallotVoteResult,
  GetBallotComment,
  GetVote,
  Question,
  Vote,
} from "@/services";

const getChildrenMaxDepth = (items: any[], depth = 0): number => {
  items.map((item) => {
    if (item.depth > depth) {
      depth = item.depth;
    }
    if (_.get(item, "children", []).length) {
      depth = getChildrenMaxDepth(item.children, depth);
    } else if (Array.isArray(item) && item.length) {
      depth = getChildrenMaxDepth(item, depth);
    }
  });
  return depth;
};

const getQuestionLabel = (
  question: Question | string,
  flattenQuestions: any[],
  onlySq = false
): string => {
  if (!onlySq && _.get(question, "abbr"))
    return _.get(question, "abbr") as string;
  const index = _.findIndex(flattenQuestions, {
    uuid: _.get(question, "uuid", question),
  });
  return _.get(
    flattenQuestions,
    `${index}.sq`,
    _.get(question, "question", "")
  ) as string;
};

class TableHeader {
  flattenQuestions: any[] = [];

  setup(questions: Question[], flattenQuestions: any[]) {
    this.flattenQuestions = flattenQuestions;

    const headers = this.prepareHeaderGroups(questions);
    const cells = this.prepareCells(headers);
    cells.splice(0, 0, { uuid: "member-type" });
    cells.splice(0, 0, { uuid: "member-title" });

    const depth = getChildrenMaxDepth(headers);
    const rows = this.prepareHeaderRows(headers, depth);
    return { rows: this.prepareHeaderRowSpans(rows, depth), cells };
  }

  prepareCells(items: any[]): any[] {
    let cells: any[] = [];
    items.map((item) => {
      const children = _.get(item, "children", []);
      if (children.length > 0)
        cells = [...cells, ...this.prepareCells(children)];
      else {
        cells.push({
          uuid: item.uuid,
        });
      }
    });
    return cells;
  }

  prepareHeaderRows(items: any[], depth: number): any[] {
    const rows: any[] = [];
    for (let i = 1; i <= depth; i++) {
      rows.push(this.prepareHeaderRow(items, i));
    }
    rows[0].splice(0, 0, {
      uuid: "member-type",
      text: "Status",
      colspan: 1,
      rowspan: 1,
      depth: 1,
      children: [],
    });
    rows[0].splice(0, 0, {
      uuid: "member-title",
      text: "Country (Member)",
      colspan: 1,
      rowspan: 1,
      depth: 1,
      children: [],
    });
    return rows;
  }

  prepareHeaderRow(items: any[], row: number): any[] {
    let cells: any[] = [];

    items.map((item) => {
      if (item.depth === row) cells.push(item);
      else {
        const subItems = _.get(item, ["children"], []);
        cells = [...cells, ...this.prepareHeaderRow(subItems, row)];
      }
    });

    return cells;
  }

  prepareHeaderRowSpans(rows: any[], depth: number): any[] {
    rows.map((cells: any[]) => {
      cells.map((cell) => {
        const subItems = _.get(cell, ["children"], []);
        if (subItems.length === 0) {
          cell.rowspan = depth - cell.depth + 1;
        } else {
          cell.rowspan = 1;
        }
      });
    });

    return rows;
  }

  prepareHeaderGroups(questions: Question[], depth = 0): any[] {
    const res: any[] = [];
    _.forEach(questions, (question) => {
      const answers = _.isObject(question.answers)
        ? Object.values(question.answers)
        : question.answers;
      const questionObj: Record<string, any> = {
        uuid: question.uuid,
        text: getQuestionLabel(question, this.flattenQuestions),
        colspan: answers.length,
        depth: depth + 1,
        children: [],
      };
      _.forEach(answers, (answer) => {
        const answerObj: Record<string, any> = {
          uuid: answer.uuid,
          text: _.get(answer, "abbr") || answer.answer,
          colspan: 1,
          depth: depth + 2,
          children: [],
        };
        const subQuestions = _.isObject(answer.questions)
          ? Object.values(answer.questions)
          : answer.questions;
        if (subQuestions && subQuestions.length > 0) {
          answerObj.children = this.prepareHeaderGroups(
            subQuestions,
            depth + 2
          );
          answerObj.colspan = _.sumBy(answerObj.children, "colspan") || 1;
        }
        questionObj.children.push(answerObj);
      });
      questionObj.colspan = _.sumBy(questionObj.children, "colspan");
      res.push(questionObj);
    });
    return res;
  }
}

class TableRows {
  voteCastedMembers: any[] = [];
  cells: any[] = [];
  result: BallotVoteResult | null = null;

  setup(result: BallotVoteResult, cells: any[]) {
    this.cells = cells;
    this.result = result;

    result.members.map((member) => {
      const index = _.findIndex(result.votes, { member_id: member.id });
      if (index > -1) this.voteCastedMembers.push(member.id);
    });

    const rows = result.members
      .filter((member) => this.voteCastedMembers.indexOf(member.id) > -1)
      .map((member) => this.eachMember(member));

    return { rows, voteCastedMembers: this.voteCastedMembers };
  }

  eachMember(member: any) {
    return this.cells.map((cell) => {
      if (cell.uuid === "member-title") return member.acronym;
      if (cell.uuid === "member-type") return member.member_type;
      const index = _.findIndex(this.result?.votes, { member_id: member.id });
      if (index === -1) return "";
      return this.getQuestionVote(
        _.get(this.result?.votes, `${index}.vote`, []),
        cell.uuid
      );
    });
  }

  getQuestionVote(votes: any[], uuid: string) {
    let str = "";
    votes.map((vote) => {
      if (vote.answer.uuid === uuid) str = "X";
      if (!str) {
        const subQuestions = _.get(vote, "answer.questions", []);
        str = this.getQuestionVote(subQuestions, uuid);
      }
    });
    return str;
  }
}

class TableFooter {
  votes: GetVote[] = [];

  setup(questions: Question[], votes: GetVote[]) {
    this.votes = votes;
    const footers = this.prepareFooterGroups(questions);
    const depth = getChildrenMaxDepth(footers);
    const rows = this.prepareFooterRows(footers, depth);
    return rows.map((row) => this.eachCellInRows(row)).reverse();
  }

  eachCellInRows(cells: any[]): any[] {
    const flattenUuid = (list: any[]): string[] => {
      let arr: string[] = [];
      list.map((item) => {
        const subList = _.get(item, "children", []);
        if (subList.length > 0) arr = [...arr, ...flattenUuid(subList)];
        if (item.uuid) arr.push(item.uuid);
      });
      return arr;
    };
    return cells.map((cell) => {
      const child = _.get(cell, "children.0", null);
      const uuid: string[] = child ? flattenUuid([child]) : [];
      if (uuid.length === 0 && cell.uuid) uuid.push(cell.uuid);
      if (uuid.length > 0) {
        const votes = this.votes.map((vote) => vote.vote);
        cell.text = this.totalVoteCount(votes, uuid);
      }
      return cell;
    });
  }

  totalVoteCount(memberVotes: Vote[][], uuid: string[]): number {
    let total = 0;

    const eachVotes = (votes: Vote[]): void => {
      for (let i = 0; i < votes.length; i++) {
        const vote = votes[i];
        if (uuid.indexOf(vote.answer.uuid) > -1) {
          total += 1;
        } else if (vote.answer.questions && vote.answer.questions.length > 0) {
          eachVotes(vote.answer.questions);
        }
      }
    };
    memberVotes.map((memberVote) => eachVotes(memberVote));
    return total;
  }

  prepareFooterRows(items: any[], depth: number): any[] {
    const rows: any[] = [];
    for (let i = 1; i <= depth; i++) {
      const row = this.prepareFooterRow(items, i);
      if (i === 1) {
        row.splice(0, 0, {
          text: "Total",
          colspan: 2,
          rowspan: 1,
          depth: depth,
          children: [],
        });
      } else {
        row.splice(0, 0, {
          text: `Sub Total #${i - 1}`,
          colspan: 2,
          rowspan: 1,
          depth: depth,
          children: [],
        });
      }
      rows.push(row);
    }
    return rows;
  }

  prepareFooterRow(items: any[], row: number): any[] {
    let cells: any[] = [];

    items.map((item) => {
      if (item.depth === row) cells.push(item);
      else {
        const subItems = _.get(item, ["children"], []);
        const subCells = this.prepareFooterRow(subItems, row);
        if (subCells.length === 0) subCells.push({ text: "" });
        cells = [...cells, ...subCells];
      }
    });

    return cells;
  }

  prepareFooterGroups(questions: Question[], depth = 0): any[] {
    const res: any[] = [];
    _.forEach(questions, (question) => {
      const answers = _.isObject(question.answers)
        ? Object.values(question.answers)
        : question.answers;
      const groups: any[] = [];
      _.forEach(answers, (answer) => {
        const answerObj: Record<string, any> = {
          questionUuid: question.uuid,
          uuid: answer.uuid,
          colspan: 1,
          rowspan: 1,
          depth: depth + 1,
          children: [],
        };
        const subQuestions = _.isObject(answer.questions)
          ? Object.values(answer.questions)
          : answer.questions;
        if (subQuestions && subQuestions.length > 0) {
          answerObj.children = this.prepareFooterGroups(
            subQuestions,
            depth + 1
          );
          answerObj.colspan = _.sumBy(answerObj.children, "colspan") || 1;
        }
        groups.push(answerObj);
      });
      res.push({ children: groups, colspan: groups.length });
    });
    return res;
  }
}

class AllAnswersToQuestions {
  result: BallotVoteResult | undefined;
  questions: any[] = [];
  flattenQuestions: any[] = [];

  setup(result: BallotVoteResult, flattenQuestions: any[]) {
    this.flattenQuestions = flattenQuestions;
    this.result = result;
    this.prepareQuestions(result.questions);
    return this.questions;
  }

  prepareQuestions(questions: Question[]) {
    questions.map((question) => {
      this.eachQuestion(question);
      question.answers.map((answer) => {
        const subQuestions = _.get(answer, "questions", []);
        this.prepareQuestions(subQuestions);
      });
    });
  }

  eachQuestion(question: Question) {
    const comments: any[] = [];
    const answers = question.answers.map((answer) => ({
      uuid: answer.uuid,
      text: answer.abbr || answer.answer,
      members: [] as any[],
    }));

    const findVote = (vote: Vote, memberId: any): void => {
      if (vote.uuid === question.uuid) {
        answers.map((answer) => {
          if (answer.uuid === vote.answer.uuid) {
            const memberIndex = _.findIndex(this.result?.members, {
              id: memberId,
            });
            const member = _.get(this.result?.members, `${memberIndex}`, null);
            if (member) answer.members.push(member);
            if (vote.answer.comment || vote.answer.files.length > 0) {
              comments.push({
                text: vote.answer.comment,
                files: vote.answer.files,
                member,
              });
            }
          }
        });
      } else {
        vote.answer.questions?.map((subVote) => findVote(subVote, memberId));
      }
    };

    this.result?.votes.map((item) => {
      item.vote.map((vote) => findVote(vote, item.member_id));
    });

    this.questions.push({
      uuid: question.uuid,
      text: question.question,
      sq: getQuestionLabel(question, this.flattenQuestions, true),
      answers,
      comments,
    });
  }
}

export class VoteResults {
  table: any = {
    headers: [],
    rows: [],
    footers: [],
    voteCastedMembers: [],
  };

  commentsFromVoters: any[] = [];
  commentsFromCommenters: any[] = [];
  flattenQuestions: any[] = [];
  allAnswersToQuestions: any[] = [];

  protected originalQuestions: Question[] = [];
  protected result: BallotVoteResult | Record<string, any> = {};

  setup(result: BallotVoteResult): VoteResults {
    this.result = result;

    this.originalQuestions = _.cloneDeep(result.questions);
    this.result.questions = this.onlyReportingQuestions(result.questions);
    this.flattenQuestions = this.prepareFlattenQuestions(result.questions);

    const headerInstance = new TableHeader().setup(
      result.questions,
      this.flattenQuestions
    );
    this.table.headers = headerInstance.rows;

    const rowInstance = new TableRows().setup(result, headerInstance.cells);
    this.table.rows = rowInstance.rows;
    this.table.voteCastedMembers = rowInstance.voteCastedMembers;

    this.table.footers = new TableFooter().setup(
      result.questions,
      result.votes
    );

    this.commentsFromVoters = this.prepareCommentsFromVoters();
    this.commentsFromCommenters = this.prepareCommentsFromCommenters();
    this.allAnswersToQuestions = new AllAnswersToQuestions().setup(
      result,
      this.flattenQuestions
    );
    return this;
  }

  protected prepareFlattenQuestions(questions: Question[], path = "Q"): any[] {
    let arr: any[] = [];
    questions.map((question, index: number) => {
      const sq = path + "." + (index + 1);
      arr.push({
        sq,
        uuid: question.uuid,
        text: question.question,
      });
      question.answers.map((answer) => {
        arr = [
          ...arr,
          ...this.prepareFlattenQuestions(answer.questions || [], sq),
        ];
      });
    });
    return arr;
  }

  protected onlyReportingQuestions(questions: Question[]): Question[] {
    const arr: any[] = questions.map((question) => {
      if (!_.get(question, "reporting", true)) return null;
      question.answers.map((answer) => {
        answer.questions = this.onlyReportingQuestions(answer.questions || []);
      });
      return question;
    });
    return arr.filter((item) => !!item) as Question[];
  }

  protected prepareCommentsFromVoters(): any[] {
    const comments: any[] = [];

    const eachMemberVote = (votes: Vote[], member: any) => {
      votes.map((vote) => {
        if (vote.answer.comment || vote.answer.files.length > 0) {
          comments.push({
            ...member,
            question: getQuestionLabel(vote.uuid, this.flattenQuestions),
            comment: vote.answer.comment,
            files: vote.answer.files,
          });
        }
        if (vote.answer.questions && vote.answer.questions.length > 0) {
          eachMemberVote(vote.answer.questions, member);
        }
      });
    };

    this.result.votes.map((memberVote: GetVote) => {
      const { vote, ...other } = memberVote;
      eachMemberVote(vote, other);
    });

    const arr: any[] = [];
    const groups = _.groupBy(comments, "member_id");
    _.forEach(groups, (items, memberId: any) => {
      const memberIndex = _.findIndex(this.result.members, {
        id: memberId * 1,
      });
      arr.push({
        member: _.get(this.result.members, memberIndex, null),
        comments: items,
      });
    });

    return arr;
  }

  protected prepareCommentsFromCommenters(): any[] {
    const arr: any[] = [];
    const groups = _.groupBy(this.result.comments, "member_id");
    _.forEach(groups, (items, memberId: any) => {
      const memberIndex = _.findIndex(this.result.members, {
        id: memberId * 1,
      });
      arr.push({
        member: _.get(this.result.members, memberIndex, null),
        comments: items,
      });
    });

    return arr;
  }
}
