<template>
  <div>
    <Confirm ref="confirmRef" />
    <EmailToMembers ref="sendEmailRef" />
    <IframeViewer />

    <div
      class="h-12 overflow-hidden overflow-ellipsis whitespace-nowrap"
      v-if="isTestSystem"
    >
      <div
        class="fixed top-0 left-0 w-full bg-yellow-600 text-white text-center font-semibold p-3"
        style="z-index: 110"
      >
        You are using the test system!
      </div>
    </div>

    <MobileMenu />
    <div
      class="side-nav__template"
      :class="{ 'side-nav--simple': $store.state.main.simpleMenu }"
    >
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav row-span-full pt-10 relative" ref="sideNavHTML">
        <!--        <button-->
        <!--          class="hidden xl:block button bg-gradient-to-b from-gray-100 to-gray-300 rounded-full w-10 h-10 shadow-xl p-2 absolute -right-16 mr-1 top-28 z-10"-->
        <!--          @click="-->
        <!--            $store.dispatch(-->
        <!--              'main/SET_SIMPLE_MENU',-->
        <!--              !$store.state.main.simpleMenu-->
        <!--            )-->
        <!--          "-->
        <!--        >-->
        <!--          <ArrowLeftIcon class="w-6 h-6" v-if="!$store.state.main.simpleMenu" />-->
        <!--          <ArrowRightIcon class="w-6 h-6" v-else />-->
        <!--        </button>-->
        <!-- BEGIN: Logo -->
        <router-link
          :to="{ name: 'dashboard' }"
          class="intro-x flex items-center pt-4"
        >
          <img class="w-28 mx-auto" src="@/assets/images/logo-white.svg" />
        </router-link>
        <!-- END: Logo -->
        <div class="avatar--container">
          <div class="avatar--ring-1">
            <div class="avatar--ring-2">
              <div class="dropdown w-full h-full">
                <a
                  class="dropdown-toggle w-full h-full flex items-center justify-center relative z-10"
                  href="javascript:;"
                >
                  <img class="avatar" :src="gravatar" />
                </a>
                <div class="dropdown-box w-40">
                  <div class="dropdown-box__content box dark:bg-dark-1 p-2">
                    <router-link
                      :to="{ name: 'users.view', params: { id: profile.id } }"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      <UserIcon class="w-4 h-4 mr-2" /> My Profile
                    </router-link>
                    <router-link
                      :to="{ name: 'organizations.view', params: { id: profile.administrated_by_id } }"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      <SvgIconOrganization class="w-4 h-4 mr-2" /> My Organization
                    </router-link>
                    <router-link
                      :to="{ name: 'feedbacks.create' }"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                    >
                      <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Feedback
                    </router-link>
                    <a
                      href="javascript:;"
                      class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                      data-dismiss="dropdown"
                      @click="$store.dispatch('auth/LOGOUT')"
                    >
                      <TrashIcon class="w-4 h-4 mr-2" /> Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="avatar--name">
              Welcome, <span class="font-extralight">{{ nameSurname }}</span>
            </div>
          </div>
        </div>
        <div class="avatar--devider"></div>
        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-10"
            ></li>
            <li v-else :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                href="javascript:;"
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown,
                }"
                @click="linkTo(menu, router)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title text-xl">
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
                <div class="side-menu__description font-light text-xs">
                  {{ menu.description }}
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      href="javascript:;"
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router)"
                    >
                      <div class="side-menu__icon">
                        <component :is="subMenu.icon" />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown,
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Top Bar -->
      <div
        class="hidden md:block text-lg text-white text-right font-light py-3 pr-5"
        id="navbar"
        ref="navbarHTML"
      >
        The Standards and Metrology Institute for Islamic Countries
      </div>
      <!-- END: Top Bar -->
      <!-- BEGIN: Content -->
      <div class="content" id="content" ref="contentHTML">
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  provide,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper, helper as $h } from "@/utils/helper";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "./index";
import Confirm from "@/components/confirm/Main.vue";
import IframeViewer from "@/components/iframe-viewer/Main.vue";
import { ConfirmRef } from "@/components/confirm";
import md5 from "blueimp-md5";
import EmailToMembers from "@/components/email-to-members/Main.vue";
import { SendEmailRef } from "@/components/email-to-members";
import { NODE_ENV } from "@/services";

export default defineComponent({
  components: {
    EmailToMembers,
    Confirm,
    // TopBar,
    MobileMenu,
    SideMenuTooltip,
    IframeViewer,
  },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("login")
        .removeClass("error-page")
        .addClass("app");
    });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const contentHTML = ref<HTMLElement>();
    const sideNavHTML = ref<HTMLElement>();
    const navbarHTML = ref<HTMLElement>();

    const confirmRef = ref<ConfirmRef>();
    provide("confirmRef", confirmRef);

    const sendEmailRef = ref<SendEmailRef>();
    provide("sendEmailRef", sendEmailRef);

    const profile = store.getters["auth/profile"];

    const nameSurname = computed(() => {
      return helper.formatNameSurname(profile)
      // return `${profile?.salutation} ${profile?.name} ${profile?.surname}`;
    });

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    );

    const isTestSystem = computed(() => NODE_ENV === "staging");

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(sideMenu.value);
      window.addEventListener("resize", handleWindowResize);
      handleWindowResize();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleWindowResize);
    });

    const handleWindowResize = () => {
      if (contentHTML.value) {
        if (navbarHTML.value) {
          contentHTML.value.style.top = `${
            navbarHTML.value.offsetTop + navbarHTML.value.offsetHeight
          }px`;
        }
        if (sideNavHTML.value) {
          contentHTML.value.style.left = `${
            sideNavHTML.value.offsetLeft + sideNavHTML.value.offsetWidth + 4
          }px`;
        }
      }
    };

    watch(() => store.state.main.simpleMenu, handleWindowResize, {
      flush: "post",
    });

    return {
      contentHTML,
      navbarHTML,
      sideNavHTML,
      nameSurname,
      gravatar,
      confirmRef,
      sendEmailRef,
      formattedMenu,
      router,
      profile,
      isTestSystem,
      linkTo,
      enter,
      leave,
    };
  },
});
</script>
