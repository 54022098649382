<template>
  <div class="section-divider my-4">Comments From Voters</div>
  <div class="overflow-auto">
    <table class="min-w-full border border-gray-300">
      <thead>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-normal"
        >
          Member
        </th>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-normal"
        >
          Comments
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in rows"
          :key="`data-comment-${rowIndex}`"
        >
          <td
            class="border-b border-r border-gray-300 px-3 py-1.5 text-left font-normal whitespace-nowrap"
          >
            <div>
              {{ $_.get(row, "member.country_title", "") }} ({{
                $_.get(row, "member.acronym", "")
              }})
            </div>
            <div class="text-xs">
              {{ $_.get(row, "comments.0.user_name", "") }}
            </div>
          </td>
          <td class="border-b border-r border-gray-300 px-3 py-1.5 font-normal">
            <div
              v-for="(comment, commentIndex) in $_.get(row, 'comments', [])"
              :key="`data-comment-item-${commentIndex}`"
              class="text-xs mb-3"
            >
              <div class="font-semibold">Comment To {{comment.question}}</div>
              <div class="mt-2" v-if="comment.comment">
                {{ comment.comment }}
              </div>
              <div class="mt-1" v-if="comment.files.length > 0">
                <router-link
                  v-for="file in $_.get(comment, 'files', [])"
                  :key="file"
                  :to="{
                    name: 'documents',
                    params: { uuid: $_.get(file, 'uuid', '') },
                  }"
                  target="_blank"
                  class="block text-xs mt-1 text-blue-500 hover:text-blue-600"
                  >{{ $_.get(file, "title", "") }}
                </router-link>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CommentsFromVoters",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
