// Create initial state
import { useStore } from "@/store";

export const state = {
  menu: [
    {
      icon: "SvgIconDocuments",
      pageName: "documents",
      title: "Document",
      description: "Documents archive for standards development",
    },
    {
      icon: "SvgIconList",
      pageName: "committees",
      title: "Committees",
      description: "Database for SMIIC Organs and Committees",
    },
    {
      icon: "SvgIconUsers",
      pageName: "meetings",
      title: "Meetings",
      description: "Storing meeting records of SMIIC Organs and Committees",
    },
    {
      icon: "SvgIconFolder",
      pageName: "ballots",
      title: "Ballots",
      description: "Managing balloting process in standards development",
    },
    {
      icon: "SvgIconTable",
      pageName: "projects",
      title: "Projects",
      description: "Managing SMIIC Committees’ projects",
    },
    {
      icon: "SvgIconUser",
      pageName: "users",
      title: "Users",
      description: "Registration of users and allocation of roles",
    },
    {
      icon: "SettingsIcon",
      pageName: "",
      title: "Administrative",
      description:
        "Management and coordination area for SMIIC General Secretariat",
      subMenu: [
        {
          icon: "SvgIconOrganization",
          pageName: "organizations",
          title: "Organizations",
        },
        {
          icon: "GlobeIcon",
          pageName: "countries",
          title: "Countries",
        },
        {
          icon: "ListIcon",
          pageName: "submissions",
          title: "Submissions",
          permission: (): boolean => {
            return useStore().getters["auth/canManageFeedback"];
          },
        },
        {
          icon: "MessageCircleIcon",
          pageName: "feedbacks",
          title: "Feedbacks",
          permission: (): boolean => {
            return useStore().getters["auth/canManageFeedback"];
          },
        },
        {
          icon: "ActivityIcon",
          pageName: "documents.logs",
          title: "Document Logs",
          permission: (): boolean => {
            return useStore().getters["auth/canAudit"];
          },
        },
        {
          icon: "AtSignIcon",
          pageName: "emails.blacklist",
          title: "Email Blacklist",
          permission: (): boolean => {
            return useStore().getters["auth/canAudit"];
          },
        },
        {
          icon: "SvgIconWorldCircle",
          pageName: "tasks.logs",
          title: "To-do Logs",
          permission: (): boolean => {
            return useStore().getters["auth/canAudit"];
          },
        },
        {
          icon: "FileTextIcon",
          pageName: "audits",
          title: "Audits",
          permission: (): boolean => {
            return useStore().getters["auth/canAudit"];
          },
        },
        {
          icon: "FileTextIcon",
          pageName: "conversation",
          title: "AI Conversation",
          permission: (): boolean => {
            return useStore().getters["auth/canAudit"];
          },
        },
      ],
    },
  ],
};

// Declare state
export type State = typeof state;
