import _ from "lodash";

export class ValidationErrors {
  errors: any = {};

  constructor() {
    this.errors = {};
  }

  has(field: string): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.errors.hasOwnProperty(field);
  }

  any(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  get(...args: any): string[] | any {
    const fields: string[] = [];
    for (let i = 0; i < args.length; i++) {
      // eslint-disable-next-line prefer-rest-params
      fields.push(args[i]);
    }

    if (fields.length === 0) {
      return this.errors;
    }

    let errors: string[] = [];
    fields.map((field: string) => {
      if (_.has(this.errors, field)) {
        errors = errors.concat(_.get(this.errors, [field], []));
      }
    });
    return errors.length > 0 ? errors : null;
  }

  first(field: string): string {
    if (field === null) {
      return "";
    }
    return _.get(this.errors, [field, 0], "");
  }

  record(errors: unknown): void {
    this.errors = errors;
  }

  clear(field?: string): void {
    let errors = _.cloneDeep(this.errors);
    if (field) _.unset(errors, field);
    else errors = {};
    this.errors = errors;
  }
}
