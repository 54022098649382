
import { defineComponent } from "vue";

interface Table {
  rows: [];
  headers: [];
  footers: [];
}

export default defineComponent({
  name: "CastedVotes",
  props: {
    table: {
      type: Object as () => Table,
    },
  },
});
