<template>
  <svg
    width="64"
    height="48"
    viewBox="0 0 64 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0C13.9778 0 15.9112 0.586491 17.5557 1.6853C19.2002 2.78412 20.4819 4.34591 21.2388 6.17317C21.9957 8.00043 22.1937 10.0111 21.8079 11.9509C21.422 13.8907 20.4696 15.6725 19.0711 17.0711C17.6725 18.4696 15.8907 19.422 13.9509 19.8079C12.0111 20.1937 10.0004 19.9957 8.17317 19.2388C6.3459 18.4819 4.78412 17.2002 3.6853 15.5557C2.58649 13.9112 2 11.9778 2 10C2 7.34784 3.05357 4.8043 4.92893 2.92893C6.8043 1.05357 9.34784 0 12 0ZM29 23C29 24.3845 29.4105 25.7378 30.1797 26.889C30.9489 28.0401 32.0421 28.9373 33.3212 29.4672C34.6003 29.997 36.0078 30.1356 37.3656 29.8655C38.7235 29.5954 39.9708 28.9287 40.9497 27.9497C41.9287 26.9708 42.5954 25.7235 42.8655 24.3656C43.1356 23.0078 42.997 21.6003 42.4672 20.3212C41.9373 19.0421 41.0401 17.9489 39.889 17.1797C38.7378 16.4105 37.3845 16 36 16C34.1435 16 32.363 16.7375 31.0503 18.0503C29.7375 19.363 29 21.1435 29 23ZM20.79 24H3.21C2.35865 24 1.54218 24.3382 0.940187 24.9402C0.338195 25.5422 0 26.3587 0 27.21L0 48H24V27.21C24 26.3587 23.6618 25.5422 23.0598 24.9402C22.4578 24.3382 21.6413 24 20.79 24ZM41.71 32H30.29C29.6835 32.0026 29.1025 32.2447 28.6736 32.6736C28.2447 33.1025 28.0026 33.6835 28 34.29V48H44V34.29C43.9974 33.6835 43.7553 33.1025 43.3264 32.6736C42.8975 32.2447 42.3165 32.0026 41.71 32ZM56 16C54.6155 16 53.2622 16.4105 52.111 17.1797C50.9599 17.9489 50.0627 19.0421 49.5328 20.3212C49.003 21.6003 48.8644 23.0078 49.1345 24.3656C49.4046 25.7235 50.0713 26.9708 51.0503 27.9497C52.0292 28.9287 53.2765 29.5954 54.6344 29.8655C55.9922 30.1356 57.3997 29.997 58.6788 29.4672C59.9579 28.9373 61.0511 28.0401 61.8203 26.889C62.5895 25.7378 63 24.3845 63 23C63 21.1435 62.2625 19.363 60.9497 18.0503C59.637 16.7375 57.8565 16 56 16ZM61.71 32H50.29C49.6835 32.0026 49.1025 32.2447 48.6736 32.6736C48.2447 33.1025 48.0026 33.6835 48 34.29V48H64V34.29C63.9974 33.6835 63.7553 33.1025 63.3264 32.6736C62.8975 32.2447 62.3165 32.0026 61.71 32Z"
      fill="currentColor"
    />
  </svg>
</template>
