<template>
  <!-- BEGIN: Table -->
  <div class="accordion__pane py-4" v-if="list.length > 0">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
    >
      Relations ({{ list.length }})
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div class="overflow-x-auto py-6">
        <table class="table w-full">
          <thead>
          <tr>
            <th class="whitespace-nowrap">Title</th>
            <th class="whitespace-nowrap">Meeting Place</th>
            <th class="whitespace-nowrap">Time</th>
            <th class="whitespace-nowrap">Status</th>
            <th class="whitespace-nowrap text-right">Functions</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="row in list" :key="'relation-' + row.id">
            <td>{{ row.title }}</td>
            <td class="whitespace-nowrap">
              <div v-if="$_.get(row, 'type', '') === 'offline'">
                {{
                  $_.get(
                    row,
                    "country.title",
                    $_.get(row, "country_code", "")
                  )
                }}
                /
                {{ $_.get(row, "city") }}
              </div>
              <div v-else>Online</div>
            </td>
            <td>{{ $h.formatDate($_.get(row, "date"), "DD MMMM YYYY") }}</td>
            <td>{{ row.status }}</td>
            <td class="text-right whitespace-nowrap">
              <Tippy
                tag="button"
                content="See Details"
                :options="{ placement: 'bottom' }"
                class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                @click="() => goToView(row.id)"
              >
                <EyeIcon class="inline w-5 h-5" />
              </Tippy>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- END: Table -->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { fetchSelection } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Relations",
  props: {
    meetingId: {
      required: true,
    },
    committeeId: {
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const router = useRouter();
    const list = ref<Record<string, any>>([]);
    const fetch = () => {
      if (props.committeeId) {
        fetchSelection({
          meeting: {
            "@get": true,
            "@select": [
              "id",
              "title",
              "city",
              "country_code",
              "time",
              "status",
              "type",
            ],
            "@where": {
              committee_id: props.committeeId,
            },
            "@whereNot": {
              id: props.meetingId,
            },
          },
        }).then((result) => {
          if (result.kind === "ok") {
            list.value = result.data.meeting;
          }
        });
      }
    };

    const goToView = (value: any) => {
      router.push({ name: "meetings.view", params: { id: value } });
    };

    watch(() => props.committeeId, fetch, { flush: "post" });
    onMounted(() => {
      fetch();
    });

    return {
      list,
      goToView,
    };
  },
});
</script>
