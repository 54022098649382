
import { defineComponent, ref, watch } from "vue";
import BallotEditInformation from "./Edit.vue";
import BallotViewInformation from "./View.vue";
import { GetBallot } from "@/services";

export default defineComponent({
  name: "BallotInformation",
  components: { BallotViewInformation, BallotEditInformation },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props, { emit }) {
    const visibleEditComponent = ref<boolean>(false);
    watch(visibleEditComponent, (val) => emit("update:active-editable", val));
    return {
      visibleEditComponent,
    };
  },
});
