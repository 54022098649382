
import { defineComponent, onMounted, ref } from "vue";
import {
  DOCUMENT_GROUP_TYPES,
  fetchSelection,
  GetCommittee,
  GetDocument,
} from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Library",
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const loading = ref<boolean>(true);
    const list = ref<GetDocument[]>([]);

    const fetch = () => {
      loading.value = true;
      fetchSelection({
        document: {
          "@get": true,
          "@select": [
            "id",
            "folder_id",
            "title",
            "type",
            "committee_id",
            "created_at",
          ],
          "@func": ["onlyFolders"],
          "@where": {
            committee_id: props.committeeId,
          },
        },
      })
        .then((result) => {
          if (result.kind === "ok") {
            const data = _.get(
              result.data,
              "document.data",
              []
            ) as GetDocument[];
            const parentFolderIndex = _.findIndex(data, {
              type: DOCUMENT_GROUP_TYPES.ROOT,
            });
            list.value = data.filter(
              (item) =>
                item.folder_id === _.get(data, [parentFolderIndex, "id"], 0)
            );
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const goToFolder = (folderId: number) => {
      router.push({ name: "documents", query: { folderId } });
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      list,
      goToFolder,
    };
  },
});
