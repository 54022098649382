import dayjs from "dayjs";
import { Litepicker } from "litepicker";
import { watch } from "vue";

interface Props {
  options: any;
  modelValue: string;
}

type LitepickerInstance = {
  litepicker: any;
};

const getDateFormat = (format: string) => {
  return format !== undefined ? format : "YYYY-MM-DD";
};

const getDisplayFormat = (format: string) => {
  return format !== undefined ? format : "D MMM, YYYY";
};

const init = (
  el: HTMLElement & LitepickerInstance,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  const format = getDateFormat(props.options.format);
  const { dropdowns = {} } = props.options;
  el.litepicker = new Litepicker({
    element: el,
    resetButton: true,
    ...props.options,
    format,
    timePicker: true,
    dropdowns: {
      minYear: new Date().getFullYear() - 25,
      maxYear: new Date().getFullYear() + 25,
      months: true,
      years: true,
      ...dropdowns,
    },
    setup: (picker: any) => {
      picker.on(
        "selected",
        (
          startDate: { dateInstance: Date },
          endDate: { dateInstance: Date }
        ) => {
          let date = startDate
            ? dayjs(startDate.dateInstance).format(format)
            : "";
          date +=
            endDate !== undefined
              ? " - " + dayjs(endDate.dateInstance).format(format)
              : "";
          emit("update:modelValue", date);
        }
      );
    },
  });
  watch(
    () => props.modelValue,
    (val) => {
      if (val) el.litepicker.setDate(val);
      else el.litepicker.clearSelection();
    },
    { flush: "post" }
  );
};

const reInit = (
  el: HTMLElement & LitepickerInstance,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  if (el.litepicker) el.litepicker.destroy();
  init(el, props, emit);
};

export { init, reInit };
