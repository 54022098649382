
import { computed, defineComponent, onMounted, watch } from "vue";
import Information from "./Information.vue";
import ParticipatingRoles from "./ParticipatingRoles.vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import ViewCommitteeLiaisons from "@/views/organizations/view/CommitteeLiaisons.vue";
import OrganizationUsers from "@/views/organizations/view/OrganizationUsers.vue";

export default defineComponent({
  name: "ViewOrganization",
  components: {
    OrganizationUsers,
    ViewCommitteeLiaisons,
    Information,
    ParticipatingRoles,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      errorCallback() {
        router.go(-1);
      },
      selectionQuery: {
        source: "organization",
        config: {
          "@select": [
            "id",
            "title",
            "acronym",
            "information",
            "contact",
            "description",
            "visible_on_website",
            "is_accreditation_body",
            "is_metrology_body",
            "is_standardization_body",
            "number_of_standards_adopted",
            "country_code",
            "created_at",
          ],
          country: {
            "@select": ["id", "title", "code"],
          },
          committees: {
            "@select": ["id", "title", "acronym", "type", "level_1_number"],
            "@order": [
              "type:asc",
              "level_1_number:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
          },
        },
      },
    });

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value)
        return `VIEW ORGANIZATION (#${id.value})`;
      return `(${data.value.acronym}) ${data.value.title}`;
    });
    const participatingRoles = computed<Record<string, any>[]>(() => {
      const committees = _.get(data.value, "committees", []);
      return committees.filter((committee: Record<string, any>) => {
        return _.get(committee, "pivot.member_type", "") === "P";
      });
    });
    const observationRoles = computed<Record<string, any>[]>(() => {
      const committees = _.get(data.value, "committees", []);
      return committees.filter((committee: Record<string, any>) => {
        return _.get(committee, "pivot.member_type", "") === "O";
      });
    });

    const canEdit = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_update", false);
    });

    const isLiaison = computed<boolean>(() => {
      return (
        !_.get(data.value, "is_accreditation_body", false) &&
        !_.get(data.value, "is_metrology_body", false) &&
        !_.get(data.value, "is_standardization_body", false)
      );
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      headerTitle,
      participatingRoles,
      observationRoles,
      detail: data,
      canEdit,
      isLiaison,
    };
  },
});
