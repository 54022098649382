
import { computed, defineComponent, onMounted, ref } from "vue";
import { useGeneralActions } from "@/utils/actions";
import {
  ConversationMessage,
  conversationService,
  GetConversationResult,
  PostConversation,
} from "@/services";
import md5 from "blueimp-md5";
import { useStore } from "@/store";
const messageList = [
  "Thank you for your patience! SMIIC IS - AI Assistant is working diligently to provide you with the best response possible.",
  "Hang in there! SMIIC IS - AI Assistant is processing your request and will be with you shortly.",
  "We appreciate your understanding as SMIIC IS - AI Assistant is working behind the scenes to find the perfect answer for you.",
  "Your inquiry is important to us! SMIIC IS - AI Assistant is working hard to deliver an informative response.",
  "Please bear with us! SMIIC IS - AI Assistant is actively searching for the best solution to your question.",
  "While you wait, here's a fun fact: Did you know that SMIIC IS - AI Assistant can process millions of pieces of information in a matter of seconds?",
  "We understand your eagerness! SMIIC IS - AI Assistant is doing its best to ensure a thorough and helpful response.",
  "Rest assured, SMIIC IS - AI Assistant is working diligently in the background to give you a prompt and accurate answer.",
  "We appreciate your patience! SMIIC IS - AI Assistant is continuously learning to provide even better responses in the future.",
];

export default defineComponent({
  name: "Conversation",
  setup() {
    const store = useStore();
    const profile = store.getters["auth/profile"];

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    const headerTitle = computed<string>(() => {
      return "AI Conversation";
    });

    const messages = ref<ConversationMessage[]>([]);
    const waitingMessage = ref<string>("");
    waitingMessage.value =
      messageList[Math.floor(Math.random() * messageList.length)];

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: conversationService.show,
      errorCallback() {
        // router.go(-1);
      },
      successCallback() {
        waitingMessage.value =
          messageList[Math.floor(Math.random() * messageList.length)];
        messages.value = data.value.messages || [];
        setFormData({
          message: "",
          conversation_id: data.value.id,
          scope_id: data.value.scope_id,
        });
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: conversationService.store,
      successCallback(result: GetConversationResult) {
        if (result.kind === "ok") {
          window.localStorage.setItem("conversationId", result.data.id as any);
          formData.conversation_id = result.data.id;
          formData.message = "";
          messages.value = [...messages.value, ...(result.data.messages || [])];
        }
      },
      params: {
        message: "",
        scope_id: 1,
        conversation_id: "",
      } as PostConversation,
      removeKeysIfEmptyValue: ["conversation_id", "scope_id"],
    });

    onMounted(() => {
      formData.conversation_id =
        window.localStorage.getItem("conversationId") || null;
      if (formData.conversation_id) fetch(formData.conversation_id).finally();
    });

    const onSubmit = () => submit();

    const onClear = () => {
      window.localStorage.removeItem("conversationId");
      messages.value = [];
      setFormData({
        message: "",
        conversation_id: "",
        scope_id: formData.scope_id,
      });
    };

    return {
      headerTitle,
      messages,
      waitingMessage,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit,
      onClear,
      gravatar,
    };
  },
});
