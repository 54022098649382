<template>
  <svg
    width="65"
    height="67"
    viewBox="0 0 65 67"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63.2276 26.1315L63.2273 26.1312L36.1416 1.42122C35.1468 0.506998 33.8467 -6.28788e-05 32.4978 5.84841e-09C31.1488 6.28905e-05 29.8488 0.507245 28.8541 1.42156L1.77272 26.1312C1.2149 26.6411 0.769148 27.2621 0.463919 27.9546C0.158691 28.6471 0.000689219 29.3958 0 30.153V61.5644C0.00164239 63.0055 0.572853 64.3871 1.58832 65.4061C2.60379 66.4251 3.98058 66.9984 5.41667 67H59.5833C61.0194 66.9984 62.3962 66.4251 63.4117 65.4061C64.4271 64.3871 64.9984 63.0055 65 61.5644V30.1532C64.9993 29.396 64.8413 28.6474 64.5362 27.9549C64.231 27.2625 63.7853 26.6415 63.2276 26.1315Z"
      fill="currentColor"
    />
  </svg>
</template>
