<template>
  <div class="accordion__pane py-4">
    <div class="flex justify-between">
      <a
        href="javascript:;"
        class="accordion__pane__toggle relative font-medium text-2xl text-gray-600 block pr-24 whitespace-nowrap overflow-hidden overflow-ellipsis"
      >
        Organization Users
        <template v-if="!loading"
          >({{ $_.get(list, "data", []).length }})</template
        >
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <Tippy
        tag="button"
        content="Export Roles"
        :options="{ placement: 'bottom' }"
        class="dropdown-toggle button p-0 absolute right-4"
        @click="exportFile"
      >
        <FileIcon class="w-8 h-8" />
      </Tippy>
    </div>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <!-- BEGIN: Table -->
      <div
        v-if="$_.get(list, 'data', []).length > 0"
        class="accordion without--bar divide-y"
      >
        <div
          v-for="user in $_.get(list, 'data', [])"
          :key="`user-${user.id}`"
          class="accordion__pane"
        >
          <a
            href="javascript:;"
            class="accordion__pane__toggle font-medium text-gray-600 flex items-center py-1.5"
          >
            <div class="flex-1">
              <div class="leading-4">
                {{ $h.formatNameSurname(user) }}
              </div>
              <div class="text-xs">{{ user.email }}</div>
            </div>
            <div>{{ $_.get(user, "roles", []).length }} Roles</div>
          </a>
          <div
            class="accordion__pane__content pb-3 leading-relaxed"
            v-if="$_.get(user, 'roles', []).length > 0"
          >
            <table class="table w-full text-xs" style="border-spacing: 0 3px">
              <thead>
                <th class="!font-medium">Role</th>
                <th class="!font-medium">Resource</th>
              </thead>
              <tbody>
                <tr
                  v-for="role in $_.get(user, 'roles', [])"
                  :key="`role-${user.id}-${role.id}`"
                >
                  <td class="!py-2.5">
                    <div class="mb-0.5">{{ $_.get(role, "title") }}</div>
                    <div>
                      <small
                        v-for="ballotType in $_.get(role, 'ballot_types', [])"
                        :key="`role-${user.id}-${role.id}-${ballotType}`"
                        class="mr-0.5 bg-yellow-200 px-2.5 py-0.5 rounded-xl"
                        >{{ ballotType }}</small
                      >
                    </div>
                  </td>
                  <td class="!py-2.5">
                    {{ $_.get(role, "scope.acronym", "") }}
                    {{ $_.get(role, "scope.title", "") }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else class="text-lg text-gray-500">
        No users found for this organization!
      </div>
    </div>
  </div>
  <!-- END: Table -->
</template>

<script lang="ts">
import { defineComponent, onMounted, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { OrganizationUsersPDFCreation } from "@/utils/pdf-creation/organization-users";
import { GetOrganization } from "@/services";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<{ data: Record<string, any>[] }>;
}

export default defineComponent({
  name: "OrganizationUsers",
  props: {
    organization: {
      type: Object as () => GetOrganization,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();

    const { fetch, loading, data: list } = fetchRecord({
      selectionQuery: {
        source: "user",
        config: {
          "@get": true,
          "@select": ["id", "salutation", "name", "surname", "email"],
          "@where": {
            administrated_by_id: props.organization.id,
          },
          "@order": "created_at:desc",
          "@func": ["withRoles"],
        },
      },
    }) as FetchRecord;

    const goToView = (value: any) => {
      router.push({ name: "users.view", params: { id: value } });
    };

    const exportFile = () => {
      console.log("exportFile");

      new OrganizationUsersPDFCreation()
        .process({
          organization: props.organization,
          users: _.get(list.value, "data", []),
        })
        .then((pdfCreation) => {
          pdfCreation.output().then((url) => {
            router.push({
              query: { ...route.query, iUrl: url },
            });
          });
        });
    };

    onMounted(() => {
      fetch({});
    });

    return {
      loading,
      list,
      goToView,
      exportFile,
    };
  },
});
</script>
