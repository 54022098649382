
import { defineComponent } from "vue";

export default defineComponent({
  name: "Questions",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
});
