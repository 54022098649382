<template>
  <svg
    width="57"
    height="55"
    viewBox="0 0 57 55"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M56.5143 51.137C52.4909 44.2072 46.0476 39.0097 38.4234 36.5438C42.0759 34.3706 44.9139 31.0583 46.5016 27.1159C48.0893 23.1734 48.3388 18.8187 47.2118 14.7207C46.0848 10.6227 43.6437 7.00796 40.2633 4.43173C36.883 1.85549 32.7504 0.46022 28.5002 0.46022C24.25 0.46022 20.1174 1.85549 16.7371 4.43173C13.3567 7.00796 10.9156 10.6227 9.7886 14.7207C8.66163 18.8187 8.91114 23.1734 10.4988 27.1159C12.0865 31.0583 14.9245 34.3706 18.577 36.5438C10.9531 39.0097 4.50988 44.207 0.486442 51.1364C0.296794 51.4642 0.196804 51.8361 0.196534 52.2148C0.196263 52.5934 0.295721 52.9655 0.484901 53.2935C0.674081 53.6216 0.946311 53.894 1.27421 54.0834C1.6021 54.2728 1.97409 54.3725 2.35276 54.3725L54.648 54.373C55.0267 54.3731 55.3987 54.2734 55.7266 54.084C56.0545 53.8946 56.3268 53.6221 56.5159 53.2941C56.7051 52.9661 56.8046 52.594 56.8043 52.2153C56.804 51.8366 56.704 51.4647 56.5143 51.137L56.5143 51.137Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
