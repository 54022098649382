<template>
  <div class="section-divider mb-4">Result</div>
  <div class="overflow-auto mb-8">
    <table class="min-w-full border border-gray-300 text-center">
      <tbody>
        <tr v-if="isSMIICBallot">
          <td class="border-b border-r border-gray-300 p-3 font-normal">
            {{ result.smiic_result }}
          </td>
        </tr>
        <tr>
          <td class="border-b border-r border-gray-300 p-3 font-normal">
            {{ result.committee_result }}
          </td>
        </tr>
        <tr>
          <td
            class="bg-blue-50 border-b border-r border-gray-300 p-3 font-bold text-lg"
          >
            {{ result.result }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "BallotResult",
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
    ballotType: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const isSMIICBallot = computed(
      () => ["SMIIC", "DS", "FDS"].indexOf(props.ballotType) > -1
    );
    return {
      isSMIICBallot,
    };
  },
});
</script>
