import {
  getGeneralApiProblem,
  useAxios,
  GetEmailResult,
  GeneralApiResponse,
} from "@/services";
import { AxiosError } from "axios";

export * from "./api.types";

export const emailService = {
  store: async (
    committeeId: unknown,
    params: Record<string, any>
  ): Promise<GetEmailResult> =>
    useAxios().store(`/api/email/${committeeId}/send`, params),

  preview: async (
    committeeId: unknown,
    params: Record<string, any>
  ): Promise<GetEmailResult> => {
    const result = await useAxios()
      .instance.post(`/api/email/${committeeId}/preview`, params, {
        responseType: "blob",
      })
      .catch((error) => error);

    if (result instanceof Error) {
      const blobData = _.get(result, "response.data", null);
      if (blobData) {
        const buffer = await (blobData as Blob).arrayBuffer();
        const res = JSON.parse(Buffer.from(buffer).toString("utf8"));
        _.set(result, "response.data", res);
      }
      return getGeneralApiProblem(result as AxiosError);
    }

    return {
      kind: "ok",
      data: result.data,
    };
  },
};

export const emailBlacklistService = {
  delete: async (email: string): Promise<GeneralApiResponse> =>
    useAxios().store(`/api/email/blacklist-removal`, { email }),
};
