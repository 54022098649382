
import { computed, defineComponent, inject, onMounted, Ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { DOCUMENT_TYPES, documentService, GetDocument } from "@/services";
import PdfView from "./preview/PdfView.vue";
import EmailView from "./preview/EmailView.vue";
import ImageView from "./preview/ImageView.vue";
import DocumentSummary from "./Summary.vue";
import dayjs from "dayjs";
import { ToastNotify } from "@/utils/toast-notify";
import { ConfirmRef } from "@/components/confirm";
import RecipientsList from "./Recipients.vue";
import DocumentsList from "./Documents.vue";
import { SendEmailRef } from "@/components/email-to-members";
import DocumentLogs from "@/views/documents/logs/List.vue";
import { useStore } from "@/store";
import _ from "lodash";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetDocument>;
}

export default defineComponent({
  name: "DocumentDetailView",
  components: {
    DocumentLogs,
    RecipientsList,
    DocumentsList,
    DocumentSummary,
    PdfView,
    EmailView,
    ImageView,
  },
  props: {
    documentUuid: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const router = useRouter();
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const { fetchRecord } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: () => documentService.show(props.documentUuid),
      errorCallback() {
        router.go(-1);
      },
    }) as FetchRecord;

    const isEmailView = computed<boolean>(
      () => data.value.type === DOCUMENT_TYPES.MAIL
    );

    const canUpdate = computed<boolean>(() =>
      _.get(data.value, "abilities.can_update", false)
    );

    watch(
      () => props.documentUuid,
      (val) => fetch(val),
      { flush: "post" }
    );
    onMounted(() => fetch(props.documentUuid));

    const onCancelDocument = () => {
      confirmRef?.value?.show({
        title: `Do you want to cancel this document?`,
        icon: "AlertCircleIcon",
        iconClass: "text-red-400",
        confirmText: "Yes",
        cancelText: "No",
        callback: () => {
          loading.value = true;
          documentService
            .cancel(data.value.uuid)
            .then((result) => {
              if (result.kind === "ok") {
                data.value.status = "cancelled";
                data.value.cancelled_at = dayjs(new Date()).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
              } else {
                ToastNotify({ text: result.message, className: "error" });
              }
            })
            .finally(() => {
              loading.value = false;
            });
        },
      });
    };

    const onPublishDocument = () => {
      confirmRef?.value?.show({
        title: `Do you want to publish this document?`,
        icon: "AlertCircleIcon",
        iconClass: "text-red-400",
        confirmText: "Yes",
        cancelText: "No",
        callback: () => {
          loading.value = true;
          documentService
            .publish(data.value.uuid)
            .then((result) => {
              if (result.kind === "ok") {
                data.value.status = "published";
                data.value.cancelled_at = "";
              } else {
                ToastNotify({ text: result.message, className: "error" });
              }
            })
            .finally(() => {
              loading.value = false;
            });
        },
      });
    };

    const onSendEmail = () => {
      sendEmailRef?.value.show({
        committee: data.value.committee,
        files: [
          {
            id: data.value.id,
            uuid: data.value.uuid,
            title: data.value.title,
            n_number: data.value.n_number,
            file_name: data.value.file_name,
            file_size: data.value.file_size,
            type: data.value.type,
          },
        ],
        success: () => {
          data.value.status = "published";
        },
      });
    };

    const onToggleFullScreen = () => {
      cash("#document--screen").fullScreen("toggle");
    };

    return {
      loading,
      isEmailView,
      canUpdate,
      document: data,
      onSendEmail,
      onCancelDocument,
      onPublishDocument,
      onToggleFullScreen,
    };
  },
});
