<template>
  <router-view />
</template>
<script lang="ts">
import { defineComponent, watch } from "vue";
import { useStore } from "@/store";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    watch(
      () => store.state.auth.visibleSplashScreen,
      (val) => {
        if (val) {
          router.push({
            name: "welcome",
          });
        }
      },
      {
        flush: "post",
      }
    );
  },
});
</script>
