<template>
  <div class="pb-8">
    <div class="grid grid-cols-2 gap-4">
      <div
        class="col-span-full md:col-span-1 shadow-xl rounded-xl border border-theme-6 p-6 text-center relative"
      >
        <div
          class="bg-theme-7 text-white p-1.4 w-16 rounded-l-full absolute right-10 top-5 mt-0.5 transform -rotate-90"
        >
          {{ $h.formatDate(detail.time, "ddd") }}
        </div>
        <div class="text-lg font-light">
          Date
          <span class="ml-1" v-if="detail.exact_date === 1"
            >(The Exact Date)</span
          >
        </div>
        <div class="text-8xl text-theme-4">
          {{ $h.formatDate(detail.time, "DD") }}
        </div>
        <div class="text-4xl text-gray-500">
          {{ $h.formatDate(detail.time, "MMM YYYY") }}
        </div>
        <div class="text-2xl text-gray-500 mt-1.5">
          {{ $h.formatDate(detail.time, "HH:mm") }} GMT
        </div>
      </div>
      <div
        class="col-span-full md:col-span-1 shadow-xl rounded-xl border border-theme-6 p-6 text-center relative"
      >
        <div class="text-lg font-light">Status</div>
        <div class="text-6xl text-theme-4">
          {{ detail.status || "-" }}
        </div>
        <div class="text-lg font-light mt-6">Committee</div>
        <div class="text-2xl text-theme-4">
          <template v-if="$_.get(detail, 'committee.acronym')">
            <router-link
              :to="{
                name: 'committees.view',
                params: { id: detail.committee_id },
              }"
              class="hover:text-blue-800"
            >
              ({{ $_.get(detail, "committee.acronym") }})
              {{ $_.get(detail, "committee.title") }}
            </router-link>
          </template>
          <template v-else>-</template>
        </div>
      </div>
    </div>
    <div class="mt-14">
      <div class="text-3xl text-theme-1 block">Information</div>
      <div class="mt-3 ProseMirror-Viewer" v-html="detail.information"></div>
      <div class="mt-6 border border-theme-6 rounded-lg flex overflow-hidden">
        <div class="bg-theme-6 text-theme-10 py-3 pl-6 pr-12">Location</div>
        <div class="py-3 px-6">
          <template v-if="$_.get(detail, 'type', '') === 'offline'">
            {{
              $_.get(
                detail,
                "country.title",
                $_.get(detail, "country_code", "")
              )
            }}
            /
            {{ $_.get(detail, "city") }}
          </template>
          <template v-else>Virtual</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GetMeeting } from "@/services";

export default defineComponent({
  name: "Information",
  props: {
    detail: {
      type: Object as () => GetMeeting,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
});
</script>
