<template>
  <div class="col-span-full grid grid-cols-12 gap-4">
    <VueInputGroup class="col-span-full">
      <template v-slot:label>
        <label class="block mb-1">Type</label>
      </template>
      <VueSelect :model-value="selectedCommitteeType" @change="changeType">
        <option value="TC">[L1] Technical Committee</option>
        <option value="OTHER">[L1] Other Committee</option>
        <option value="SC">[L2] Sub Committee</option>
        <option value="TC-WG">[L2] Working Group (TC / OTHER)</option>
        <option value="SC-WG">[L3] Working Group (SC)</option>
      </VueSelect>
    </VueInputGroup>
    <VueInputGroup class="col-span-full" v-if="showParentCommittee">
      <template v-slot:label>
        <label class="block mb-1">Select Parent Committee</label>
      </template>
      <Select2Input
        v-model="parentCommitteeId"
        :options="committeeSelect2Options"
        @update:selected-item="changeParentCommittee"
      />
    </VueInputGroup>
    <template v-if="showNumberAndAcronym">
      <VueInputGroup
        class="col-span-full sm:col-span-6"
        :validation-errors="
          validationErrors.get(
            'level_1_number',
            'level_2_number',
            'level_3_number'
          )
        "
      >
        <template v-slot:label>
          <label class="block mb-1">Number</label>
        </template>
        <div class="flex">
          <VueInput
            class="flex-1"
            :class="{ 'rounded-r-none': loadingNextAvailableNumber }"
            v-model="committeeNumber"
          />
          <div
            class="bg-gray-300 rounded-r-lg w-12 flex items-center justify-center"
            v-if="loadingNextAvailableNumber"
          >
            <LoaderIcon class="spin-5 inline-block w-5 h-5" />
          </div>
        </div>
        <div
          class="mt-2 text-xs text-gray-500 text-gray-500"
          v-if="nextAvailableNumber"
        >
          Next available number will be <b>{{ nextAvailableNumber }}</b>
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-6"
        :validation-errors="
          validationErrors.get(
            'acronym',
            'level_1_type',
            'level_2_type',
            'level_3_type'
          )
        "
      >
        <template v-slot:label>
          <label class="block mb-1">Acronym</label>
        </template>
        <VueInput
          v-model="acronym"
          :readonly="level1Type === 'TC'"
          :disabled="level1Type === 'TC'"
        />
        <div
          class="mt-2 text-xs text-gray-500 text-gray-500"
          v-if="level1Type === 'TC'"
        >
          The acronym will be created automatically.
        </div>
      </VueInputGroup>
    </template>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { committeeService, PostCommittee } from "@/services";
import { ValidationErrors } from "@/utils/form";
import { Select2Options } from "@/global-components/select2";

export default defineComponent({
  name: "CommitteeLevel",
  props: {
    committee: {
      required: true,
      type: Object as () => PostCommittee,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableCommittee = reactive(toRefs<PostCommittee>(props.committee));

    const whichLevel = ref<number>(1);
    const loadingNextAvailableNumber = ref<boolean>(false);
    const nextAvailableNumber = ref<any>(null);
    const selectedCommitteeType = ref<string>("TC");

    const parentCommitteeId = ref<any>(null);
    const parentCommittee = ref<any>(null);
    const level1Type = computed<string>(() => {
      const type = selectedCommitteeType.value === "OTHER" ? "OTHER" : "TC";
      return _.get(parentCommittee.value, "level.level_1_type", type);
    });

    const acronym = computed({
      get: () => {
        if (level1Type.value !== "TC") {
          return editableCommittee.acronym;
        }
        return generateAcronymFromTypeNumber();
      },
      set: (val: any) => {
        if (level1Type.value !== "TC") {
          editableCommittee.acronym = val;
        }
      },
    });
    const committeeNumber = computed({
      get: () => {
        switch (whichLevel.value) {
          case 3:
            return editableCommittee.level_3_number;
          case 2:
            return editableCommittee.level_2_number;
          default:
            return editableCommittee.level_1_number;
        }
      },
      set: (val: any) => {
        switch (whichLevel.value) {
          case 3:
            editableCommittee.level_3_number = val;
            break;
          case 2:
            editableCommittee.level_2_number = val;
            break;
          default:
            editableCommittee.level_1_number = val;
            break;
        }
      },
    });
    const committeeSelect2Options = computed<Select2Options>(() => {
      const where: any = {};
      switch (whichLevel.value) {
        case 3:
          _.set(where, "level", 2);
          _.set(where, "level_2_type", "SC");
          break;
        case 2:
          _.set(where, "level", 1);
          break;
      }
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
              level: "level",
              level_1_type: "level_1_type",
              level_1_number: "level_1_number",
              level_2_type: "level_2_type",
              level_2_number: "level_2_number",
              level_3_type: "level_3_type",
              level_3_number: "level_3_number",
            },
            "@where": where,
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
          },
        },
        templateSelection: (obj: any): string => {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });
    const showParentCommittee = computed(() => whichLevel.value > 1);
    const showNumberAndAcronym = computed(() => {
      return whichLevel.value === 1 || parentCommitteeId.value;
    });

    const changeParentCommittee = (committee: any) => {
      parentCommittee.value = committee;
      if (whichLevel.value > 1) {
        editableCommittee.level_1_type = _.get(
          committee,
          "level.level_1_type",
          null
        );
        editableCommittee.level_1_number = _.get(
          committee,
          "level.level_1_number",
          null
        );
      }
      if (whichLevel.value > 2) {
        editableCommittee.level_2_type = _.get(
          committee,
          "level.level_2_type",
          null
        );
        editableCommittee.level_2_number = _.get(
          committee,
          "level.level_2_number",
          null
        );
      }
      editableCommittee.acronym = generateAcronymFromTypeNumber();
      if (committee) fetchAvailableNumber();
    };

    const changeType = (e:any) => {
      selectedCommitteeType.value = e.target.value
      parentCommitteeId.value = null;
      parentCommittee.value = null;
      editableCommittee.level_1_type = null;
      editableCommittee.level_1_number = null;
      editableCommittee.level_2_type = null;
      editableCommittee.level_2_number = null;
      editableCommittee.level_3_type = null;
      editableCommittee.level_3_number = null;

      switch (selectedCommitteeType.value) {
        case "TC":
        case "OTHER":
          whichLevel.value = 1;
          editableCommittee.level_1_type = selectedCommitteeType.value;
          fetchAvailableNumber();
          break;
        case "SC":
        case "TC-WG":
          whichLevel.value = 2;
          editableCommittee.level_2_type =
            selectedCommitteeType.value === "SC" ? "SC" : "WG";
          break;
        case "SC-WG":
          whichLevel.value = 3;
          editableCommittee.level_3_type = "WG";
          break;
      }

      editableCommittee.acronym = generateAcronymFromTypeNumber();
    };

    const generateAcronymFromTypeNumber = (): string => {
      let acronym = "";
      if (whichLevel.value > 0)
        acronym += `${editableCommittee.level_1_type || ""}${
          editableCommittee.level_1_number || ""
        }`;
      if (whichLevel.value > 1)
        acronym += `/${editableCommittee.level_2_type || ""}${
          editableCommittee.level_2_number || ""
        }`;
      if (whichLevel.value > 2)
        acronym += `/${editableCommittee.level_3_type || ""}${
          editableCommittee.level_3_number || ""
        }`;
      return acronym;
    };

    const fetchAvailableNumber = () => {
      loadingNextAvailableNumber.value = true;
      const params: any = {};
      switch (selectedCommitteeType.value) {
        case "TC":
        case "OTHER":
          _.set(params, "type", selectedCommitteeType.value);
          break;
        case "SC":
          _.set(params, "type", "SC");
          break;
        case "TC-WG":
        case "SC-WG":
          _.set(params, "type", "WG");
          break;
      }
      if (parentCommitteeId.value)
        _.set(params, "parent", parentCommitteeId.value);
      committeeService
        .nextCommitteeNumber(params)
        .then((result) => {
          nextAvailableNumber.value = result.number;
          committeeNumber.value = result.number;
        })
        .finally(() => {
          loadingNextAvailableNumber.value = false;
        });
    };

    onMounted(() => {
      fetchAvailableNumber();
    });

    return {
      editableCommittee,
      acronym,
      committeeNumber,
      loadingNextAvailableNumber,
      nextAvailableNumber,
      selectedCommitteeType,
      level1Type,
      committeeSelect2Options,
      showParentCommittee,
      showNumberAndAcronym,
      parentCommittee,
      parentCommitteeId,
      changeParentCommittee,
      changeType,
    };
  },
});
</script>
