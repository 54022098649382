
import { defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee } from "@/services";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CommitteeMeetings",
  components: { Pagination },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "meeting",
        config: {
          "@paginate": 5,
          "@page": 1,
          "@select": [
            "id",
            "committee_id",
            "status",
            "title",
            "city",
            "country_code",
            "time",
            "type",
          ],
          "@where": {
            committee_id: _.get(props, "committee.id", props.committeeId),
          },
          "@func": ["withCount"],
          "@order": "time:desc",
          country: {
            "@select": ["id", "title", "code"],
          },
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    const goToView = (value: any) => {
      router.push({ name: "meetings.view", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      changePage,
      changePerPage,
      goToView,
    };
  },
});
