
import { computed, defineComponent } from "vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "AnswerViewItem",
  props: {
    answer: { type: Object, required: true },
    path: { type: Array, required: true },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const label = computed(() => {
      const sq: any[] = [];
      props.path.map((val: any, index) => {
        if (_.get(props.path, index - 1, "") === "answers")
          sq.push(val * 1 + 1);
      });
      return `A.${sq.join(".")})`;
    });

    return {
      label,
    };
  },
});
