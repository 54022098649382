import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_TOKEN](
    { commit }: AugmentedActionContext,
    token: string
  ): void;
  [ActionTypes.SET_PROFILE](
    { commit }: AugmentedActionContext,
    data: any
  ): void;
  [ActionTypes.SET_SPLASH_SCREEN](
    { commit }: AugmentedActionContext,
    data: boolean
  ): void;
  [ActionTypes.LOGOUT]({ commit }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_TOKEN]({ commit }, data: string) {
    window.localStorage.setItem("auth_token", data);
    commit(MutationTypes.SET_TOKEN, data);
    commit(MutationTypes.SET_SPLASH_SCREEN, true);
  },
  [ActionTypes.SET_PROFILE]({ commit }, data: any) {
    try {
      window.localStorage.setItem(
        "auth_info",
        // eslint-disable-next-line node/no-deprecated-api
        new Buffer(JSON.stringify(data), "utf-8").toString("base64")
      );
    } catch (e) {}
    commit(MutationTypes.SET_PROFILE, data);
  },
  [ActionTypes.SET_SPLASH_SCREEN]({ commit }, data: boolean) {
    commit(MutationTypes.SET_SPLASH_SCREEN, data);
  },
  [ActionTypes.LOGOUT]({ commit }) {
    window.localStorage.removeItem("auth_token");
    window.localStorage.removeItem("auth_info");
    commit(MutationTypes.SET_TOKEN, "");
    commit(MutationTypes.SET_PROFILE, "");
    commit(MutationTypes.SET_SPLASH_SCREEN, true);
  },
};
