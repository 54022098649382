import { GeneralApiResponse, GetUserResult, useAxios } from "@/services";

export * from "./api.types";

export const userService = {
  show: async (id: unknown): Promise<GetUserResult> =>
    useAxios().get(`/api/users/${id}`),

  store: async (params: Record<string, any>): Promise<GetUserResult> =>
    useAxios().store(`/api/users`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetUserResult> => useAxios().update(`/api/users/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/users/${id}`),
};
