import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { useStore } from "@/store";
import {
  GeneralApiProblem,
  GeneralApiResponse,
  getGeneralApiProblem,
} from "@/services/api.types";

export const NODE_ENV = process.env.NODE_ENV;
export const FRONTEND_URL = process.env.VUE_APP_FRONTEND_URL;
export const BACKEND_URL = process.env.VUE_APP_BACKEND_URL;

const axiosDefaultConfig: AxiosRequestConfig = {
  withCredentials: false,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/vnd.api+json",
  },
};

interface UseAxios {
  instance: AxiosInstance;

  fetch(
    url: string,
    params?: Record<string, any>
  ): Promise<
    (GeneralApiResponse & { data: any; meta: any }) | GeneralApiProblem
  >;

  get(
    url: string
  ): Promise<(GeneralApiResponse & { data: any }) | GeneralApiProblem>;

  store(
    url: string,
    params: Record<string, any>
  ): Promise<(GeneralApiResponse & { data: any }) | GeneralApiProblem>;

  update(
    url: string,
    params?: Record<string, any>
  ): Promise<(GeneralApiResponse & { data: any }) | GeneralApiProblem>;

  delete(url: string): Promise<GeneralApiResponse | GeneralApiProblem>;
}

export const useAxios = (
  config: AxiosRequestConfig & { responseField?: string } = {}
): UseAxios => {
  const { responseField = "data", ...axiosConfig } = config;
  const store = useStore();
  const token =
    store.getters["auth/token"] || window.localStorage.getItem("auth_token");
  if (token)
    axiosConfig.headers = {
      ...axiosConfig.headers,
      Authorization: `Bearer ${token}`,
    };

  const instance = axios.create({
    baseURL: BACKEND_URL,
    ...axiosDefaultConfig,
    ...axiosConfig,
  });

  const getField = (result: any) => {
    if (responseField) return _.get(result, responseField, null);
    return result;
  };

  return {
    instance,
    fetch: async (url, params = {}) => {
      const result = await instance
        .get(url, { params })
        .catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      const { data = [], ...meta } = result.data;
      return {
        kind: "ok",
        data: data,
        meta: _.get(meta, "meta", meta),
      };
    },
    get: async (url) => {
      const result = await instance.get(url).catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return {
        kind: "ok",
        data: getField(result.data),
      };
    },
    store: async (url, params) => {
      const result = await instance.post(url, params).catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return {
        kind: "ok",
        data: getField(result.data),
      };
    },
    update: async (url, params) => {
      const result = await instance.put(url, params).catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return {
        kind: "ok",
        data: getField(result.data),
      };
    },
    delete: async (url) => {
      const result = await instance.delete(url).catch((error) => error);
      if (result instanceof Error) {
        return getGeneralApiProblem(result as AxiosError);
      }
      return { kind: "ok" };
    },
  };
};
