
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import {
  ballotVoteService,
  ballotService,
  GetBallot,
  PostVote,
  Question,
  SimpleDocumentItem,
  Vote,
} from "@/services";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import VoteQuestion from "@/views/ballots/vote/VoteQuestion.vue";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetBallot>;
}

export default defineComponent({
  name: "Main",
  components: { VoteQuestion },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const uploading = ref<boolean>(false);
    const id = computed(() => route.params.id);

    const { loading: loadingBallot, data, fetch } = fetchRecord({
      fetchApiMethod: ballotService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback: () => {
        const canVote = _.get(data.value, "abilities.can_vote", false);
        if (!canVote) {
          router.go(-1);
        } else {
          fetchVote(id.value).finally();
        }
      },
    }) as FetchRecord;

    const {
      loading: loadingVote,
      data: voteData,
      fetch: fetchVote,
    } = fetchRecord({
      fetchApiMethod: ballotVoteService.show,
      successCallback: () => {
        recursiveQuestionsFormat(data.value.questions);
      },
    });

    const {
      processing,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      updateApiMethod: ballotVoteService.store,
      successCallback() {
        router.go(-1);
      },
      params: {
        vote: [],
      } as PostVote,
    });

    const recursiveFindAnswer = (
      questionUuid: string,
      voteQuestions: Record<string, any>[]
    ) => {
      let vote: {
        answer: string;
        comment: string;
        files: SimpleDocumentItem[];
      } = {
        answer: "",
        comment: "",
        files: [],
      };
      voteQuestions.map((question) => {
        if (questionUuid === question.uuid) {
          vote.answer = question.answer.uuid || "";
          vote.comment = question.answer.comment || "";
          vote.files = question.answer.files || [];
        } else if (
          !vote.answer &&
          _.isArray(question.answer.questions) &&
          question.answer.questions.length > 0
        ) {
          vote = {
            ...vote,
            ...recursiveFindAnswer(questionUuid, question.answer.questions),
          };
        }
      });
      return vote;
    };

    const recursiveQuestionsFormat = (questions: Question[]) => {
      questions.map((question) => {
        question.vote = recursiveFindAnswer(
          question.uuid,
          _.get(voteData.value, `0.vote`, [])
        );
        question.answers.map((answer) => {
          if (_.isArray(answer.questions))
            recursiveQuestionsFormat(answer.questions);
        });
      });
    };

    const recursiveVoteFormat = (questions: Question[]) => {
      const votes: Vote[] = [];
      questions.map((question) => {
        const answerIndex = _.findIndex(question.answers, {
          uuid: question.vote?.answer,
        });
        votes.push({
          uuid: question.uuid,
          answer: {
            uuid: question.vote?.answer || "",
            comment: question.vote?.comment || "",
            files: question.vote?.files || [],
            questions: recursiveVoteFormat(
              _.get(question.answers, `${answerIndex}.questions`, []) || []
            ),
          },
        });
      });
      return votes;
    };

    const onSubmit = () => {
      if (uploading.value) return;
      setFormData({
        vote: recursiveVoteFormat(data.value.questions),
      });
      submit(id.value);
    };

    const loading = computed(() => loadingBallot.value || loadingVote.value);

    watch(id, (val) => fetch(val).finally());
    onMounted(() => {
      fetch(id.value).finally();
    });

    return {
      id,
      uploading,
      loading,
      detail: data,
      processing,
      validationErrors,
      onSubmit,
    };
  },
});
