<template>
  <div>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="block font-semibold mb-1">Title</label>
      </template>
      <VueInput
        class="input--sm shadow-none bg-gray-50"
        v-model.lazy="editableFilter.title"
      />
    </VueInputGroup>
    <div class="flex items-end mb-4">
      <VueInputGroup class="flex-1 mr-3">
        <template v-slot:label>
          <label class="block font-semibold mb-1">N Number</label>
        </template>
        <VueInput
          class="input--sm shadow-none bg-gray-50"
          v-model.lazy="editableFilter.n_number_from"
          placeholder="From"
        />
      </VueInputGroup>
      <VueInputGroup class="flex-1">
        <VueInput
          class="input--sm shadow-none bg-gray-50"
          v-model.lazy="editableFilter.n_number_to"
          placeholder="To"
        />
      </VueInputGroup>
    </div>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="font-semibold block mb-1">Committee Reference</label>
      </template>
      <div v-if="visibleCommittees.length === 0">No available results</div>
      <div class="mt-2.5" v-else>
        <div
          class="flex items-center mb-1.5"
          v-for="committee in visibleCommittees"
          :key="`fc-${committee.id}`"
        >
          <div class="w-14 flex items-center mr-1.5">
            <input
              :id="`fc-${committee.id}`"
              type="checkbox"
              class="input border block focus:bg-white focus:ring-0 input--lg mr-1.5"
              :value="committee.id"
              v-model="editableFilter.committee_id"
            />

            <router-link
              :to="{
                name: 'documents',
                query: { folderId: $_.get(committee, 'folder.id', null) },
              }"
            >
              <FolderIcon class="w-5 h-5"
            /></router-link>
          </div>

          <Tippy
            tag="label"
            :for="`fc-${committee.id}`"
            :content="`${committee.title} (${committee.count || 0})`"
            :options="{ placement: 'bottom' }"
            class="block flex-1 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <strong>{{ committee.acronym }}</strong> {{ committee.title }} ({{
              committee.count || 0
            }})
          </Tippy>
        </div>
      </div>
    </VueInputGroup>
    <div
      class="mb-4"
      v-for="documentType in visibleDocumentTypes"
      :key="`document-types-${documentType.value}`"
    >
      <VueInputGroup>
        <template v-slot:label>
          <label class="font-semibold block mb-1">{{
            documentType.text
          }}</label>
        </template>
        <div v-if="documentType.children.length === 0">
          No available results
        </div>
        <div class="mt-2.5" v-else>
          <div
            class="flex items-center mb-1.5"
            v-for="childType in documentType.children"
            :key="`document-type-${childType.value}`"
          >
            <div class="w-8">
              <input
                :id="`document-type-${childType.value}`"
                type="checkbox"
                class="input border block focus:bg-white focus:ring-0 input--lg mr-1.5"
                :value="childType.value"
                v-model="editableFilter.type"
              />
            </div>

            <Tippy
              tag="label"
              :for="`document-type-${childType.value}`"
              :content="`${childType.text} (${childType.count || 0})`"
              :options="{ placement: 'bottom' }"
              class="block whitespace-nowrap overflow-hidden overflow-ellipsis"
              >{{ childType.text }} ({{ childType.count || 0 }})
            </Tippy>
          </div>
        </div>
      </VueInputGroup>
    </div>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="font-semibold block mb-1">Status</label>
      </template>
      <div v-if="visibleStatus.length === 0">No available results</div>
      <div class="mt-2.5" v-else>
        <div
          class="flex items-center mb-1.5"
          v-for="status in visibleStatus"
          :key="`status-${status.value}`"
        >
          <div class="w-8">
            <input
              :id="`status-${status.value}`"
              type="checkbox"
              class="input border block focus:bg-white focus:ring-0 input--lg mr-1.5"
              :value="status.value"
              v-model="editableFilter.status"
            />
          </div>

          <Tippy
            tag="label"
            :for="`status-${status.value}`"
            :content="`${status.text} (${status.count || 0})`"
            :options="{ placement: 'bottom' }"
            class="block whitespace-nowrap overflow-hidden overflow-ellipsis"
            >{{ status.text }} ({{ status.count || 0 }})
          </Tippy>
        </div>
      </div>
    </VueInputGroup>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="font-semibold block mb-1">Expected Actions</label>
      </template>
      <div v-if="visibleExpectedActions.length === 0">No available results</div>
      <div class="mt-2.5" v-else>
        <div
          class="flex items-center mb-1.5"
          v-for="expectedAction in visibleExpectedActions"
          :key="`expectedAction-${expectedAction.value}`"
        >
          <div class="w-8">
            <input
              :id="`expectedAction-${expectedAction.value}`"
              type="checkbox"
              class="input border block focus:bg-white focus:ring-0 input--lg mr-1.5"
              :value="expectedAction.value"
              v-model="editableFilter.expected_action"
            />
          </div>

          <Tippy
            tag="label"
            :for="`expectedAction-${expectedAction.value}`"
            :content="`${expectedAction.text} (${expectedAction.count || 0})`"
            :options="{ placement: 'bottom' }"
            class="block whitespace-nowrap overflow-hidden overflow-ellipsis"
            >{{ expectedAction.text }} ({{ expectedAction.count || 0 }})
          </Tippy>
        </div>
      </div>
    </VueInputGroup>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="block font-semibold mb-1">Expected Action: From</label>
      </template>
      <Litepicker
        class="input--sm shadow-none bg-white border-gray-300"
        v-model.lazy="editableFilter.exp_action_from"
      />
    </VueInputGroup>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="block font-semibold mb-1">Expected Action: From</label>
      </template>
      <Litepicker
        class="input--sm shadow-none bg-white border-gray-300"
        v-model.lazy="editableFilter.exp_action_to"
      />
    </VueInputGroup>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="block font-semibold mb-1">Creation Date: From</label>
      </template>
      <Litepicker
        class="input--sm shadow-none bg-white border-gray-300"
        v-model.lazy="editableFilter.created_at_from"
      />
    </VueInputGroup>
    <VueInputGroup class="mb-4">
      <template v-slot:label>
        <label class="block font-semibold mb-1">Creation Date: To</label>
      </template>
      <Litepicker
        class="input--sm shadow-none bg-white border-gray-300"
        v-model.lazy="editableFilter.created_at_to"
      />
    </VueInputGroup>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "DocumentFilter",
  components: { VueInputGroup },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    apply: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const editableFilter = reactive(toRefs(props.filter));

    const aggregation = computed(() => store.state.document.aggregation);
    const visibleCommittees = computed(() => {
      return store.state.committee.committees
        .map((committee) => {
          const index = _.findIndex(aggregation.value, {
            value: committee.acronym,
            type: "committee",
          });
          return {
            ...committee,
            count: _.get(aggregation.value, [index, "count"], 0) * 1,
          };
        })
        .filter((committee) => {
          return committee.count > 0;
        });
    });

    const documentTypes = computed(() => store.state.document.types);
    const visibleDocumentTypes = computed(() => {
      return documentTypes.value.map((type) => {
        const children = (type.children || [])
          .map((child) => {
            const index = _.findIndex(aggregation.value, {
              value: child.value.toString(),
              type: "type",
            });
            return {
              ...child,
              count: _.get(aggregation.value, [index, "count"], 0) * 1,
            };
          })
          .filter((child) => child.count > 0);
        return {
          text: type.text,
          value: type.value,
          sub_values: children.map((child) => child.value),
          count: _.sumBy(children, "count"),
          children,
        };
      });
    });

    const expectedActions = computed<any[]>(() =>
      aggregation.value.filter((agg) => agg.type === "expected_action")
    );
    const visibleExpectedActions = computed(() => {
      return expectedActions.value
        .map((type) => {
          const index = _.findIndex(aggregation.value, {
            value: type.value.toString(),
            type: "expected_action",
          });
          return {
            text: type.title,
            value: type.value,
            count: _.get(aggregation.value, [index, "count"], 0),
          };
        })
        .filter((item) => item.count > 0);
    });

    const status = computed<any[]>(() =>
      aggregation.value.filter((agg) => agg.type === "status")
    );
    const visibleStatus = computed(() => {
      return status.value
        .map((type) => {
          const index = _.findIndex(aggregation.value, {
            value: type.value.toString(),
            type: "status",
          });
          return {
            text: type.title,
            value: type.value,
            count: _.get(aggregation.value, [index, "count"], 0),
          };
        })
        .filter((item) => item.count > 0);
    });

    watch(editableFilter, () => props.apply(), { flush: "post", deep: true });

    return {
      visibleCommittees,
      visibleDocumentTypes,
      visibleExpectedActions,
      visibleStatus,
      editableFilter,
    };
  },
});
</script>
