
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { committeeService } from "@/services";
import { Select2Options } from "@/global-components/select2";
import { helper } from "@/utils/helper";

export default defineComponent({
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "committee",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "type",
            "title",
            "acronym",
            "assistant_user_id",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
        filterFormat: {
          paginate: "@paginate",
          title: "@where.q",
          acronym: "@where._acronym",
          type: "@where.type",
          secretariat_member_id: "@where.secretariat_member_id",
          secretary_user_id: "@where.secretary_user_id",
          assistant_user_id: "@where.assistant_user_id",
          chairperson_user_id: "@where.chairperson_user_id",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      acronym: "",
      type: "",
      secretariat_member_id: "",
      secretary_user_id: "",
      assistant_user_id: "",
      chairperson_user_id: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: committeeService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const userTemplateFormat = (obj: any): string => {
      if (obj.name) return helper.formatNameSurname(obj);
      return obj.text;
    };

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            salutation: "salutation",
            title: "title",
          },
        },
      },
      templateSelection: userTemplateFormat,
    });

    const goToEdit = (value: any) => {
      router.push({ name: "committees.edit", params: { id: value } });
    };

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      userSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
    };
  },
});
