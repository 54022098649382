<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconUser
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">USERS LIST</div>
      <div class="text-white text-sm font-light">
        Registration of users and allocation of roles
      </div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <label class="text-white text-lg">First Name</label>
          <input
            v-model="filter.query.name"
            placeholder="Enter first name"
            class="input w-full border shadow-lg focus:bg-white flex-grow"
            @keyup.enter="applyFilter"
          />
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <label class="text-white text-lg">Last Name</label>
          <input
            v-model="filter.query.surname"
            placeholder="Enter last name"
            class="input w-full border shadow-lg focus:bg-white flex-grow"
            @keyup.enter="applyFilter"
          />
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <label class="text-white text-lg">Email</label>
          <input
            v-model="filter.query.email"
            placeholder="Enter title or reference"
            class="input w-full border shadow-lg focus:bg-white flex-grow"
            @keyup.enter="applyFilter"
          />
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Administrated By</label>
            </template>
            <Select2Input
              :options="organizationSelect2Options"
              v-model="filter.query.administrated_by_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Role</label>
            </template>
            <VueSelect v-model="filter.query.role">
              <option value="">All</option>
              <option
                v-for="role in $store.state.user.roleTypes"
                :key="role.behaviour"
                :value="role.behaviour"
              >
                {{ role.title }}
              </option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div
          class="col-span-full sm:col-span-6 md:col-span-4"
          v-if="filter.query.role"
        >
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Role Detail</label>
            </template>
            <Select2Input
              :options="roleSelect2Options"
              v-model="filter.query.role_id"
              :key="selectedRoleType.scope || 'role-resource'"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex flex-col-reverse sm:flex-row items-end sm:items-center">
        <div class="w-full sm:w-auto sm:flex-grow flex sm:mr-3 mt-3 sm:mt-0">
          <input
            placeholder="Quick Search In Name"
            class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
            v-model="filter.query.q"
            @keyup.enter="applyFilter"
          />
          <button
            class="button bg-gray-100 border rounded-l-none shadow-lg"
            @click="applyFilter"
          >
            <SearchIcon class="inline w-6 h-6" />
          </button>
        </div>
        <router-link
          tag="button"
          :to="{ name: 'users.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-if="$store.getters['auth/canCreateUser']"
        >
          New User
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-3">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Last Name</th>
                <th class="whitespace-nowrap">First Name</th>
                <th class="whitespace-nowrap">Email</th>
                <th class="whitespace-nowrap">Administrated By</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.id"
              >
                <td>{{ row.surname }}</td>
                <td>{{ row.name }}</td>
                <td>
                  <div>{{ row.email }}</div>
                  <div
                    class="inline-block px-2 py-0.5 mt-0.5 rounded bg-red-400 text-xs text-white"
                    v-if="$_.get(row, 'status', 0) * 1 === 0"
                  >
                    Passive
                  </div>
                </td>
                <td>
                  <template v-if="$_.get(row, 'administrated_by', '')">
                    <div class="font-medium">
                      {{ $_.get(row, "administrated_by.acronym", "") }}
                    </div>
                    <div class="text-xs">
                      {{ $_.get(row, "administrated_by.title", "") }}
                    </div>
                  </template>
                </td>
                <td class="text-right">
                  <div class="inline-flex">
                    <Tippy
                      tag="button"
                      content="See Details"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="() => goToView(row.id)"
                    >
                      <EyeIcon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      tag="button"
                      content="Edit"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="() => goToEdit(row.id)"
                      v-if="$_.get(row, 'abilities.can_update', false)"
                    >
                      <Edit2Icon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      tag="button"
                      content="Login"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      v-if="$store.getters['auth/isSuperAdmin']"
                      @click="() => loginAs(row.id)"
                    >
                      <LogInIcon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      tag="button"
                      content="Delete"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="() => triggerDelete([row.id])"
                      v-if="$_.get(row, 'abilities.can_delete', false)"
                    >
                      <Trash2Icon class="inline w-5 h-5" />
                    </Tippy>
                  </div>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :per-page="filter.query.paginate * 1"
          :current="filter.query.page * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { authService, userService } from "@/services";
import _ from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { Select2Options } from "@/global-components/select2";
import VueSelect from "@/global-components/form-elements/select/Main.vue";
import { useStore } from "@/store";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "Users",
  components: {
    VueSelect,
    TransactionStatement,
    Pagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const selectionQuery = computed(() => {
      const config = {
        "@page": 1,
        "@paginate": 15,
        "@select": [
          "id",
          "surname",
          "name",
          "email",
          "administrated_by_id",
          "status",
        ],
        "@where": {},
        "@func": ["withCount"],
        "@order": "created_at:desc",
        administratedBy: {
          "@select": ["id", "acronym", "title"],
        },
      };

      if (filter && filter.query.role) {
        let hasRole = `whereHasRole:${filter.query.role}`;
        if (filter.query.role_id) hasRole += `,${filter.query.role_id}`;
        config["@func"].push(hasRole);
      }

      return config;
    });

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "user",
        config: selectionQuery,
        filterFormat: {
          q: "@where.q",
          paginate: "@paginate",
          name: "@where._name",
          surname: "@where._surname",
          email: "@where._email",
          administrated_by_id: "@where.administrated_by_id",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      q: "",
      name: "",
      surname: "",
      email: "",
      administrated_by_id: "",
      role: "",
      role_id: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: userService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const roleTemplateSelection = (obj: any): any => {
      if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
      return obj.text;
    };
    const organizationSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          organization: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "title",
              acronym: "acronym",
            },
          },
        },
        templateSelection: roleTemplateSelection,
      };
    });

    const roleSelect2Options = computed<Select2Options>(() => {
      const source =
        selectedRoleType.value && selectedRoleType.value?.scope === "committee"
          ? "committee"
          : "organization";
      const where = {};
      if (source === "organization")
        _.set(where, "id", filter.query.administrated_by_id);
      return {
        ajaxQuery: {
          [source]: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "title",
              acronym: "acronym",
            },
            "@where": where,
          },
        },
        templateSelection: roleTemplateSelection,
      };
    });

    const selectedRoleType = computed(() =>
      store.getters["user/roleType"](filter.query.role)
    );

    watch(
      () => selectedRoleType.value?.type,
      () => {
        filter.query.role_id = "";
      }
    );

    const goToView = (value: any) => {
      router.push({ name: "users.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "users.edit", params: { id: value } });
    };

    const loginAs = (value: any) => {
      authService
        .loginAs(value)
        .then((result) => {
          if (result.kind === "ok") {
            store.dispatch("auth/SET_TOKEN", result.meta.token || "");
            store.dispatch("auth/SET_PROFILE", result.data);
            router.push({ name: "welcome" });
          } else {
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      organizationSelect2Options,
      roleSelect2Options,
      selectedRoleType,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
      loginAs,
    };
  },
});
</script>
