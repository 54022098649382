<template>
  <div class="accordion__pane active pb-4">
    <!-- BEGIN Information -->
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >Information</a
    >
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div class="grid grid-cols-4 gap-4 py-6">
        <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Title
          </div>
          <div
            class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5"
          >
            ({{ detail.acronym }}) {{ detail.title }}
          </div>
        </div>

        <div
          class="col-span-full grid grid-cols-2 sm:col-span-2 bg-gray-100 flex rounded-lg"
        >
          <div
            class="col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Country
          </div>
          <div class="col-span-1 flex items-center px-6 py-3.5">
            {{ $h.capitalizeFirstLetter($_.get(detail, "country.title", "")) }}
          </div>
        </div>
        <div
          class="col-span-full grid grid-cols-2 sm:col-span-2 bg-gray-100 flex rounded-lg"
        >
          <div
            class="col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            # of Standards Adopted and/or Implemented
          </div>
          <div class="col-span-1 flex items-center px-6 py-3.5">
            {{ $_.get(detail, "number_of_standards_adopted", "0") }}
          </div>
        </div>

        <div
          class="col-span-full grid grid-cols-2 sm:col-span-2 bg-gray-100 flex rounded-lg"
        >
          <div
            class="col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Visible On Website
          </div>
          <div class="col-span-1 flex items-center px-6 py-3.5">
            {{
              $h.convertIdToText(
                $_.get(detail, "visible_on_website", "0"),
                "yesOrNo"
              )
            }}
          </div>
        </div>
        <div
          class="col-span-full grid grid-cols-2 sm:col-span-2 bg-gray-100 flex rounded-lg"
        >
          <div
            class="col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Accreditation Body
          </div>
          <div class="col-span-1 flex items-center px-6 py-3.5">
            {{
              $h.convertIdToText(
                $_.get(detail, "is_accreditation_body", "0"),
                "yesOrNo"
              )
            }}
          </div>
        </div>
        <div
          class="col-span-full grid grid-cols-2 sm:col-span-2 bg-gray-100 flex rounded-lg"
        >
          <div
            class="col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Metrology Body
          </div>
          <div class="col-span-1 flex items-center px-6 py-3.5">
            {{
              $h.convertIdToText(
                $_.get(detail, "is_metrology_body", "0"),
                "yesOrNo"
              )
            }}
          </div>
        </div>
        <div
          class="col-span-full grid grid-cols-2 sm:col-span-2 bg-gray-100 flex rounded-lg"
        >
          <div
            class="col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Standardization Body
          </div>
          <div class="col-span-1 flex items-center px-6 py-3.5">
            {{
              $h.convertIdToText(
                $_.get(detail, "is_standardization_body", "0"),
                "yesOrNo"
              )
            }}
          </div>
        </div>
        <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Description
          </div>
          <div
            class="col-span-full sm:col-span-3 px-6 py-3.5 ProseMirror-Viewer"
            v-html="detail.description"
          ></div>
        </div>
        <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Information
          </div>
          <div
            class="col-span-full sm:col-span-3 px-6 py-3.5 ProseMirror-Viewer"
            v-html="detail.information"
          ></div>
        </div>
        <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Contact
          </div>
          <div
            class="col-span-full sm:col-span-3 px-6 py-3.5 ProseMirror-Viewer"
            v-html="detail.contact"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Information",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
});
</script>
