<template>
  <div class="accordion__pane py-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      :class="{
        '!text-red-400': validationErrors && validationErrors.has('questions'),
      }"
    >
      Questions & Answers
      <button
        v-if="canEdit && visibleEditToggleButton"
        class="button p-0 float-right mr-4"
        @click.stop.prevent="visibleEditComponent = !visibleEditComponent"
      >
        <EyeIcon v-if="visibleEditComponent" class="w-7 h-7" />
        <Edit3Icon v-else class="w-7 h-7" />
      </button>
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <template v-if="!canEdit || !visibleEditComponent">
        <BallotViewQuestions
          v-if="$_.get(ballot, 'questions', []).length > 0"
          :ballot-id="ballotId"
          :ballot="ballot"
          :validation-errors="validationErrors"
        />
        <div v-else class="text-lg text-gray-500">
          No questions found for this ballot!
        </div>
      </template>
      <BallotEditQuestion
        v-else
        :ballot-id="ballotId"
        :ballot="ballot"
        @update:ballot="(val) => $emit('update:ballot', val)"
        :close="() => (visibleEditComponent = false)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { GetBallot } from "@/services";
import BallotViewQuestions from "@/views/ballots/view/questions/view/Main.vue";
import BallotEditQuestion from "@/views/ballots/view/questions/Edit.vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "BallotQuestions",
  components: { BallotEditQuestion, BallotViewQuestions },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const visibleEditComponent = ref<boolean>(false);
    const visibleEditToggleButton = computed<boolean>(() => {
      return ["ADHOC", "SMIIC"].indexOf(props.ballot.ballot_type) > -1;
    });
    watch(visibleEditComponent, (val) => emit("update:active-editable", val));
    return {
      visibleEditComponent,
      visibleEditToggleButton,
    };
  },
});
</script>
