<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        Feel free to ask anything about standardization
      </div>
    </div>
    <div class="">
      <ul class="flex flex-col gap-4 text-lg">
        <li
          v-for="item in messages"
          :key="item.id"
          class="whitespace-pre-wrap p-3 sm:p-5 rounded-md"
          :class="{ 'bg-gray-300': item.role === 'user' }"
        >
          <div
            class="-mt-2 mr-2 w-12 h-12 absolute justify-center items-center rounded-full bg-gray-500 text-xl text-white"
          >
            <img
              v-if="item.role === 'user'"
              :src="gravatar"
              class="rounded-full"
            />
            <img
              v-else
              class="rounded-full"
              src="http://source.unsplash.com/100x100/?c3po"
            />
          </div>
          <div class="ml-24" v-html="item.content"></div>
        </li>
      </ul>

      <div class="mt-4 flex bg-blue-500" v-if="processing || loading">
        <div
          class="flex items-center text-white text-sm font-bold p-3 sm:p-5 relative"
        >
          <div class="ml-4 absolute rounded-md">
            <svg
              class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <div class="ml-24 flex-1 text-lg">{{ waitingMessage }}</div>
        </div>
      </div>

      <div class="relative mt-10 p-3 sm:p-5">
        <div class="w-full">
          <div class="w-full flex">
            <div
              class="mr-2 w-12 h-12 absolute justify-center items-center rounded-full bg-gray-500 text-xl text-white"
            >
              <img :src="gravatar" class="rounded-full" />
            </div>

            <textarea
              placeholder="Write your message!"
              class="ml-24 flex-1 focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 bg-gray-200 rounded-md p-3 sm:p-5 text-lg"
              v-model="formData.message"
            ></textarea>
          </div>

          <div class="mt-4 grid grid-cols-2">
            <button
              :disabled="processing"
              type="button"
              class="inline-flex ml-24 place-self-start w-60 justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-blue-500 hover:bg-blue-400 focus:outline-none"
              @click="onSubmit"
            >
              <span class="font-bold">Send</span>
            </button>
            <button
              :disabled="processing"
              type="button"
              class="inline-flex place-self-end justify-center rounded-lg px-4 py-3 transition duration-500 ease-in-out text-white bg-gray-500 hover:bg-gray-400 focus:outline-none"
              @click="onClear"
            >
              <span class="font-bold">Clear</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useGeneralActions } from "@/utils/actions";
import {
  ConversationMessage,
  conversationService,
  GetConversationResult,
  PostConversation,
} from "@/services";
import md5 from "blueimp-md5";
import { useStore } from "@/store";
const messageList = [
  "Thank you for your patience! SMIIC IS - AI Assistant is working diligently to provide you with the best response possible.",
  "Hang in there! SMIIC IS - AI Assistant is processing your request and will be with you shortly.",
  "We appreciate your understanding as SMIIC IS - AI Assistant is working behind the scenes to find the perfect answer for you.",
  "Your inquiry is important to us! SMIIC IS - AI Assistant is working hard to deliver an informative response.",
  "Please bear with us! SMIIC IS - AI Assistant is actively searching for the best solution to your question.",
  "While you wait, here's a fun fact: Did you know that SMIIC IS - AI Assistant can process millions of pieces of information in a matter of seconds?",
  "We understand your eagerness! SMIIC IS - AI Assistant is doing its best to ensure a thorough and helpful response.",
  "Rest assured, SMIIC IS - AI Assistant is working diligently in the background to give you a prompt and accurate answer.",
  "We appreciate your patience! SMIIC IS - AI Assistant is continuously learning to provide even better responses in the future.",
];

export default defineComponent({
  name: "Conversation",
  setup() {
    const store = useStore();
    const profile = store.getters["auth/profile"];

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    const headerTitle = computed<string>(() => {
      return "AI Conversation";
    });

    const messages = ref<ConversationMessage[]>([]);
    const waitingMessage = ref<string>("");
    waitingMessage.value =
      messageList[Math.floor(Math.random() * messageList.length)];

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: conversationService.show,
      errorCallback() {
        // router.go(-1);
      },
      successCallback() {
        waitingMessage.value =
          messageList[Math.floor(Math.random() * messageList.length)];
        messages.value = data.value.messages || [];
        setFormData({
          message: "",
          conversation_id: data.value.id,
          scope_id: data.value.scope_id,
        });
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: conversationService.store,
      successCallback(result: GetConversationResult) {
        if (result.kind === "ok") {
          window.localStorage.setItem("conversationId", result.data.id as any);
          formData.conversation_id = result.data.id;
          formData.message = "";
          messages.value = [...messages.value, ...(result.data.messages || [])];
        }
      },
      params: {
        message: "",
        scope_id: 1,
        conversation_id: "",
      } as PostConversation,
      removeKeysIfEmptyValue: ["conversation_id", "scope_id"],
    });

    onMounted(() => {
      formData.conversation_id =
        window.localStorage.getItem("conversationId") || null;
      if (formData.conversation_id) fetch(formData.conversation_id).finally();
    });

    const onSubmit = () => submit();

    const onClear = () => {
      window.localStorage.removeItem("conversationId");
      messages.value = [];
      setFormData({
        message: "",
        conversation_id: "",
        scope_id: formData.scope_id,
      });
    };

    return {
      headerTitle,
      messages,
      waitingMessage,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit,
      onClear,
      gravatar,
    };
  },
});
</script>
