
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { RecycleScroller } from "vue3-virtual-scroller";
import { helper } from "@/utils/helper";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "DocumentFilteredSimpleList",
  components: { RecycleScroller },
  props: {
    documentUuid: {
      type: String,
      default: () => "",
    },
    changeActiveList: {
      type: Function,
      default: () => {
        console.log("changeActiveList");
      },
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const wrapperRef = ref<HTMLElement>();
    const contentHeight = ref<number>(0);

    const documents = computed(() =>
      store.state.document.documents.map((document) => {
        return {
          uuid: document.uuid || helper.uuidv4(),
          ...document,
        };
      })
    );

    const openItem = (item: any) => {
      if (item.type * 1 < 100) {
        router.push({
          name: "documents",
          params: {},
          query: { folderId: item.id },
        });
      } else {
        router.push({
          name: "documents",
          params: { uuid: item.uuid },
        });
      }
    };

    const calculateHeights = () => {
      contentHeight.value = wrapperRef.value?.clientHeight || 0;
    };

    onMounted(() => {
      window.addEventListener("resize", calculateHeights);
      setTimeout(() => {
        calculateHeights();
      }, 100);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", calculateHeights);
    });

    return {
      documents,
      wrapperRef,
      contentHeight,
      openItem,
    };
  },
});
