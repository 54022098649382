<template>
  <div
    class="flex flex-col-reverse sm:flex-row justify-center sm:justify-between items-center"
  >
    <div class="text-xs mt-4 sm:mt-0">
      <a
        href="javascript:;"
        class="font-bold text-blue-500 hover:text-blue-700"
        v-if="onRefresh"
        @click="() => onRefresh()"
      >
        Refresh List
      </a>
      <div class="font-light">
        Showing <b>{{ shownDataStartNumber }} to {{ shownDataEndNumber }}</b> of
        {{ total }} data.
      </div>
      <div class="font-light">
        Items per page
        <select :value="perPage" @change="updatePerPage">
          <option disabled value="">Please select one</option>
          <option>5</option>
          <option>15</option>
          <option>50</option>
          <option>100</option>
          <option>500</option>
          <option>1000</option>
        </select>
      </div>
    </div>
    <ul class="pagination">
      <Tippy tag="li" content="First Page" :options="{ placement: 'bottom' }">
        <a class="pagination__link" href="javascript:;" @click="updatePage(1)">
          <ChevronsLeftIcon class="w-4 h-4" />
        </a>
      </Tippy>
      <Tippy
        tag="li"
        content="Previous Page"
        :options="{ placement: 'bottom' }"
      >
        <a class="pagination__link" href="javascript:;" @click="prevButton">
          <ChevronLeftIcon class="w-4 h-4" />
        </a>
      </Tippy>
      <li v-if="$_.get(numbersShown, 0) !== 1">
        <a class="pagination__link" href="">...</a>
      </li>
      <li v-for="number in numbersShown" :key="'page-' + number">
        <a
          class="pagination__link"
          :class="{ 'pagination__link--active': number === current }"
          href="javascript:;"
          @click="updatePage(number)"
          >{{ number }}</a
        >
      </li>
      <li v-if="$_.last(numbersShown) !== lastPage">
        <a class="pagination__link" href="">...</a>
      </li>
      <Tippy tag="li" content="Next Page" :options="{ placement: 'bottom' }">
        <a class="pagination__link" href="javascript:;" @click="nextButton">
          <ChevronRightIcon class="w-4 h-4" />
        </a>
      </Tippy>
      <Tippy tag="li" content="Last Page" :options="{ placement: 'bottom' }">
        <a
          class="pagination__link"
          href="javascript:;"
          @click="updatePage(lastPage)"
        >
          <ChevronsRightIcon class="w-4 h-4" />
        </a>
      </Tippy>
    </ul>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  name: "Pagination",
  emits: ["update-page", "update-perpage"],
  props: {
    current: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 15,
    },
    total: {
      type: Number,
      default: 1,
    },
    onRefresh: {
      type: Function,
      default: null,
    },
  },
  setup(props, { emit }) {
    const lastPage = computed<number>(() => {
      return Math.ceil(props.total / props.perPage);
    });

    const shownDataStartNumber = computed<number>(() => {
      return props.current * props.perPage - props.perPage + 1;
    });

    const shownDataEndNumber = computed<number>(() => {
      const val = props.current * props.perPage;
      if (val > props.total) return props.total;
      return val;
    });

    const numbersShown = computed<number[]>(() => {
      const arr = [];
      let start = props.current > 2 ? props.current - 1 : 1;
      let end =
        lastPage.value - props.current > 1 ? props.current + 1 : lastPage.value;

      if (props.current === lastPage.value)
        start = lastPage.value - 2 > 0 ? lastPage.value - 2 : start;

      if (props.current === 1)
        end =
          lastPage.value - props.current > 2
            ? props.current + 2
            : lastPage.value;
      if (end - start > 3) end = start + 3;
      for (let i = start; i <= end; i++) {
        arr.push(i);
      }
      return arr;
    });

    const updatePage = (page: number) => {
      if (props.current * 1 !== page) {
        console.log({
          title: "updatePage",
          val: page,
        });
        emit("update-page", page);
      }
    };

    const prevButton = () => {
      if (props.current > 1) updatePage(props.current - 1);
    };

    const nextButton = () => {
      if (props.current < lastPage.value) updatePage(props.current + 1);
    };

    const updatePerPage = (e: any) => {
      console.log({
        title: "updatePerPage Dispatch",
        val: _.get(e, "target.value", props.perPage),
      });
      emit("update-perpage", _.get(e, "target.value", props.perPage) * 1);
    };

    return {
      shownDataStartNumber,
      shownDataEndNumber,
      numbersShown,
      lastPage,
      updatePage,
      prevButton,
      nextButton,
      updatePerPage,
    };
  },
});
</script>
