<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">
        {{ headerTitle }}
      </div>
    </div>
    <div class="p-3 sm:p-5">
      <!-- BEGIN: Top Buttons -->
      <div
        class="flex justify-end transform -translate-y-9 sm:-translate-y-11"
        v-if="id"
      >
        <router-link
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap ml-4"
          :to="{ name: 'users.view', params: { id } }"
        >
          View User
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <EyeIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <!-- END: Top Buttons -->
      <div
        class="grid grid-cols-12 gap-4 rounded-lg bg-theme-11 bg-opacity-30 p-10"
      >
        <div v-if="loading" class="text-center col-span-full p-8 pt-4">
          <LoaderIcon class="spin-5 inline-block w-8 h-8" />
          <div class="mt-2 font-light">
            Please wait while the records are loading.
          </div>
        </div>
        <img
          :src="require('@/assets/images/3d_users.png')"
          class="hidden lg:block w-full col-span-5 mr-5"
        />
        <div class="col-span-full lg:col-span-7 flex flex-col justify-center">
          <div class="text-theme-3 text-2xl">
            All mandatory fields are indicated with red asterisks.
          </div>
          <div class="text-gray-500 font-light mt-3">
            You need to select SMIIC Members who will administrate this user.
            When members create users, this option will be hidden and set as
            themselves.
          </div>
          <div class="mt-4">
            <VueInputGroup
              :validation-errors="validationErrors.get('administrated_by_id')"
            >
              <template v-slot:label>
                <label class="block text-theme-1 text-lg mb-1"
                  >Administrated By Member</label
                >
              </template>
              <Select2Input
                v-if="$store.getters['auth/isSuperAdmin']"
                v-model="formData.administrated_by_id"
                :validation-errors="validationErrors.get('administrated_by_id')"
                :options="memberSelect2Options"
              />
              <div class="text-lg font-medium" v-else>
                <template v-if="$_.get(detail, 'administrated_by', '')">
                  {{
                    `(${$_.get(
                      detail,
                      "administrated_by.acronym",
                      ""
                    )}) ${$_.get(detail, "administrated_by.title", "")}`
                  }}
                </template>
                <template v-else>-</template>
              </div>
            </VueInputGroup>
          </div>
        </div>
      </div>
      <div class="section-divider my-8">Identity</div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('surname')">
            <template v-slot:label>
              <label class="block mb-1">Last Name</label>
            </template>
            <VueInput
              placeholder="Enter last name"
              v-model="formData.surname"
              :validation-errors="validationErrors.get('surname')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('name')">
            <template v-slot:label>
              <label class="block mb-1">First Name</label>
            </template>
            <VueInput
              placeholder="Enter first name"
              v-model="formData.name"
              :validation-errors="validationErrors.get('name')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-6">
          <VueInputGroup
            :validation-errors="validationErrors.get('salutation')"
          >
            <template v-slot:label>
              <label class="block mb-1">Salutation</label>
            </template>
            <VueSelect
              v-model="formData.salutation"
              :validation-errors="validationErrors.get('salutation')"
            >
              <option value="">Select one of list</option>
              <option value="Mr.">Mr.</option>
              <option value="Ms.">Ms.</option>
              <option value="Mrs.">Mrs.</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('title')">
            <template v-slot:label>
              <label class="block mb-1">Title</label>
            </template>
            <VueInput
              placeholder="Enter title"
              v-model="formData.title"
              :validation-errors="validationErrors.get('title')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('email')">
            <template v-slot:label>
              <label class="block mb-1">Email</label>
            </template>
            <VueInput
              placeholder="Enter email"
              v-model="formData.email"
              :validation-errors="validationErrors.get('email')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-6" v-if="id">
          <VueInputGroup
            :validation-errors="validationErrors.get('notification_email')"
          >
            <template v-slot:label>
              <label class="block mb-1">Notification Email</label>
            </template>
            <VueInput
              placeholder="Enter email"
              v-model="formData.notification_email"
              :validation-errors="validationErrors.get('notification_email')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('password')">
            <template v-slot:label>
              <label class="block mb-1">Password</label>
            </template>
            <VueInput
              placeholder="Enter password"
              v-model="formData.password"
              :validation-errors="validationErrors.get('password')"
            />
          </VueInputGroup>
        </div>
      </div>
      <div class="section-divider my-8">Contact</div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-full sm:col-span-4">
          <VueInputGroup :validation-errors="validationErrors.get('phone')">
            <template v-slot:label>
              <label class="block mb-1">Phone</label>
            </template>
            <VueInput
              placeholder="Enter phone"
              v-model="formData.phone"
              :validation-errors="validationErrors.get('phone')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-6 md:col-span-4">
          <VueInputGroup :validation-errors="validationErrors.get('city')">
            <template v-slot:label>
              <label class="block mb-1">City</label>
            </template>
            <VueInput
              placeholder="Enter city"
              v-model="formData.city"
              :validation-errors="validationErrors.get('city')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-6 md:col-span-4">
          <VueInputGroup
            :validation-errors="validationErrors.get('country_code')"
          >
            <template v-slot:label>
              <label class="block mb-1">Country</label>
            </template>
            <Select2Input
              :options="countrySelect2Options"
              v-model="formData.country_code"
              :validation-errors="validationErrors.get('country_code')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full">
          <VueInputGroup :validation-errors="validationErrors.get('address')">
            <template v-slot:label>
              <label class="block mb-1">Address</label>
            </template>
            <VueTextarea
              placeholder="Enter address"
              v-model="formData.address"
              :validation-errors="validationErrors.get('address')"
            />
          </VueInputGroup>
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <VueButton
          :loading="processing"
          @click="onSubmit"
          class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
          >Save User
        </VueButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import VueButton from "@/global-components/form-elements/button/Main.vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueTextarea from "@/global-components/form-elements/textarea/Main.vue";
import VueSelect from "@/global-components/form-elements/select/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import { useRoute, useRouter } from "vue-router";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { userService, PostUser } from "@/services";

export default defineComponent({
  name: "CreateUser",
  components: { VueInput, VueSelect, VueTextarea, VueInputGroup, VueButton },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT USER (#${id.value})`;
      return "CREATE USER";
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });
    const memberSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          member: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "title",
              acronym: "acronym",
            },
          },
        },
        templateResult(obj: any): any {
          if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
          return obj.text;
        },
      };
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: userService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        const canEdit = _.get(data.value, "abilities.can_update", false);
        if (canEdit) {
          setFormData(data.value);
        } else {
          router.go(-1);
        }
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: userService.store,
      updateApiMethod: userService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        salutation: "",
        name: "",
        surname: "",
        email: "",
        notification_email: "",
        title: "",
        phone: "",
        city: "",
        country_code: "",
        address: "",
        password: "",
        administrated_by_id: null,
      } as PostUser,
      removeKeysIfEmptyValue: ["password", "administrated_by_id"],
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      id,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      countrySelect2Options,
      memberSelect2Options,
      detail: data,
      onSubmit,
    };
  },
});
</script>
