
import { computed, defineComponent, ref } from "vue";
import DashboardTaskList from "@/views/dashboard/TaskList.vue";
import SvgIconUsers from "@/global-components/svg-icon/SvgIconUsers.vue";

export default defineComponent({
  components: { SvgIconUsers, DashboardTaskList },
  setup() {
    const quickSearchUser = ref<string>("");
    const searchUserQuery = computed(() => ({
      q: quickSearchUser.value,
    }));

    return {
      quickSearchUser,
      searchUserQuery,
    };
  },
});
