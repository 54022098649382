import {
  GeneralApiResponse,
  GetLoginResult,
  GetUserResult,
  useAxios,
  getGeneralApiProblem,
} from "@/services";
import { AxiosError } from "axios";

export * from "./api.types";

export const authService = {
  profile: async (): Promise<GetUserResult> => useAxios().get(`/api/auth/me`),
  forgotPassword: async (
    params: Record<string, any>
  ): Promise<GeneralApiResponse> =>
    useAxios().store(`/api/auth/forgot-password`, params),
  resetPassword: async (
    params: Record<string, any>
  ): Promise<GeneralApiResponse> =>
    useAxios().store(`/api/auth/reset-password`, params),

  login: async (params: Record<string, any>): Promise<GetLoginResult> => {
    const result = await useAxios()
      .instance.post(`/api/auth/login`, params)
      .catch((error) => error);
    if (result instanceof Error) {
      return getGeneralApiProblem(result as AxiosError);
    }
    return {
      kind: "ok",
      data: _.get(result.data, "data", null),
      meta: _.get(result.data, "meta", null),
    };
  },

  loginAs: async (id: unknown): Promise<GetLoginResult> => {
    const result = await useAxios()
      .instance.post(`/api/auth/login-as/${id}`)
      .catch((error) => error);
    if (result instanceof Error) {
      return getGeneralApiProblem(result as AxiosError);
    }
    return {
      kind: "ok",
      data: _.get(result.data, "data", null),
      meta: _.get(result.data, "meta", null),
    };
  },
};
