import {
  GetRoleResults,
  useAxios,
  GeneralApiResponse,
  GetSubmissionResult,
} from "@/services";

export * from "./api.types";

export const roleService = {
  fetchRoleTypes: async (): Promise<GetRoleResults> =>
    useAxios().get(`/api/roles`),

  fetch: async (userId: unknown): Promise<GetRoleResults> =>
    useAxios().get(`/api/roles/${userId}`),

  store: async (
    userId: unknown,
    params: Record<string, any>
  ): Promise<GetSubmissionResult> =>
    useAxios().store(`/api/roles/${userId}`, params),

  update: async (
    userId: unknown,
    roleId: unknown,
    params: Record<string, any>
  ): Promise<GetSubmissionResult> =>
    useAxios().update(`/api/roles/${userId}/${roleId}`, params),

  delete: async (
    userId: unknown,
    roleId: unknown
  ): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/roles/${userId}/${roleId}`),
};
