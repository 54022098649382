// Create initial state

export type State = {
  darkMode: boolean;
  simpleMenu: boolean;
};
export const state: State = {
  darkMode: false,
  simpleMenu: false,
};
