<template>
  <div class="flex">
    <div>
      <VueButton
        class="button bg-gradient-to-b from-gray-400 to-gray-500 text-white relative pl-16 whitespace-nowrap"
        v-if="state.activeStep === 'summary' && !state.upload.processing"
        @click="() => onChangeStep('info')"
      >
        <div
          class="h-full absolute left-0 top-0 pointer-events-none bg-gradient-to-b from-gray-600 to-gray-700 text-white rounded-md py-2.5 px-3"
        >
          <ChevronLeftIcon class="inline w-6 h-6" />
        </div>
        Previous Step
      </VueButton>
    </div>
    <div class="text-right ml-4">
      <template v-if="state.activeStep === 'info'">
        <VueButton
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-if="formData.is_n_document"
          @click="onPreview"
        >
          Preview Document
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <EyeIcon class="inline w-6 h-6" />
          </div>
        </VueButton>
        <VueButton
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-else
          @click="onValidateAndUpload"
        >
          Upload The File
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <UploadCloudIcon class="inline w-6 h-6" />
          </div>
        </VueButton>
      </template>
      <VueButton
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        v-else
        @click="onUpload"
      >
        Upload The File
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <UploadCloudIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PostDocument } from "@/services";

export default defineComponent({
  name: "DocumentActionButtons",
  props: {
    formData: { type: Object as () => PostDocument, default: () => ({}) },
    state: { type: Object, default: () => ({}) },
    onUpload: { type: Function, required: true },
    onPreview: { type: Function, required: true },
    onValidateAndUpload: { type: Function, required: true },
    onChangeStep: { type: Function, required: true },
  },
});
</script>
