<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <AtSignIcon
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">EMAIL BLACKLIST</div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">Email</label>
          </template>
          <VueInput v-model="filter.query.email" @keydown.enter="applyFilter" />
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">From Time</label>
          </template>
          <Litepicker v-model="filter.query.from" />
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">To Time</label>
          </template>
          <Litepicker v-model="filter.query.to" />
        </VueInputGroup>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->

          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap w-1"></th>
                <th class="whitespace-nowrap">Email & Note</th>
                <th class="whitespace-nowrap">Registration Date</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.email"
              >
                <td>
                  <input
                    type="checkbox"
                    class="input input--lg border mr-2"
                    :value="row.email"
                    v-model="selectedRows"
                  />
                </td>
                <td>
                  <div class="font-bold">{{ row.email }}</div>
                  <div v-if="row.note" class="text-xs mt-0.5">
                    {{ row.note }}
                  </div>
                </td>
                <td>
                  {{
                    $h.formatDate($_.get(row, "created_at"), "DD.MM.YYYY HH:mm")
                  }}
                  GMT
                </td>
                <td class="text-right whitespace-nowrap">
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => triggerDelete([row.email])"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0)"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { emailBlacklistService } from "@/services";

export default defineComponent({
  name: "EmailBlacklist",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "blacklist",
        config: {
          "@page": 1,
          "@select": ["id", "email", "note", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
        filterFormat: {
          email: "@where._email",
          to: "@whereDateRange.created_at.to",
          from: "@whereDateRange.created_at.from",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      email: "",
      to: "",
      from: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: emailBlacklistService.delete,
      successCallback(email) {
        if (email) {
          const index = _.findIndex(list.data, { email });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
    };
  },
});
</script>
