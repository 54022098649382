
import { defineComponent, onMounted, ref } from "vue";
import { submissionService, GetProject } from "@/services";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import CreateOrUpdateSubmissionModal from "@/views/projects/view/submissions/CreateOrUpdateModal.vue";

export default defineComponent({
  name: "ProductSubmissions",
  components: {
    CreateOrUpdateSubmissionModal,
    Pagination,
    TransactionStatement,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props) {
    const router = useRouter();
    const refCreateOrUpdateModal = ref();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "submission",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "text",
            "files",
            "committee_id",
            "project_id",
            "secretary_id",
            "created_at",
          ],
          "@where": {
            project_id: props.projectId,
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: submissionService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const goToView = (uuid: any) => {
      if (uuid) {
        router.push({ name: "documents", params: { uuid } });
      }
    };

    const addSubmission = () => {
      cash("#submission-modal").modal("show");
    };

    const openEditSubmissionModal = (submission: Record<string, any>) => {
      if (refCreateOrUpdateModal.value)
        refCreateOrUpdateModal.value.openEditModal(submission);
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      refCreateOrUpdateModal,
      loading,
      list,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      changePage,
      changePerPage,
      addSubmission,
      goToView,
      openEditSubmissionModal,
    };
  },
});
