<template>
  <div>
    <div class="flex justify-end border-b pb-2.5 mb-2.5 border-gray-300">
      <Tippy
        tag="a"
        :options="{ placement: 'bottom' }"
        content="Full Screen"
        href="javascript:;"
        class="w-12 sm:w-1/5 inline-flex items-center justify-center hover:text-theme-1"
        @click="onToggleFullScreen"
      >
        <Maximize2Icon class="w-6 h-6" />
      </Tippy>
      <Tippy
        tag="a"
        :options="{ placement: 'bottom' }"
        content="Send Email To Members"
        href="javascript:;"
        class="w-12 sm:w-1/5 inline-flex items-center justify-center hover:text-theme-1"
        @click="onSendEmail"
        v-if="!isEmailView && canUpdate"
      >
        <SendIcon class="w-6 h-6" />
      </Tippy>
      <Tippy
        tag="a"
        :options="{ placement: 'bottom' }"
        content="Delete Document"
        href="javascript:;"
        class="w-12 sm:w-1/5 inline-flex items-center justify-center text-red-400 hover:text-red-600"
        @click="onDelete"
        v-if="canDelete"
      >
        <Trash2Icon class="w-6 h-6" />
      </Tippy>
      <div class="dropdown w-12 sm:w-1/5" v-if="isEmailView || canUpdate">
        <Tippy
          tag="a"
          :options="{ placement: 'bottom' }"
          content="Screen"
          href="javascript:;"
          class="dropdown-toggle w-full inline-flex items-center justify-center hover:text-theme-1"
        >
          <MoreVerticalIcon class="w-6 h-6" />
        </Tippy>
        <div class="dropdown-box w-44">
          <div class="dropdown-box__content box">
            <template v-for="row in tabs" :key="row.value">
              <a
                v-if="row.show"
                class="block py-3 px-4 border-b"
                href="javascript:;"
                data-dismiss="dropdown"
                data-toggle="tab"
                :data-target="`#${row.value}`"
              >
                <component :is="row.icon" class="w-5 h-5 inline-block mr-1.5" />
                {{ row.text }}
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-red-400 rounded-lg p-5 mb-5 text-white"
      v-if="
        $_.get(document, 'replaced_by.uuid', '') ||
        $_.get(document, 'cancelled_at', '')
      "
    >
      <div
        v-if="$_.get(document, 'cancelled_at', '')"
        :class="{
          'mb-3 pb-3 border-b border-dashed': $_.get(
            document,
            'replaced_by.uuid',
            ''
          ),
        }"
      >
        This document was cancelled on
        {{
          $h.formatDate($_.get(document, "cancelled_at", ""), "DD MMMM YYYY")
        }}.
      </div>
      <template v-if="$_.get(document, 'replaced_by.uuid', '')">
        <div class="mb-0.5">This document replaced by</div>
        <router-link
          class="hover:underline font-semibold"
          :to="{
            name: 'documents',
            params: { uuid: $_.get(document, 'replaced_by.uuid', '') },
          }"
        >
          N{{ $_.get(document, "replaced_by.n_number", "") }}
          {{ $_.get(document, "replaced_by.title", "") }}
        </router-link>
      </template>
    </div>
    <template v-if="document.n_number">
      <div class="grid grid-cols-12 gap-4 mb-2.5">
        <div class="col-span-4 text-gray-900 font-semibold">N Number:</div>
        <div class="col-span-8">{{ document.n_number || "-" }}</div>
      </div>
      <div class="grid grid-cols-12 items-center gap-4 mb-2.5">
        <div class="col-span-4 text-gray-900 font-semibold">Version:</div>
        <div class="col-span-8 flex">
          <VueSelect
            v-model="selectedVersionId"
            class="input !bg-gray-300 !shadow-none"
            :class="{ '!rounded-r-none': canCreateVersion }"
          >
            <option v-for="row in versions" :key="row.uuid" :value="row.uuid">
              Version {{ row.version }}
            </option>
          </VueSelect>
          <Tippy
            v-if="canCreateVersion"
            tag="button"
            content="Add New Version"
            :options="{ placement: 'bottom' }"
            class="button bg-gray-300 !rounded-l-none"
            @click="addNewVersion"
          >
            <PlusIcon class="w-4 h-4" />
          </Tippy>
        </div>
      </div>
    </template>
    <div class="grid grid-cols-12 gap-4 mb-2.5" v-if="document.status">
      <div class="col-span-4 text-gray-900 font-semibold">Status:</div>
      <div class="col-span-8">
        <div>{{ $h.convertIdToText(document.status, "documentStatus") }}</div>
        <div class="text-xs" v-if="canUpdate">
          <a
            v-if="document.status === 'published'"
            href="javascript:;"
            class="text-blue-500 hover:text-blue-600"
            @click="onCancelDocument"
            >Cancel the document</a
          >
          <a
            v-else-if="document.status === 'cancelled'"
            href="javascript:;"
            class="text-blue-500 hover:text-blue-600"
            @click="onPublishDocument"
            >Publish the document</a
          >
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mb-2.5">
      <div class="col-span-4 text-gray-900 font-semibold">Committee:</div>
      <div class="col-span-8">
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{
            name: 'committees.view',
            params: { id: $_.get(document, 'committee_id') },
          }"
        >
          ({{ $_.get(document, "committee.acronym", "") }})
          {{ $_.get(document, "committee.title", "") }}
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mb-2.5">
      <div class="col-span-4 text-gray-900 font-semibold">Title:</div>
      <div class="col-span-8">{{ document.title }}</div>
    </div>
    <div class="grid grid-cols-12 gap-4 mb-2.5">
      <div class="col-span-4 text-gray-900 font-semibold">Type:</div>
      <div class="col-span-8">
        {{ $store.getters["document/documentTypeTitle"](document.type) }}
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mb-2.5" v-if="document.expected_action">
      <div class="col-span-4 text-gray-900 font-semibold">Expected Action:</div>
      <div class="col-span-8">
        {{ $h.capitalizeEachWords(document.expected_action) }}
      </div>
    </div>
    <div
      class="grid grid-cols-12 gap-4 mb-2.5"
      v-if="document.exp_action_due_date"
    >
      <div class="col-span-4 text-gray-900 font-semibold">Due Date:</div>
      <div class="col-span-8">
        {{ $h.formatDate(document.exp_action_due_date, "DD MMMM YYYY") }}
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mb-2.5" v-if="document.document_date">
      <div class="col-span-4 text-gray-900 font-semibold">Document Date:</div>
      <div class="col-span-8">
        {{ $h.formatDate(document.document_date, "DD MMMM YYYY") }}
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mb-2.5" v-if="document.language">
      <div class="col-span-4 text-gray-900 font-semibold">Language:</div>
      <div class="col-span-8">
        {{ $h.capitalizeEachWords(document.language) }}
      </div>
    </div>
    <div
      class="grid grid-cols-12 gap-4 mb-2.5"
      v-if="
        document.resource_type &&
        document.resource_type !== 'committee' &&
        $_.get(document, 'resource.reference', '')
      "
    >
      <div class="col-span-4 text-gray-900 font-semibold">
        {{ $h.capitalizeEachWords(document.resource_type) }}:
      </div>
      <div class="col-span-8">
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{
            name: 'projects.view',
            params: { id: document.resource_id },
          }"
          v-if="document.resource_type === 'project'"
        >
          {{ $_.get(document, "resource.reference", "") }}
        </router-link>
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{ name: 'ballots.view', params: { id: document.resource_id } }"
          v-else-if="document.resource_type === 'ballot'"
        >
          {{ $_.get(document, "resource.reference", "") }}
        </router-link>
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{
            name: 'meetings.view',
            params: { id: document.resource_id },
          }"
          v-else-if="document.resource_type === 'meeting'"
        >
          {{ $_.get(document, "resource.title", "") }}
        </router-link>
        <template v-else>
          {{ $_.get(document, "resource.title", "") }}
        </template>
      </div>
    </div>
    <div
      class="grid grid-cols-12 gap-4 mb-2.5"
      v-if="$_.get(document, 'replaces.uuid', '')"
    >
      <div class="col-span-4 text-gray-900 font-semibold">Replaces:</div>
      <div class="col-span-8">
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{
            name: 'documents',
            params: { uuid: $_.get(document, 'replaces.uuid', '') },
          }"
        >
          <strong>N{{ $_.get(document, "replaces.n_number", "") }}</strong>
          {{ $_.get(document, "replaces.title", "") }}
        </router-link>
      </div>
    </div>
    <template v-if="isEmailView">
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-4 text-gray-900 font-semibold">Sent Date:</div>
        <div class="col-span-8">
          {{ $h.formatDate(document.created_at, "DD MMMM YYYY HH:mm") }} GMT
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mt-4 mb-2.5 text-lg font-semibold">
        Sender
      </div>
      <div class="grid grid-cols-12 gap-4 mb-1">
        <div class="col-span-4 text-gray-900 font-semibold">Name:</div>
        <div class="col-span-8">
          <router-link
            class="text-blue-500 hover:text-blue-600"
            :to="{
              name: 'users.view',
              params: { id: $_.get(document, 'sender.id', '') },
            }"
          >
            {{ $h.formatNameSurname($_.get(document, "sender")) }}
          </router-link>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-4 mb-1">
        <div class="col-span-4 text-gray-900 font-semibold">Email:</div>
        <div class="col-span-8">
          <a
            :href="`mailto:${$_.get(document, 'sender.email', '')}`"
            class="text-blue-500 hover:text-blue-600"
          >
            {{ $_.get(document, "sender.email", "") }}
          </a>
        </div>
      </div>
      <div
        class="grid grid-cols-12 gap-4 mb-1"
        v-if="$_.get(document, 'sender.phone', '')"
      >
        <div class="col-span-4 text-gray-900 font-semibold">Phone:</div>
        <div class="col-span-8">
          <a
            :href="`tel:${$_.get(document, 'sender.phone', '')}`"
            class="text-blue-500 hover:text-blue-600"
          >
            {{ $_.get(document, "sender.phone", "") }}
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import {
  DOCUMENT_TYPES,
  documentService,
  fetchSelection,
  GetDocument,
} from "@/services";
import { useRouter } from "vue-router";
import _ from "lodash";
import { useStore } from "@/store";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "DocumentSummary",
  props: {
    documentUuid: {
      type: String,
      default: () => "",
    },
    document: {
      type: Object as () => GetDocument,
      default: () => ({}),
    },
    onSendEmail: {
      type: Function,
      default: () => null,
    },
    onCancelDocument: {
      type: Function,
      default: () => null,
    },
    onPublishDocument: {
      type: Function,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { deleteRecords } = useGeneralActions();

    const versions = ref<any[]>([]);

    const isEmailView = computed<boolean>(
      () => props.document.type === DOCUMENT_TYPES.MAIL
    );
    const selectedVersionId = computed({
      get: () => props.documentUuid,
      set: (uuid: any) =>
        router.push({
          name: "documents",
          params: { uuid },
        }),
    });
    const lastVersion = computed(() => {
      return _.get(
        _.orderBy(versions.value, ["version"], ["desc"]),
        "0",
        props.document
      );
    });

    const isToBePublished = computed<boolean>(
      () => _.get(lastVersion.value, "status", "") === "to_be_published"
    );

    const canDelete = computed<boolean>(() => {
      return (
        isToBePublished.value &&
        _.get(props.document, "abilities.can_delete", false) &&
        !_.get(props.document, "versioned_at", "")
      );
    });

    const canUpdate = computed<boolean>(() => {
      return _.get(props.document, "abilities.can_update", false);
    });

    const canCreateVersion = computed<boolean>(() => {
      return (
        isToBePublished.value &&
        store.getters["auth/canCreateDocument"]() &&
        canUpdate.value &&
        !_.get(props.document, "versioned_at", "")
      );
    });

    const { triggerDelete } = deleteRecords({
      text: `Do you want to delete this document?`,
      deleteApiMethod: documentService.delete,
      successCallback() {
        router.go(-1);
      },
    });

    const fetchVersionDocuments = () => {

      if(!props.document.committee_id){
        return null
      }

      fetchSelection({
        document: {
          "@get": true,
          "@select": [
            "uuid",
            "title",
            "n_number",
            "version",
            "status",
            "folder_id",
            "committee_id",
          ],
          "@where": {
            committee_id: props.document.committee_id,
            language: props.document.language,
            n_number: props.document.n_number,
          },
          "@func": ["withVersioned"],
        },
      }).then((result) => {
        if (result.kind === "ok") {
          versions.value = _.get(result.data, "document.data", []);
        }
      });
    };

    const addNewVersion = () => {
      if (isToBePublished.value) {
        const folderId = _.get(lastVersion.value, "folder_id", null);
        const routeQuery = {
          name: "documents.create",
          params: {
            committeeId: _.get(lastVersion.value, "committee_id", ""),
          },
          query: {
            v: _.get(lastVersion.value, "uuid", ""),
          },
        };
        if (folderId) {
          _.set(routeQuery, "query.folderId", folderId);
        }
        router.push(routeQuery);
      }
    };

    const onDelete = () => triggerDelete([props.documentUuid]);

    const onToggleFullScreen = () => {

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // mobile
        window.open(_.get(props.document, 'file_path', ''), "_blank");
      }else{
        // not mobile
        cash("#document--screen").fullScreen("toggle");
      }

    };

    onMounted(() => {
      fetchVersionDocuments();
    });

    return {
      versions,
      isEmailView,
      selectedVersionId,
      canUpdate,
      canDelete,
      canCreateVersion,
      addNewVersion,
      onDelete,
      onToggleFullScreen,
      tabs: [
        {
          value: "preview",
          text: "Preview",
          icon: "book-open-icon",
          show: true,
        },
        {
          value: "documents",
          text: "Documents",
          icon: "layers-icon",
          show: isEmailView.value,
        },
        {
          value: "recipients",
          text: "Recipients",
          icon: "users-icon",
          show: isEmailView.value,
        },
        {
          value: "logs",
          text: "Logs",
          icon: "activity-icon",
          show: canUpdate.value,
        },
      ],
    };
  },
});
</script>
