
import { defineComponent } from "vue";
import { GetDocument } from "@/services";

export default defineComponent({
  name: "ImageView",
  props: {
    document: {
      type: Object as () => GetDocument,
      required: true,
    },
  },
});
