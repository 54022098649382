
import { computed, defineComponent, inject, onMounted, Ref } from "vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { committeeService, GetCommittee } from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import { SendEmailRef } from "@/components/email-to-members";

export default defineComponent({
  name: "CommitteeUsersWithVoters",
  components: { Pagination, TransactionStatement },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await committeeService.fetchCommitteeUsersFullList(
          props.committee.id,
          query
        );
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const headerTitle = computed(() => {
      return "Committee Users List + Voters";
    });

    const goToView = (value: any) => {
      router.push({ name: "users.view", params: { id: value } });
    };

    const onSendEmail = () => {
      sendEmailRef?.value.show({
        committee: props.committee,
        users_full_list: true,
      });
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      headerTitle,
      loading,
      list,
      filter,
      changePage,
      changePerPage,
      goToView,
      onSendEmail,
    };
  },
});
