
import { computed, defineComponent, reactive, toRefs, watch } from "vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "DocumentFilter",
  components: { VueInputGroup },
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    apply: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const editableFilter = reactive(toRefs(props.filter));

    const aggregation = computed(() => store.state.document.aggregation);
    const visibleCommittees = computed(() => {
      return store.state.committee.committees
        .map((committee) => {
          const index = _.findIndex(aggregation.value, {
            value: committee.acronym,
            type: "committee",
          });
          return {
            ...committee,
            count: _.get(aggregation.value, [index, "count"], 0) * 1,
          };
        })
        .filter((committee) => {
          return committee.count > 0;
        });
    });

    const documentTypes = computed(() => store.state.document.types);
    const visibleDocumentTypes = computed(() => {
      return documentTypes.value.map((type) => {
        const children = (type.children || [])
          .map((child) => {
            const index = _.findIndex(aggregation.value, {
              value: child.value.toString(),
              type: "type",
            });
            return {
              ...child,
              count: _.get(aggregation.value, [index, "count"], 0) * 1,
            };
          })
          .filter((child) => child.count > 0);
        return {
          text: type.text,
          value: type.value,
          sub_values: children.map((child) => child.value),
          count: _.sumBy(children, "count"),
          children,
        };
      });
    });

    const expectedActions = computed<any[]>(() =>
      aggregation.value.filter((agg) => agg.type === "expected_action")
    );
    const visibleExpectedActions = computed(() => {
      return expectedActions.value
        .map((type) => {
          const index = _.findIndex(aggregation.value, {
            value: type.value.toString(),
            type: "expected_action",
          });
          return {
            text: type.title,
            value: type.value,
            count: _.get(aggregation.value, [index, "count"], 0),
          };
        })
        .filter((item) => item.count > 0);
    });

    const status = computed<any[]>(() =>
      aggregation.value.filter((agg) => agg.type === "status")
    );
    const visibleStatus = computed(() => {
      return status.value
        .map((type) => {
          const index = _.findIndex(aggregation.value, {
            value: type.value.toString(),
            type: "status",
          });
          return {
            text: type.title,
            value: type.value,
            count: _.get(aggregation.value, [index, "count"], 0),
          };
        })
        .filter((item) => item.count > 0);
    });

    watch(editableFilter, () => props.apply(), { flush: "post", deep: true });

    return {
      visibleCommittees,
      visibleDocumentTypes,
      visibleExpectedActions,
      visibleStatus,
      editableFilter,
    };
  },
});
