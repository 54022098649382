
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { getTaskableTitle } from "@/views/task-manager";
import { Select2Options } from "@/global-components/select2";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "TodoLogs",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const refDetailModal = ref<HTMLElement>();
    const selectedRow = ref<any>(null);

    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "task",
        config: {
          "@select": [
            "id",
            "name",
            "scope_type",
            "scope_id",
            "due_at",
            "created_at",
          ],
          "@func": ["withCount"],
          "@order": "created_at:desc",
          users: {
            "@select": [
              "id",
              "name",
              "surname",
              "salutation",
              "title",
              "email",
            ],
          },
          scope: {
            "@select": ["*"],
          },
          taskable: {
            "@select": ["*"],
          },
        },
        filterFormat: {
          type: "@where.taskable_type",
          taskable_id: "@where.taskable_id",
          scope_type: "@where.scope_type",
          scope_id: "@where.scope_id",
          user_id: "@whereHas.users.id",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
      type: "",
      taskable_id: "",
      scope_type: "",
      scope_id: "",
      user_id: "",
    });

    const scopeSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          [filter.query.scope_type]: {
            "@select": { value: "id", acronym: "acronym", text: "title" },
          },
        },
        templateResult(obj: any): any {
          if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
          return obj.text;
        },
      };
    });

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            salutation: "salutation",
            title: "title",
          },
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.name) return helper.formatNameSurname(obj);
        return obj.text;
      },
    });

    watch(
      () => filter.query.scope_type,
      () => (filter.query.scope_id = "")
    );

    const onSelectRow = (row: any) => {
      selectedRow.value = row;
      cash("#todo-detail-modal").modal("show");
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    onBeforeUnmount(() => {
      cash(refDetailModal.value || "#todo-detail-modal").modal("hide");
    });

    return {
      refDetailModal,
      loading,
      list,
      filter,
      selectedRow,
      scopeSelect2Options,
      userSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      getTaskableTitle,
      onSelectRow,
    };
  },
});
