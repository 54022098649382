<template>
  <div class="accordion__pane py-4">
    <div class="flex justify-between">
      <a
        href="javascript:;"
        class="accordion__pane__toggle relative font-medium text-2xl text-gray-600 block pr-24 whitespace-nowrap overflow-hidden overflow-ellipsis"
      >
        Meetings
        <template v-if="!loading">({{ list.meta.total }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <Tippy
        v-if="$_.get(committee, 'abilities.can_create_meeting', false)"
        tag="button"
        content="Add Meeting"
        :options="{ placement: 'bottom' }"
        class="dropdown-toggle button p-0 absolute right-4"
        @click="
          () =>
            $router.push({
              name: 'meetings.create',
              query: { committee_id: committeeId },
            })
        "
      >
        <PlusSquareIcon class="w-8 h-8" />
      </Tippy>
    </div>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <!-- BEGIN: Table -->
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Title</th>
                <th class="whitespace-nowrap">Meeting Place</th>
                <th class="whitespace-nowrap text-center">Status</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in list.data" :key="'meeting-' + row.id">
                <td>
                  <router-link
                    :to="{ name: 'meetings.view', params: { id: row.id } }"
                    class="text-blue-500 hover:text-blue-700"
                    >{{ $_.get(row, "title") }}
                  </router-link>
                </td>
                <td class="whitespace-nowrap">
                  <div v-if="$_.get(row, 'type', '') === 'offline'">
                    {{
                      $_.get(
                        row,
                        "country.title",
                        $_.get(row, "country_code", "")
                      )
                    }}
                    /
                    {{ $_.get(row, "city") }}
                  </div>
                  <div v-else>Online</div>
                  <div class="text-theme-1 text-xs">
                    {{
                      $h.formatDate($_.get(row, "time"), "DD MMMM YYYY HH:mm")
                    }}
                    GMT
                  </div>
                </td>
                <td class="text-center">{{ $_.get(row, "status") }}</td>
                <td class="text-right whitespace-nowrap">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToView(row.id)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Table -->
        <Pagination
          class="mt-2"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-lg text-gray-500">
        No meetings found for this committee!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee } from "@/services";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CommitteeMeetings",
  components: { Pagination },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "meeting",
        config: {
          "@paginate": 5,
          "@page": 1,
          "@select": [
            "id",
            "committee_id",
            "status",
            "title",
            "city",
            "country_code",
            "time",
            "type",
          ],
          "@where": {
            committee_id: _.get(props, "committee.id", props.committeeId),
          },
          "@func": ["withCount"],
          "@order": "time:desc",
          country: {
            "@select": ["id", "title", "code"],
          },
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    const goToView = (value: any) => {
      router.push({ name: "meetings.view", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      changePage,
      changePerPage,
      goToView,
    };
  },
});
</script>
