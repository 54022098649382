
import { defineComponent } from "vue";

export default defineComponent({
  name: "TransactionStatement",
  props: {
    tag: { required: true },
    processing: { type: Boolean, default: () => false },
    waiting: { type: Boolean, default: () => false },
  },
  setup() {
    return {};
  },
});
