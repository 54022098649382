<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconWorldCircle
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">TO-DO LIST</div>
      <div class="text-white text-sm font-light">
        A simple task manager which allows Secretaries, Chairmen and Voters to
        keep track of all their Tasks and To-Dos.
      </div>
    </div>
    <div class="p-3 sm:px-5">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->

          <table class="table w-full">
            <thead>
              <tr>
                <th class="">Todo</th>
                <th class="whitespace-nowrap w-1 text-center">Due Date</th>
                <th class="whitespace-nowrap w-1 text-center">
                  Completed Date
                </th>
                <th class="whitespace-nowrap w-1 text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :class="{ 'opacity-50 line-through': !!row.completed_at }"
              >
                <td>
                  <div>{{ row.name }}</div>
                  <div
                    class="text-xs font-light"
                    v-html="getTaskableTitle(row)"
                  ></div>
                </td>
                <td class="whitespace-nowrap text-center">
                  {{ $h.formatDate(row.due_at, "DD MMM YYYY") || "-" }}
                </td>
                <td class="whitespace-nowrap text-center">
                  {{ $h.formatDate(row.completed_at, "DD MMM YYYY") || "-" }}
                </td>
                <td class="whitespace-nowrap text-right">
                  <Tippy
                    tag="button"
                    content="Go To Page"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => onCompleteTask(row)"
                    v-if="row.completion_event && !row.completed_at"
                  >
                    <ArrowRightIcon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Complete The Task"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => onCompleteTask(row)"
                    v-if="!row.completion_event && !row.completed_at"
                  >
                    <DiscIcon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, Ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { taskService, GetTask } from "@/services";
import { completeTask, getTaskableTitle } from "@/views/task-manager";
import { useRouter } from "vue-router";
import { ConfirmRef } from "@/components/confirm";
import { SendEmailRef } from "@/components/email-to-members";

export default defineComponent({
  name: "TodoList",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const { fetchRecords, filterRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      fetchApiMethod: taskService.fetch,
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    const onCompleteTask = (task: GetTask) => {
      completeTask(task, router, confirmRef, sendEmailRef);
    };

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      getTaskableTitle,
      onCompleteTask,
    };
  },
});
</script>
