<template>
  <TransactionStatement
    tag="tr"
    :processing="processing && transactionIds.indexOf(item.id) > -1"
    :waiting="transactionIds[0] !== item.id"
  >
    <td class="text-center whitespace-nowrap w-0">
      <div class="inline-block bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md">
        {{ $_.get(item, "stage", "00:00") }}
      </div>
      <div
        class="text-blue-500 text-xs mt-1"
        v-if="
          $_.get(item, 'abilities.can_update', false) &&
          $_.get(item, 'stage') !== '60.60'
        "
      >
        <a href="javascript:;" @click="onLaunchAction">
          <template v-if="visibleDecision">Proceed To Decision</template>
          <template v-else-if="visibleLaunchNewBallot"
            >Launch New Ballot</template
          >
        </a>
      </div>
    </td>
    <td class="text-center">
      <div
        class="inline-block bg-yellow-500 bg-opacity-40 px-3 py-1 rounded-md"
      >
        {{ $_.get(item, "committee.acronym", "") }}
      </div>
    </td>
    <td>
      <div class="font-medium">{{ $_.get(item, "reference", "") }}</div>
      <div class="text-xs">{{ $_.get(item, "title_en", "") }}</div>
    </td>
    <td class="text-center whitespace-nowrap">
      {{
        $h.formatDate(
          $_.get(item, "current_line.start_date", ""),
          "DD MMMM YYYY"
        ) || "-"
      }}
    </td>
    <td class="text-center whitespace-nowrap">
      {{
        $h.formatDate(
          $_.get(item, "current_line.target_date", ""),
          "DD MMMM YYYY"
        ) || "-"
      }}
    </td>
    <td class="text-right">
      <div class="inline-flex">
        <Tippy
          tag="button"
          content="See Details"
          :options="{ placement: 'bottom' }"
          class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
          @click="() => goToView(item.id)"
        >
          <EyeIcon class="inline w-5 h-5" />
        </Tippy>
        <Tippy
          tag="button"
          content="Delete"
          :options="{ placement: 'bottom' }"
          class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
          @click="() => triggerDelete([item.id])"
          v-if="$_.get(item, 'abilities.can_delete', false)"
        >
          <Trash2Icon class="inline w-5 h-5" />
        </Tippy>
      </div>
    </td>
  </TransactionStatement>
</template>

<script lang="ts">
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { launchActionForProject } from "@/views/projects/actions";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProjectListItem",
  components: { TransactionStatement },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    transactionIds: {
      type: Array,
      default: () => [],
    },
    processing: {
      type: Boolean,
      default: () => false,
    },
    triggerDelete: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const goToView = (value: any) => {
      router.push({ name: "projects.view", params: { id: value } });
    };

    const visibleDecision = computed<boolean>(() => {
      return store.state.project.manualStages.indexOf(props.item.stage) > -1;
    });

    const visibleLaunchNewBallot = computed<boolean>(() => {
      return (
        store.getters["auth/canCreateCommitteeBallot"](
          _.get(props.item, "committee_id", 0)
        ) && store.state.project.ballotStages.indexOf(props.item.stage) > -1
      );
    });

    const onLaunchAction = () =>
      launchActionForProject({
        projectId: _.get(props.item, "id", 0) * 1,
        committeeId: _.get(props.item, "committee_id", 0) * 1,
        stage: _.get(props.item, "stage", ""),
        route,
        router,
        store,
      });

    return {
      visibleDecision,
      visibleLaunchNewBallot,
      goToView,
      onLaunchAction,
    };
  },
});
</script>
