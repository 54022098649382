<template>
  <div class="divide-y">
    <div class="accordion__pane py-4">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >
        {{ headerTitle }} ({{ structures.length }})
      </a>
      <div class="accordion__pane__content mt-3 leading-relaxed">
        <div class="overflow-x-auto mt-5">
          <table class="table w-full">
            <thead>
            <tr>
              <th class="whitespace-nowrap">Acronym</th>
              <th class="whitespace-nowrap">Title</th>
              <th class="whitespace-nowrap text-right">Functions</th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="row in structures"
              :key="'record-' + row.id"
            >
              <td class="whitespace-nowrap w-1">
                <div
                  class="inline-block bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md"
                >
                  {{ row.acronym }}
                </div>
              </td>
              <td>
                <div class="font-bold">{{ row.title }}</div>
                <div class="text-xs">{{ $h.convertIdToText(row.type, "committeeTypes") }}</div>
              </td>
              <td class="text-right">
                <div class="inline-flex">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                    @click="() => goToView(row.id)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { GetCommittee } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CommitteeStructures",
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter()
    const headerTitle = computed(() => {
      return "Structures";
    });

    const structures = computed(() => {
      const subCommittees: any[] = _.get(props.committee, "sub_committees", []);
      subCommittees.splice(0, 0, {
        id: _.get(props.committee, "id", null),
        type: _.get(props.committee, "type", null),
        acronym: _.get(props.committee, "acronym", null),
        title: _.get(props.committee, "title", null),
      });
      return subCommittees;
    });

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    return {
      headerTitle,
      structures,
      goToView
    };
  },
});
</script>
