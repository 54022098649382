<template>
  <svg
    width="140"
    height="140"
    viewBox="0 0 140 140"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M132.365 7.6352C127.441 2.71168 120.895 0.000273438 113.932 0.000546875L26.0676 0C19.1045 0.000273438 12.5584 2.71195 7.63492 7.63547C2.71141 12.559 0 19.1051 0.000546875 26.0676L0 113.932C0.000546875 128.306 11.6946 140 26.0679 140H113.932C128.306 140 140 128.306 140 113.932V26.0684C140 19.1051 137.289 12.5587 132.365 7.6352ZM45.3534 49.3071L67.0991 27.5606C67.8683 26.7914 68.9117 26.3591 69.9992 26.3591C71.0872 26.3591 72.1301 26.7911 72.8993 27.5606L94.6449 49.3057C96.247 50.9072 96.247 53.5043 94.6455 55.1061C93.0434 56.7077 90.4468 56.7082 88.8445 55.1061C83.4035 49.6652 74.1004 53.5187 74.1005 61.2134L74.1007 84.1168C74.1007 86.3819 72.2646 88.2183 69.9992 88.2183C67.7337 88.2183 65.8976 86.3819 65.8976 84.1168L65.8972 61.2141C65.897 53.5198 56.5942 49.6666 51.1536 55.1075C50.3527 55.9084 49.303 56.309 48.2535 56.309C47.2035 56.309 46.1541 55.9087 45.3532 55.1075C43.7516 53.506 43.7516 50.9091 45.3534 49.3071ZM109.539 113.641H30.4612C28.1963 113.641 26.3596 111.804 26.3596 109.539C26.3596 107.274 28.1963 105.438 30.4612 105.438H109.54C111.804 105.438 113.641 107.274 113.641 109.539C113.641 111.804 111.804 113.641 109.539 113.641Z"
      fill="currentColor"
    />
  </svg>
</template>
s
