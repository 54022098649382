<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <!-- BEGIN: Top Buttons -->
      <div class="flex justify-end transform -translate-y-9 sm:-translate-y-11">
        <VueButton
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          @click="onSubmit"
          :loading="processing"
        >
          Save Project
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <SaveIcon class="inline w-6 h-6" />
          </div>
        </VueButton>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <div class="accordion divide-y">
          <ProjectEditInformation
            :project-id="id"
            :form-data="formData"
            :validation-errors="validationErrors"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetProjectResult, PostProject, projectService } from "@/services";
import { useRoute, useRouter } from "vue-router";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { useStore } from "@/store";
import ProjectEditInformation from "@/views/projects/create/Information.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostProject;
}

export default defineComponent({
  name: "CreateProject",
  components: { ProjectEditInformation },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: projectService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: projectService.store,
      updateApiMethod: projectService.update,
      successCallback(result: GetProjectResult) {
        if (result.kind === "ok") {
          if (!id.value) {
            router.replace({
              name: "projects.view",
              params: {
                id: result.data.id,
              },
            });
          } else {
            router.go(-1);
          }
        }
      },
      params: {
        reference_suffix: "",
        sequence: "",
        committee_id: _.get(route.query, "committee_id", "") || "",
        wg_id: "",
        title_en: "",
        description_en: "",
        title_fr: "",
        description_fr: "",
        title_ar: "",
        description_ar: "",
        price: "",
        edition_no: "",
        visible_on_website: "0",
        leader_user_id: "",
        details: "",
        stage: _.get(store.state, `project.initialTypes.0.value`, ""),
        time_frame: "24",
        project_no: "",
        part_no: "",
      } as PostProject,
    }) as CreateOrUpdate;

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT PROJECT (#${id.value})`;
      return "CREATE PROJECT";
    });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    return {
      id,
      loading,
      headerTitle,
      processing,
      formData,
      project: data,
      validationErrors,
      onSubmit,
    };
  },
});
</script>
