<template>
  <form @submit.prevent="submit">
    <div class="intro-x mt-8 text-2xl text-gray-600">Reset Password</div>
    <div class="intro-x mt-1 text-small text-gray-600 font-light">
      We need your e-mail address so we can send a password reset link.
    </div>
    <div class="intro-x mt-8">
      <VueInputGroup :validation-errors="validationErrors.get('email')">
        <VueInput
          type="email"
          class="intro-x login__input input--lg"
          placeholder="Email Address"
          v-model="formData.email"
          :validation-errors="validationErrors.get('email')"
        />
      </VueInputGroup>
    </div>
    <div class="intro-x mt-4">
      <VueButton
        :loading="loading"
        type="submit"
        class="button button--lg w-full text-white bg-theme-4 align-top shadow-xl"
      >
        <span class="mr-2">Send Password Reset Link</span>
        <SendIcon class="inline" />
      </VueButton>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { authService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  props: {
    email: {
      type: String,
      default: "",
    },
  },
  components: {},
  setup(props) {
    const loading = ref<boolean>(false);
    const formData = reactive({
      email: props.email,
    });

    const validationErrors = ref(new ValidationErrors());

    const submit = () => {
      validationErrors.value.clear();
      loading.value = true;
      authService.forgotPassword(formData)
        .then((result) => {
          if (result.kind === "ok") {
            ToastNotify({
              text: "The link to reset the password has been sent by email.",
              className: "success",
            });
          } else {
            if (result.kind === "validation")
              validationErrors.value.record(result.fields);
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      loading,
      formData,
      validationErrors,
      submit,
    };
  },
});
</script>
