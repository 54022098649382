
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import createOrUpdate, {
  GeneralCreateOrUpdate,
} from "@/utils/actions/create-or-update";
import {
  submissionService,
  GetSubmissionResult,
  PostSubmission,
} from "@/services";
import _ from "lodash";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostSubmission;
}

export default defineComponent({
  name: "CreateOrUpdateSubmissionModal",
  props: {
    list: { type: Array as () => Record<string, any>[], default: () => [] },
    filterQuery: { type: Object, default: () => ({}) },
    projectId: { default: () => null },
    committeeId: { default: () => null },
  },
  setup(props, { emit }) {
    const localList = ref<Record<string, any>[]>(props.list);
    watch(
      () => props.list,
      (val) => (localList.value = val),
      { deep: true, flush: "post" }
    );

    const submissionId = ref<any>(null);
    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: submissionService.store,
      updateApiMethod: submissionService.update,
      successCallback(result: GetSubmissionResult) {
        if (result.kind === "ok") {
          const submissionIndex = _.findIndex(props.list, {
            id: submissionId.value,
          });
          if (submissionIndex > -1) {
            localList.value.splice(submissionIndex, 1, result.data);
          } else {
            localList.value.splice(0, 0, result.data);
            emit("update:add-item");
          }
          submissionId.value = null;
          setFormData({ name: "", description: "" });
          emit("update:list", localList.value);
          cash("#submission-modal").modal("hide");
        }
      },
      params: {
        text: "",
        files: [],
        project_id: props.projectId,
      },
    }) as CreateOrUpdate;

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            uuid: "uuid",
            n_number: "n_number",
            version: "version",
          },
          "@order": ["n_number:desc", "created_at:desc"],
          "@where": {
            committee_id: props.committeeId,
          },
          "@whereNot": {
            uuid: formData.files.map((file) => file.uuid),
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const openEditModal = (submission: Record<string, any>) => {
      submissionId.value = submission.id;
      setFormData({
        text: _.cloneDeep(_.get(submission, "text", "")),
        files: _.cloneDeep(_.get(submission, "files", [])),
      });
      cash("#submission-modal").modal("show");
    };

    const onSubmit = () => submit(submissionId.value);

    const documentValue = ref<string>("");
    const onAddSubmissionFile = (document: Record<string, any>) => {
      if (document) {
        formData.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.text,
          n_number: document.n_number,
          file_name: document.file_name,
          file_size: document.file_size,
        });
        setTimeout(() => {
          documentValue.value = "";
        }, 100);
      }
    };

    onMounted(() => {
      cash("#submission-modal").modal("on.hide", () => {
        submissionId.value = null;
        setFormData({ text: "", files: [] });
      });
    });

    return {
      documentValue,
      processing,
      formData,
      validationErrors,
      documentSelect2Options,
      openEditModal,
      onSubmit,
      onAddSubmissionFile,
    };
  },
});
