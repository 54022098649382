
import { computed, defineComponent, onMounted, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import {
  committeeService,
  GetCommittee,
  GetCommitteeResult,
  PostCommittee
} from "@/services";
import CommitteeIdentity from "@/views/committees/create/Identity.vue";
import CommitteeLiaisons from "@/views/committees/create/CommitteeLiaisons.vue";
import OrganizationLiaisons from "@/views/committees/create/OrganizationLiaisons.vue";
import CommitteeMemberContainer from "@/views/committees/create/MemberContainer.vue";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetCommittee>;
}

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostCommittee;
}

export default defineComponent({
  name: "CreateCommittee",
  components: {
    CommitteeMemberContainer,
    OrganizationLiaisons,
    CommitteeLiaisons,
    CommitteeIdentity
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data: committee, fetch } = fetchRecord({
      fetchApiMethod: committeeService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData({
          ...committee.value,
          level: committee.value.level.level,
          level_1_type: committee.value.level.level_1.type,
          level_2_type: committee.value.level.level_2.type,
          level_3_type: committee.value.level.level_3.type,
          level_1_number: committee.value.level.level_1.number,
          level_2_number: committee.value.level.level_2.number,
          level_3_number: committee.value.level.level_3.number
        });
      }
    }) as FetchRecord;

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit
    } = createOrUpdate({
      createApiMethod: committeeService.store,
      updateApiMethod: committeeService.update,
      successCallback(result: GetCommitteeResult) {
        if (result.kind === "ok") {
          if (!id.value) {
            router.replace({
              name: "committees.edit",
              params: {
                id: result.data.id
              }
            });
          } else {
            router.go(-1);
          }
        }
      },
      params: {
        title: "",
        acronym: "",
        scope: "",
        creation_date: "",
        type: "",
        level: 1,
        level_1_type: _.get(route.params, "type", "TC"),
        level_1_number: null,
        level_2_type: "",
        level_2_number: null,
        level_3_type: "",
        level_3_number: null,
        assistant_user_id: "",
        chairperson_user_id: "",
        secretary_user_id: "",
        secretariat_member_id: "",
        chairperson_info: "",
        chairperson_user_term: ""
      }
    }) as CreateOrUpdate;

    const id = computed(() => route.params.id);

    const committeeType = computed(() => {
      if (formData.level_3_type) return formData.level_3_type;
      if (formData.level_2_type) return formData.level_2_type;
      return formData.level_1_type;
    });

    const isWg = computed(() => {
      return committeeType.value === "WG";
    });

    const committeeOrWorkingGroup = computed(() => {
      return isWg.value ? "Working Group" : "Committee";
    });

    const headerTitle = computed<string>(() => {
      if (id.value) return `Edit ${committeeOrWorkingGroup.value} ${formData.acronym || id.value}`;
      return "CREATE";
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      id,
      committee,
      committeeType,
      isWg,
      committeeOrWorkingGroup,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit
    };
  }
});
