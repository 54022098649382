
import { defineComponent, onMounted, ref, watch } from "vue";
import { fetchSelection } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Relations",
  props: {
    meetingId: {
      required: true,
    },
    committeeId: {
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const router = useRouter();
    const list = ref<Record<string, any>>([]);
    const fetch = () => {
      if (props.committeeId) {
        fetchSelection({
          meeting: {
            "@get": true,
            "@select": [
              "id",
              "title",
              "city",
              "country_code",
              "time",
              "status",
              "type",
            ],
            "@where": {
              committee_id: props.committeeId,
            },
            "@whereNot": {
              id: props.meetingId,
            },
          },
        }).then((result) => {
          if (result.kind === "ok") {
            list.value = result.data.meeting;
          }
        });
      }
    };

    const goToView = (value: any) => {
      router.push({ name: "meetings.view", params: { id: value } });
    };

    watch(() => props.committeeId, fetch, { flush: "post" });
    onMounted(() => {
      fetch();
    });

    return {
      list,
      goToView,
    };
  },
});
