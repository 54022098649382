import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { GetRoleType } from "@/services";

// Getters types
export type Getters = {
  roleType(state: LocalState): (behaviour: string) => GetRoleType | null;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  roleType: (state) => (behaviour) => {
    const index = _.findIndex(state.roleTypes, { behaviour });
    return _.get(state.roleTypes, index, null);
  },
};
