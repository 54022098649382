
import { defineComponent, onMounted, ref, watch } from "vue";
import { Editor, EditorContent } from "@tiptap/vue-3";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import Heading from "@tiptap/extension-heading";
import HardBreak from "@tiptap/extension-hard-break";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  components: { VueInputGroup, VueInput, EditorContent },
  name: "VueTiptap",
  props: {
    modelValue: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "Write something …",
    },
  },
  setup(props, { emit }) {
    const editorUuid = ref(helper.uuidv4());
    const editor = ref<Editor>();

    onMounted(() => {
      editor.value = new Editor({
        content: props.modelValue,
        extensions: [
          Document,
          Paragraph,
          Text,
          TextAlign,
          Bold,
          Heading.configure({
            levels: [1, 2],
          }),
          BulletList,
          ListItem,
          Link,
          HardBreak,
          Dropcursor,
          Gapcursor,
          Placeholder.configure({
            placeholder: props.placeholder,
          }),
        ],
        onUpdate: (ctx) => {
          emit("update:modelValue", ctx.editor.getHTML());
          emit("update:json-data", ctx.editor.getJSON());
        },
      });

      if (props.modelValue) {
        emit("update:json-data", editor.value?.getJSON());
      }

      cash("body").on(
        "click",
        'a[rel="noopener noreferrer nofollow"]',
        (e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }
      );
    });

    watch(
      () => props.modelValue,
      (val: any) => {
        if (editor.value && val !== editor.value?.getHTML()) {
          editor.value?.commands.setContent(val || "");
          emit("update:json-data", editor.value?.getJSON());
        }
      },
      { flush: "post" }
    );

    const linkRef = ref<string>("");

    const openLinkModal = () => {
      linkRef.value = _.get(
        editor.value?.getMarkAttributes("link"),
        "href",
        ""
      );
      cash(`#${editorUuid.value}`).modal("show");
    };

    const clearLink = () => {
      editor.value?.chain().focus().unsetLink().run();
    };

    const saveLink = () => {
      if (!linkRef.value) clearLink();
      else {
        editor.value?.chain().focus().setLink({ href: linkRef.value }).run();
      }
      hiddenLinkModal();
    };

    const hiddenLinkModal = () => {
      linkRef.value = "";
      cash(`#${editorUuid.value}`).modal("hide");
    };

    return {
      editorUuid,
      editor,
      linkRef,
      openLinkModal,
      hiddenLinkModal,
      saveLink,
      clearLink,
    };
  },
});
