<template>
  <div>
    <div class="text-white text-xl">
      TO-DO LIST ({{ list.meta.total || 0 }})
    </div>
    <div class="mt-4 relative">
      <template v-for="(row, rowIndex) in list.data">
        <div
          :key="`task-${row.uuid}`"
          v-if="rowIndex < 5"
          class="px-4 py-3 rounded-lg mb-2 last:m-0 flex items-center zoom-in bg-gradient-to-r from-theme-8 to-theme-9 text-gray-500"
          @click="() => onCompleteTask(row)"
          :class="{ 'opacity-50 line-through': !!row.completed_at }"
        >
          <div class="flex-1 relative pr-8">
            <div>{{ row.name }}</div>
            <div class="w-full relative h-4 text-xs font-light">
              <span
                class="absolute whitespace-nowrap w-full overflow-hidden overflow-ellipsis"
                v-html="getTaskableTitle(row)"
              ></span>
            </div>
            <!--            <div class="text-xs font-light">-->
            <!--              {{ getTaskableTitle(row) }}-->
            <!--            </div>-->
          </div>
          <div class="text-right text-xs">
            <div class="mb-1" v-if="row.due_at">
              {{ $h.formatDate(row.due_at, "DD MMM YYYY") || "-" }}
            </div>
            <div>
              {{ $h.capitalizeEachWords(row.taskable_type) }}
              <span
                class="w-2 h-2 rounded-full ml-0.5 bg-red-400 inline-block"
                :class="{
                  'bg-yellow-500': row.taskable_type === 'meeting',
                  'bg-blue-500': row.taskable_type === 'project',
                  'bg-green-500': row.taskable_type === 'document',
                }"
              ></span>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, Ref } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { taskService, GetTask } from "@/services";
import { completeTask, getTaskableTitle } from "@/views/task-manager";
import { useRouter } from "vue-router";
import { ConfirmRef } from "@/components/confirm";
import { SendEmailRef } from "@/components/email-to-members";

export default defineComponent({
  name: "DashboardTaskList",
  setup() {
    const router = useRouter();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const { fetchRecords } = useGeneralActions();
    const { loading, list, fetch } = fetchRecords({
      fetchApiMethod: taskService.fetch,
    });

    onMounted(() => {
      fetch({ page: 1 });
    });

    const onCompleteTask = (task: GetTask) => {
      completeTask(task, router, confirmRef, sendEmailRef);
    };

    return {
      loading,
      list,
      getTaskableTitle,
      onCompleteTask,
    };
  },
});
</script>
