
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import { useGeneralActions } from "@/utils/actions";
import { userService, GetRole, roleService } from "@/services";
import UserInformation from "@/views/users/view/Information.vue";
import UserRoles from "@/views/users/view/roles/Main.vue";
import { UserInformationPDFCreation } from "@/utils/pdf-creation/user-information";

export default defineComponent({
  name: "ViewUser",
  components: { UserRoles, UserInformation },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const definedRoles = ref<GetRole[]>([]);
    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: userService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        loading.value = true;
        roleService
          .fetch(id.value)
          .then((result) => {
            if (result.kind === "ok") {
              definedRoles.value = result.data;
            }
          })
          .finally(() => {
            loading.value = false;
          });
      },
    });

    const id = computed<number>(() => _.get(route, "params.id", 0) * 1);
    const headerTitle = computed<string>(() => {
      return `VIEW USER (#${id.value})`;
    });
    const roles = computed<Record<string, any>[]>(() => {
      return _.get(data.value, "roles", []);
    });
    const canEdit = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_update", false);
    });

    const exportUser = () => {
      if (data.value) {
        new UserInformationPDFCreation()
          .process({
            user: data.value,
            roles: definedRoles.value,
          })
          .then((pdfCreation) => {
            pdfCreation.output().then((url) => {
              router.push({
                query: { ...route.query, iUrl: url },
              });
            });
          });
      }
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      headerTitle,
      roles,
      detail: data,
      definedRoles,
      canEdit,
      exportUser,
    };
  },
});
