
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "VueSelect",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      default: () => "",
    },
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue);

    watch(inputValue, (val) => {
      emit("update:modelValue", val);
    });

    watch(
      computed(() => props.modelValue),
      (val) => {
        if (val !== inputValue.value) inputValue.value = val;
      }
    );

    return {
      inputValue,
    };
  },
});
