
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "Submissions",
  components: {
    Pagination,
  },
  setup() {
    const valueCompare = ref<Record<string, any>>({
      json: {
        old: {},
        new: {},
      },
      string: {
        old: "",
        new: "",
      },
    });
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "audit",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "event",
            "user_type",
            "user_id",
            "auditable_type",
            "auditable_id",
            "user_agent",
            "ip_address",
            "created_at",
            "old_values",
            "new_values",
          ],
          "@order": "created_at:desc",
          "@func": ["withCount"],
          user: {
            "@select": [
              "id",
              "name",
              "surname",
              "title",
              "salutation",
              "email",
            ],
          },
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    const openAudit = (audit: Record<string, any>) => {
      valueCompare.value = {
        json: {
          old: _.get(audit, "old_values", {}),
          new: _.get(audit, "new_values", {}),
        },
        string: {
          old: "",
          new: "",
        },
      };
      setTimeout(() => {
        valueCompare.value = {
          ...valueCompare.value,
          string: {
            old: cash("#preOldValue").html(),
            new: cash("#preNewValue").html(),
          },
        };
        cash("#audit-detail-modal").modal("show");
      }, 150);
    };

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      valueCompare,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      openAudit,
    };
  },
});
