import {
  GeneralApiResponse,
  GetDocumentResult,
  GetDocumentResults,
  GetDocumentTypeResults,
  GetSignedUploadUrlResult,
  useAxios,
} from "@/services";

export * from "./api.types";

export const documentService = {
  fetch: async (): Promise<GetDocumentResults> =>
    useAxios().fetch(`/api/documents`, {}),

  show: async (id: unknown): Promise<GetDocumentResult> =>
    useAxios().get(`/api/documents/${id}`),

  store: async (params: Record<string, any>): Promise<GetDocumentResult> =>
    useAxios().store(`/api/documents`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetDocumentResult> =>
    useAxios().update(`/api/documents/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/documents/${id}`),

  cancel: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().update(`/api/documents/${id}/cancel`, {}),

  publish: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().update(`/api/documents/${id}/publish`, {}),

  validate: async (params: Record<string, any>): Promise<GetDocumentResult> =>
    useAxios().store(`/api/documents/validate`, params),

  fetchDocumentTypes: async (): Promise<GetDocumentTypeResults> =>
    useAxios().get(`/api/documents/types`),

  fetchNextNumber: async (
    params: Record<string, any>
  ): Promise<GetDocumentResult> =>
    useAxios({ responseField: "" }).store(
      `/api/documents/next-n-number`,
      params
    ),

  generateSignedUploadUrl: async (): Promise<GetSignedUploadUrlResult> =>
    useAxios({ responseField: "" }).get(`/api/documents/generate-signed-url`),
};

export const DOCUMENT_GROUP_TYPES = {
  PUBLIC: 1,
  DRAFT: 2,
  COMMITTEE: 3,
  MEETING: 4,
  BALLOT: 6,
  SECRETARY: 7,
  MAIL: 8,
  ROOT: 10,
};

export const DOCUMENT_TYPES = {
  FOLDER_GENERAL : 1,
  FOLDER_PROJECT : 2,
  FOLDER_MEETING : 4,
  FOLDER_BALLOT : 6,
  FOLDER_SECRETARY : 7,
  FOLDER_MAIL : 8,
  FOLDER_ROOT : 10,

  GENERAL_FILE : 101,
  GENERAL_COMMENT : 102,
  GENERAL_FEEDBACK : 103,

  COMMITTEE_DOCUMENT : 311,
  COMMITTEE_BUSINESS_PLAN : 312,
  COMMITTEE_RESOLUTIONS : 313,
  COMMITTEE_REPORT : 314,
  COMMITTEE_OTHER : 315,

  WORKING_DRAFT : 201,
  COMMITTEE_DRAFT : 202,
  DRAFT_TECHNICAL_REPORT : 203,
  DRAFT_AMENDMENT : 204,
  DRAFT_CORRIGENDUM : 205,
  OTHER_DRAFT : 206,
  DRAFT_STANDARD : 208,
  FINAL_DRAFT_STANDARD : 209,
  STANDARD_DOCUMENT : 207,
  SUBMISSION_DOCUMENT : 210,

  OTHER_BALLOT : 608,
  SUMMARY_OF_VOTING : 609,
  BALLOT_COMMENTS : 610,
  BALLOT_FORM : 611,
  REFERENCE_DOCUMENT : 612,

  MEETING_AGENDA : 401,
  MEETING_MINUTES : 402,
  MEETING_PARTICIPANT_LIST : 404,
  MEETING_ANNOUNCEMENT : 405,
  MEETING_ANNEXES : 406,
  MEETING_PRESENTATION : 407,
  MEETING_OTHER : 408,
  MEETING_INVITATION : 409,

  SECRETARY_DOCUMENT : 701,

  MAIL : 801,
};
