import { Content } from "pdfmake/interfaces";
import {
  EncryptedPDFError,
  PDFArray,
  PDFDict,
  PDFDocument,
  PDFName,
  PDFNull,
  PDFNumber,
  PDFPageLeaf,
  PDFString,
} from "pdf-lib";
import { ToastNotify } from "@/utils/toast-notify";
import fileDownload from "js-file-download";

export class PdfCreation {
  protected query: Record<string, any> = {};
  protected pdfDoc?: PDFDocument;
  protected bookMarkNames: string[] = [
    "first",
    "second",
    "third",
    "fourth",
    "Contents",
  ];

  protected pageCount: any = 0;
  protected outlineItemRefsArr: any[] = [];
  protected outlineItemArr: any[] = [];

  async process(query: Record<string, any>): Promise<PdfCreation> {
    this.query = query;
    this.pdfDoc = await PDFDocument.create();
    if (this.pdfDoc) {
      await this.addPagesFromUrl(0);
      const file = await this.prepareCoverPages();
      if (file) {
        const firstPDFDocuments = await this.loadPDFFile(file);
        const copiedPages = await this.pdfDoc.copyPages(
          firstPDFDocuments,
          firstPDFDocuments.getPageIndices()
        );
        copiedPages.map((page, index) => {
          this.pdfDoc?.insertPage(index, page);
        });
      }
      // this.pageCount = this.pdfDoc.getPageCount();
      // await this.bookmark();
      await this.overwriteAllPages();
    }
    return this;
  }

  protected async addPagesFromUrl(index: number): Promise<void> {
    const urls = _.get(this.query, "urls", []);
    if (this.pdfDoc && index < urls.length) {
      const pdfBytes = await fetch(urls[index]).then((res) =>
        res.arrayBuffer()
      );
      if (pdfBytes) {
        const pDFDocuments = await this.loadPDFFile(pdfBytes);
        if (this.pdfDoc.getPageCount() === 0) {
          this.pdfDoc = pDFDocuments;
        } else {
          const copiedPages = await this.pdfDoc.copyPages(
            pDFDocuments,
            pDFDocuments.getPageIndices()
          );
          copiedPages.map((page) => {
            this.pdfDoc?.addPage(page);
          });
        }
        await this.addPagesFromUrl(index + 1);
      }
    }
  }

  protected prepareCoverPages(): Promise<any> {
    return new Promise((resolve) => resolve(null));
  }

  protected async loadPDFFile(pdfBytes: ArrayBuffer): Promise<PDFDocument> {
    return await PDFDocument.load(pdfBytes).catch((error) => {
      let message = error.message;
      const errorType = error.stack.toString().split("@")[0] || null;
      if (
        error instanceof EncryptedPDFError ||
        errorType === "EncryptedPDFError"
      ) {
        message =
          "Since this pdf is encrypted, the process cannot be continued. Try again after removing the password of the file.";
      }
      ToastNotify({
        text: message,
        className: "error",
      });
      throw error;
    });
  }

  protected replace(str: string): string {
    let rspString = _.clone(str);
    const regex = /(?:\{\{)([a-zA-Z0-9.()_]+)(?:\}\})/gm;
    let m;
    while ((m = regex.exec(str)) !== null) {
      if (m.index === regex.lastIndex) regex.lastIndex++;
      if (m.length > 1)
        rspString = rspString.replace(m[0], _.get(this.query, m[1], "") || "");
    }
    return rspString;
  }

  protected replaceLines(lines: Array<Content>): Array<Content> {
    return lines.map((line: Content) => {
      if (_.isObject(line) && _.has(line, "text")) {
        let text = _.get(line, "text", "");
        if (Array.isArray(text)) {
          text = this.replaceLines(text);
        } else {
          text = this.replace(text);
          const link = _.get(line, "link", "");
          if (link) {
            _.set(line, "link", this.replace(link));
          }
        }

        _.set(line, "text", text);
      } else if (Array.isArray(line)) {
        line = this.replaceLines(line);
      } else if (_.isString(line)) {
        line = this.replace(line);
      }

      if (typeof line === "object") {
        let columns = _.get(line, "columns", []);
        if (columns.length > 0) {
          columns = columns.map((column: any) => {
            return this.replaceLines(_.cloneDeep(column));
          });
          _.set(line, "columns", this.replaceLines(columns));
        }
        let body = _.get(line, "table.body", []);
        if (body.length > 0) {
          body = body.map((column: any) => {
            return this.replaceLines(_.cloneDeep(column));
          });
          _.set(line, "table.body", this.replaceLines(body));
        }
      }
      return line;
    });
  }

  async save(): Promise<void> {
    if (this.pdfDoc) {
      const pdfBytes = await this.pdfDoc.save();
      fileDownload(pdfBytes, "test.pdf", "application/pdf");
    }
  }

  async output(): Promise<string> {
    const blob = await this.outputBlob();
    if (blob) return URL.createObjectURL(blob);
    return "";
  }

  async outputBlob(): Promise<null | Blob> {
    if (this.pdfDoc) {
      const pdfBytes = await this.pdfDoc.save();
      return new Blob([pdfBytes], { type: "application/pdf" });
    }
    return null;
  }

  async overwriteAllPages() {
    console.log("");
  }

  async bookmark() {
    if (this.pdfDoc) {
      const references = this.getPageRefs(this.pdfDoc);
      const outlinesDictRef = this.pdfDoc.context.nextRef();

      for (let y = 0; y < this.pageCount; y++) {
        const pdf_reference = references[y];
        const bookmark_title =
          typeof this.bookMarkNames[y] === "undefined"
            ? this.bookMarkNames[4]
            : this.bookMarkNames[y];
        const outlineItemRef = this.pdfDoc.context.nextRef();
        this.outlineItemRefsArr.push(outlineItemRef);
        this.outlineItemArr.push(
          this.createOutlineItem(
            this.pdfDoc,
            bookmark_title,
            outlinesDictRef,
            outlineItemRef,
            pdf_reference,
            y === this.pageCount - 1
          )
        );
      }

      const outlinesDictMap = new Map();
      const countOfBookmarks = this.outlineItemArr.length;
      const firstBookmark = this.outlineItemArr[0];
      const lastBookmark = this.outlineItemArr[countOfBookmarks - 1];

      outlinesDictMap.set(PDFName.Type, PDFName.of("Outlines"));
      outlinesDictMap.set(PDFName.of("First"), firstBookmark);
      outlinesDictMap.set(PDFName.of("Last"), lastBookmark);
      outlinesDictMap.set(PDFName.of("Count"), PDFNumber.of(countOfBookmarks));

      for (let z = 0; z < this.pageCount.length; z++) {
        this.pdfDoc.context.assign(
          this.outlineItemRefsArr[z],
          this.outlineItemArr[z]
        );
      }

      this.pdfDoc.catalog.set(PDFName.of("Outlines"), outlinesDictRef);

      const outlinesDict = PDFDict.fromMapWithContext(
        outlinesDictMap,
        this.pdfDoc.context
      );

      this.pdfDoc.context.assign(outlinesDictRef, outlinesDict);
    }
  }

  getPageRefs(pdfDoc: PDFDocument): any[] {
    const refs: any[] = [];
    pdfDoc.catalog.Pages().traverse((kid, ref) => {
      if (kid instanceof PDFPageLeaf) refs.push(ref);
    });
    return refs;
  }

  createOutlineItem(
    pdfDoc: PDFDocument,
    bookmarkTitle: string,
    parent: any,
    nextOrPrev: any,
    page: any,
    isLast = false
  ): PDFDict {
    const array = PDFArray.withContext(pdfDoc.context);
    array.push(page);
    array.push(PDFName.of("XYZ"));
    array.push(PDFNull);
    array.push(PDFNull);
    array.push(PDFNull);
    const map = new Map();
    map.set(PDFName.Title, PDFString.of(bookmarkTitle));
    map.set(PDFName.Parent, parent);
    map.set(PDFName.of(isLast ? "Prev" : "Next"), nextOrPrev);
    map.set(PDFName.of("Dest"), array);

    return PDFDict.fromMapWithContext(map, pdfDoc.context);
  }
}
