import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { GetStageType } from "@/services";

// Getters types
export type Getters = {
  stageTypes(state: LocalState): GetStageType[];
  initialStageTypes(state: LocalState): GetStageType[];
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  stageTypes: (state) => {
    return state.types;
  },
  initialStageTypes: (state) => {
    return state.initialTypes;
  },
};
