<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <!-- BEGIN: Top Buttons -->
      <div class="flex justify-end transform -mt-9 sm:-mt-11 mb-4">
        <VueButton
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          @click="onSubmit"
          :loading="processing"
          :disabled="isActiveEditableQuestion"
        >
          Save Ballot
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <SaveIcon class="inline w-6 h-6" />
          </div>
        </VueButton>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <div class="accordion divide-y">
          <BallotEditInformation
            :form-data="formData"
            :validation-errors="validationErrors"
            :ballot-id="id"
            :ballot-type="ballotType"
          />
          <BallotQuestions
            :ballot-id="id"
            :ballot="formData"
            @update:ballot="changeBallot"
            @update:active-editable="(val) => (isActiveEditableQuestion = val)"
            :validation-errors="validationErrors"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { ballotService, GetBallotResult, PostBallot } from "@/services";
import BallotEditInformation from "@/views/ballots/create/Information.vue";
import BallotQuestions from "@/views/ballots/view/questions/Main.vue";
import { useStore } from "@/store";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { helper } from "@/utils/helper";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostBallot;
}

export default defineComponent({
  name: "BallotCreate",
  components: { BallotQuestions, BallotEditInformation },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isActiveEditableQuestion = ref<boolean>(false);
    const id = computed(() => route.params.id);

    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: ballotService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const ballotType = computed(() => data.value.type || route.params.type);

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: ballotService.store,
      updateApiMethod: ballotService.update,
      successCallback(result: GetBallotResult) {
        if (result.kind === "ok") {
          if (!id.value) {
            router.replace({
              name: "ballots.view",
              params: {
                id: result.data.id,
              },
            });
          } else {
            router.go(-1);
          }
        }
      },
      params: {
        project_id: _.get(route.query, "project_id", "") || "",
        stage_id: "",
        committee_id: _.get(route.query, "committee_id", "") || "",
        version: "",
        ballot_type: ballotType.value,
        reference: "",
        english_title: "",
        note: "",
        start_date: helper.formatDate(new Date(), "YYYY-MM-DD"),
        end_date: "",
        files: [],
        questions: [],
      },
    }) as CreateOrUpdate;

    const changeBallot = (val: any) => {
      setFormData(val);
    };

    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT BALLOT (#${id.value})`;
      return `CREATE BALLOT (${ballotType.value})`;
    });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
      formData.questions = _.cloneDeep(
        store.getters["ballot/getStageQuestions"](ballotType.value)
      );
      if (ballotType.value === "SMIIC") {
        setFormData({
          committee_id: _.get(
            store.getters["committee/smiicCommittee"],
            "id",
            null
          ),
        });
      }
    });

    return {
      isActiveEditableQuestion,
      id,
      ballotType,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit,
      changeBallot,
    };
  },
});
</script>
