import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { GetDocumentType } from "@/services";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_DOCUMENTS](
    state: S,
    documents: Record<string, any>[]
  ): void;
  [MutationTypes.SET_AGGREGATION](
    state: S,
    aggregation: Record<string, any>[]
  ): void;
  [MutationTypes.SET_DOCUMENT_TYPES](state: S, types: GetDocumentType[]): void;
  // [MutationTypes.SET_FOLDERS](state: S, documents: Record<string, any>[]): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_DOCUMENTS](
    state: LocalState,
    documents: Record<string, any>[]
  ) {
    state.documents = documents;
  },
  [MutationTypes.SET_AGGREGATION](
    state: LocalState,
    aggregation: Record<string, any>[]
  ) {
    state.aggregation = aggregation;
  },
  [MutationTypes.SET_DOCUMENT_TYPES](
    state: LocalState,
    types: GetDocumentType[]
  ) {
    state.types = types;
  },
  // [MutationTypes.SET_FOLDERS](
  //   state: LocalState,
  //   folders: Record<string, any>[]
  // ) {
  //   state.folders = folders;
  // },
};
