import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { Question } from "@/services";

// Getters types
export type Getters = {
  getStageQuestions(state: LocalState): (stage: string) => Question[];
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  getStageQuestions: (state) => (type: string) => {
    const index = _.findIndex(state.questions, { type });
    return _.get(state.questions, `${index}.questions`, []) as Question[];
  },
};
