
import { defineComponent } from "vue";
import { PostDocument } from "@/services";

export default defineComponent({
  name: "DocumentActionButtons",
  props: {
    formData: { type: Object as () => PostDocument, default: () => ({}) },
    state: { type: Object, default: () => ({}) },
    onUpload: { type: Function, required: true },
    onPreview: { type: Function, required: true },
    onValidateAndUpload: { type: Function, required: true },
    onChangeStep: { type: Function, required: true },
  },
});
