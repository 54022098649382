
import { computed, defineComponent, ref, watch } from "vue";
import ProjectEditInformation from "@/views/projects/view/information/Edit.vue";
import ProjectViewInformation from "@/views/projects/view/information/View.vue";
import { GetProject } from "@/services";

export default defineComponent({
  name: "ProjectInformation",
  components: { ProjectViewInformation, ProjectEditInformation },
  props: {
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props, { emit }) {
    const visibleEditComponent = ref<boolean>(false);
    const canEdit = computed(() => {
      return _.get(props.project, "abilities.can_update", false);
    });
    watch(visibleEditComponent, (val) => emit("update:active-editable", val));
    return {
      visibleEditComponent,
      canEdit,
    };
  },
});
