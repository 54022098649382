
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { GetProject, GetProjectLine, projectService } from "@/services";
import TimelineVisJS from "@/global-components/timeline/Main.vue";
import { helper } from "@/utils/helper";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Litepicker from "@/global-components/litepicker/Main.vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "ProjectTimeline",
  components: { Litepicker, TransactionStatement, TimelineVisJS },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props, { emit }) {
    const editableLine = reactive({
      id: null,
      processing: false,
      formData: {
        target_date: "",
      },
      validationErrors: new ValidationErrors(),
    });
    const lines = ref<GetProjectLine[]>([]);
    const timelineOptions = computed(() => {
      const targetRows = lines.value
        .filter((line) => !!line.target_date)
        .map((line) => {
          return {
            id: `target-${line.id}`,
            content: `(${line.stage}) ${line.description || ""}`,
            start: line.target_date,
            className: "vis-target-date",
          };
        });
      const startedRows = lines.value
        .filter((line) => !!line.start_date)
        .map((line) => {
          return {
            id: `started-${line.id}`,
            content: `(${line.stage}) ${line.description || ""}`,
            start: line.start_date,
            end: line.close_date || helper.formatDate(new Date(), "YYYY-MM-DD"),
            className: line.status === "CURRENT" ? "vis-current-line" : "",
          };
        });
      return {
        delay: 500,
        rows: [...targetRows, ...startedRows],
      };
    });
    const onEditLine = (line: GetProjectLine) => {
      if (!editableLine.processing) {
        editableLine.processing = false;
        editableLine.id = line.id;
        editableLine.formData.target_date = _.cloneDeep(line.target_date);
      }
    };
    const onCloseEditLine = () => {
      editableLine.processing = false;
      editableLine.id = null;
      editableLine.formData.target_date = "";
    };
    const onSaveEditLine = () => {
      editableLine.processing = true;
      projectService
        .updateProjectLine(
          props.projectId,
          editableLine.id,
          editableLine.formData
        )
        .then((result) => {
          if (result.kind === "ok") {
            onCloseEditLine();
            const index = _.findIndex(lines.value, { id: result.data.id });
            if (index > -1) {
              _.set(
                lines.value,
                `${index}.target_date`,
                result.data.target_date
              );
              emit("update:project", { ...props.project, lines: lines.value });
            }
          } else {
            if (result.kind === "validation") {
              editableLine.validationErrors.record(result.fields);
            }
          }
        })
        .finally(() => {
          editableLine.processing = false;
        });
    };
    onMounted(() => {
      lines.value = _.orderBy(
        _.get(props.project, "lines", []),
        ["stage"],
        ["asc"]
      );
    });
    watch(
      () => _.get(props.project, "lines", []),
      (val) => {
        lines.value = _.orderBy(val, ["stage"], ["asc"]);
      },
      { deep: true, flush: "post" }
    );
    return {
      timelineOptions,
      lines,
      editableLine,
      onEditLine,
      onCloseEditLine,
      onSaveEditLine,
    };
  },
});
