<template>
  <div class="relative w-full h-full">
    <table
      class="table w-full"
      v-if="document.files && document.files.length > 0"
    >
      <thead>
        <tr>
          <th class="whitespace-nowrap text-center w-1">N Number</th>
          <th class="whitespace-nowrap">File</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in document.files" :key="'document-' + row.id">
          <td class="text-center text-lg">
            <span class="font-semibold">{{ row.n_number }}</span>
          </td>
          <td>
            <router-link
              :to="{ name: 'documents', params: { uuid: row.uuid } }"
              class="text-blue-500 hover:text-blue-700"
            >
              {{ row.title }}
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center font-bold p-3" v-else>No results found</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GetDocument } from "@/services";

export default defineComponent({
  name: "DocumentsList",
  props: {
    document: {
      type: Object as () => GetDocument,
      default: () => ({}),
    },
  },
});
</script>
