
import { computed, defineComponent } from "vue";
import { GetBallot } from "@/services";

export default defineComponent({
  name: "BallotViewInformation",
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props) {
    const previousCaster = computed(() => {
      const user = _.get(props.ballot, "votes.0.user");

      if (!user) return null;
      const date = _.get(props.ballot, "votes.0.created_at");
      return user.name + " " + user.surname + " on " + date;
    });
    return {
      previousCaster,
    };
  },
});
