
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { ballotService } from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import CreateBallotDropdownList from "@/views/ballots/list/CreateBallotDropdownList.vue";
import { Select2Options } from "@/global-components/select2";

export default defineComponent({
  components: {
    CreateBallotDropdownList,
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "ballot",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "project_id",
            "committee_id",
            "ballot_type",
            "reference",
            "english_title",
            "status",
            "start_date",
            "end_date",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          committee: {
            "@select": ["id", "acronym"],
          },
        },
        filterFormat: {
          reference: "@where._reference",
          title: "@where._english_title",
          committee_id: "@where.committee_id",
          type: "@where.ballot_type",
          status: "@where.status",
          start_date: "@where.start_date",
          end_date: "@where.end_date",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      committee_id: "",
      reference: "",
      title: "",
      type: "",
      status: "",
      start_date: "",
      end_date: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: ballotService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.value) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "ballots.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "ballots.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      committeeSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
    };
  },
});
