<template>
  <div class="accordion__pane py-4">
    <!-- BEGIN ABOUT -->
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >About</a
    >

    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div
        class="grid grid-cols-1 md:grid-cols-2 gap-4 py-6"
        v-if="$_.get(detail, 'type') === 'WG'"
      >
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Title
          </div>
          <div class="flex-1 px-6 py-3.5">
            ({{ $_.get(detail, "acronym", "") }})
            {{ $_.get(detail, "title", "") }}
          </div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Convenor
          </div>
          <div
            class="flex-1 px-6 py-3.5 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <template v-if="detail.secretary_user_id">
              <router-link
                :to="{
                  name: 'users.view',
                  params: { id: detail.secretary_user_id },
                }"
                class="text-blue-500 hover:text-blue-700"
              >
                {{ $h.formatNameSurname($_.get(detail, "secretary_user")) }}
              </router-link>
            </template>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 py-6" v-else>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Title
          </div>
          <div class="flex-1 px-6 py-3.5">
            ({{ $_.get(detail, "acronym", "") }})
            {{ $_.get(detail, "title", "") }}
          </div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Secretariat
          </div>
          <div class="flex-1 px-6 py-3.5">
            <template v-if="detail.secretariat_member_id">
              <router-link
                :to="{
                  name: 'organizations.view',
                  params: { id: detail.secretariat_member_id },
                }"
                class="text-blue-500 hover:text-blue-700"
              >
                ({{ $_.get(detail, "secretariat.acronym", "") }})
                {{ $_.get(detail, "secretariat.title", "") }}
              </router-link>
            </template>
          </div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            SMIIC Technical Assistant
          </div>
          <div
            class="flex-1 px-6 py-3.5 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <template v-if="detail.assistant_user_id">
              <router-link
                :to="{
                  name: 'users.view',
                  params: { id: detail.assistant_user_id },
                }"
                class="text-blue-500 hover:text-blue-700"
              >
                {{ $h.formatNameSurname($_.get(detail, "assistant_user")) }}
              </router-link>
            </template>
          </div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Secretary
          </div>
          <div
            class="flex-1 px-6 py-3.5 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <template v-if="detail.secretary_user_id">
              <router-link
                :to="{
                  name: 'users.view',
                  params: { id: detail.secretary_user_id },
                }"
                class="text-blue-500 hover:text-blue-700"
              >
                {{ $h.formatNameSurname($_.get(detail, "secretary_user")) }}
              </router-link>
            </template>
          </div>
        </div>

        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Chairperson
          </div>
          <div
            class="flex-1 px-6 py-3.5 whitespace-nowrap overflow-hidden overflow-ellipsis"
          >
            <template v-if="detail.chairperson_user_id">
              <router-link
                :to="{
                  name: 'users.view',
                  params: { id: detail.chairperson_user_id },
                }"
                class="text-blue-500 hover:text-blue-700"
              >
                {{ $h.formatNameSurname($_.get(detail, "chairperson_user")) }}
              </router-link>
            </template>
          </div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Chairperson Info
          </div>
          <div
            class="flex-1 px-6 py-3.5 whitespace-nowrap overflow-hidden overflow-ellipsis"
            v-html="$_.get(detail, 'chairperson_info.contact', '')"
          ></div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Chairperson Term
          </div>
          <div
            class="flex-1 px-6 py-3.5 whitespace-nowrap overflow-hidden overflow-ellipsis"
            v-html="$_.get(detail, 'chairperson_user_term.contact', '')"
          ></div>
        </div>
        <div class="bg-gray-100 flex rounded-lg">
          <div class="flex-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium">
            Creation date
          </div>
          <div class="flex-1 px-6 py-3.5">
            {{ $_.get(detail, "creation_date", "") }}
          </div>
        </div>
        <div class="bg-gray-100 grid grid-cols-4 col-span-full rounded-lg">
          <div
            class="col-span-2 md:col-span-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Secretariat Contact
          </div>
          <div
            class="col-span-2 md:col-span-3 px-6 py-3.5 ProseMirror-Viewer"
            v-html="$_.get(detail, 'secretariat.contact', '')"
          ></div>
        </div>
        <div class="bg-gray-100 grid grid-cols-4 col-span-full rounded-lg">
          <div
            class="col-span-2 md:col-span-1 px-6 py-3.5 bg-gray-200 rounded-lg font-medium"
          >
            Scope
          </div>
          <div
            class="col-span-2 md:col-span-3 px-6 py-3.5 ProseMirror-Viewer"
            v-html="$_.get(detail, 'scope', '')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "About",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
});
</script>
