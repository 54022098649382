import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
  RouterView,
} from "vue-router";

import SideMenu from "../layouts/side-menu/Main.vue";
import Dashboard from "../views/dashboard/Main.vue";
import Documents from "../views/documents/views/Main.vue";
// import DocumentView from "../views/documents/view/Main.vue";
import CreateDocument from "../views/documents/create/Main.vue";
import DocumentLogs from "../views/documents/logs/Main.vue";
import Committees from "../views/committees/list/Main.vue";
import CommitteesCreate from "../views/committees/create/Main.vue";
import CommitteeView from "../views/committees/view/Main.vue";
import Projects from "../views/projects/list/Main.vue";
import ProjectCreate from "../views/projects/create/Main.vue";
import ProjectView from "../views/projects/view/Main.vue";
import Ballots from "../views/ballots/list/Main.vue";
import BallotCreate from "../views/ballots/create/Main.vue";
import BallotView from "../views/ballots/view/Main.vue";
import BallotVote from "../views/ballots/vote/Main.vue";
import Users from "../views/users/list/Main.vue";
import UserCreate from "../views/users/create/Main.vue";
import UserView from "../views/users/view/Main.vue";
import Organizations from "../views/organizations/list/Main.vue";
import OrganizationCreate from "../views/organizations/create/Main.vue";
import OrganizationView from "../views/organizations/view/Main.vue";
import Meetings from "../views/meetings/list/Main.vue";
import MeetingCreate from "../views/meetings/create/Main.vue";
import MeetingView from "../views/meetings/view/Main.vue";
import Submissions from "../views/submissions/list/Main.vue";
import Countries from "../views/countries/list/Main.vue";
import CountryCreate from "../views/countries/create/Main.vue";
import Feedbacks from "../views/feedbacks/list/Main.vue";
import FeedbackCreate from "../views/feedbacks/create/Main.vue";
import Tasks from "../views/task-manager/list/Main.vue";
import TaskLogs from "../views/task-manager/log/Main.vue";
import Audits from "../views/audits/list/Main.vue";
import EmailBlacklist from "../views/email/blacklist/Main.vue";
import Conversation from "../views/conversations/Main.vue";

import Login from "../views/login/Main.vue";
import ResetPassword from "../views/login/ResetPassword.vue";
import Welcome from "../views/welcome/Main.vue";
import ErrorPage from "../views/error-page/Main.vue";
import { Store, useStore } from "@/store";
import { ToastNotify } from "@/utils/toast-notify";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: SideMenu,
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
        meta: { requiresAuth: true },
      },
      {
        path: "documents",
        component: RouterView,
        children: [
          {
            path: ":uuid?",
            name: "documents",
            component: Documents,
            meta: { requiresAuth: true },
          },
          {
            path: "create/:committeeId(\\d+)",
            name: "documents.create",
            component: CreateDocument,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canCreateDocument"]();
              },
            },
          },
          {
            path: "logs",
            name: "documents.logs",
            component: DocumentLogs,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/isSuperAdmin"];
              },
            },
          },
        ],
      },
      {
        path: "committees",
        component: RouterView,
        children: [
          {
            path: "",
            name: "committees",
            component: Committees,
            meta: { requiresAuth: true },
          },
          {
            path: "create",
            name: "committees.create",
            component: CommitteesCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canCreateCommittee"];
              },
            },
          },
          {
            path: "edit/:id(\\d+)",
            name: "committees.edit",
            component: CommitteesCreate,
            meta: { requiresAuth: true },
          },
          {
            path: "view/:id(\\d+)",
            name: "committees.view",
            component: CommitteeView,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "projects",
        component: RouterView,
        children: [
          {
            path: "",
            name: "projects",
            component: Projects,
            meta: { requiresAuth: true },
          },
          {
            path: "create",
            name: "projects.create",
            component: ProjectCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canCreateProject"]();
              },
            },
          },
          {
            path: "edit/:id(\\d+)",
            name: "projects.edit",
            component: ProjectCreate,
            meta: { requiresAuth: true },
          },
          {
            path: "view/:id(\\d+)",
            name: "projects.view",
            component: ProjectView,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "ballots",
        component: RouterView,
        children: [
          {
            path: "",
            name: "ballots",
            component: Ballots,
            meta: { requiresAuth: true },
          },
          {
            path: "create/:type",
            name: "ballots.create",
            component: BallotCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (
                store: Store,
                route: RouteLocationNormalized
              ): boolean => {
                if (route.params.type === "SMIIC") {
                  return store.getters["auth/canCreateSmiicBallot"]();
                }
                return store.getters["auth/canCreateCommitteeBallot"]();
              },
            },
          },
          {
            path: "view/:id",
            name: "ballots.view",
            component: BallotView,
            meta: { requiresAuth: true },
          },
          {
            path: "vote/:id",
            name: "ballots.vote",
            component: BallotVote,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "users",
        component: RouterView,
        children: [
          {
            path: "",
            name: "users",
            component: Users,
            meta: { requiresAuth: true },
          },
          {
            path: "create",
            name: "users.create",
            component: UserCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canCreateUser"];
              },
            },
          },
          {
            path: "edit/:id(\\d+)",
            name: "users.edit",
            component: UserCreate,
            meta: { requiresAuth: true },
          },
          {
            path: "view/:id(\\d+)",
            name: "users.view",
            component: UserView,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "organizations",
        component: RouterView,
        children: [
          {
            path: "",
            name: "organizations",
            component: Organizations,
            meta: { requiresAuth: true },
          },
          {
            path: "create",
            name: "organizations.create",
            component: OrganizationCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canCreateOrganization"];
              },
            },
          },
          {
            path: "edit/:id(\\d+)",
            name: "organizations.edit",
            component: OrganizationCreate,
            meta: { requiresAuth: true },
          },
          {
            path: "view/:id(\\d+)",
            name: "organizations.view",
            component: OrganizationView,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "meetings",
        component: RouterView,
        children: [
          {
            path: "",
            name: "meetings",
            component: Meetings,
            meta: { requiresAuth: true },
          },
          {
            path: "create",
            name: "meetings.create",
            component: MeetingCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canCreateMeeting"]();
              },
            },
          },
          {
            path: "edit/:id(\\d+)",
            name: "meetings.edit",
            component: MeetingCreate,
            meta: { requiresAuth: true },
          },
          {
            path: "view/:id(\\d+)",
            name: "meetings.view",
            component: MeetingView,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "feedbacks",
        component: RouterView,
        children: [
          {
            path: "",
            name: "feedbacks",
            component: Feedbacks,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canManageFeedback"];
              },
            },
          },
          {
            path: "create",
            name: "feedbacks.create",
            component: FeedbackCreate,
            meta: { requiresAuth: true },
          },
        ],
      },
      {
        path: "submissions",
        component: RouterView,
        children: [
          {
            path: "",
            name: "submissions",
            component: Submissions,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canManageSubmission"];
              },
            },
          },
        ],
      },
      {
        path: "countries",
        component: RouterView,
        children: [
          {
            path: "",
            name: "countries",
            component: Countries,
            meta: { requiresAuth: true },
          },
          {
            path: "create",
            name: "countries.create",
            component: CountryCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canManageCountry"];
              },
            },
          },
          {
            path: "edit/:id(\\d+)",
            name: "countries.edit",
            component: CountryCreate,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canManageCountry"];
              },
            },
          },
        ],
      },
      {
        path: "tasks",
        component: RouterView,
        children: [
          {
            path: "",
            name: "tasks",
            component: Tasks,
            meta: { requiresAuth: true },
          },
          {
            path: "logs",
            name: "tasks.logs",
            component: TaskLogs,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canAudit"];
              },
            },
          },
        ],
      },
      {
        path: "audits",
        component: RouterView,
        children: [
          {
            path: "",
            name: "audits",
            component: Audits,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canAudit"];
              },
            },
          },
        ],
      },
      {
        path: "emails",
        component: RouterView,
        children: [
          {
            path: "blacklist",
            name: "emails.blacklist",
            component: EmailBlacklist,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canAudit"];
              },
            },
          },
        ],
      },
      {
        path: "conversation",
        component: RouterView,
        children: [
          {
            path: "conversation",
            name: "conversation",
            component: Conversation,
            meta: {
              requiresAuth: true,
              checkPermission: (store: Store): boolean => {
                return store.getters["auth/canAudit"];
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { requiresGuest: true },
  },
  {
    path: "/reset-password",
    name: "password.reset",
    component: ResetPassword,
    meta: { requiresGuest: true },
  },
  {
    path: "/welcome",
    name: "welcome",
    component: Welcome,
    meta: { requiresAuth: true },
  },
  {
    name: "page.notfound",
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.FRONTEND_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const store = useStore();

  const query = {};
  if (to.fullPath && ["/", "/welcome"].indexOf(to.fullPath) === -1)
    _.set(query, "redirect", to.fullPath);

  if (store.getters["auth/token"]) {
    if (
      store.getters["auth/visibleSplashScreen"] &&
      to.name !== "page.notfound" &&
      to.name !== "welcome"
    )
      return next({ name: "welcome" });

    if (to.meta.requiresGuest === true) return next({ name: "dashboard" });
  } else {
    if (to.meta.requiresAuth === true) {
      return next({ name: "login", query });
    }
  }

  if (
    typeof to.meta.checkPermission === "function" &&
    !to.meta.checkPermission(store, to)
  ) {
    ToastNotify({
      text: "You are not authorized to view this page!",
      className: "error",
    });
    return next(false);
  }

  return next();
});

export default router;
