import Dropzone, { DropzoneFile, DropzoneOptions } from "dropzone";
import { documentService, GetDocument, PostDocument } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { computed, ref, watch } from "vue";

type DropzoneInstance = {
  dropzone: any;
};

interface Props {
  options: any;
  success?(result: GetDocument, file: DropzoneFile): void;
  failure?(message: string, file: DropzoneFile): void;
  remove?(uuid: string): void;
  uploading?(): void;
  completed?(): void;
}

const init = (el: HTMLElement & DropzoneInstance, props: Props): void => {
  const { params = {}, ...options } = props.options;

  const signedFiles = ref<Record<string, any>[]>([]);

  el.dropzone = new Dropzone(el, {
    url: (files) => {
      const index = _.findIndex(signedFiles.value, {
        fileUUID: files[0]?.upload?.uuid,
      });
      return _.get(signedFiles.value, [index, "signedURL"], "");
    },
    method: "PUT",
    parallelUploads: 1,
    uploadMultiple: false,
    maxFiles: 10,
    timeout: 300000,
    createImageThumbnails: false,
    addRemoveLinks: false,
    previewTemplate: `<div class="dz-preview dz-file-preview well" id="dz-preview-template">
         <div class="dz-detail-container">
           <div class="dz-file-icon"></div>
           <div class="dz-details">
             <div class="dz-filename"><span data-dz-name></span></div>
             <div class="dz-size" data-dz-size></div>
             <a class="dz-remove" href="javascript:;" data-dz-remove></a>
           </div>
         </div>
         <div class="dz-progress">
           <span class="dz-upload" data-dz-uploadprogress></span>
         </div>
         <div class="dz-success-mark"><span></span></div>
         <div class="dz-error-mark"><span></span></div>
         <div class="dz-error-message">
           <span data-dz-errormessage></span>
         </div>
       </div>`,
    init: function () {
      this.on("addedfile", (file: DropzoneFile) => {
        const extension = file.name.split(".").pop();
        let icon = "txt";
        switch (extension) {
          case "jpg":
          case "png":
          case "gif":
          case "pdf":
          case "zip":
          case "doc":
            icon = extension;
            break;
          case "jpeg":
            icon = "jpg";
            break;
          case "docx":
            icon = "doc";
            break;
        }

        cash(file.previewElement)
          .find(".dz-file-icon")
          .addClass(`dz-file-${icon}`);
      });
      this.on("removedfile", (file: DropzoneFile) => {
        const documentUuid = _.get(file, "documentUuid");
        if (documentUuid) {
          documentService.delete(documentUuid).finally();
          if (props.remove) props.remove(documentUuid);
        }
      });
      this.on("addedfiles", () => {
        if (props.uploading) props.uploading();
      });
      this.on("canceled", (file: DropzoneFile) => {
        const index = _.findIndex(signedFiles.value, {
          fileUUID: file.upload?.uuid,
        });
        signedFiles.value.splice(index, 1);
        if (signedFiles.value.length === 0 && props.completed)
          props.completed();
      });
    },
    ...options,
    accept(file: DropzoneFile, done: (error?: string | Error) => void) {
      documentService.generateSignedUploadUrl().then((result) => {
        if (result.kind !== "ok") {
          ToastNotify({ text: result.message, className: "error" });
        } else {
          signedFiles.value.push({
            fileUUID: file.upload?.uuid,
            signedUUID: result.data.uuid,
            signedURL: result.data.url,
          });
          done();
        }
      });
    },
    success(file: DropzoneFile) {
      const index = _.findIndex(signedFiles.value, {
        fileUUID: file.upload?.uuid,
      });
      const uuid = _.get(signedFiles.value, [index, "signedUUID"], "");
      documentService
        .store({
          ...params,
          uuid,
          title: params.title || file.name,
          file_name: file.name,
          file_size: file.size,
        } as PostDocument)
        .then((result) => {
          const lastIndex = _.findIndex(signedFiles.value, { fileUUID: uuid });
          signedFiles.value.splice(lastIndex, 1);
          if (result.kind === "ok") {
            _.set(file, "documentUuid", result.data.uuid);
            _.set(file, "documentId", result.data.id);
            if (props.success) props.success(result.data, file);
            if (file.previewElement) {
              file.previewElement.classList.add("dz-success");
            }
          } else {
            if (file.previewElement) {
              file.previewElement.classList.add("dz-error");
              cash(file.previewElement)
                .addClass("dz-error")
                .find(".dz-error-message")
                .text(result.message);
            }
            if (props.failure) props.failure(result.message as string, file);
          }
          if (signedFiles.value.length === 0 && props.completed)
            props.completed();
          if (!props.options.autoProcessQueue) el.dropzone.processQueue();
        });
    },
    // sending(file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formData: FormData) {
    //   console.log(file, formData)
    // },
    // transformFile(file: Dropzone.DropzoneFile, done: (file: (string | Blob)) => void) {
    //   console.log(file)
    // }
  } as DropzoneOptions);

  watch(
    computed(() => props.options),
    () => {
      el.dropzone.destroy();
      init(el, props);
    },
    { flush: "post", deep: true }
  );
};

export { init };
