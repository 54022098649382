
import { computed, defineComponent, onMounted, watch } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetProjectResult, PostProject, projectService } from "@/services";
import { useRoute, useRouter } from "vue-router";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { useStore } from "@/store";
import ProjectEditInformation from "@/views/projects/create/Information.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostProject;
}

export default defineComponent({
  name: "CreateProject",
  components: { ProjectEditInformation },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: projectService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: projectService.store,
      updateApiMethod: projectService.update,
      successCallback(result: GetProjectResult) {
        if (result.kind === "ok") {
          if (!id.value) {
            router.replace({
              name: "projects.view",
              params: {
                id: result.data.id,
              },
            });
          } else {
            router.go(-1);
          }
        }
      },
      params: {
        reference_suffix: "",
        sequence: "",
        committee_id: _.get(route.query, "committee_id", "") || "",
        wg_id: "",
        title_en: "",
        description_en: "",
        title_fr: "",
        description_fr: "",
        title_ar: "",
        description_ar: "",
        price: "",
        edition_no: "",
        visible_on_website: "0",
        leader_user_id: "",
        details: "",
        stage: _.get(store.state, `project.initialTypes.0.value`, ""),
        time_frame: "24",
        project_no: "",
        part_no: "",
      } as PostProject,
    }) as CreateOrUpdate;

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT PROJECT (#${id.value})`;
      return "CREATE PROJECT";
    });

    const onSubmit = () => submit(id.value);

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    return {
      id,
      loading,
      headerTitle,
      processing,
      formData,
      project: data,
      validationErrors,
      onSubmit,
    };
  },
});
