
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { PostProject, projectService } from "@/services";
import { ValidationErrors } from "@/utils/form";
import { Select2Options } from "@/global-components/select2";
import { useStore } from "@/store";
import VueTiptap from "@/components/tiptap/Main.vue";

export default defineComponent({
  name: "ProjectEditInformation",
  components: { VueTiptap },
  props: {
    projectId: { default: () => null },
    formData: { type: Object as () => PostProject, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const editableFormData = reactive(toRefs(props.formData));
    const state = reactive<Record<string, any>>({
      selectedCommittee: null,
      nextProjectNo: "",
    });
    const stageAbbr = computed<string>(() => {
      const index = _.findIndex(store.state.project.initialTypes, {
        value: editableFormData.stage,
      });
      return _.get(store.state.project.initialTypes, `${index}.abbr`, "");
    });
    const reference = computed<string>(() => {
      let val = `OIC/SMIIC ${stageAbbr.value} ${editableFormData.project_no}`;
      if (editableFormData.part_no) val += `-${editableFormData.part_no}`;
      if (editableFormData.reference_suffix)
        val += `${editableFormData.reference_suffix}`;
      return val;
    });
    const onChangeCommittee = (committee: any) => {
      state.selectedCommittee = committee;
      editableFormData.wg_id = "";
    };
    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
            level: "level",
            type: "type",
          },
          "@where": {
            level: [1, 2],
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              "can_create_project"
            ),
          },
          "@whereNot": {
            type: ["WG"],
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });
    const workingGroupSelect2Options = computed<Select2Options>(() => {
      const selectedCommitteeLevel = _.get(
        state.selectedCommittee,
        "level.level"
      );
      const where: any = {
        level_1_type: _.get(state.selectedCommittee, "level.level_1.type"),
        level_1_number: _.get(state.selectedCommittee, "level.level_1.number"),
      };
      if (selectedCommitteeLevel === 1) {
        _.set(where, "level", 2);
        _.set(where, "level_2_type", "WG");
      } else if (selectedCommitteeLevel === 2) {
        _.set(where, "level", 3);
        _.set(
          where,
          "level_2_type",
          _.get(state.selectedCommittee, "level.level_2.type")
        );
        _.set(
          where,
          "level_2_number",
          _.get(state.selectedCommittee, "level.level_2.number")
        );
        _.set(where, "level_3_type", "WG");
      }
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
            "@where": where,
          },
        },
        templateSelection: (obj: any): string => {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    const fetchNextNumber = () => {
      projectService.nextProjectNumber().then((result) => {
        state.nextProjectNo = result.project_no;
        if (state.nextProjectNo)
          editableFormData.project_no = result.project_no;
      });
    };

    onMounted(() => {
      fetchNextNumber();
    });

    return {
      state,
      reference,
      editableFormData,
      committeeSelect2Options,
      workingGroupSelect2Options,
      onChangeCommittee,
    };
  },
});
