
import {
  computed,
  defineComponent,
  onMounted,
  provide,
  reactive,
  ref,
  toRefs,
} from "vue";
import { GetDocument, Question, QuestionAnswer } from "@/services";
import Dropzone from "@/global-components/dropzone/Main.vue";
import { DropzoneFile, DropzoneOptions } from "dropzone";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "VoteQuestion",
  components: { Dropzone },
  props: {
    question: {
      type: Object as () => Question,
      default: () => ({}),
    },
    questionIndex: {
      type: Number,
      default: () => -1,
    },
    label: {
      type: String,
      default: () => "Q.",
    },
    committeeId: {
      default: () => null,
    },
    ballotId: {
      default: () => null,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    path: {
      type: String,
      default: () => "",
    },
    startUpload: {
      type: Function,
      default: () => null,
    },
    endUpload: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const dropzoneRef = ref<HTMLElement>();
    provide("bind[dropzoneRef]", (el: HTMLElement) => {
      dropzoneRef.value = el;
    });

    const dropzoneOptions = computed<DropzoneOptions>(() => {
      return {
        acceptedFiles:
          "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        params: {
          committee_id: props.committeeId,
          resource_type: "ballot",
          resource_id: props.ballotId,
          // folder_id: _.get(props.committeeFolder, "id", null),
          type: 610,
        },
      };
    });
    const editableQuestion = reactive(toRefs<Question>(props.question));
    const selectedAnswerIndex = computed(() => {
      return _.findIndex(editableQuestion.answers, {
        uuid: editableQuestion.vote?.answer,
      });
    });
    const selectedAnswer = computed<QuestionAnswer>(() => {
      return (
        _.get(editableQuestion.answers, `${selectedAnswerIndex.value}`) || null
      );
    });
    const subQuestions = computed<Question[]>(() => {
      return _.get(selectedAnswer.value, `questions`, []);
    });

    const successUploadFile = (document: GetDocument) => {
      if (editableQuestion.vote) {
        editableQuestion.vote.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.title,
          n_number: document.n_number,
          file_name: document.file_name,
          file_size: document.file_size,
        });
      }
    };

    const removeUploadedFile = (uuid: string) => {
      if (editableQuestion.vote) {
        const fileIndex = _.findIndex(editableQuestion.vote.files, { uuid });
        if (fileIndex > -1) editableQuestion.vote.files.splice(fileIndex, 1);
      }
    };

    const manualAddDocuments = (documents: Record<string, any>[]) => {
      documents.map((document: Record<string, any>) => {
        const mockFile: Record<string, any> = {
          accepted: true,
          name: document.file_name,
          size: document.file_size,
          processing: true,
          status: "success",
          documentUuid: document.uuid,
          documentId: document.id,
        };
        dropzoneRef.value?.dropzone.files.push(mockFile as DropzoneFile);
        dropzoneRef.value?.dropzone.emit("addedfile", mockFile);
        dropzoneRef.value?.dropzone.emit("complete", mockFile);
      });
    };

    onMounted(() => {
      const files = editableQuestion.vote?.files || [];
      if (files.length > 0) {
        setTimeout(() => manualAddDocuments(files), 100);
      }
    });

    return {
      dropzoneRef,
      subQuestions,
      editableQuestion,
      selectedAnswerIndex,
      selectedAnswer,
      dropzoneOptions,
      successUploadFile,
      removeUploadedFile,
    };
  },
});
