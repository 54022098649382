<template>
  <div>
    <div class="section-divider my-8">General Properties</div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <VueInputGroup :validation-errors="validationErrors.get('type')">
          <template v-slot:label>
            <label class="block mb-1">Document Type</label>
          </template>
          <VueSelect
            v-model="editableFormData.type"
            :validation-errors="validationErrors.get('type')"
          >
            <optgroup
              v-for="row in documentTypes"
              :key="`document-type-${row.value}`"
              :label="row.text"
            >
              <option
                v-for="child in row.children"
                :key="`sub-document-type-${child.value}`"
                :value="child.value"
              >
                {{ child.text }}
              </option>
            </optgroup>
          </VueSelect>
        </VueInputGroup>
      </div>
      <div
        class="col-span-4"
        v-if="
          localState.resourceTypes.length > 0 &&
          (!editableFormData.resource_type ||
            localState.resourceTypes.length > 1)
        "
      >
        <VueInputGroup
          :validation-errors="validationErrors.get('resource_type')"
        >
          <template v-slot:label>
            <label class="block mb-1">Resource Type</label>
          </template>
          <VueSelect
            v-model="editableFormData.resource_type"
            :validation-errors="validationErrors.get('resource_type')"
          >
            <option value="" :disabled="localState.resourceTypes.length === 1">
              Select one of list
            </option>
            <option
              v-for="row in localState.resourceTypes"
              :key="`resource-type-${row.value}`"
              :value="row.value"
            >
              {{ row.text }}
            </option>
          </VueSelect>
        </VueInputGroup>
      </div>
      <div class="col-span-4" v-if="editableFormData.resource_type">
        <VueInputGroup :validation-errors="validationErrors.get('resource_id')">
          <template v-slot:label>
            <label class="block mb-1">{{ selectedResourceTypeTitle }}</label>
          </template>
          <Select2Input
            :key="editableFormData.resource_type"
            v-model="editableFormData.resource_id"
            :options="resourceSelect2Options"
            :validation-errors="validationErrors.get('resource_id')"
            @update:selected-item="(ctx) => onChangeResource(ctx)"
          />
        </VueInputGroup>
      </div>
      <div class="col-span-full">
        <VueInputGroup :validation-errors="validationErrors.get('title')">
          <template v-slot:label>
            <label class="block mb-1">Title</label>
          </template>
          <VueInput
            v-model="editableFormData.title"
            :validation-errors="validationErrors.get('title')"
          />
        </VueInputGroup>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import {
  DOCUMENT_GROUP_TYPES,
  GetDocumentType,
  PostDocument,
} from "@/services";
import { ValidationErrors } from "@/utils/form";
import { useStore } from "@/store";
import { Select2Options } from "@/global-components/select2";
import { useRoute } from "vue-router";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "DocumentGeneralProperties",
  props: {
    state: { type: Object, default: () => ({}) },
    formData: { type: Object as () => PostDocument, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const route = useRoute();
    const store = useStore();
    const editableState = reactive(toRefs<Record<string, any>>(props.state));
    const editableFormData = reactive(toRefs<PostDocument>(props.formData));
    const localState = reactive<Record<string, any>>({
      parentDocumentType: null,
      resourceTypes: [],
    });

    const documentTypes = computed<GetDocumentType[]>(() => {
      const types = store.getters["document/documentTypes"];
      const resourceType = _.get(route.query, "resource_type", null);
      switch (resourceType) {
        case "project":
          return types.filter(
            (type) => type.value === DOCUMENT_GROUP_TYPES.DRAFT
          );
        case "committee":
          return types.filter(
            (type) => type.value === DOCUMENT_GROUP_TYPES.COMMITTEE
          );
        case "meeting":
          return types.filter(
            (type) => type.value === DOCUMENT_GROUP_TYPES.MEETING
          );
        case "ballot":
          return types.filter(
            (type) => type.value === DOCUMENT_GROUP_TYPES.BALLOT
          );
        case "secretary":
          return types.filter(
            (type) => type.value === DOCUMENT_GROUP_TYPES.SECRETARY
          );
        case "mail":
          return types.filter(
            (type) => type.value === DOCUMENT_GROUP_TYPES.MAIL
          );
        default:
          return types;
      }
    });
    const selectedResourceTypeTitle = computed<string>(() => {
      const index = _.findIndex(localState.resourceTypes, {
        value: editableFormData.resource_type,
      });
      return _.get(localState.resourceTypes, `${index}.text`, "");
    });
    watch(
      () => editableFormData.type,
      () => changedType(),
      { flush: "post" }
    );
    watch(
      () => editableFormData.resource_type,
      () => setResourceId()
    );

    const committeeSelect2Options = computed(() => ({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": { value: "id", text: "title", acronym: "acronym" },

          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
          "@where": {
            id: editableFormData.committee_id,
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.acronym) return `(${obj.acronym || ""}) ${obj.text}`;
        return obj.text;
      },
    }));
    const resourceSelect2Options = computed<Select2Options>(() => {
      const source = computed<string>(
        () => editableFormData.resource_type || ""
      );
      if (source.value === "committee") return committeeSelect2Options.value;
      return {
        withoutQueue: true,
        ajaxQuery: {
          [source.value]: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text:
                ["project", "ballot"].indexOf(source.value) > -1
                  ? "reference"
                  : "title",
            },
            "@order": "id:desc",
            "@where": {
              committee_id: editableFormData.committee_id,
            },
          },
        },
      };
    });

    const onChangeResource = (ctx: Record<string, any>): void => {
      editableState.resource = ctx;
    };
    const setResourceId = () => {
      if (!editableFormData.resource_type) {
        editableFormData.resource_id = "";
        return;
      }
      if (
        _.get(localState.parentDocumentType, "value") ===
        DOCUMENT_GROUP_TYPES.COMMITTEE
      ) {
        editableFormData.resource_id = _.cloneDeep(
          editableFormData.committee_id
        );
      } else if (
        editableFormData.resource_type === _.get(route.query, "resource_type")
      ) {
        editableFormData.resource_id = _.get(route.query, "resource_id", "");
      } else {
        editableFormData.resource_id = "";
      }
    };

    const changedType = () => {
      documentTypes.value.map((type) => {
        _.get(type, "children", []).map((subType: any) => {
          if (editableFormData.type * 1 === subType.value) {
            localState.parentDocumentType = _.cloneDeep(type);
            localState.resourceTypes = _.get(type, "resource_types", []).map(
              (item: string) => {
                return {
                  text: helper.capitalizeEachWords(item),
                  value: item,
                };
              }
            );
          }
        });
      });

      if (
        !editableFormData.resource_type ||
        _.findIndex(localState.resourceTypes, {
          value: editableFormData.resource_type,
        }) === -1
      ) {
        editableFormData.resource_type = _.get(
          localState.resourceTypes,
          "0.value",
          ""
        );
      }
    };

    onMounted(() => {
      changedType();
      setTimeout(() => {
        let definedFirstType = false;
        documentTypes.value.map((group) => {
          group.children?.map((item) => {
            if (item.value === editableFormData.type * 1) {
              definedFirstType = true;
            }
          });
        });
        if (!definedFirstType) {
          editableFormData.type = _.get(
            documentTypes.value,
            `0.children.0.value`,
            101
          );
        } else {
          setResourceId()
        }
      }, 100);
    });

    return {
      localState,
      editableFormData,
      documentTypes,
      resourceSelect2Options,
      selectedResourceTypeTitle,
      onChangeResource,
    };
  },
});
</script>
