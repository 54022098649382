import { taskService, GetTask } from "@/services";
import { helper } from "@/utils/helper";
import { Router } from "vue-router";
import { Ref } from "vue";
import { ConfirmRef } from "@/components/confirm";
import { SendEmailRef } from "@/components/email-to-members";

export function getTaskableTitle(task: GetTask): string {
  let title = "";
  if (task.taskable && task.taskable.acronym)
    title += `${task.taskable.acronym}`;
  if (task.taskable) {
    if (task.taskable.reference) title += `${task.taskable.reference}`;
    else if (task.taskable.title) title += `${task.taskable.title}`;
  }
  return `<b>${helper.capitalizeEachWords(
    task.taskable_type || ""
  )}:</b> ${title}`;
}

export function completeTask(
  task: GetTask,
  router: Router,
  confirmRef: Ref<ConfirmRef> | undefined,
  sendEmailRef: Ref<SendEmailRef> | undefined
): void {
  if (task.completed_at) return;
  if (!task.completion_event) {
    confirmRef?.value?.show({
      title: `Do you want to complete the selected task?`,
      icon: "Trash2Icon",
      iconClass: "text-red-400",
      confirmText: "Yes",
      cancelText: "No",
      callback: () => {
        task.completed_at = helper.formatDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        );
        taskService.complete(task.id).then((result) => {
          if (result.kind === "ok") {
            task.completed_at = result.data.completed_at;
          } else {
            task.completed_at = "";
          }
        });
      },
    });
  } else {
    let route: any = null;
    switch (task.policy) {
      case "meeting.manage":
        route = goToMeetingPage(task, sendEmailRef);
        break;
      case "ballot.vote":
        route = goToBallotPage(task);
        break;
      case "project.manage":
        route = goToProjectPage(task);
        break;
    }
    if (route) {
      router.push(route).finally();
    }
  }
}

const goToMeetingPage = (
  task: GetTask,
  sendEmailRef: Ref<SendEmailRef> | undefined
) => {
  if (task.completion_event === "App\\Events\\Meetings\\EmailsToMembersSent") {
    sendEmailRef?.value.show({
      committee: _.get(task.taskable, "committee", null),
      success: () => {
        task.completed_at = helper.formatDate(
          new Date(),
          "YYYY-MM-DD HH:mm:ss"
        );
      },
    });
  }
  if (
    [
      "App\\Events\\Meetings\\AgendaUploaded",
      "App\\Events\\Meetings\\MeetingResolutionsUploaded",
      "App\\Events\\Meetings\\ParticipantListUploaded",
    ].indexOf(task.completion_event) > -1
  ) {
    const route: Record<string, any> = {
      name: "documents.create",
      params: {
        committeeId: _.get(task.taskable, "committee_id", null),
      },
      query: {
        resource_type: "meeting",
        resource_id: task.taskable_id,
      },
    };
    switch (task.completion_event) {
      case "App\\Events\\Meetings\\AgendaUploaded":
        _.set(route, "query.type", 401);
        break;
      case "App\\Events\\Meetings\\MeetingResolutionsUploaded":
        _.set(route, "query.type", 402);
        break;
      case "App\\Events\\Meetings\\ParticipantListUploaded":
        _.set(route, "query.type", 404);
        break;
    }
    return route;
  }

  return null;
};

const goToBallotPage = (task: GetTask) => {
  const taskable = task.taskable;
  if (
    taskable &&
    ["App\\Events\\Ballots\\BallotVoted"].indexOf(task.completion_event) > -1
  ) {
    return {
      name: `ballots.view`,
      params: {
        id: taskable.id || null,
      },
    };
  }
  return null;
};

const goToProjectPage = (task: GetTask) => {
  const taskable = task.taskable;
  if (
    taskable &&
    ["App\\Events\\Ballots\\ProjectStageDecisionSubmitted"].indexOf(
      task.completion_event
    ) > -1
  ) {
    return {
      name: `projects.view`,
      params: {
        id: taskable.id || null,
      },
      query: {
        openDecisionModal: 1,
      },
    };
  }
  return null;
};
