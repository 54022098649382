// Create initial state

import { GetRoleType } from "@/services";

export type State = {
  roleTypes: GetRoleType[];
};
export const state: State = {
  roleTypes: [],
};
