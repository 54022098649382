
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee } from "@/services";
import Pagination from "@/components/pagination/Main.vue";
import CreateBallotDropdownList from "@/views/ballots/list/CreateBallotDropdownList.vue";

export default defineComponent({
  name: "Library",
  components: { CreateBallotDropdownList, Pagination },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const isWg = computed(() => {
      return props.committee.type === "WG";
    });

    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "ballot",
        config: {
          // "@paginate": 5,
          "@page": 1,
          "@select": [
            "id",
            "committee_id",
            "ballot_type",
            "reference",
            "english_title",
            "status",
            "end_date",
          ],
          "@where": {
            committee_id: _.get(props, "committee.id", props.committeeId),
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      isWg,
      loading,
      list,
      filter,
      changePage,
      changePerPage,
    };
  },
});
