<template>
  <div class="section-divider mb-4">Questions</div>
  <div class="overflow-auto mb-8">
    <table class="min-w-full border border-gray-300 text-xs">
      <thead>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-normal"
        >
          ID
        </th>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-normal"
        >
          Question
        </th>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="`data-question-${rowIndex}`">
          <td
            class="border-b border-r border-gray-300 px-3 py-1.5 text-left font-bold whitespace-nowrap w-0"
          >
            {{ row.sq }}
          </td>
          <td class="border-b border-r border-gray-300 px-3 py-1.5 font-normal">
            {{ row.text }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Questions",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
