
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useGeneralActions } from "@/utils/actions";
import { debounce } from "debounce";
import { useStore } from "@/store";
import DocumentFilteredSimpleList from "@/views/documents/views/list/FilteredSimpleList.vue";
import DocumentFilter from "@/views/documents/views/list/Filter.vue";
import DocumentList from "@/views/documents/views/list/Main.vue";
import DocumentDetailView from "@/views/documents/views/detail/Main.vue";
import { useRoute, useRouter } from "vue-router";
import { helper } from "@/utils/helper";
import { DOCUMENT_GROUP_TYPES, fetchSelection } from "@/services";

export default defineComponent({
  name: "DocumentViews",
  components: {
    DocumentDetailView,
    DocumentList,
    DocumentFilteredSimpleList,
    DocumentFilter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { filterRecords } = useGeneralActions();

    const wrapperRef = ref<HTMLElement>();
    const headerRef = ref<HTMLElement>();
    const contentRef = ref<HTMLElement>();
    const resourceType = ref<string>("");
    const folder = ref<any>(null);
    const loadingCurrentFolder = ref<boolean>(false);

    const state = reactive({
      scroll: {
        offset: 0,
        perPage: 20,
      },
    });

    const documents = computed(() =>
      store.state.document.documents.map((document) => {
        return {
          uuid: document.uuid || helper.uuidv4(),
          ...document,
        };
      })
    );

    const documentUuid = computed(() => _.get(route.params, "uuid", null));
    const folderId = computed<number>(
      () => _.get(route, "query.folderId", 0) * 1
    );
    const committeeId = computed<any>(
      () => _.get(folder.value, "committee_id", "0") * 1
    );
    const breadcrumbs = computed<any[]>(() => {
      const arr: any[] = [];
      if (_.get(folder.value, "folder.folder_id", "0") * 1 > 0) {
        arr.push({
          title: "",
          value: _.get(folder.value, "folder.folder_id", 0),
        });
      }
      if (_.get(folder.value, "folder.id", "0") * 1 > 0) {
        arr.push({
          title: _.get(folder.value, "folder.title", ""),
          value: _.get(folder.value, "folder.id", 0),
        });
      }
      if (_.get(folder.value, "id", "0") * 1 > 0) {
        arr.push({
          title: _.get(folder.value, "title", ""),
          value: _.get(folder.value, "id", 0),
        });
      }
      return arr;
    });

    const fetchDocuments = async (withAggregation?: any): Promise<void> => {
      const query = formattedQuery();
      const funcs = [];
      if (typeof withAggregation !== "undefined" && withAggregation)
        funcs.push("aggregation");
      if (folderId.value) funcs.push("withFolders");
      store.dispatch("document/FETCH_DOCUMENTS", {
        config: {
          "@func": funcs,
        },
        filterFormat: {
          offset: "@offset",
          perPage: "@paginate",
          title: "@where._title",
          committee_id: "@where.committee_id",
          expected_action: "@where.expected_action",
          status: "@where.status",
          type: "@where.type",
          folderId: "@where.folder_id",
          n_number_from: "@whereRange.n_number.from",
          n_number_to: "@whereRange.n_number.to",
          exp_action_from: "@whereDateRange.exp_action_due_date.from",
          exp_action_to: "@whereDateRange.exp_action_due_date.to",
          created_at_from: "@whereDateRange.created_at.from",
          created_at_to: "@whereDateRange.created_at.to",
        },
        formattedQuery: {
          ...query,
          folderId: folderId.value || null,
          offset: state.scroll.offset,
          perPage: state.scroll.perPage,
        },
      });
    };

    const changeActiveList = debounce(
      (startIndex: number, endIndex: number) => {
        const perPage = endIndex - startIndex;
        if (
          startIndex !== state.scroll.offset ||
          perPage > state.scroll.perPage
        ) {
          state.scroll.offset = startIndex;
          state.scroll.perPage = perPage;
          const documents = store.state.document.documents.slice(
            startIndex,
            startIndex + perPage
          );
          const emptyDocuments = _.filter(documents, (o) => !o.uuid);
          if (emptyDocuments.length > 0) {
            fetchDocuments().finally();
          }
        }
      },
      250
    );

    const { filter, formattedQuery, applyFilter, clearFilter } = filterRecords(
      fetchDocuments,
      {
        title: "",
        type: [],
        status: [],
        committee_id: [],
        expected_action: [],
        n_number_from: "",
        n_number_to: "",
        exp_action_from: "",
        exp_action_to: "",
        created_at_from: "",
        created_at_to: "",
      },
      true
    );

    const fetchCurrentFolder = () => {
      loadingCurrentFolder.value = true;
      fetchSelection({
        document: {
          "@first": true,
          "@select": ["id", "committee_id", "title", "folder_id", "type"],
          folder: {
            "@select": ["id", "title", "folder_id"],
          },
          "@func": ["onlyFolders"],
          "@where": {
            id: folderId.value,
          },
        },
      })
        .then((result) => {
          if (result.kind === "ok") {
            folder.value = _.get(result.data, "document", null);
            _.forEach(DOCUMENT_GROUP_TYPES, (val, key) => {
              if (_.get(folder.value, "type", null) === val) {
                switch (key) {
                  case "DRAFT":
                    resourceType.value = "project";
                    break;
                  case "COMMITTEE":
                    resourceType.value = "committee";
                    break;
                  case "MEETING":
                    resourceType.value = "meeting";
                    break;
                  case "BALLOT":
                    resourceType.value = "ballot";
                    break;
                  case "SECRETARY":
                    resourceType.value = "secretary";
                    break;
                  case "MAIL":
                    resourceType.value = "mail";
                    break;
                  default:
                    resourceType.value = "";
                    break;
                }
              }
            });
          }
        })
        .finally(() => {
          loadingCurrentFolder.value = false;
        });
    };

    const calculateHeights = () => {
      const wrapperHeight = wrapperRef.value?.clientHeight || 0;
      const headerHeight = headerRef.value?.offsetHeight || 0;
      const height = wrapperHeight - headerHeight;
      if (contentRef.value) cash(contentRef.value).height(height);
    };

    watch(
      documentUuid,
      (val) => {
        if (!val && !folderId.value) fetchDocuments(true);
      },
      { flush: "post" }
    );
    watch(
      folderId,
      (val) => {
        if (!documentUuid.value) fetchDocuments(true);
        if (val) fetchCurrentFolder();
        else folder.value = null;
      },
      { flush: "post" }
    );

    const goToDocumentCreate = () => {
      if (committeeId.value) {
        const query = { folderId: folderId.value };
        if (resourceType.value) {
          _.set(query, "resource_type", resourceType.value);
        }
        router.push({
          name: "documents.create",
          params: {
            committeeId: committeeId.value,
          },
          query,
        });
      }
    };

    const onOpenCreateFolderModal = () => {
      cash("#document-folder-modal").modal("show");
    };

    onMounted(() => {
      if (!documentUuid.value) fetchDocuments(true);
      if (folderId.value) fetchCurrentFolder();

      if (!cash("#content").hasClass("content--fixed"))
        cash("#content").addClass("content--fixed");
      window.addEventListener("resize", calculateHeights);
      calculateHeights();
    });

    onBeforeUnmount(() => {
      cash("#content").removeClass("content--fixed");
      window.removeEventListener("resize", calculateHeights);
    });

    return {
      wrapperRef,
      headerRef,
      contentRef,
      documents,
      documentUuid,
      state,
      breadcrumbs,
      folderId,
      committeeId,
      filter: filter.query,
      loadingCurrentFolder,
      changeActiveList,
      applyFilter,
      clearFilter,
      onOpenCreateFolderModal,
      goToDocumentCreate,
    };
  },
});
