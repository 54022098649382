<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEye
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">VIEW USER</div>
    </div>
    <div v-if="loading" class="text-center p-4 pt-8">
      <LoaderIcon class="spin-5 inline-block w-8 h-8" />
      <div class="mt-2 font-light">
        Please wait while the records are loading.
      </div>
    </div>
    <div class="p-3 sm:p-5" v-else>
      <!-- BEGIN: Top Buttons -->
      <div class="flex justify-end transform -translate-y-9 sm:-translate-y-11">
        <button
          class="button bg-gradient-to-b from-gray-400 to-gray-500 text-white relative pr-16 whitespace-nowrap"
          @click="exportUser"
        >
          Export User
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-gray-600 to-gray-700 text-white rounded-md py-2.5 px-3"
          >
            <FileTextIcon class="inline w-6 h-6" />
          </div>
        </button>
        <router-link
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap ml-4"
          :to="{ name: 'users.edit', params: { id } }"
          v-if="canEdit"
        >
          Edit User
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <Edit2Icon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <!-- END: Top Buttons -->
      <UserInformation :detail="detail" />
      <UserRoles
        :detail="detail"
        :user-id="id"
        v-model:defined-roles="definedRoles"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import { useGeneralActions } from "@/utils/actions";
import { userService, GetRole, roleService } from "@/services";
import UserInformation from "@/views/users/view/Information.vue";
import UserRoles from "@/views/users/view/roles/Main.vue";
import { UserInformationPDFCreation } from "@/utils/pdf-creation/user-information";

export default defineComponent({
  name: "ViewUser",
  components: { UserRoles, UserInformation },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const definedRoles = ref<GetRole[]>([]);
    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: userService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        loading.value = true;
        roleService
          .fetch(id.value)
          .then((result) => {
            if (result.kind === "ok") {
              definedRoles.value = result.data;
            }
          })
          .finally(() => {
            loading.value = false;
          });
      },
    });

    const id = computed<number>(() => _.get(route, "params.id", 0) * 1);
    const headerTitle = computed<string>(() => {
      return `VIEW USER (#${id.value})`;
    });
    const roles = computed<Record<string, any>[]>(() => {
      return _.get(data.value, "roles", []);
    });
    const canEdit = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_update", false);
    });

    const exportUser = () => {
      if (data.value) {
        new UserInformationPDFCreation()
          .process({
            user: data.value,
            roles: definedRoles.value,
          })
          .then((pdfCreation) => {
            pdfCreation.output().then((url) => {
              router.push({
                query: { ...route.query, iUrl: url },
              });
            });
          });
      }
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      headerTitle,
      roles,
      detail: data,
      definedRoles,
      canEdit,
      exportUser,
    };
  },
});
</script>
