import { App } from "vue";

import SvgIconDashboard from "./SvgIconDashboard.vue";
import SvgIconDocuments from "./SvgIconDocuments.vue";
import SvgIconUser from "./SvgIconUser.vue";
import SvgIconTable from "./SvgIconTable.vue";
import SvgIconFolder from "./SvgIconFolder.vue";
import SvgIconAnalytic from "./SvgIconAnalytic.vue";
import SvgIconWorldCircle from "./SvgIconWorldCircle.vue";
import SvgIconEdit from "./SvgIconEdit.vue";
import SvgIconUpload from "./SvgIconUpload.vue";
import SvgIconDocument from "./SvgIconDocument.vue";
import SvgIconEye from "./SvgIconEye.vue";
import SvgIconSettings from "./SvgIconSettings.vue";
import SvgIconOrganization from "./SvgIconOrganization.vue";
import SvgIconList from "./SvgIconList.vue";
import SvgIconUsers from "./SvgIconUsers.vue";

export default (app: App): void => {
  app.component("SvgIconDashboard", SvgIconDashboard);
  app.component("SvgIconDocuments", SvgIconDocuments);
  app.component("SvgIconUser", SvgIconUser);
  app.component("SvgIconTable", SvgIconTable);
  app.component("SvgIconFolder", SvgIconFolder);
  app.component("SvgIconAnalytic", SvgIconAnalytic);
  app.component("SvgIconWorldCircle", SvgIconWorldCircle);
  app.component("SvgIconEdit", SvgIconEdit);
  app.component("SvgIconUpload", SvgIconUpload);
  app.component("SvgIconDocument", SvgIconDocument);
  app.component("SvgIconEye", SvgIconEye);
  app.component("SvgIconSettings", SvgIconSettings);
  app.component("SvgIconOrganization", SvgIconOrganization);
  app.component("SvgIconList", SvgIconList);
  app.component("SvgIconUsers", SvgIconUsers);
};
