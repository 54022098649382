<template>
  <div class="shadow-lg rounded-md w-full">
    <div :id="editorUuid" class="modal">
      <div class="modal__content p-6">
        <VueInputGroup
          class="mb-6"
        >
          <VueInput
            v-model="linkRef"
            type="url"
            placeholder="(https or http)://..."
            class="bg-gray-200 shadow-none"
          />
        </VueInputGroup>
        <div class="text-center">
          <button
            class="button rounded-l-full bg-red-400 text-white font-light px-8 shadow-lg"
            @click="hiddenLinkModal"
          >
            Cancel
          </button>
          <button
            class="button rounded-r-full bg-gray-500 text-white font-light px-8 shadow-lg"
            @click="saveLink"
          >
            Save
          </button>
        </div>
      </div>
    </div>
    <ul class="flex flex-wrap bg-gray-200 rounded-t-md" v-if="editor">
      <li class="p-1">
        <Tippy
          tag="button"
          content="Bold"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'bg-gray-300': editor.isActive('bold') }"
        >
          <bold-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="Heading 1"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all text-lg leading-5 font-light"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'bg-gray-300': editor.isActive('heading', { level: 1 }) }"
        >
          H<small>1</small>
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="Align Left"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'bg-gray-300': editor.isActive({ textAlign: 'left' }) }"
        >
          <align-left-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="Align Left"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'bg-gray-300': editor.isActive({ textAlign: 'center' }) }"
        >
          <align-center-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="Align Left"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'bg-gray-300': editor.isActive({ textAlign: 'right' }) }"
        >
          <align-right-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="Align Left"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="editor.chain().focus().setTextAlign('justify').run()"
          :class="{ 'bg-gray-300': editor.isActive({ textAlign: 'justify' }) }"
        >
          <align-justify-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="List"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'bg-gray-300': editor.isActive('bulletList') }"
        >
          <list-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1">
        <Tippy
          tag="button"
          content="Link"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all"
          @click="openLinkModal"
          :class="{ 'bg-gray-300': editor.isActive('link') }"
        >
          <link-icon class="w-5 h-5" />
        </Tippy>
      </li>
      <li class="p-1" v-if="editor.isActive('link')">
        <Tippy
          tag="button"
          content="Remove Link"
          :options="{ placement: 'bottom' }"
          class="p-2.5 rounded-md hover:bg-gray-300 transition-all relative"
          @click="clearLink"
        >
          <link-icon class="w-5 h-5" />
          <div
            class="absolute w-4 h-4 leading-none bg-red-400 text-white rounded-full left-1 top-1"
          >
            <x-icon class="inline-block w-3 h-3" />
          </div>
        </Tippy>
      </li>
    </ul>
    <EditorContent :editor="editor" :key="editorUuid" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { Editor, EditorContent } from "@tiptap/vue-3";
import Dropcursor from "@tiptap/extension-dropcursor";
import Gapcursor from "@tiptap/extension-gapcursor";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import Bold from "@tiptap/extension-bold";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import Heading from "@tiptap/extension-heading";
import HardBreak from "@tiptap/extension-hard-break";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  components: { VueInputGroup, VueInput, EditorContent },
  name: "VueTiptap",
  props: {
    modelValue: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "Write something …",
    },
  },
  setup(props, { emit }) {
    const editorUuid = ref(helper.uuidv4());
    const editor = ref<Editor>();

    onMounted(() => {
      editor.value = new Editor({
        content: props.modelValue,
        extensions: [
          Document,
          Paragraph,
          Text,
          TextAlign,
          Bold,
          Heading.configure({
            levels: [1, 2],
          }),
          BulletList,
          ListItem,
          Link,
          HardBreak,
          Dropcursor,
          Gapcursor,
          Placeholder.configure({
            placeholder: props.placeholder,
          }),
        ],
        onUpdate: (ctx) => {
          emit("update:modelValue", ctx.editor.getHTML());
          emit("update:json-data", ctx.editor.getJSON());
        },
      });

      if (props.modelValue) {
        emit("update:json-data", editor.value?.getJSON());
      }

      cash("body").on(
        "click",
        'a[rel="noopener noreferrer nofollow"]',
        (e: any) => {
          e.preventDefault();
          e.stopPropagation();
        }
      );
    });

    watch(
      () => props.modelValue,
      (val: any) => {
        if (editor.value && val !== editor.value?.getHTML()) {
          editor.value?.commands.setContent(val || "");
          emit("update:json-data", editor.value?.getJSON());
        }
      },
      { flush: "post" }
    );

    const linkRef = ref<string>("");

    const openLinkModal = () => {
      linkRef.value = _.get(
        editor.value?.getMarkAttributes("link"),
        "href",
        ""
      );
      cash(`#${editorUuid.value}`).modal("show");
    };

    const clearLink = () => {
      editor.value?.chain().focus().unsetLink().run();
    };

    const saveLink = () => {
      if (!linkRef.value) clearLink();
      else {
        editor.value?.chain().focus().setLink({ href: linkRef.value }).run();
      }
      hiddenLinkModal();
    };

    const hiddenLinkModal = () => {
      linkRef.value = "";
      cash(`#${editorUuid.value}`).modal("hide");
    };

    return {
      editorUuid,
      editor,
      linkRef,
      openLinkModal,
      hiddenLinkModal,
      saveLink,
      clearLink,
    };
  },
});
</script>
