<template>
  <div class="grid grid-cols-4 gap-4">
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Stage
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "stage", "") }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Version Number
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(currentStage, "version", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full grid grid-cols-4 sm:col-span-full bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Committee
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        <router-link
          :to="{
            name: 'committees.view',
            params: { id: project.committee_id },
          }"
          class="text-blue-500 hover:text-blue-700"
        >
          ({{ $_.get(project, "committee.acronym", "") }})
          {{ $_.get(project, "committee.title", "") }}
        </router-link>
      </div>
    </div>
    <div
      class="col-span-full grid grid-cols-4 sm:col-span-full bg-gray-100 flex rounded-lg"
      v-if="$_.get(project, 'wg_id')"
    >
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Working Group
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        <template v-if="$_.get(project, 'working_group.id')">
          ({{ $_.get(project, "working_group.acronym", "") }})
          {{ $_.get(project, "working_group.title", "") }}
        </template>
        <template v-else>-</template>
      </div>
    </div>
    <div class="col-span-full section-divider">English Information</div>
    <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Title
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        {{ $_.get(project, "title_en", "") }}
      </div>
    </div>
    <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Description
        </div>
      </div>
      <div
        class="col-span-full sm:col-span-3 ProseMirror-Viewer px-6 py-3.5"
        v-html="$_.get(project, 'description_en', '')"
      ></div>
    </div>
    <div class="col-span-full section-divider">French Information</div>
    <template
      v-if="
        $_.get(project, 'title_fr', '') || $_.get(project, 'description_fr', '')
      "
    >
      <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
        <div
          class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
        >
          <div
            class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
          >
            Title
          </div>
        </div>
        <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
          {{ $_.get(project, "title_fr", "") }}
        </div>
      </div>
      <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
        <div
          class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
        >
          <div
            class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
          >
            Description
          </div>
        </div>
        <div
          class="col-span-full sm:col-span-3 ProseMirror-Viewer px-6 py-3.5"
          v-html="$_.get(project, 'description_fr', '')"
        ></div>
      </div>
    </template>
    <div class="col-span-full section-divider">Arabic Information</div>
    <template
      v-if="
        $_.get(project, 'title_ar', '') || $_.get(project, 'description_ar', '')
      "
    >
      <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
        <div
          class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
        >
          <div
            class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
          >
            Title
          </div>
        </div>
        <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
          {{ $_.get(project, "title_ar", "") }}
        </div>
      </div>
      <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
        <div
          class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
        >
          <div
            class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
          >
            Description
          </div>
        </div>
        <div
          class="col-span-full sm:col-span-3 ProseMirror-Viewer px-6 py-3.5"
          v-html="$_.get(project, 'description_ar', '')"
        ></div>
      </div>
    </template>
    <div class="col-span-full section-divider">Other Information</div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Project No
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "project_no", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Part No
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "part_no", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Reference
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "reference", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Edition No
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "edition_no", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Price
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "price", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Visible On Website
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.convertIdToText(
            $_.get(project, "visible_on_website", "0"),
            "yesOrNo"
          )
        }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Time Frame
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(project, "time_frame", "") }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Target Date
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.formatDate(
            $_.get(currentStage, "target_date", ""),
            "DD MMMM YYYY"
          ) || "-"
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { GetProject, GetProjectLine } from "@/services";

export default defineComponent({
  name: "ProjectViewInformation",
  props: {
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props) {
    const currentStage = ref<GetProjectLine>({});
    const findCurrentStage = () => {
      props.project.lines.map((line: GetProjectLine) => {
        if (line.status === "CURRENT") currentStage.value = line;
      });
    };
    watch(() => props.project.lines, findCurrentStage, {
      deep: true,
      flush: "post",
    });
    onMounted(() => {
      findCurrentStage();
    });
    return {
      currentStage,
    };
  },
});
</script>
