
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { committeeMemberService } from "@/services";
import _ from "lodash";
import { useGeneralActions } from "@/utils/actions/";
import TransactionStatement from "@/components/transaction-statement/Main.vue";

export default defineComponent({
  name: "ParticipatingRoles",
  components: { TransactionStatement },
  props: {
    memberId: {
      required: true,
      default: () => null,
    },
    list: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "Participating Roles",
    },
  },
  setup(props) {
    const router = useRouter();

    const { deleteRecords } = useGeneralActions();

    const localList = ref<any[]>(props.list);

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: async (id) => {
        return await committeeMemberService.delete(id, props.memberId);
      },
      successCallback(id) {
        if (id) {
          const index = _.findIndex(localList.value, { id });
          if (index > -1) localList.value.splice(index, 1);
        }
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    return {
      localList,
      processing,
      transactionIds,
      triggerDelete,
      goToView,
    };
  },
});
