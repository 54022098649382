<template>
  <div class="accordion__pane py-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      @click="() => $emit('update:active-tab', 'timeline')"
    >
      Timeline & History
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <TimelineVisJS
        v-if="activeTab === 'timeline'"
        :options="timelineOptions"
      />
      <div class="section-divider py-8">History</div>
      <!-- BEGIN: Table -->
      <div class="overflow-x-auto">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap w-1 text-center">Stage</th>
              <th class="whitespace-nowrap w-1 text-center">Version</th>
              <th class="whitespace-nowrap">Description</th>
              <th class="whitespace-nowrap text-center">Target Date</th>
              <th class="whitespace-nowrap text-center">Started</th>
              <th class="whitespace-nowrap text-center">Status</th>
              <th
                class="whitespace-nowrap text-right"
                v-if="$_.get(project, 'abilities.can_update', false)"
              >
                Functions
              </th>
            </tr>
          </thead>
          <tbody>
            <TransactionStatement
              tag="tr"
              v-for="row in lines"
              :key="'project-line-' + row.id"
              :processing="
                editableLine.processing && row.id === editableLine.id
              "
              :waiting="true"
            >
              <td class="text-center">{{ row.stage }}</td>
              <td class="text-center">{{ row.version }}</td>
              <td>{{ row.description }}</td>
              <td class="text-center">
                <template v-if="editableLine.id !== row.id">
                  {{ $h.formatDate(row.target_date, "DD MMMM YYYY") || "-" }}
                </template>
                <VueInputGroup
                  v-else
                  :validation-errors="
                    editableLine.validationErrors.get('target_date')
                  "
                >
                  <Litepicker
                    class="bg-white px-4 py-2 text-center"
                    v-model="editableLine.formData.target_date"
                    :validation-errors="
                      editableLine.validationErrors.get('target_date')
                    "
                  />
                </VueInputGroup>
              </td>
              <td class="text-center">
                {{ $h.formatDate(row.start_date, "DD MMMM YYYY") || "-" }}
              </td>
              <td class="text-center">
                {{ row.status }}
              </td>
              <td
                class="text-right whitespace-nowrap"
                v-if="$_.get(project, 'abilities.can_update', false)"
              >
                <template v-if="row.status !== 'CLOSED'">
                  <template v-if="editableLine.id === row.id">
                    <Tippy
                      tag="button"
                      content="Save"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="onSaveEditLine"
                    >
                      <CheckIcon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      tag="button"
                      content="Close"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-red-500 rounded-full shadow-lg mr-2"
                      @click="onCloseEditLine"
                    >
                      <XIcon class="inline w-5 h-5" />
                    </Tippy>
                  </template>
                  <template v-else>
                    <Tippy
                      tag="button"
                      content="Edit"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="() => onEditLine(row)"
                      :disabled="editableLine.processing"
                    >
                      <Edit2Icon class="inline w-5 h-5" />
                    </Tippy>
                  </template>
                </template>
              </td>
            </TransactionStatement>
          </tbody>
        </table>
      </div>
      <!-- END: Table -->
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { GetProject, GetProjectLine, projectService } from "@/services";
import TimelineVisJS from "@/global-components/timeline/Main.vue";
import { helper } from "@/utils/helper";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Litepicker from "@/global-components/litepicker/Main.vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "ProjectTimeline",
  components: { Litepicker, TransactionStatement, TimelineVisJS },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props, { emit }) {
    const editableLine = reactive({
      id: null,
      processing: false,
      formData: {
        target_date: "",
      },
      validationErrors: new ValidationErrors(),
    });
    const lines = ref<GetProjectLine[]>([]);
    const timelineOptions = computed(() => {
      const targetRows = lines.value
        .filter((line) => !!line.target_date)
        .map((line) => {
          return {
            id: `target-${line.id}`,
            content: `(${line.stage}) ${line.description || ""}`,
            start: line.target_date,
            className: "vis-target-date",
          };
        });
      const startedRows = lines.value
        .filter((line) => !!line.start_date)
        .map((line) => {
          return {
            id: `started-${line.id}`,
            content: `(${line.stage}) ${line.description || ""}`,
            start: line.start_date,
            end: line.close_date || helper.formatDate(new Date(), "YYYY-MM-DD"),
            className: line.status === "CURRENT" ? "vis-current-line" : "",
          };
        });
      return {
        delay: 500,
        rows: [...targetRows, ...startedRows],
      };
    });
    const onEditLine = (line: GetProjectLine) => {
      if (!editableLine.processing) {
        editableLine.processing = false;
        editableLine.id = line.id;
        editableLine.formData.target_date = _.cloneDeep(line.target_date);
      }
    };
    const onCloseEditLine = () => {
      editableLine.processing = false;
      editableLine.id = null;
      editableLine.formData.target_date = "";
    };
    const onSaveEditLine = () => {
      editableLine.processing = true;
      projectService
        .updateProjectLine(
          props.projectId,
          editableLine.id,
          editableLine.formData
        )
        .then((result) => {
          if (result.kind === "ok") {
            onCloseEditLine();
            const index = _.findIndex(lines.value, { id: result.data.id });
            if (index > -1) {
              _.set(
                lines.value,
                `${index}.target_date`,
                result.data.target_date
              );
              emit("update:project", { ...props.project, lines: lines.value });
            }
          } else {
            if (result.kind === "validation") {
              editableLine.validationErrors.record(result.fields);
            }
          }
        })
        .finally(() => {
          editableLine.processing = false;
        });
    };
    onMounted(() => {
      lines.value = _.orderBy(
        _.get(props.project, "lines", []),
        ["stage"],
        ["asc"]
      );
    });
    watch(
      () => _.get(props.project, "lines", []),
      (val) => {
        lines.value = _.orderBy(val, ["stage"], ["asc"]);
      },
      { deep: true, flush: "post" }
    );
    return {
      timelineOptions,
      lines,
      editableLine,
      onEditLine,
      onCloseEditLine,
      onSaveEditLine,
    };
  },
});
</script>
