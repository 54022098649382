import { degrees } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import { PdfCreation } from "@/utils/pdf-creation/index";
import { helper } from "@/utils/helper";
import { FRONTEND_URL } from "@/services";

interface QueryProps {
  user: any;
}

export class StandardDocumentPDFCreation extends PdfCreation {
  query: QueryProps = {
    user: {},
  };

  async overwriteAllPages() {
    const url = `${FRONTEND_URL}/fonts/dosis/Dosis-Regular.ttf`;
    const fontBytes = await fetch(url).then((res) => res.arrayBuffer());

    this.pdfDoc?.registerFontkit(fontkit);
    const customFont = await this.pdfDoc?.embedFont(fontBytes);

    this.pdfDoc?.getPages().map((page) => {
      const { width, height } = page.getSize();
      const isLandscape = width >= height;
      const time = new Date();
      const nameSurname = `${_.get(this.query.user, "salutation", "")} ${_.get(
        this.query.user,
        "name",
        ""
      )} ${_.get(this.query.user, "surname", "")}`;
      const text = `This document was downloaded by ${nameSurname} from SMIIC IS at ${helper.formatDate(
        time,
        "HH:mm"
      )} on ${helper.formatDate(time, "DD/MM/YYYY")}.`;
      page.drawText(text, {
        size: 9,
        x: isLandscape ? height - 15 : 15,
        y: 20,
        rotate: degrees(isLandscape ? 90 : 0),
        maxWidth: isLandscape ? height - 30 : width - 30,
        lineHeight: 13,
        font: customFont,
      });
    });
  }
}
