<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconUpload
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">CREATE DOCUMENT</div>
      <div class="text-white text-sm font-light">
        Morbi auctor lacus nec nisl egestas molestie. Praesent bibendum mi ut
        massa consectetur auctor.
      </div>
    </div>

    <div class="p-3 sm:px-5 sm:py-5">
      <div
        v-if="loading"
        class="text-center p-4 pt-8"
      >
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <!-- BEGIN: Top Buttons -->
        <div class="transform -translate-y-9 sm:-translate-y-11">
          <DocumentActionButtons
            class="justify-between"
            :form-data="formData"
            :state="state"
            :on-change-step="onChangeStep"
            :on-preview="onPreview"
            :on-upload="onUpload"
            :on-validate-and-upload="onValidateAndUpload"
          />
        </div>
        <!-- END: Top Buttons -->

        <div
          class="p-5 bg-gray-200 rounded-lg"
          v-show="!state.upload.processing && state.activeStep === 'info'"
        >
          <VueInputGroup
            :validation-errors="validationErrors.get('uuid', 'file_name')"
          >
            <VueFileBrowser
              class="bg-transparent shadow-none border-0 p-0"
              type="file"
              v-model="state.selectedFile"
              :disabled="state.upload.processing"
            />
          </VueInputGroup>
        </div>
        <!-- BEGIN: WIZARD -->
        <div class="pt-4">
          <div
            :class="{
              'mb-8':
                state.upload.processing ||
                state.upload.percent > 0 ||
                state.upload.hasError,
            }"
          >
            <div
              v-if="state.upload.processing || state.upload.percent > 0"
              class="p-1 bg-gradient-to-b from-theme-12 to-theme-13 text-center text-white text-lg font-semibold rounded-xl overflow-hidden relative"
            >
              <div
                class="absolute left-0 top-0 h-full bg-gray-600 opacity-75 rounded-xl transition-all"
                :class="{ 'bg-red-400': !!state.upload.hasError }"
                :style="{ width: `${state.upload.percent}%` }"
              ></div>
              <span class="relative">{{ `${state.upload.percent}%` }}</span>
              <LoaderIcon
                class="spin-5 w-5 h-5 absolute left-4 top-0 mt-2"
                v-if="state.upload.processing"
              />
            </div>
            <div
              class="text-red-400 p-4 text-center"
              v-if="state.upload.processing"
            >
              <a
                href="javascript:;"
                class="cursor-pointer hover:text-red-600"
                @click="onCancelUpload"
                >Cancel Upload</a
              >
            </div>
            <div
              class="mt-3 bg-red-400 text-white p-4 rounded-lg"
              v-if="!!state.upload.error"
              v-html="state.upload.error"
            ></div>
          </div>
          <DocumentInfo
            :state="state"
            :form-data="formData"
            :validation-errors="validationErrors"
            v-show="state.activeStep === 'info'"
          />
          <DocumentSummary
            :state="state"
            :form-data="formData"
            :on-upload="onUpload"
            v-if="state.activeStep === 'summary'"
          />
          <DocumentActionButtons
            class="justify-center mt-8"
            :form-data="formData"
            :state="state"
            :on-change-step="onChangeStep"
            :on-preview="onPreview"
            :on-upload="onUpload"
            :on-validate-and-upload="onValidateAndUpload"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { init } from "./index";
import VueFileBrowser from "@/global-components/form-elements/file-browser/Main.vue";
import DocumentInfo from "@/views/documents/create/info/Main.vue";
import DocumentActionButtons from "@/views/documents/create/Buttons.vue";
import DocumentSummary from "@/views/documents/create/summary/Main.vue";

export default defineComponent({
  name: "DocumentCreate",
  components: {
    DocumentSummary,
    DocumentActionButtons,
    DocumentInfo,
    VueFileBrowser,
  },
  setup() {
    const documentProperties = init();
    return {
      ...documentProperties,
    };
  },
});
</script>
