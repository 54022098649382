
import { defineComponent } from "vue";

export default defineComponent({
  name: "About",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
});
