import {
  GeneralApiResponse,
  GetCountryResult,
  GetCountryResults,
  useAxios,
} from "@/services";

export * from "./api.types";

export const countryService = {
  fetch: async (): Promise<GetCountryResults> =>
    useAxios().fetch(`/api/countries`, {}),

  show: async (id: unknown): Promise<GetCountryResult> =>
    useAxios().get(`/api/countries/${id}`),

  store: async (params: Record<string, any>): Promise<GetCountryResult> =>
    useAxios().store(`/api/countries`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetCountryResult> =>
    useAxios().update(`/api/countries/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/countries/${id}`),
};
