import {
  GeneralApiResponse,
  GetLiaisonResult,
  GetLiaisonResults,
  useAxios,
} from "@/services";

const committee = {
  fetch: async (
    committeeId: unknown,
    params: Record<string, any>
  ): Promise<GetLiaisonResults> =>
    useAxios().fetch(`/api/committees/${committeeId}/committees`, params),

  store: async (params: Record<string, any>): Promise<GetLiaisonResult> =>
    useAxios().store(
      `/api/committees/${params.committeeId}/committee-liaison/${params.liaisonId}`,
      params
    ),

  delete: async (
    committeeId: unknown,
    liaisonId: unknown
  ): Promise<GeneralApiResponse> =>
    useAxios().delete(
      `/api/committees/${committeeId}/committee-liaison/${liaisonId}`
    ),
};

const organization = {
  fetch: async (
    committeeId: unknown,
    params: Record<string, any>
  ): Promise<GetLiaisonResults> =>
    useAxios().fetch(`/api/committees/${committeeId}/organizations`, params),

  store: async (params: Record<string, any>): Promise<GetLiaisonResult> =>
    useAxios().store(
      `/api/committees/${params.committeeId}/organization-liaison/${params.liaisonId}`,
      params
    ),

  delete: async (
    committeeId: unknown,
    liaisonId: unknown
  ): Promise<GeneralApiResponse> =>
    useAxios().delete(
      `/api/committees/${committeeId}/organization-liaison/${liaisonId}`
    ),
};

export const liaisonService = {
  committee,
  organization,
  fetchCommitteeLiaisonsFromOrganization: async (
    organizationId: unknown,
    params: Record<string, any>
  ): Promise<GetLiaisonResults> =>
    useAxios().fetch(`/api/organizations/${organizationId}/liaisons`, params),
};
