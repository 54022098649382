import { fetchSelection } from "@/services";
import { computed } from "vue";
import { RouteLocationNormalizedLoaded, Router } from "vue-router";
import { Store } from "@/store";

interface Props {
  stage: string;
  committeeId: number;
  projectId: number;
  router: Router;
  route: RouteLocationNormalizedLoaded;
  store: Store;
}

interface LaunchBallotProps extends Props {
  mainStage: string;
}

const launchBallot = async (props: LaunchBallotProps): Promise<void> => {
  const isThereAPendingBallot = async (type: string): Promise<number> => {
    const result = await fetchSelection({
      ballot: {
        "@first": true,
        "@select": ["id", "status", "reference"],
        "@where": {
          ballot_type: type,
          committee_id: props.committeeId,
          project_id: props.projectId,
          status: [1],
        },
      },
    });
    if (result.kind === "ok") {
      return _.get(result.data, "ballot.id", 0) * 1;
    }
    return 0;
  };
  const params = { type: "" };
  switch (props.mainStage) {
    case "00":
      params.type = "PWI";
      break;
    case "10":
      params.type = "NP";
      break;
    case "20":
      params.type = "WD";
      break;
    case "30":
      params.type = "CD";
      break;
    case "40":
      params.type = "DS";
      break;
    case "50":
      params.type = "FDS";
      break;
    case "60":
      params.type = "SR";
      break;
    case "90":
      params.type = "WITHDRAWAL";
      break;
  }
  if (params.type) {
    const ballotId = await isThereAPendingBallot(params.type);
    if (ballotId > 0) {
      props.router
        .push({
          name: "ballots.view",
          params: { id: ballotId },
        })
        .finally();
    } else {
      props.router
        .push({
          name: "ballots.create",
          query: {
            committee_id: props.committeeId,
            project_id: props.projectId as any,
          },
          params,
        })
        .finally();
    }
  }
};

const openDecisionModal = (props: Props) => {
  if (
    props.route.name === "projects.view" &&
    props.route.params.id === props.projectId.toString()
  ) {
    cash("#stage-modal").modal("show");
  } else {
    props.router
      .push({
        name: "projects.view",
        params: {
          id: props.projectId,
        },
        query: {
          openDecisionModal: 1,
        },
      })
      .finally();
  }
};

export const launchActionForProject = async (props: Props): Promise<void> => {
  const mainStage = computed<string>(() => {
    const stage = props.stage.toString().split(".");
    return _.get(stage, [0], "00");
  });

  const isDecision = computed<boolean>(() => {
    return props.store.state.project.manualStages.indexOf(props.stage) > -1;
  });

  const isLaunchNewBallot = computed<boolean>(() => {
    return props.store.state.project.ballotStages.indexOf(props.stage) > -1;
  });

  if (isDecision.value) {
    openDecisionModal(props);
  } else if (isLaunchNewBallot.value) {
    launchBallot({
      ...props,
      mainStage: mainStage.value,
    }).finally();
  }
};
