import { App } from "vue";
import svgIcons from "./svg-icon";
import Litepicker from "./litepicker/Main.vue";
import Tippy from "./tippy/Main.vue";
import TippyContent from "./tippy-content/Main.vue";
import LoadingIcon from "./loading-icon/Main.vue";
import Dropzone from "./dropzone/Main.vue";
import TimelineVisJS from "./timeline/Main.vue";
import * as featherIcons from "@zhuowenli/vue-feather-icons";

import VueInputGroup from "./form-elements/input-group/Main.vue";
import VueInput from "./form-elements/input/Main.vue";
import VueTextarea from "./form-elements/textarea/Main.vue";
import VueSelect from "./form-elements/select/Main.vue";
import VueButton from "./form-elements/button/Main.vue";
import Select2Input from "./select2/Main.vue";
import VueDiff from "vue-diff";
import QuestionViewItem from "@/views/ballots/view/questions/view/QuestionViewItem.vue";
import AnswerViewItem from "@/views/ballots/view/questions/view/AnswerViewItem.vue";

export default (app: App): void => {
  app.use(VueDiff, { componentName: "VueDiff" });
  svgIcons(app);
  app.component("Litepicker", Litepicker);
  app.component("Tippy", Tippy);
  app.component("TippyContent", TippyContent);
  app.component("LoadingIcon", LoadingIcon);
  app.component("Dropzone", Dropzone);
  app.component("TimelineVisJS", TimelineVisJS);
  app.component("VueInputGroup", VueInputGroup);
  app.component("VueInput", VueInput);
  app.component("VueTextarea", VueTextarea);
  app.component("VueSelect", VueSelect);
  app.component("Select2Input", Select2Input);
  app.component("VueButton", VueButton);
  app.component("QuestionViewItem", QuestionViewItem);
  app.component("AnswerViewItem", AnswerViewItem);

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = "24";
    app.component(key, icon);
  }
};
