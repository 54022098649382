<template>
  <div class="accordion__pane py-6">
    <!-- BEGIN ABOUT -->
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
    >
      Committee Liaisons
      <template v-if="!loading">({{ list.meta.total }})</template>
      <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
    </a>
    <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
      <VueInputGroup :validation-errors="validationErrors.get('committee')">
        <template v-slot:label>
          <label class="block mb-1">Add New Liaison</label>
        </template>
        <div class="flex">
          <Select2Input
            class="rounded-r-none"
            v-model="newLiaisonId"
            :options="liaisonSelect2Options"
            :validation-errors="validationErrors.get('committee')"
          />
          <VueButton
            :disabled="!newLiaisonId"
            class="rounded-l-none bg-gray-300 hover:bg-gray-200 shadow-lg px-6"
            @click="onAddNewLiaison"
            :loading="submitProcessing"
          >
            <PlusCircleIcon class="w-5 h-5" />
          </VueButton>
        </div>
      </VueInputGroup>

      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="section-divider mb-2 mt-8">List Of Selected Liaisons</div>
        <!-- BEGIN: Table -->
        <div class="overflow-x-auto">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x my-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap w-1">
                  <input
                    type="checkbox"
                    class="input input--lg border mr-2"
                    v-model="selectAll"
                  />
                </th>
                <th class="whitespace-nowrap text-center w-0">Acronym</th>
                <th class="whitespace-nowrap">Title</th>
                <th class="whitespace-nowrap w-1 text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="`liaison-o-${row.id}`"
                :processing="
                  deleteProcessing && transactionIds.indexOf(row.id) > -1
                "
                :waiting="transactionIds[0] !== row.id"
              >
                <td>
                  <input
                    type="checkbox"
                    class="input input--lg border mr-2"
                    :value="row.id"
                    v-model="selectedRows"
                  />
                </td>
                <td class="text-center">{{ row.acronym }}</td>
                <td>
                  <router-link
                    class="text-blue-500 hover:text-blue-600"
                    :to="{
                      name: 'committees.view',
                      params: { id: row.id },
                    }"
                  >
                    {{ row.title }}
                  </router-link>
                </td>
                <td class="text-right">
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button w-10 h-10 p-0 bg-white text-red-400 rounded-full shadow-lg"
                    @click="triggerDelete([row.id])"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <!-- END: Table -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { liaisonService, GetLiaisonResult } from "@/services";
import _ from "lodash";
import { Select2Options } from "@/global-components/select2";
import createOrUpdate from "@/utils/actions/create-or-update";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "CommitteeLiaisons",
  components: { TransactionStatement, Pagination },
  props: {
    committeeId: {
      required: true,
      default: () => null,
    },
    levelTcNumber: {
      default: () => null,
    },
  },
  setup(props) {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await liaisonService.committee.fetch(props.committeeId, query);
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) => {
        return await liaisonService.committee.delete(props.committeeId, id);
      },
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const {
      processing: submitProcessing,
      submit,
      setFormData,
      validationErrors,
    } = createOrUpdate({
      createApiMethod: async (params) => {
        return await liaisonService.committee.store(params);
      },
      successCallback(result: GetLiaisonResult) {
        newLiaisonId.value = null;
        if (result.kind === "ok") {
          list.data.splice(0, 0, result.data);
          list.meta.total++;
        }
      },
      params: {
        committeeId: "",
        liaisonId: "",
      },
    });

    const liaisonsIds = computed(() => list.data.map((item) => item.id));
    const newLiaisonId = ref(null);

    const liaisonSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@where": {
              level: [1, 2],
              type: ["TC", "SC"],
            },
            "@whereNot": {
              id: liaisonsIds.value,
              level_1_number: props.levelTcNumber,
            },
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
          },
        },
        templateSelection(obj: any): any {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    const onAddNewLiaison = (): void => {
      if (newLiaisonId.value) {
        setFormData({
          committeeId: props.committeeId,
          liaisonId: newLiaisonId.value,
        });
        submit();
      }
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      liaisonSelect2Options,
      newLiaisonId,
      submitProcessing,
      validationErrors,
      changePage,
      changePerPage,
      onAddNewLiaison,
    };
  },
});
</script>
