import { GeneralApiResponse, GetDocumentResult, useAxios } from "@/services";

export const folderService = {
  store: async (params: Record<string, any>): Promise<GetDocumentResult> =>
    useAxios().store(`/api/folders`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetDocumentResult> =>
    useAxios().update(`/api/folders/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/folders/${id}`),
};
