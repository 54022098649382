
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import VueButton from "@/global-components/form-elements/button/Main.vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueTextarea from "@/global-components/form-elements/textarea/Main.vue";
import VueSelect from "@/global-components/form-elements/select/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import { useRoute, useRouter } from "vue-router";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { userService, PostUser } from "@/services";

export default defineComponent({
  name: "CreateUser",
  components: { VueInput, VueSelect, VueTextarea, VueInputGroup, VueButton },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT USER (#${id.value})`;
      return "CREATE USER";
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });
    const memberSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          member: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "title",
              acronym: "acronym",
            },
          },
        },
        templateResult(obj: any): any {
          if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
          return obj.text;
        },
      };
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: userService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        const canEdit = _.get(data.value, "abilities.can_update", false);
        if (canEdit) {
          setFormData(data.value);
        } else {
          router.go(-1);
        }
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: userService.store,
      updateApiMethod: userService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        salutation: "",
        name: "",
        surname: "",
        email: "",
        notification_email: "",
        title: "",
        phone: "",
        city: "",
        country_code: "",
        address: "",
        password: "",
        administrated_by_id: null,
      } as PostUser,
      removeKeysIfEmptyValue: ["password", "administrated_by_id"],
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      id,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      countrySelect2Options,
      memberSelect2Options,
      detail: data,
      onSubmit,
    };
  },
});
