<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconUser
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">COMMITTEES LIST</div>
      <div class="text-white text-sm font-light">
        Database for SMIIC Organs and Committees
      </div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Title</label>
            </template>
            <VueInput
              v-model="filter.query.title"
              placeholder="Enter committee title"
              @keydown.enter="applyFilter"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Acronym</label>
            </template>
            <VueInput
              v-model="filter.query.acronym"
              placeholder="Enter committee title"
              @keydown.enter="applyFilter"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Type</label>
            </template>
            <VueSelect v-model="filter.query.type">
              <option value="">All</option>
              <option
                v-for="(row, key) in $h.getIdToText('committeeTypes')"
                :key="key"
                :value="key"
              >
                {{ row }}
              </option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg"
                >SMIIC Technical Assistant</label
              >
            </template>
            <Select2Input
              :options="userSelect2Options"
              v-model="filter.query.assistant_user_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Secretary</label>
            </template>
            <Select2Input
              :options="userSelect2Options"
              v-model="filter.query.secretary_user_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Chair Person</label>
            </template>
            <Select2Input
              :options="userSelect2Options"
              v-model="filter.query.chairperson_user_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex flex-col-reverse sm:flex-row items-end sm:items-center">
        <div class="w-full flex sm:mr-3 mt-3 sm:mt-0">
          <input
            v-model="filter.query.title"
            placeholder="Quick Search In Title"
            class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
            @keydown.enter="applyFilter"
          />
          <button
            class="button bg-gray-100 border rounded-l-none shadow-lg"
            @click="applyFilter"
          >
            <SearchIcon class="inline w-6 h-6" />
          </button>
        </div>
        <router-link
          v-if="$store.getters['auth/canCreateCommittee']"
          :to="{ name: 'committees.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        >
          Create Committee
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-3">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Acronym</th>
                <th class="whitespace-nowrap">Title</th>
                <th class="whitespace-nowrap">Type</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.id"
              >
                <td class="whitespace-nowrap w-1">
                  <div
                    class="inline-block bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md"
                  >
                    {{ row.acronym }}
                  </div>
                </td>
                <td>{{ row.title }}</td>
                <td>{{ $h.convertIdToText(row.type, "committeeTypes") }}</td>
                <td class="text-right">
                  <div class="inline-flex">
                    <Tippy
                      tag="button"
                      content="See Details"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                      @click="() => goToView(row.id)"
                    >
                      <EyeIcon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      tag="button"
                      content="Edit"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg ml-2"
                      @click="() => goToEdit(row.id)"
                      v-if="$_.get(row, 'abilities.can_update', false)"
                    >
                      <Edit2Icon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      tag="button"
                      content="Delete"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg ml-2"
                      @click="() => triggerDelete([row.id])"
                      v-if="$_.get(row, 'abilities.can_delete', false)"
                    >
                      <Trash2Icon class="inline w-5 h-5" />
                    </Tippy>
                  </div>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :per-page="filter.query.paginate * 1"
          :current="filter.query.page * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { committeeService } from "@/services";
import { Select2Options } from "@/global-components/select2";
import { helper } from "@/utils/helper";

export default defineComponent({
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "committee",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "type",
            "title",
            "acronym",
            "assistant_user_id",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
        filterFormat: {
          paginate: "@paginate",
          title: "@where.q",
          acronym: "@where._acronym",
          type: "@where.type",
          secretariat_member_id: "@where.secretariat_member_id",
          secretary_user_id: "@where.secretary_user_id",
          assistant_user_id: "@where.assistant_user_id",
          chairperson_user_id: "@where.chairperson_user_id",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      acronym: "",
      type: "",
      secretariat_member_id: "",
      secretary_user_id: "",
      assistant_user_id: "",
      chairperson_user_id: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: committeeService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const userTemplateFormat = (obj: any): string => {
      if (obj.name) return helper.formatNameSurname(obj);
      return obj.text;
    };

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            salutation: "salutation",
            title: "title",
          },
        },
      },
      templateSelection: userTemplateFormat,
    });

    const goToEdit = (value: any) => {
      router.push({ name: "committees.edit", params: { id: value } });
    };

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      userSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
    };
  },
});
</script>
