<template>
  <select v-select="{ props, emit }" class="select">
    <slot></slot>
  </select>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { init, destroy } from "./index";

export default defineComponent({
  name: "Select2Input",
  directives: {
    select: {
      mounted(el, { value }) {
        init(el, value.props, value.emit);
      },
      unmounted(el) {
        destroy(el);
      },
    },
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      default: () => "",
    },
  },
  setup(props, context) {
    return {
      props,
      ...context,
    };
  },
});
</script>
