
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueInputGroup",
  props: {
    validationErrors: {
      type: Array,
      default: () => null,
    },
    labelClass: {
      type: String,
      default: () => "",
    },
  },
});
