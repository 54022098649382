
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { PostBallot } from "@/services";
import { ValidationErrors } from "@/utils/form";
import { Select2Options } from "@/global-components/select2";
import Litepicker from "@/global-components/litepicker/Main.vue";
import { useStore } from "@/store";
import dayjs from "dayjs";
import VueTextarea from "@/global-components/form-elements/textarea/Main.vue";

export default defineComponent({
  name: "BallotEditInformation",
  components: { VueTextarea, Litepicker },
  props: {
    ballotId: { default: () => null },
    ballotType: { type: String, default: () => "" },
    formData: { type: Object as () => PostBallot, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const editableFormData = reactive(toRefs(props.formData));

    const state = reactive({
      selectedCommittee: null,
    });
    const onChangeCommittee = (committee: any) => {
      state.selectedCommittee = committee;
    };
    const committeeId = computed(() => props.formData.committee_id);
    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
          },
          "@where": {
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              editableFormData.ballot_type === "SMIIC"
                ? "can_create_smiic_ballot"
                : "can_create_committee_ballot"
            ),
          },
          "@whereNot": {
            type: "SMIIC",
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });
    const projectStagesCodes = computed(() => {
      if (props.ballotType === "PWI") {
        // return ["00.00", "00.60", "00.92", "00.93"];
        return ["00.00"];
      }
      if (props.ballotType === "NP") {
        return ["10.00"];
      }
      if (props.ballotType === "WD") {
        return ["20.00"];
      }
      if (props.ballotType === "CD") {
        return ["30.00"];
      }
      if (props.ballotType === "DS") {
        return ["40.00"];
      }
      if (props.ballotType === "FDS") {
        return ["50.00"];
      }
      return [];
    });
    const projectSelect2Options = computed<Select2Options>(() => ({
      ajaxQuery: {
        project: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "reference",
            title: "title_en",
          },
          "@where": {
            committee_id: committeeId.value,
            stage: projectStagesCodes.value,
          },
        },
      },
    }));

    watch(
      () => editableFormData.start_date,
      () => setEndDate()
    );

    const setEndDate = () => {
      let addMount = 0;
      if (["NP", "DS"].indexOf(props.ballotType) > -1) addMount = 3;
      if (props.ballotType === "FDS") addMount = 2;
      if (addMount > 0) {
        editableFormData.end_date = dayjs(editableFormData.start_date)
          .add(dayjs.duration({ months: addMount }))
          .format("YYYY-MM-DD");
      }
    };

    const selectedProject = (project: any) => {
      if (project) {
        editableFormData.english_title = project.title || "";
        editableFormData.reference = project.text || "";
      }
    };

    onMounted(() => {
      if (props.ballotType !== "ADHOC") {
        const committeeAjaxQuery = _.cloneDeep(
          committeeSelect2Options.value.ajaxQuery
        );
        _.set(committeeAjaxQuery, "committee.@where.level", [1, 2]);
        _.set(committeeAjaxQuery, "committee.@whereNot.type", ["WG"]);
        committeeSelect2Options.value.ajaxQuery = committeeAjaxQuery;
      }
      setEndDate();
    });

    return {
      editableFormData,
      committeeSelect2Options,
      projectSelect2Options,
      onChangeCommittee,
      selectedProject,
    };
  },
});
