
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { liaisonService, GetCommittee } from "@/services";
import _ from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "OrganizationLiaisons",
  components: { Pagination, TransactionStatement },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await liaisonService.organization.fetch(
          props.committee.id,
          query
        );
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) =>
        await liaisonService.organization.delete(props.committeeId, id),
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "organizations.view", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      changePage,
      changePerPage,
      goToView,
    };
  },
});
