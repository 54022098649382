<template>
  <div class="divide-y">
    <div class="accordion__pane py-6">
      <!-- BEGIN ABOUT -->
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >
        {{ participantMemberTitle }}
        <template v-if="!loading">({{ pMembers.length }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
        <CommitteeMembers
          :committee-id="committeeId"
          :committee-members="list.data"
          :committee-type="committeeType"
          type="P"
          @update:members="changeMemberList"
        />
      </div>
    </div>
    <div class="accordion__pane py-6" v-if="!isWg">
      <!-- BEGIN ABOUT -->
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >
        Observer Members
        <template v-if="!loading">({{ oMembers.length }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
        <CommitteeMembers
          :committee-id="committeeId"
          :committee-members="list.data"
          :committee-type="committeeType"
          type="O"
          @update:members="changeMemberList"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { committeeMemberService, GetMember, PaginationMeta } from "@/services";
import CommitteeMembers from "@/views/committees/create/Members.vue";
import { GeneralFetchRecords } from "@/utils/actions/fetch-records";

interface FetchRecords extends GeneralFetchRecords {
  list: { data: GetMember[]; meta: PaginationMeta };
}

export default defineComponent({
  name: "CommitteeMemberContainer",
  props: {
    committeeId: {
      required: true,
      default: () => null,
    },
    committeeType: {
      required: true,
      type: String,
      default: () => "TC",
    },
  },
  components: { CommitteeMembers },
  setup(props) {
    const pMembers = ref<GetMember[]>([]);
    const oMembers = ref<GetMember[]>([]);

    const isWg = computed(() => {
      return props.committeeType === "WG";
    });

    const participantMemberTitle = computed(() => {
      if (isWg.value) return "Working Group Members";
      return "Participant Members";
    });

    const { fetchRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await committeeMemberService.fetch(props.committeeId, query);
      },
      successCallback() {
        changeMemberList(list.data);
      },
    }) as FetchRecords;

    const changeMemberList = (val: GetMember[]) => {
      list.data = val;
      pMembers.value = list.data.filter(
        (item) => item.committee_member_type === "P"
      );
      oMembers.value = list.data.filter(
        (item) => item.committee_member_type === "O"
      );
    };

    onMounted(() => {
      fetch({});
    });

    return {
      isWg,
      participantMemberTitle,
      loading,
      list,
      pMembers,
      oMembers,
      changeMemberList,
    };
  },
});
</script>
