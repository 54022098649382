<template>
  <component :is="tag" class="relative">
    <slot></slot>
    <template v-if="processing">
      <div
        class="fixed left-0 top-0 w-full h-full z-10 bg-gray-50 bg-opacity-5"
      ></div>
      <div
        class="absolute left-0 top-0 w-full h-full z-20 bg-gray-100 bg-opacity-95 rounded-lg flex items-center px-6 text-theme-5"
      >
        <LoaderIcon
          class="inline-block w-8 h-8"
          :class="{ 'spin-5': processing }"
        />
        <span class="ml-3" v-if="waiting">Waiting</span>
        <span class="ml-3" v-else>Processing, please wait</span>
      </div>
    </template>
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TransactionStatement",
  props: {
    tag: { required: true },
    processing: { type: Boolean, default: () => false },
    waiting: { type: Boolean, default: () => false },
  },
  setup() {
    return {};
  },
});
</script>
