
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { helper } from "@/utils/helper";
import { useStore } from "@/store";
import { RecycleScroller } from "vue3-virtual-scroller";
import { useGeneralActions } from "@/utils/actions";
import { folderService } from "@/services";
import CreateOrUpdateFolderModal from "../CreateOrUpdateModal.vue";

export default defineComponent({
  name: "DocumentList",
  components: { CreateOrUpdateFolderModal, RecycleScroller },
  props: {
    folderId: {
      default: () => null,
    },
    changeActiveList: {
      type: Function,
      default: () => {
        console.log("changeActiveList");
      },
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const wrapperRef = ref<HTMLElement>();
    const headerRef = ref<HTMLElement>();
    const contentHeight = ref<number>(0);
    const refCreateOrUpdateModal = ref();

    const documents = computed(() =>
      store.state.document.documents.map((document) => {
        return {
          uuid: document.uuid || helper.uuidv4(),
          ...document,
        };
      })
    );

    const { deleteRecords } = useGeneralActions();

    const { triggerDelete } = deleteRecords({
      deleteApiMethod: folderService.delete,
      successCallback(id) {
        if (id) {
          const list = documents.value.filter(
            (document: any) => document.id !== id
          );
          store.dispatch("document/SET_FORCE_DOCUMENTS", list);
        }
      },
    });

    const onDeleteFolder = (id: any) => triggerDelete([id]);

    const calculateHeights = () => {
      const wrapperHeight = wrapperRef.value?.clientHeight || 0;
      const headerHeight = headerRef.value?.offsetHeight || 0;
      contentHeight.value = wrapperHeight - headerHeight;
    };

    const openItem = (item: any) => {
      if (item.type * 1 < 100) {
        router.push({
          name: "documents",
          params: {},
          query: { folderId: item.id },
        });
      } else {
        router.push({
          name: "documents",
          params: { uuid: item.uuid },
        });
      }
    };

    const onEditFolder = (folder: any) => {
      refCreateOrUpdateModal?.value.openEditModal(folder);
    };

    onMounted(() => {
      window.addEventListener("resize", calculateHeights);
      setTimeout(() => {
        calculateHeights();
      }, 100);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", calculateHeights);
    });

    return {
      documents,
      wrapperRef,
      headerRef,
      contentHeight,
      refCreateOrUpdateModal,
      openItem,
      onEditFolder,
      onDeleteFolder,
    };
  },
});
