<template>
  <div>
    <div id="preview-email-modal" class="modal p-0">
      <div class="modal__content modal__content--xl h-full !mb-0 flex flex-col">
        <div class="p-1 text-right">
          <button class="button py-1" data-dismiss="modal">
            <XIcon class="w-9 h-9 inline" />
          </button>
        </div>
        <iframe
          class="w-full flex-1"
          v-if="state.previewUrl"
          :src="state.previewUrl"
        ></iframe>
      </div>
    </div>
    <div id="create-email-modal" class="modal modal--not__closed">
      <div
        class="modal__content modal__content--xl p-4 sm:p-10 pt-4 max-h-full overflow-y-auto"
      >
        <div class="mb-4 text-right">
          <button class="button py-1" data-dismiss="modal">
            <XIcon class="w-9 h-9 inline" />
          </button>
        </div>
        <VueInputGroup
          :validation-errors="validationErrors.get('users_full_list')"
        >
          <template v-slot:label>
            <label class="block font-semibold mb-2">Recipients</label>
          </template>
          <VueSelect
            class="shadow-none !border"
            v-model="formData.users_full_list"
            :validation-errors="validationErrors.get('users_full_list')"
          >
            <option :value="false">Committee Users List</option>
            <option :value="true">Committee Users List + Voters</option>
          </VueSelect>
        </VueInputGroup>
        <VueInputGroup
          class="mt-4"
          :validation-errors="validationErrors.get('subject')"
        >
          <template v-slot:label>
            <label class="block font-semibold mb-2">Subject</label>
          </template>
          <VueInput
            class="shadow-none !border"
            v-model="formData.subject"
            :validation-errors="validationErrors.get('subject')"
          />
        </VueInputGroup>
        <VueInputGroup
          class="mt-4"
          :validation-errors="validationErrors.get('body')"
        >
          <template v-slot:label>
            <label class="block font-semibold mb-2">Body</label>
          </template>
          <VueTiptap
            class="shadow-none"
            v-model="formData.body"
            :validation-errors="validationErrors.get('body')"
          />
        </VueInputGroup>
        <VueInputGroup
          class="mt-4"
          :validation-errors="validationErrors.get('files')"
        >
          <template v-slot:label>
            <label class="block font-semibold mb-2">Select Document</label>
          </template>
          <Select2Input
            class="shadow-none"
            :options="documentSelect2Options"
            v-model="state.documentValue"
            :validation-errors="validationErrors.get('files')"
            @update:selected-item="onAddSubmissionFile"
          />
        </VueInputGroup>
        <div class="overflow-x-auto mt-4" v-if="formData.files.length > 0">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Document</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, index) in formData.files"
                :key="'submission-document-' + row.uuid"
              >
                <td>
                  <span class="font-semibold" v-if="$_.get(row, 'n_number', '')"
                    >N{{ $_.get(row, "n_number", "") }}
                  </span>
                  {{ $_.get(row, "title", "") }}
                </td>
                <td class="text-right">
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => formData.files.splice(index, 1)"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mt-8 flex justify-between">
          <VueButton
            :loading="processing"
            class="button w-1/2 sm:w-1/3 bg-indigo-500 text-white"
            @click="onPreview"
          >
            <EyeIcon class="w-6 h-6 inline mr-1" /> Preview
          </VueButton>
          <VueButton
            :loading="processing"
            class="button w-1/2 sm:w-1/3 ml-1 bg-blue-600 text-white"
            @click="onSubmit"
          >
            <SendIcon class="w-6 h-6 inline mr-1" /> Send
          </VueButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  Ref,
} from "vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import VueTiptap from "@/components/tiptap/Main.vue";
import createOrUpdate, {
  GeneralCreateOrUpdate,
} from "@/utils/actions/create-or-update";
import { PostEmail, emailService } from "@/services";
import Select2Input from "@/global-components/select2/Main.vue";
import VueButton from "@/global-components/form-elements/button/Main.vue";
import { SendEmailOptions } from "@/components/email-to-members/index";
import { ConfirmRef } from "@/components/confirm";
import { useStore } from "@/store";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostEmail;
}

export default defineComponent({
  name: "EmailToMembers",
  components: { VueButton, Select2Input, VueTiptap, VueInput, VueInputGroup },
  setup() {
    const store = useStore();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const state = reactive<Record<string, any>>({
      committee: null,
      documentValue: null,
      previewUrl: null,
      success: () => null,
    });

    const nameSurname = computed(() => {
      return `${_.get(store.state.auth.profile, "name", "")} ${_.get(
        store.state.auth.profile,
        "surname",
        ""
      )}`;
    });

    const {
      processing,
      formData,
      validationErrors,
      submit,
      setFormData,
    } = createOrUpdate({
      createApiMethod: (params) =>
        emailService.store(_.get(state.committee, "id"), params),
      successCallback(result: any) {
        if (result.kind === "ok") {
          if (state.success) state.success();
          hide();
        }
      },
      params: {
        users_full_list: false,
        subject: "",
        body: "",
        files: [],
      },
      successMessage: "Mail successfully sent",
    }) as CreateOrUpdate;

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            uuid: "uuid",
            n_number: "n_number",
            version: "version",
          },
          "@order": ["created_at:desc"],
          "@where": {
            committee_id: _.get(state.committee, "id"),
          },
          "@whereNot": {
            uuid: formData.files.map((file) => file.uuid),
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const onAddSubmissionFile = (document: Record<string, any>) => {
      if (document) {
        formData.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.text,
          n_number: document.n_number,
          file_name: document.file_name,
          file_size: document.file_size,
        });
        setTimeout(() => (state.documentValue = ""), 100);
      }
    };

    const onSubmit = () => {
      confirmRef?.value?.show({
        title: `Do you want to send email all to committee members?`,
        icon: "SendIcon",
        iconClass: "text-indigo-600",
        confirmText: "Send",
        cancelText: "Cancel",
        callback: () => submit(),
      });
    };

    const onPreview = () => {
      processing.value = true;
      emailService
        .preview(_.get(state.committee, "id"), formData)
        .then((result) => {
          if (result.kind === "ok") {
            state.previewUrl = URL.createObjectURL(result.data);
            cash("#preview-email-modal").modal("show");
          } else if (result.kind === "validation") {
            validationErrors.value.record(result.fields);
          }
        })
        .finally(() => {
          processing.value = false;
        });
    };

    const clearPreviewUrl = () => {
      if (state.previewUrl) {
        URL.revokeObjectURL(state.previewUrl);
        state.previewUrl = null;
      }
    };

    const clearFormData = () => {
      setFormData({ subject: "", body: "", files: [] });
      clearPreviewUrl();
    };

    const show = (options: SendEmailOptions) => {
      state.committee = options.committee;
      formData.users_full_list = options?.users_full_list || false;
      if (typeof options.success === "function")
        state.success = options.success;
      if (options.files) formData.files = options.files;
      formData.subject = `${_.get(state.committee, "acronym", "")} ${_.get(
        state.committee,
        "title",
        ""
      )} - `;

      formData.body =
        `<p>Dear committee member,<br>Please find below new document(s) that have been uploaded on SMIIC IS</p>` +
        `<p></p>` +
        `<p>Kind regards,<br>${nameSurname.value}</p>`;
      cash("#create-email-modal").modal("show");
    };

    const hide = () => {
      cash("#create-email-modal").modal("hide");
    };

    onMounted(() => {
      cash("#create-email-modal").modal("on.hide", () => clearFormData());
      cash("#preview-email-modal").modal("on.hide", () => clearPreviewUrl());
    });

    return {
      state,
      processing,
      formData,
      validationErrors,
      documentSelect2Options,
      onAddSubmissionFile,
      onSubmit,
      onPreview,
      show,
      hide,
    };
  },
});
</script>
