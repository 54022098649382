import { GetSelectionResult, getGeneralApiProblem, useAxios } from "@/services";
import axios, { CancelTokenSource, CancelTokenStatic } from "axios";

export * from "./api.types";

class MergeSelectionRequest {
  counter: number;
  axiosToken: CancelTokenStatic;
  axiosSource: CancelTokenSource | null;
  path: string;
  requests: Record<string, any>;
  callbacks: { keys: any[]; callback: (value: any) => void }[];
  constructor() {
    this.counter = 0;
    this.axiosToken = axios.CancelToken;
    this.axiosSource = null;
    this.path = "";
    this.requests = {};
    this.callbacks = [];
  }

  setRequest(
    request: Record<string, any>,
    callback: (value: any) => void
  ): void {
    const callbackKeys: any[] = [];
    _.forEach(request, (data, key) => {
      let newParamKey = _.cloneDeep(key);
      let counter = 0;
      Object.keys(this.requests).map((requestKey) => {
        if (this.getBasicKey(requestKey) === key) counter += 1;
      });
      if (counter > 0) newParamKey += "#" + counter;
      this.requests[newParamKey] = {
        "@source": key,
        ..._.cloneDeep(data),
      };
      callbackKeys.push(newParamKey);
    });
    this.callbacks.push({
      keys: callbackKeys,
      callback,
    });
  }

  setSource(source: CancelTokenSource): void {
    if (this.axiosSource) this.axiosSource.cancel();
    this.axiosSource = source;
  }

  complete(result: Record<string, any>): void {
    this.callbacks.map((item) => {
      const callbackResponse: Record<string, any> = {};
      item.keys.map((itemKey) => {
        callbackResponse[this.getBasicKey(itemKey)] = _.get(
          result,
          itemKey,
          null
        );
      });
      if (typeof item.callback === "function") {
        item.callback({
          kind: "ok",
          data: callbackResponse,
        });
      }
    });

    this.reset();
  }

  reset(): void {
    this.counter = 0;
    if (this.axiosSource) this.axiosSource.cancel();
    this.axiosSource = null;
    this.path = "";
    this.requests = {};
    this.callbacks = [];
  }

  getBasicKey(key: string): string {
    const index = key.toString().indexOf("#");
    if (index > -1) key = key.toString().substring(0, index);
    return key;
  }
}

const instanceMergeSelection = new MergeSelectionRequest();

export const fetchSelection = async (
  params: Record<string, any>,
  withoutQueue = false
): Promise<GetSelectionResult> => {
  if (withoutQueue) {
    return await useAxios({ responseField: "" }).store(
      `/api/selection`,
      params
    );
  }

  instanceMergeSelection.counter++;
  return new Promise((resolve) => {
    const path = window.location.href;
    if (!instanceMergeSelection.path) instanceMergeSelection.path = path;

    if (instanceMergeSelection.path !== path) instanceMergeSelection.reset();
    instanceMergeSelection.setRequest(params, resolve);
    instanceMergeSelection.setSource(
      instanceMergeSelection.axiosToken.source()
    );

    useAxios()
      .instance.post(`/api/selection`, instanceMergeSelection.requests, {
        cancelToken: instanceMergeSelection.axiosSource?.token,
      })
      .then((result) => {
        instanceMergeSelection.complete(result.data);
      })
      .catch(async (error) => {
        if (!axios.isCancel(error)) {
          const errorKind = getGeneralApiProblem(error);
          instanceMergeSelection.callbacks.map((item) => {
            if (typeof item.callback === "function") {
              item.callback(errorKind);
            }
          });
          instanceMergeSelection.reset();
        }
      });
  });
};
