<template>
  <svg
    width="128"
    height="128"
    viewBox="0 0 128 128"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M113.333 0H14.6667C6.576 0 0 6.576 0 14.6667V113.333C0 121.424 6.576 128 14.6667 128H113.333C121.424 128 128 121.424 128 113.333V14.6667C128 6.576 121.424 0 113.333 0ZM111.643 65.664C103.221 84.0907 84.5227 96 64 96C43.4773 96 24.7787 84.0907 16.3573 65.664C15.8773 64.608 15.8773 63.3973 16.3573 62.3413C24.7787 43.9093 43.4773 32 64 32C84.5227 32 103.221 43.9093 111.643 62.336C112.117 63.392 112.117 64.608 111.643 65.664Z"
        fill="currentColor"
      />
    </g>
    <g>
      <path
        d="M64 80C72.8366 80 80 72.8366 80 64C80 55.1634 72.8366 48 64 48C55.1634 48 48 55.1634 48 64C48 72.8366 55.1634 80 64 80Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
