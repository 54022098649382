import {
  GeneralApiResponse,
  GetLiaisonResult,
  GetLiaisonResults,
  useAxios,
} from "@/services";

export const committeeMemberService = {
  fetch: async (
    committeeId: unknown,
    params: Record<string, any>
  ): Promise<GetLiaisonResults> =>
    useAxios().fetch(`/api/committees/${committeeId}/members`, params),

  store: async (params: Record<string, any>): Promise<GetLiaisonResult> =>
    useAxios().store(
      `/api/committees/${params.committeeId}/members/${params.memberId}`,
      params
    ),

  delete: async (
    committeeId: unknown,
    memberId: unknown
  ): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/committees/${committeeId}/members/${memberId}`),
};
