import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { BallotQuestions } from "@/services";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_BALLOT_QUESTIONS](
    state: S,
    questions: BallotQuestions[]
  ): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_BALLOT_QUESTIONS](
    state: LocalState,
    questions: BallotQuestions[]
  ) {
    state.questions = questions;
  },
};
