
import { defineComponent } from "vue";
import { GetMeeting } from "@/services";

export default defineComponent({
  name: "Information",
  props: {
    detail: {
      type: Object as () => GetMeeting,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
  },
});
