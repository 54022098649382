
import { computed, defineComponent, onMounted, watch } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { authService, userService } from "@/services";
import _ from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { Select2Options } from "@/global-components/select2";
import VueSelect from "@/global-components/form-elements/select/Main.vue";
import { useStore } from "@/store";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "Users",
  components: {
    VueSelect,
    TransactionStatement,
    Pagination,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const selectionQuery = computed(() => {
      const config = {
        "@page": 1,
        "@paginate": 15,
        "@select": [
          "id",
          "surname",
          "name",
          "email",
          "administrated_by_id",
          "status",
        ],
        "@where": {},
        "@func": ["withCount"],
        "@order": "created_at:desc",
        administratedBy: {
          "@select": ["id", "acronym", "title"],
        },
      };

      if (filter && filter.query.role) {
        let hasRole = `whereHasRole:${filter.query.role}`;
        if (filter.query.role_id) hasRole += `,${filter.query.role_id}`;
        config["@func"].push(hasRole);
      }

      return config;
    });

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "user",
        config: selectionQuery,
        filterFormat: {
          q: "@where.q",
          paginate: "@paginate",
          name: "@where._name",
          surname: "@where._surname",
          email: "@where._email",
          administrated_by_id: "@where.administrated_by_id",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      q: "",
      name: "",
      surname: "",
      email: "",
      administrated_by_id: "",
      role: "",
      role_id: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: userService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const roleTemplateSelection = (obj: any): any => {
      if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
      return obj.text;
    };
    const organizationSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          organization: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "title",
              acronym: "acronym",
            },
          },
        },
        templateSelection: roleTemplateSelection,
      };
    });

    const roleSelect2Options = computed<Select2Options>(() => {
      const source =
        selectedRoleType.value && selectedRoleType.value?.scope === "committee"
          ? "committee"
          : "organization";
      const where = {};
      if (source === "organization")
        _.set(where, "id", filter.query.administrated_by_id);
      return {
        ajaxQuery: {
          [source]: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "title",
              acronym: "acronym",
            },
            "@where": where,
          },
        },
        templateSelection: roleTemplateSelection,
      };
    });

    const selectedRoleType = computed(() =>
      store.getters["user/roleType"](filter.query.role)
    );

    watch(
      () => selectedRoleType.value?.type,
      () => {
        filter.query.role_id = "";
      }
    );

    const goToView = (value: any) => {
      router.push({ name: "users.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "users.edit", params: { id: value } });
    };

    const loginAs = (value: any) => {
      authService
        .loginAs(value)
        .then((result) => {
          if (result.kind === "ok") {
            store.dispatch("auth/SET_TOKEN", result.meta.token || "");
            store.dispatch("auth/SET_PROFILE", result.data);
            router.push({ name: "welcome" });
          } else {
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      organizationSelect2Options,
      roleSelect2Options,
      selectedRoleType,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
      loginAs,
    };
  },
});
