// Create initial state
const loadProfile = () => {
  const data = window.localStorage.getItem("auth_info") || "";
  try {
    // eslint-disable-next-line node/no-deprecated-api
    return JSON.parse(new Buffer(data, "base64").toString("utf-8"));
  } catch (e) {
    return data;
  }
};

export const state = {
  token: window.localStorage.getItem("auth_token") || "",
  profile: loadProfile(),
  visibleSplashScreen: true,
};

// Declare state
export type State = typeof state;
