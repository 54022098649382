<template>
  <div class="relative w-full h-full">
    <div v-if="loading" class="text-center p-4 pt-8">
      <LoaderIcon class="spin-5 inline-block w-8 h-8" />
      <div class="mt-2 font-light">
        Please wait while the records are loading.
      </div>
    </div>
    <div
      class="font-light text-center text-xl text-gray-500 h-full flex flex-col items-center justify-center px-8"
      v-else-if="!path"
    >
      <LinkIcon class="w-16 h-16 mx-auto text-gray-400 mb-4" />
      <div>Link is broken!</div>
    </div>
    <iframe v-else :src="`${path}`" class="absolute left-0 top-0 w-full h-full">
      <p>This browser does not support PDF!</p>
    </iframe>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { DOCUMENT_TYPES, GetDocument } from "@/services";
import { StandardDocumentPDFCreation } from "@/utils/pdf-creation/standard-document";
import { useStore } from "@/store";

export default defineComponent({
  name: "PdfView",
  props: {
    document: {
      type: Object as () => GetDocument,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const path = ref<string>("");
    const loading = ref<boolean>(false);

    const prepareStandardDocument = () => {
      loading.value = true
      new StandardDocumentPDFCreation()
        .process({
          user: store.state.auth.profile,
          urls: [props.document.file_path],
        })
        .then((pdfCreation) => {
          pdfCreation.output().then((url) => {
            path.value = url;
          }).finally(() => {
            loading.value = false
          })
        });
    };

    onMounted(() => {
      if (props.document.type === DOCUMENT_TYPES.STANDARD_DOCUMENT) {
        prepareStandardDocument();
      } else {
        path.value = props.document.file_path;
      }
    });

    return {
      path,
      loading
    };
  },
});
</script>
