
import { computed, defineComponent, ref, watch } from "vue";
import { Select2Options } from "@/global-components/select2";
import {
  committeeMemberService,
  GetCommitteeResult,
  GetMember,
} from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import _ from "lodash";

export default defineComponent({
  name: "CommitteeMembers",
  components: { TransactionStatement },
  emits: ["update:members"],
  props: {
    type: { default: () => "P" },
    committeeId: {
      required: true,
      default: () => null,
    },
    committeeType: {
      required: true,
      type: String,
      default: () => "TC",
    },
    committeeMembers: {
      type: Array as () => GetMember[],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { createOrUpdate, deleteRecords } = useGeneralActions();

    const setCommitteeMembers = (members: GetMember[]) => {
      emit("update:members", members);
    };

    watch(
      () => props.committeeMembers,
      (val) => setCommitteeMembers(val || []),
      { deep: true, flush: "post" }
    );
    const committeeMembersIds = computed(() =>
      props.committeeMembers.map((item) => item.id)
    );
    const members = computed<GetMember[]>(() => {
      return props.committeeMembers.filter((item) => {
        return item?.committee_member_type === props.type;
      });
    });

    // const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const newMemberId = ref(null);
    const memberSelect2Options = computed<Select2Options>(() => {
      const where = {};
      const func = [];
      switch (props.committeeType) {
        case "AC":
          _.set(where, "is_accreditation_body", 1);
          break;
        case "MC":
          _.set(where, "is_metrology_body", 1);
          break;
        case "SMIIC":
          func.push("getBodies");
          break;
        default:
          _.set(where, "is_standardization_body", 1);
          break;
      }
      return {
        ajaxQuery: {
          organization: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@where": {
              ...where,
            },
            "@whereNot": {
              id: committeeMembersIds.value,
            },
            "@order": "created_at:desc",
            "@func": func,
          },
        },
        templateResult(obj: any): any {
          if (obj.id) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    const {
      processing: submitProcessing,
      submit,
      setFormData,
      validationErrors,
    } = createOrUpdate({
      createApiMethod: committeeMemberService.store,
      successCallback(result: GetCommitteeResult) {
        newMemberId.value = null;
        if (result.kind === "ok" && result.data.members) {
          setCommitteeMembers(result.data.members);
        }
      },
      params: {
        committeeId: props.committeeId,
        memberId: "",
        member_type: props.type,
      },
    });

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) =>
        await committeeMemberService.delete(props.committeeId, id),
      successCallback(id) {
        if (id) {
          setCommitteeMembers(
            props.committeeMembers.filter((item) => item.id !== id)
          );
        }
      },
    });

    const onAddNewMember = (): void => {
      setFormData({
        committeeId: props.committeeId,
        memberId: newMemberId.value,
      });
      submit();
    };

    return {
      submitProcessing,
      deleteProcessing,
      members,
      newMemberId,
      memberSelect2Options,
      validationErrors,
      onAddNewMember,
      transactionIds,
      triggerDelete,
    };
  },
});
