<template>
  <div class="my-2 p-3 rounded bg-gray-100">
    <VueInputGroup
      :validation-errors="
        validationErrors?.get(
          `${path.join('.')}`,
          `${[...path, 'question'].join('.')}`,
          `${[...path, 'answers'].join('.')}`
        ) || []
      "
    >
      <span class="font-semibold mr-1.5">{{ label }}</span>
      {{ question.question || "-" }}
    </VueInputGroup>
    <AnswerViewItem
      v-for="(answer, answerIndex) in $_.get(question, 'answers', [])"
      :key="answer.uuid"
      :answer="answer"
      :path="[...path, 'answers', answerIndex]"
      :validation-errors="validationErrors"
    >
    </AnswerViewItem>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "QuestionViewItem",
  props: {
    question: { type: Object, required: true },
    path: { type: Array, required: true },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const label = computed(() => {
      const sq: any[] = [];
      props.path.map((val: any, index) => {
        if (_.get(props.path, index - 1, "") === "questions")
          sq.push(val * 1 + 1);
      });
      return `Q.${sq.join(".")})`;
    });

    return {
      label,
    };
  },
});
</script>
