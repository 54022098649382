<template>
  <div>
    <pre id="preOldValue" class="hidden"
      >{{ $_.get(valueCompare, "json.old", []) }}
        </pre
    >
    <pre id="preNewValue" class="hidden"
      >{{ $_.get(valueCompare, "json.new", []) }}
        </pre
    >
    <!-- BEGIN: Detail Modal -->
    <div id="audit-detail-modal" class="modal">
      <div class="modal__content modal__content--xl p-5">
        <div class="text-3xl text-theme-1">Audit Detail</div>
        <div class="border-t h-0.5 mt-3 mb-3"></div>
        <VueDiff
          :prev="$_.get(valueCompare, 'string.old', '')"
          :current="$_.get(valueCompare, 'string.new', '')"
        />
      </div>
    </div>
    <!-- END: Detail Modal -->
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 pt-8 pb-4 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <ListIcon
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">AUDITS LIST</div>
      <div class="text-white text-sm font-light">
        Monitoring Users’ activities and performance
      </div>
    </div>
    <div class="p-3 sm:px-5">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">User</th>
                <th class="whitespace-nowrap">User Agent</th>
                <th class="whitespace-nowrap"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in list.data" :key="'record-' + row.id">
                <td>
                  <div>
                    {{ $h.formatNameSurname($_.get(row, "user")) }}
                  </div>
                  <div
                    class="text-xs text-blue-500 hover:text-blue-600"
                    v-if="$_.get(row, 'user.email', '')"
                  >
                    <a :href="`mailto:${$_.get(row, 'user.email', '')}`">{{
                      $_.get(row, "user.email", "")
                    }}</a>
                  </div>
                </td>
                <td class="">
                  <div class="text-xs mb-1.5">
                    {{ row.user_agent }}
                  </div>
                  <a
                    href="javascript:;"
                    @click="() => openAudit(row)"
                    class="inline-block px-3.5 py-1.5 bg-blue-100 rounded-xl text-xs"
                  >
                    Open Detail
                  </a>
                </td>
                <td class="text-xs text-right whitespace-nowrap">
                  <div>
                    <div
                      class="inline-block px-3.5 py-1.5 bg-yellow-200 rounded-xl mr-2 mb-2"
                    >
                      {{ $h.capitalizeEachWords(row.auditable_type) }} ({{
                        row.auditable_id
                      }})
                    </div>
                    <div
                      class="inline-block px-3.5 py-1.5 bg-green-200 rounded-xl mr-2 mb-2"
                    >
                      {{ $h.capitalizeEachWords(row.event) }}
                    </div>
                    <div
                      class="inline-block px-3.5 py-1.5 bg-red-200 rounded-xl mr-2 mb-2"
                    >
                      {{ row.ip_address }}
                    </div>
                    <div
                      class="inline-block px-3.5 py-1.5 bg-blue-200 rounded-xl mb-2"
                    >
                      {{ $h.formatDate(row.created_at, "DD MMMM YYYY") }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "Submissions",
  components: {
    Pagination,
  },
  setup() {
    const valueCompare = ref<Record<string, any>>({
      json: {
        old: {},
        new: {},
      },
      string: {
        old: "",
        new: "",
      },
    });
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "audit",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "event",
            "user_type",
            "user_id",
            "auditable_type",
            "auditable_id",
            "user_agent",
            "ip_address",
            "created_at",
            "old_values",
            "new_values",
          ],
          "@order": "created_at:desc",
          "@func": ["withCount"],
          user: {
            "@select": [
              "id",
              "name",
              "surname",
              "title",
              "salutation",
              "email",
            ],
          },
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    const openAudit = (audit: Record<string, any>) => {
      valueCompare.value = {
        json: {
          old: _.get(audit, "old_values", {}),
          new: _.get(audit, "new_values", {}),
        },
        string: {
          old: "",
          new: "",
        },
      };
      setTimeout(() => {
        valueCompare.value = {
          ...valueCompare.value,
          string: {
            old: cash("#preOldValue").html(),
            new: cash("#preNewValue").html(),
          },
        };
        cash("#audit-detail-modal").modal("show");
      }, 150);
    };

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      valueCompare,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      openAudit,
    };
  },
});
</script>
