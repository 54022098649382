<template>
  <!-- BEGIN: Folder Modal -->
  <div id="document-folder-modal" class="modal">
    <div class="modal__content p-5">
      <div class="text-3xl text-theme-1">Create Folder</div>
      <div class="border-t h-0.5 mt-3 mb-3"></div>
      <VueInputGroup
        class="mb-4"
        :validation-errors="validationErrors.get('title')"
      >
        <template v-slot:label>
          <label class="block mb-1">Folder Name</label>
        </template>
        <VueInput
          capitalize
          v-model="formData.title"
          :validation-errors="validationErrors.get('title')"
        />
      </VueInputGroup>
      <VueInputGroup
        class="mb-6"
        :validation-errors="validationErrors.get('note')"
      >
        <template v-slot:label>
          <label class="block mb-1">Description</label>
        </template>
        <VueTextarea
          uppercase
          v-model="formData.note"
          :validation-errors="validationErrors.get('note')"
        />
      </VueInputGroup>
      <div class="flex justify-end">
        <VueButton
          :loading="processing"
          @click="onSubmit"
          class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
          >Save Folder</VueButton
        >
      </div>
    </div>
  </div>
  <!-- END: Folder Modal -->
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import {
  folderService,
  GetDocumentResult,
} from "@/services";
import { useStore } from "@/store";
import _ from "lodash";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "CreateOrUpdateFolderModal",
  props: {
    parentFolderId: { type: Number, required: true },
  },
  setup(props) {
    const store = useStore();

    const folderId = ref<any>(null);
    const localList = ref<Array<any>>([]);

    const { createOrUpdate } = useGeneralActions();

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: folderService.store,
      updateApiMethod: folderService.update,
      successCallback(result: GetDocumentResult) {
        if (result.kind === "ok") {
          const folderIndex = _.findIndex(localList.value, {
            id: folderId.value,
          });
          if (folderIndex > -1) {
            localList.value.splice(folderIndex, 1, result.data);
          } else {
            localList.value.splice(0, 0, result.data);
          }
          folderId.value = null;
          setFormData({ title: "", note: "" });
          cash("#document-folder-modal").modal("hide");
          store.dispatch("document/SET_DOCUMENTS", {
            documents: localList.value,
            offset: 0,
          });
        }
      },
      params: {
        title: "",
        note: "",
        folder_id: props.parentFolderId,
      },
    });

    const openEditModal = (folder: any) => {
      folderId.value = folder.id;
      setFormData({
        title: folder.title,
        note: folder?.note || "",
      });
      cash("#document-folder-modal").modal("show");
    };

    const onSubmit = () => submit(folderId.value);

    onMounted(() => {
      cash("#document-folder-modal").modal("on.show", () => {
        localList.value = _.cloneDeep(store.state.document.documents);
      });
      cash("#document-folder-modal").modal("on.hide", () => {
        folderId.value = null;
        setFormData({ title: "", note: "" });
      });
    });

    return {
      processing,
      formData,
      validationErrors,
      openEditModal,
      onSubmit,
    };
  },
});
</script>
