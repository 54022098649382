
import { computed, defineComponent, ref, watch } from "vue";
import { GetBallot } from "@/services";
import BallotViewQuestions from "@/views/ballots/view/questions/view/Main.vue";
import BallotEditQuestion from "@/views/ballots/view/questions/Edit.vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "BallotQuestions",
  components: { BallotEditQuestion, BallotViewQuestions },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const visibleEditComponent = ref<boolean>(false);
    const visibleEditToggleButton = computed<boolean>(() => {
      return ["ADHOC", "SMIIC"].indexOf(props.ballot.ballot_type) > -1;
    });
    watch(visibleEditComponent, (val) => emit("update:active-editable", val));
    return {
      visibleEditComponent,
      visibleEditToggleButton,
    };
  },
});
