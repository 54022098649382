<template>
  <div
    class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
  >
    <AwardIcon
      class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
    />
    <div class="text-white text-xl">Ballot Vote</div>
    <div class="text-white text-md mt-1" v-if="!loading">
      {{ detail.reference }}
    </div>
    <div class="text-white text-md mt-1" v-if="!loading">
      <strong class="mr-2">Committee:</strong>
      <span v-if="$_.get(detail, 'committee.acronym', '')"
        >({{ $_.get(detail, "committee.acronym", "") }})
        {{ $_.get(detail, "committee.title", "") }}</span
      >
      <span v-else>SMIIC</span>
    </div>
  </div>
  <div class="p-3 sm:px-5 sm:py-5">
    <!-- BEGIN: Top Buttons -->
    <div class="flex justify-end -mt-9 sm:-mt-11 mb-4">
      <VueButton
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
        :disabled="!!uploading"
      >
        Cast Vote
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
    <!-- END: Top Buttons -->
    <div v-if="loading" class="text-center p-4 pt-8">
      <LoaderIcon class="spin-5 inline-block w-8 h-8" />
      <div class="mt-2 font-light">
        Please wait while the records are loading.
      </div>
    </div>
    <template v-else>
      <VoteQuestion
        v-for="(question, questionIndex) in detail.questions"
        :key="question.uuid"
        :committee-id="detail.committee_id"
        :ballot-id="detail.id"
        :question="question"
        :question-index="questionIndex"
        :validation-errors="validationErrors"
        :path="`vote.${questionIndex}`"
        :start-upload="() => (uploading = true)"
        :end-upload="() => (uploading = false)"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import {
  ballotVoteService,
  ballotService,
  GetBallot,
  PostVote,
  Question,
  SimpleDocumentItem,
  Vote,
} from "@/services";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import VoteQuestion from "@/views/ballots/vote/VoteQuestion.vue";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetBallot>;
}

export default defineComponent({
  name: "Main",
  components: { VoteQuestion },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const uploading = ref<boolean>(false);
    const id = computed(() => route.params.id);

    const { loading: loadingBallot, data, fetch } = fetchRecord({
      fetchApiMethod: ballotService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback: () => {
        const canVote = _.get(data.value, "abilities.can_vote", false);
        if (!canVote) {
          router.go(-1);
        } else {
          fetchVote(id.value).finally();
        }
      },
    }) as FetchRecord;

    const {
      loading: loadingVote,
      data: voteData,
      fetch: fetchVote,
    } = fetchRecord({
      fetchApiMethod: ballotVoteService.show,
      successCallback: () => {
        recursiveQuestionsFormat(data.value.questions);
      },
    });

    const {
      processing,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      updateApiMethod: ballotVoteService.store,
      successCallback() {
        router.go(-1);
      },
      params: {
        vote: [],
      } as PostVote,
    });

    const recursiveFindAnswer = (
      questionUuid: string,
      voteQuestions: Record<string, any>[]
    ) => {
      let vote: {
        answer: string;
        comment: string;
        files: SimpleDocumentItem[];
      } = {
        answer: "",
        comment: "",
        files: [],
      };
      voteQuestions.map((question) => {
        if (questionUuid === question.uuid) {
          vote.answer = question.answer.uuid || "";
          vote.comment = question.answer.comment || "";
          vote.files = question.answer.files || [];
        } else if (
          !vote.answer &&
          _.isArray(question.answer.questions) &&
          question.answer.questions.length > 0
        ) {
          vote = {
            ...vote,
            ...recursiveFindAnswer(questionUuid, question.answer.questions),
          };
        }
      });
      return vote;
    };

    const recursiveQuestionsFormat = (questions: Question[]) => {
      questions.map((question) => {
        question.vote = recursiveFindAnswer(
          question.uuid,
          _.get(voteData.value, `0.vote`, [])
        );
        question.answers.map((answer) => {
          if (_.isArray(answer.questions))
            recursiveQuestionsFormat(answer.questions);
        });
      });
    };

    const recursiveVoteFormat = (questions: Question[]) => {
      const votes: Vote[] = [];
      questions.map((question) => {
        const answerIndex = _.findIndex(question.answers, {
          uuid: question.vote?.answer,
        });
        votes.push({
          uuid: question.uuid,
          answer: {
            uuid: question.vote?.answer || "",
            comment: question.vote?.comment || "",
            files: question.vote?.files || [],
            questions: recursiveVoteFormat(
              _.get(question.answers, `${answerIndex}.questions`, []) || []
            ),
          },
        });
      });
      return votes;
    };

    const onSubmit = () => {
      if (uploading.value) return;
      setFormData({
        vote: recursiveVoteFormat(data.value.questions),
      });
      submit(id.value);
    };

    const loading = computed(() => loadingBallot.value || loadingVote.value);

    watch(id, (val) => fetch(val).finally());
    onMounted(() => {
      fetch(id.value).finally();
    });

    return {
      id,
      uploading,
      loading,
      detail: data,
      processing,
      validationErrors,
      onSubmit,
    };
  },
});
</script>
