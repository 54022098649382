<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <div class="section-divider mb-8">Meeting Information</div>
        <div class="grid grid-cols-12 gap-4">

          <div class="col-span-full">
            <VueInputGroup
              :validation-errors="validationErrors.get('committee_id')"
            >
              <template v-slot:label>
                <label class="block mb-1">Committee</label>
              </template>
              <Select2Input
                :options="committeeSelect2Options"
                v-model="formData.committee_id"
                :validation-errors="validationErrors.get('committee_id')"
                @update:selected-item="onChangeCommittee"
              />
            </VueInputGroup>
          </div>


          <VueInputGroup
            class="col-span-full sm:col-span-4"
            :validation-errors="validationErrors.get('meeting_no')"
          >
            <template v-slot:label>
              <label class="block mb-1">Meeting No</label>
            </template>
            <VueInput
              placeholder="Enter meeting no"
              v-model="formData.meeting_no"
              :validation-errors="validationErrors.get('meeting_no')"
            />
            <div
              class="mt-2 text-xs text-gray-500 text-gray-500"
              v-if="state.nextMeetingNo"
            >
              Next available number will be <b>{{ state.nextMeetingNo }}</b>
            </div>
          </VueInputGroup>

          <div class="col-span-full sm:col-span-8">
            <VueInputGroup :validation-errors="validationErrors.get('title')">
              <template v-slot:label>
                <label class="block mb-1">Title</label>
              </template>
              <VueInput
                placeholder="Enter title"
                v-model="formData.title"
                :validation-errors="validationErrors.get('title')"
              />
            </VueInputGroup>
          </div>


          <div class="col-span-full">
            <VueInputGroup
              :validation-errors="validationErrors.get('information')"
            >
              <template v-slot:label>
                <label class="block mb-1">Information</label>
              </template>
              <VueTiptap v-model="formData.information" />
            </VueInputGroup>
          </div>
          <div class="col-span-full grid grid-cols-12 gap-4">
            <VueInputGroup
              class="col-span-full sm:col-span-4"
              :validation-errors="validationErrors.get('type')"
            >
              <template v-slot:label>
                <label class="block mb-1">Type</label>
              </template>
              <VueSelect
                v-model="formData.type"
                :validation-errors="validationErrors.get('type')"
              >
                <option value="offline">Physical</option>
                <option value="online">Virtual</option>
              </VueSelect>
            </VueInputGroup>
            <template v-if="formData.type === 'offline'">
              <VueInputGroup
                class="col-span-full sm:col-span-4"
                :validation-errors="validationErrors.get('country_code')"
              >
                <template v-slot:label>
                  <label class="block mb-1">Country</label>
                </template>
                <Select2Input
                  :options="countrySelect2Options"
                  v-model="formData.country_code"
                  :validation-errors="validationErrors.get('country_code')"
                />
              </VueInputGroup>
              <VueInputGroup
                class="col-span-full sm:col-span-4"
                :validation-errors="validationErrors.get('city')"
              >
                <template v-slot:label>
                  <label class="block mb-1">City</label>
                </template>
                <VueInput
                  placeholder="Enter city"
                  v-model="formData.city"
                  :validation-errors="validationErrors.get('city')"
                />
              </VueInputGroup>
            </template>
          </div>
          <div class="col-span-6">
            <VueInputGroup :validation-errors="validationErrors.get('time')">
              <template v-slot:label>
                <label class="block mb-1">Date</label>
              </template>
              <Litepicker
                v-model="meetingDate"
                :validation-errors="validationErrors.get('time')"
              />
            </VueInputGroup>
          </div>
          <div class="col-span-6">
            <VueInputGroup>
              <template v-slot:label>
                <label class="block mb-1">Time (GMT)</label>
              </template>
              <VueSelect v-model="meetingTime">
                <option v-for="row in times" :key="`hour-${row}`" :value="row">
                  {{ row }}
                </option>
              </VueSelect>
            </VueInputGroup>
          </div>
          <div class="col-span-full sm:col-span-6">
            <VueInputGroup
              :validation-errors="validationErrors.get('exact_date')"
            >
              <template v-slot:label>
                <label class="block mb-1">The Exact Date</label>
              </template>
              <VueSelect
                v-model="formData.exact_date"
                :validation-errors="validationErrors.get('exact_date')"
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </VueSelect>
            </VueInputGroup>
          </div>
          <div class="col-span-full sm:col-span-6">
            <VueInputGroup :validation-errors="validationErrors.get('status')">
              <template v-slot:label>
                <label class="block mb-1">Status</label>
              </template>
              <VueSelect
                v-model="formData.status"
                :validation-errors="validationErrors.get('status')"
              >
                <option value="">Select one of list</option>
                <option value="Proposed">Proposed</option>
                <option value="Definite">Definite</option>
                <option value="Convened">Convened</option>
                <option value="Ongoing">Ongoing</option>
                <option value="Closed">Closed</option>
                <option value="Postponed">Postponed</option>
                <option value="Canseled">Canseled</option>
                <option value="Archived">Archived</option>
              </VueSelect>
            </VueInputGroup>
          </div>
        </div>
        <div class="flex justify-end mt-5">
          <VueButton
            :loading="processing"
            @click="onSubmit"
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
            >Save Meeting</VueButton
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { PostMeeting, meetingService } from "@/services";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { helper } from "@/utils/helper";
import VueTiptap from "@/components/tiptap/Main.vue";
import { useStore } from "@/store";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostMeeting;
}

export default defineComponent({
  name: "CreateMeeting",
  components: { VueTiptap },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive<Record<string, any>>({
      nextMeetingNo: "",
    });


    const onChangeCommittee = (committee: any) => {
      fetchNextNumber(committee);
    };


    const fetchNextNumber = (committee: any) => {
      meetingService
        .nextMeetingNumber({committee_id:committee.id})
        .then((result) => {
          state.nextMeetingNo = result.meeting_no
          if (!id.value && state.nextMeetingNo) formData.meeting_no = result.meeting_no
        })
    };


    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT MEETING (#${id.value})`;
      return "CREATE MEETING";
    });

    const times = computed<string[]>(() => {
      const values = [];
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : i;
        for (let j = 0; j < 2; j++) {
          let minute: any = j * 30;
          minute = minute < 10 ? `0${minute}` : minute;
          values.push(`${hour}:${minute}`);
        }
      }
      return values;
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            acronym: "acronym",
          },
          "@where": {
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              "can_create_meeting"
            ),
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
        return obj.text;
      },
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: meetingService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        const canEdit = _.get(data.value, "abilities.can_update", false);
        if (canEdit) {
          setFormData({
            ...data.value,
            time: helper.formatDate(data.value.time, "YYYY-MM-DD HH:mm"),
          });
        } else {
          router.go(-1);
        }
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: meetingService.store,
      updateApiMethod: meetingService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        title: "",
        committee_id: _.get(route.query, "committee_id", "") || "",
        information: "",
        meeting_no: "",
        city: "",
        country_code: "",
        time: helper.formatDate(new Date(), "YYYY-MM-DD HH") + ":00",
        exact_date: "0",
        status: "",
        type: "offline",
      },
    }) as CreateOrUpdate;

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const meetingDate = computed<string>({
      get: () => {
        return formData.time.split(" ")[0] || "";
      },
      set: (val) => {
        formData.time = `${val} ${meetingTime.value}`;
      },
    });

    const meetingTime = computed<string>({
      get: () => {
        return formData.time.split(" ")[1] || "00:00";
      },
      set: (val) => {
        formData.time = `${meetingDate.value} ${val}`;
      },
    });

    const meetingHour = computed<string>({
      get: () => {
        const splitArr = formData.time.split(" ") || [];
        const time = splitArr[1] || "";
        return time.split(":")[0] || "";
      },
      set: (val) => {
        formData.time = `${meetingDate.value} ${val}:${meetingMinute.value}`;
      },
    });

    const meetingMinute = computed<string>({
      get: () => {
        const splitArr = formData.time.split(" ") || [];
        const time = splitArr[1] || "";
        return time.split(":")[1] || "";
      },
      set: (val) => {
        formData.time = `${meetingDate.value} ${meetingHour.value}:${val}`;
      },
    });

    const onSubmit = () => submit(id.value);

    return {
      state,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      committeeSelect2Options,
      countrySelect2Options,
      meetingDate,
      meetingHour,
      meetingMinute,
      times,
      meetingTime,
      meeting: data,
      onSubmit,
      onChangeCommittee,
    };
  },
});
</script>
