<template>
  <div class="grid grid-cols-12 gap-4">
    <template v-if="!committeeId">
      <CommitteeLevel
        :validation-errors="validationErrors"
        :committee="editableFormData"
      />
    </template>
    <div class="col-span-6">
      <VueInputGroup :validation-errors="validationErrors.get('title')">
        <template v-slot:label>
          <label class="block mb-1">Title</label>
        </template>
        <VueInput
          capitalize
          placeholder="Enter title"
          v-model="editableFormData.title"
          :validation-errors="validationErrors.get('title')"
        />
      </VueInputGroup>
    </div>
    <div class="col-span-6">
      <VueInputGroup :validation-errors="validationErrors.get('creation_date')">
        <template v-slot:label>
          <label class="block mb-1">Creation Date</label>
        </template>
        <VueInput
          v-model="editableFormData.creation_date"
          :validation-errors="validationErrors.get('creation_date')"
        />
      </VueInputGroup>
    </div>
    <div class="col-span-full sm:col-span-6" v-if="!isWg">
      <VueInputGroup
        :validation-errors="validationErrors.get('secretariat_member_id')"
      >
        <template v-slot:label>
          <label class="block mb-1">Secretariat - Member Body</label>
        </template>
        <Select2Input
          :options="memberSelect2Options"
          v-model="editableFormData.secretariat_member_id"
          :validation-errors="validationErrors.get('secretariat_member_id')"
        />
      </VueInputGroup>
    </div>
    <div class="col-span-full">
      <VueInputGroup :validation-errors="validationErrors.get('scope')">
        <template v-slot:label>
          <label class="block mb-1">Scope</label>
        </template>
        <VueTiptap
          placeholder="Enter scope"
          v-model="editableFormData.scope"
          :validation-errors="validationErrors.get('scope')"
        />
      </VueInputGroup>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs } from "vue";
import { PostCommittee } from "@/services";
import { ValidationErrors } from "@/utils/form";
import { Select2Options } from "@/global-components/select2";
import VueTiptap from "@/components/tiptap/Main.vue";
import CommitteeLevel from "@/views/committees/create/CommitteeLevel.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "CommitteeIdentity",
  components: { CommitteeLevel, VueTiptap },
  props: {
    committeeType: { default: () => null },
    committeeId: { default: () => null },
    isWg: { default: () => null },
    formData: { type: Object as () => PostCommittee, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableFormData = reactive(toRefs<PostCommittee>(props.formData));
    const memberTemplateFormat = (obj: any): string => {
      if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
      return obj.text;
    };
    const memberSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        organization: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            acronym: "acronym",
          },
        },
      },
      templateSelection: memberTemplateFormat,
    });

    const userTemplateFormat = (obj: any): string => {
      if (obj.name) return helper.formatNameSurname(obj);
      // if (obj.name) return `${obj.salutation} ${obj.name} ${obj.surname}`;
      return obj.text;
    };

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            salutation: "salutation",
            title: "title",
          },
        },
      },
      templateSelection: userTemplateFormat,
    });

    return {
      editableFormData,
      memberSelect2Options,
      userSelect2Options,
    };
  },
});
</script>
