<template>
  <div class="section-divider mb-4">Members Not Casted Vote</div>
  <div class="overflow-auto text-xs mb-8">
    <table class="min-w-full border border-gray-300">
      <thead>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-center font-normal w-0"
        >
          Type
        </th>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-normal"
        >
          Member
        </th>
        <th
          class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-normal"
        >
          Title
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in rows"
          :key="`data-not-casted-${rowIndex}`"
        >
          <td
            class="border-b border-r border-gray-300 px-3 py-1.5 text-center font-normal whitespace-nowrap"
          >
            {{ $_.get(row, "member_type", "") }}
          </td>
          <td
            class="border-b border-r border-gray-300 px-3 py-1.5 text-left font-normal whitespace-nowrap"
          >
            {{ $_.get(row, "country_title", "") }} ({{
              $_.get(row, "acronym", "")
            }})
          </td>
          <td
            class="border-b border-r border-gray-300 px-3 py-1.5 text-left font-normal whitespace-nowrap"
          >
            {{ $_.get(row, "title", "") }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NotCastedVotes",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
