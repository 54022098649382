
import { computed, defineComponent } from "vue";
import { GetRole, GetRoleType, GetUser, roleService } from "@/services";
import { useGeneralActions } from "@/utils/actions";
import TransactionStatement from "@/components/transaction-statement/Main.vue";

export default defineComponent({
  name: "UserRoleBehaviourList",
  components: { TransactionStatement },
  props: {
    definedRoles: {
      type: Array as () => GetRole[],
      default: () => [],
    },
    roleType: {
      type: Object as () => GetRoleType,
      default: () => ({}),
    },
    onAddRole: {
      type: Function,
      default: () => null,
    },
    onEditRole: {
      type: Function,
      default: () => null,
    },
    userId: {
      type: Number,
      default: () => 0,
    },
    user: {
      type: Object as () => GetUser,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { deleteRecords } = useGeneralActions();

    const filterDefinedRoles = computed(() =>
      props.definedRoles.filter(
        (role) => props.roleType.behaviour === role.behaviour
      )
    );

    const canEdit = computed<boolean>(() => {
      let can = _.get(props.user, "abilities.can_update_roles", false);
      if (props.roleType.type === "staff") {
        can = _.get(props.user, "abilities.can_update_staff_roles", false);
      }
      return can;
    });

    const visibleBallotTypes = computed<boolean>(() => {
      return (
        [
          "behave_as_ballot_monitor",
          "behave_as_voter",
          "behave_as_c_ballot_monitor",
          "behave_as_c_voter",
        ].indexOf(props.roleType.behaviour) > -1
      );
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: async (id) => {
        return await roleService.delete(props.userId, id);
      },
      successCallback(id) {
        if (id) {
          const list = _.cloneDeep(props.definedRoles);
          const index = _.findIndex(list, { id });
          if (index > -1) list.splice(index, 1);
          emit("update:defined-roles", list);
        }
      },
    });

    return {
      processing,
      transactionIds,
      triggerDelete,
      filterDefinedRoles,
      visibleBallotTypes,
      canEdit,
    };
  },
});
