
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { PostOrganization, organizationService } from "@/services";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import VueTiptap from "@/components/tiptap/Main.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostOrganization;
}

export default defineComponent({
  name: "CreateOrganization",
  components: { VueTiptap },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT ORGANIZATION (#${id.value})`;
      return "CREATE ORGANIZATION";
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: organizationService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: organizationService.store,
      updateApiMethod: organizationService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        visible_on_website: 0,
        acronym: "",
        title: "",
        information: "",
        description: "",
        contact: "",
        country_code: "",
        is_accreditation_body: 0,
        is_metrology_body: 0,
        is_standardization_body: 0,
        number_of_standards_adopted: 0,
      },
    }) as CreateOrUpdate;

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      countrySelect2Options,
      onSubmit,
    };
  },
});
