<template>
  <div class="text-xs h-full recycle--table simple--list" ref="wrapperRef">
    <RecycleScroller
      :items="documents"
      :item-size="40"
      :style="{ height: `${contentHeight}px` }"
      :emit-update="true"
      key-field="uuid"
      @update="changeActiveList"
    >
      <template v-slot="{ item }">
        <a
          href="javascript:;"
          class="recycle--table__cell whitespace-nowrap overflow-hidden overflow-ellipsis text-blue-500 hover:text-blue-700"
          style="flex-basis: 250px; flex-grow: 100"
          :class="{
            'flex items-center': !item.id,
            '!bg-blue-50': item.type * 1 < 100,
            '!bg-blue-100': item.uuid === documentUuid,
          }"
          @click="openItem(item)"
        >
          <template v-if="item.id">
            <span class="font-semibold mr-1" v-if="$_.get(item, 'n_number')"
              >N{{ $_.get(item, "n_number", "") }}</span
            >
            <span>{{ $_.get(item, "title", "") || "-" }}</span>
          </template>
          <div
            v-else
            class="rounded-lg w-full h-1.5 bg-blue-200 animate-pulse"
          ></div>
        </a>
      </template>
    </RecycleScroller>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { RecycleScroller } from "vue3-virtual-scroller";
import { helper } from "@/utils/helper";
import { useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "DocumentFilteredSimpleList",
  components: { RecycleScroller },
  props: {
    documentUuid: {
      type: String,
      default: () => "",
    },
    changeActiveList: {
      type: Function,
      default: () => {
        console.log("changeActiveList");
      },
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const wrapperRef = ref<HTMLElement>();
    const contentHeight = ref<number>(0);

    const documents = computed(() =>
      store.state.document.documents.map((document) => {
        return {
          uuid: document.uuid || helper.uuidv4(),
          ...document,
        };
      })
    );

    const openItem = (item: any) => {
      if (item.type * 1 < 100) {
        router.push({
          name: "documents",
          params: {},
          query: { folderId: item.id },
        });
      } else {
        router.push({
          name: "documents",
          params: { uuid: item.uuid },
        });
      }
    };

    const calculateHeights = () => {
      contentHeight.value = wrapperRef.value?.clientHeight || 0;
    };

    onMounted(() => {
      window.addEventListener("resize", calculateHeights);
      setTimeout(() => {
        calculateHeights();
      }, 100);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", calculateHeights);
    });

    return {
      documents,
      wrapperRef,
      contentHeight,
      openItem,
    };
  },
});
</script>
