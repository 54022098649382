import {
  useAxios,
  GeneralApiResponse,
  GetBallotResult,
  GetBallotResults,
  GetBallotQuestionResult,
  GetVoteResult,
  GetVoteResults,
  GetBallotVoteResults,
  GetBallotCommentResult,
  GetBallotCommentResults,
} from "@/services";

export * from "./api.types";

export const ballotService = {
  fetch: async (): Promise<GetBallotResults> =>
    useAxios().fetch(`/api/ballots`, {}),

  show: async (id: unknown): Promise<GetBallotResult> =>
    useAxios().get(`/api/ballots/${id}`),

  store: async (params: Record<string, any>): Promise<GetBallotResult> =>
    useAxios().store(`/api/ballots`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetBallotResult> =>
    useAxios().update(`/api/ballots/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/ballots/${id}`),

  fetchQuestions: async (): Promise<GetBallotQuestionResult> =>
    useAxios().get(`/api/ballots/questions`),

  fetchResults: async (ballotId: unknown): Promise<GetBallotVoteResults> =>
    useAxios({ responseField: "" }).get(`/api/ballots/${ballotId}/result`),

  open: async (id: unknown): Promise<GetBallotResult> =>
    useAxios().update(`/api/ballots/${id}/open`),

  close: async (id: unknown): Promise<GetBallotResult> =>
    useAxios().update(`/api/ballots/${id}/close`),
};

export const ballotVoteService = {
  store: async (
    ballotId: unknown,
    params: Record<string, any>
  ): Promise<GetVoteResult> =>
    useAxios().store(`/api/ballots/${ballotId}/votes`, params),

  show: async (ballotId: unknown): Promise<GetVoteResults> =>
    useAxios().get(`/api/ballots/${ballotId}/votes`),
};

export const ballotCommentService = {
  store: async (
    ballotId: unknown,
    params: Record<string, any>
  ): Promise<GetBallotCommentResult> =>
    useAxios().store(`/api/ballots/${ballotId}/comments`, params),

  show: async (ballotId: unknown): Promise<GetBallotCommentResults> =>
    useAxios().get(`/api/ballots/${ballotId}/comments`),
};
