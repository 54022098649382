<template>
  <div class="accordion__pane active pb-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
    >
      Libraries
      <template v-if="!loading">({{ list.length }})</template>
      <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.length > 0">
        <div class="grid grid-cols-12 gap-3 sm:gap-6">
          <div
            v-for="(folder, folderIndex) in list"
            :key="`folder-${folder.id}-${folderIndex}`"
            class="-intro-y intro-fast col-span-6 md:col-span-3 xxl:col-span-2"
            @click="() => goToFolder(folder.id * 1)"
          >
            <div
              class="file bg-gray-100 hover:bg-gray-200 cursor-pointer box rounded-md px-5 pt-8 pb-5 px-3 sm:px-5 relative zoom-in"
            >
              <a
                class="w-1/2 file__icon file__icon--empty-directory mx-auto"
                :class="{
                  'file__icon--empty-committee-directory':
                    folder.type === 'committee',
                }"
              ></a>
              <a
                :title="folder.title"
                class="block font-medium mt-4 text-center truncate"
                >{{ folder.title }}</a
              >
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import {
  DOCUMENT_GROUP_TYPES,
  fetchSelection,
  GetCommittee,
  GetDocument,
} from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Library",
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const loading = ref<boolean>(true);
    const list = ref<GetDocument[]>([]);

    const fetch = () => {
      loading.value = true;
      fetchSelection({
        document: {
          "@get": true,
          "@select": [
            "id",
            "folder_id",
            "title",
            "type",
            "committee_id",
            "created_at",
          ],
          "@func": ["onlyFolders"],
          "@where": {
            committee_id: props.committeeId,
          },
        },
      })
        .then((result) => {
          if (result.kind === "ok") {
            const data = _.get(
              result.data,
              "document.data",
              []
            ) as GetDocument[];
            const parentFolderIndex = _.findIndex(data, {
              type: DOCUMENT_GROUP_TYPES.ROOT,
            });
            list.value = data.filter(
              (item) =>
                item.folder_id === _.get(data, [parentFolderIndex, "id"], 0)
            );
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const goToFolder = (folderId: number) => {
      router.push({ name: "documents", query: { folderId } });
    };

    onMounted(() => {
      fetch();
    });

    return {
      loading,
      list,
      goToFolder,
    };
  },
});
</script>
