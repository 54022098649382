
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueButton",
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    loadingText: {
      type: String,
      default: () => "Please Waiting"
    }
  }
});
