<template>
  <div v-if="$_.get(project, 'abilities.can_update', false)">
    <!-- BEGIN: Stage Modal -->
    <div ref="refStageModal" id="stage-modal" class="modal">
      <div class="modal__content p-5">
        <div class="text-3xl text-theme-1">Change Project Stage</div>
        <div class="border-t h-0.5 mt-3 mb-3"></div>
        <VueInputGroup
          class="mb-4"
          :validation-errors="stageValidationErrors.get('stage')"
        >
          <template v-slot:label>
            <label class="block mb-1">Stage</label>
          </template>
          <VueSelect
            v-model="stageFormData.stage"
            :validation-errors="stageValidationErrors.get('stage')"
          >
            <option value="">Select one of list</option>
            <option
              v-for="row in availableNextStages"
              :key="`stage-${row.value}`"
              :value="row.value"
            >
              ({{ row.value }}) {{ row.text }}
            </option>
          </VueSelect>
        </VueInputGroup>
        <div class="flex justify-end">
          <VueButton
            :loading="stageProcessing"
            @click="submitNextStage"
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
            >Save Stage</VueButton
          >
        </div>
      </div>
    </div>
    <!-- END: Folder Modal -->

    <div
      class="flex justify-end -mt-9 sm:-mt-11 mb-4"
      v-if="visibleDecision || visibleLaunchNewBallot"
    >
      <button
        :disabled="disabled"
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onLaunchAction"
      >
        <template v-if="visibleDecision">Next Stage</template>
        <template v-else-if="visibleLaunchNewBallot"
          >Launch New Ballot</template
        >
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <ChevronRightIcon class="inline w-6 h-6" />
        </div>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import {
  GetProject,
  GetProjectResult,
  GetStageType,
  projectStageService,
} from "@/services";
import { useGeneralActions } from "@/utils/actions";
import { launchActionForProject } from "@/views/projects/actions";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "ProjectActionButton",
  props: {
    canEdit: { type: Boolean, default: () => true },
    disabled: { type: Boolean, default: () => false },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
    onFetch: { type: Function, default: () => null },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const availableNextStages = ref<GetStageType[]>([]);
    const refStageModal = ref<HTMLElement>();

    const { createOrUpdate } = useGeneralActions();

    const fetchAvailableNextStages = () => {
      projectStageService.fetchNextStages(props.projectId).then((result) => {
        if (result.kind === "ok") {
          stageFormData.stage = "";
          availableNextStages.value = result.data;
        }
      });
    };

    const {
      formData: stageFormData,
      validationErrors: stageValidationErrors,
      processing: stageProcessing,
      submit: stageSubmit,
    } = createOrUpdate({
      updateApiMethod: projectStageService.updateStage,
      successCallback(result: GetProjectResult) {
        if (result.kind === "ok") {
          props.onFetch();
          cash("#stage-modal").modal("hide");
        }
      },
      params: {
        stage: "",
      },
    });

    const visibleDecision = computed<boolean>(() => {
      return store.state.project.manualStages.indexOf(props.project.stage) > -1;
    });

    const visibleLaunchNewBallot = computed<boolean>(() => {
      return store.state.project.ballotStages.indexOf(props.project.stage) > -1;
    });

    const submitNextStage = () => stageSubmit(props.projectId);

    const onLaunchAction = () =>
      launchActionForProject({
        projectId: (props.projectId as any) * 1,
        committeeId: (props.project.committee_id as any) * 1,
        stage: props.project.stage,
        route,
        router,
        store,
      });

    onMounted(() => {
      if (visibleDecision.value) fetchAvailableNextStages();
      if (_.get(route.query, "openDecisionModal") === "1") {
        cash("#stage-modal").modal("show");
        const routeQuery = _.cloneDeep(route.query);
        _.unset(routeQuery, "openDecisionModal");
        router.replace({
          name: "projects.view",
          params: { ...route.params },
          query: routeQuery,
        });
      }
    });

    onBeforeUnmount(() => {
      cash(refStageModal.value || "#stage-modal").modal("hide");
    });

    return {
      stageProcessing,
      stageValidationErrors,
      stageFormData,
      availableNextStages,
      visibleDecision,
      visibleLaunchNewBallot,
      refStageModal,
      submitNextStage,
      onLaunchAction,
    };
  },
});
</script>
