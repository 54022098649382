
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import _ from "lodash";
import fileDownload from "js-file-download";

export default defineComponent({
  name: "IframeViewer",
  setup() {
    const route = useRoute();
    const show = ref<boolean>(false);
    const iframeUrl = ref<string>("");
    const iframeRef = ref<HTMLIFrameElement>();

    function getScrollbarWidth(el: HTMLElement | string) {
      return window.innerWidth - cash(el)[0].clientWidth;
    }

    watch(
      computed(() => route.query),
      () => {
        iframeUrl.value = _.get(route.query, "iUrl", "") as string;
      }
    );
    watch(iframeUrl, (val) => {
      show.value = !!val;
      if (show.value) {
        cash("body")
          .css(
            "padding-right",
            `${
              parseInt(cash("body").css("padding-right")) +
              getScrollbarWidth("html")
            }px`
          )
          .addClass("overflow-y-hidden");
      } else {
        cash("body").removeClass("overflow-y-hidden").css("padding-right", "");
      }
    });

    const onSave = async () => {
      const buffer = await fetch(iframeUrl.value).then((res) => res.arrayBuffer());
      fileDownload(buffer, new Date() + ".pdf", "application/pdf");
    };

    return {
      show,
      iframeRef,
      iframeUrl,
      onSave,
    };
  },
});
