
import { computed, defineComponent } from "vue";

export default defineComponent({
  name: "BallotResult",
  props: {
    result: {
      type: Object,
      default: () => ({}),
    },
    ballotType: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const isSMIICBallot = computed(
      () => ["SMIIC", "DS", "FDS"].indexOf(props.ballotType) > -1
    );
    return {
      isSMIICBallot,
    };
  },
});
