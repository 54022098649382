import {
  GetFeedbackResult,
  GetFeedbackResults,
  useAxios,
  GeneralApiResponse,
} from "@/services";

export * from "./api.types";

export const feedbackService = {
  fetch: async (): Promise<GetFeedbackResults> =>
    useAxios().fetch(`/api/feedbacks`, {}),

  show: async (id: unknown): Promise<GetFeedbackResult> =>
    useAxios().get(`/api/feedbacks/${id}`),

  store: async (params: Record<string, any>): Promise<GetFeedbackResult> =>
    useAxios().store(`/api/feedbacks`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/feedbacks/${id}`),
};
