<template>
  <div class="my-6">
    <!-- BEGIN: Folder Modal -->
    <div id="reference-document-modal" class="modal">
      <div class="modal__content modal__content--lg p-5">
        <div class="text-3xl text-theme-1">Add Reference Document</div>
        <div class="border-t h-0.5 mt-3 mb-4"></div>
        <VueInputGroup
          class="mt-4"
          :validation-errors="validationErrors.get('files')"
        >
          <template v-slot:label>
            <label class="block mb-2">Select Document</label>
          </template>
          <Select2Input
            :options="documentSelect2Options"
            v-model="documentValue"
            :validation-errors="validationErrors.get('files')"
            @update:selected-item="onAddSubmissionFile"
          />
        </VueInputGroup>
        <div class="overflow-x-auto mt-4" v-if="formData.files.length > 0">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Document</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="row in formData.files"
                :key="'submission-document-' + row.uuid"
              >
                <td>
                  <span class="font-semibold" v-if="$_.get(row, 'n_number', '')"
                    >N{{ $_.get(row, "n_number", "") }}
                  </span>
                  {{ $_.get(row, "title", "") }}
                </td>
                <td class="text-right">
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => onDeleteReference(row.uuid)"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex justify-end mt-6">
          <VueButton
            :loading="processing"
            @click="onSubmit"
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
            >Save Reference Links</VueButton
          >
        </div>
      </div>
    </div>
    <!-- END: Folder Modal -->
    <div class="section-divider mb-3">Reference Links</div>
    <div class="overflow-x-auto text-xs">
      <table class="table w-full">
        <thead>
          <tr>
            <th class="whitespace-nowrap text-center w-1">N Number</th>
            <th class="whitespace-nowrap">Type</th>
            <th class="whitespace-nowrap">File</th>
            <th class="whitespace-nowrap text-right">Functions</th>
          </tr>
        </thead>
        <tbody>
          <TransactionStatement
            tag="tr"
            v-for="row in ballot.files"
            :key="'reference-document-' + row.id"
            :processing="processing"
            :waiting="false"
          >
            <td class="text-center text-lg">
              <span class="font-semibold">{{ row.n_number }}</span>
            </td>
            <td>
              {{ $store.getters["document/documentTypeTitle"](row.type) }}
            </td>
            <td>
              <router-link
                :to="{ name: 'documents', params: { uuid: row.uuid } }"
                class="text-blue-500 hover:text-blue-700"
              >
                {{ row.title }}
              </router-link>
            </td>
            <td class="whitespace-nowrap text-right">
              <Tippy
                tag="button"
                content="See Details"
                :options="{ placement: 'bottom' }"
                class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                @click="() => goToView(row.uuid)"
              >
                <EyeIcon class="inline w-5 h-5" />
              </Tippy>
              <Tippy
                tag="button"
                content="Open Edit Modal"
                :options="{ placement: 'bottom' }"
                class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                @click="() => onOpenModal()"
                v-if="canEdit"
              >
                <Edit2Icon class="inline w-5 h-5" />
              </Tippy>
            </td>
          </TransactionStatement>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import { saveInit } from "@/views/ballots/view/save";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { GetBallot } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BallotReferenceLinks",
  components: { TransactionStatement },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      onSubmit,
    } = saveInit(
      {
        ...props,
        close: () => {
          cash("#reference-document-modal").modal("hide");
        },
      },
      emit
    );

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            uuid: "uuid",
            n_number: "n_number",
            type: "type",
            file_name: "file_name",
            file_size: "file_size",
          },
          "@order": ["n_number:desc", "created_at:desc"],
          "@where": {
            committee_id: props.ballot.committee_id,
            status: "published"
          },
          "@whereNot": {
            uuid: formData.files.map((file) => file.uuid),
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const documentValue = ref<string>("");
    const onAddSubmissionFile = (document: Record<string, any>) => {
      if (document) {
        formData.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.text,
          n_number: document.n_number,
          type: document.type,
          file_name: document.file_name,
          file_size: document.file_size,
        });
        setTimeout(() => {
          documentValue.value = "";
        }, 100);
      }
    };

    const onOpenModal = () => {
      cash("#reference-document-modal").modal("show");
    };

    const onDeleteReference = (uuid: any) => {
      if (uuid) {
        const index = _.findIndex(formData.files, { uuid });
        if (index > -1) {
          formData.files.splice(index, 1);
        }
      }
    };

    const goToView = (uuid: any) => {
      router.push({ name: "documents", params: { uuid } });
    };

    onMounted(() => {
      cash("#reference-document-modal").modal("on.show", () => {
        setFormData(_.cloneDeep(props.ballot));
      });
    });

    return {
      processing,
      formData,
      validationErrors,
      documentSelect2Options,
      documentValue,
      onAddSubmissionFile,
      onSubmit,
      onDeleteReference,
      onOpenModal,
      goToView,
    };
  },
});
</script>
