import {
  GeneralApiResponse,
  GetMeetingResult,
  GetMeetingResults,
  useAxios,
} from "@/services";

export * from "./api.types";

export const meetingService = {
  fetch: async (): Promise<GetMeetingResults> =>
    useAxios().fetch(`/api/meetings`, {}),

  show: async (id: unknown): Promise<GetMeetingResult> =>
    useAxios().get(`/api/meetings/${id}`),

  store: async (params: Record<string, any>): Promise<GetMeetingResult> =>
    useAxios().store(`/api/meetings`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetMeetingResult> =>
    useAxios().update(`/api/meetings/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/meetings/${id}`),

  nextMeetingNumber: async (
    params: Record<string, any>
  ): Promise<{ meeting_no: any }> => {
    const result = await useAxios()
      .instance.post(`/api/meetings/next-meeting-number`, params)
      .catch((error) => error);
    return {
      meeting_no: _.get(result.data, "meeting_no", ""),
    };
  },
};
