import {
  GeneralApiResponse,
  GetSubmissionResult,
  GetSubmissionResults,
  useAxios,
} from "@/services";

export * from "./api.types";

export const submissionService = {
  fetch: async (): Promise<GetSubmissionResults> =>
    useAxios().fetch(`/api/submissions`, {}),

  show: async (id: unknown): Promise<GetSubmissionResult> =>
    useAxios().get(`/api/submissions/${id}`),

  store: async (params: Record<string, any>): Promise<GetSubmissionResult> =>
    useAxios().store(`/api/submissions`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetSubmissionResult> =>
    useAxios().update(`/api/submissions/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/submissions/${id}`),
};
