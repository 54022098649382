<template>
  <div class="relative w-full h-full">
    <table
      class="table"
      v-if="document.recipients && document.recipients.length > 0"
    >
      <thead>
        <tr>
          <th class="whitespace-nowrap">Email</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in document.recipients" :key="'recipient-' + row.id">
          <td class="whitespace-nowrap">
            <a
              :href="`mailto:${row}`"
              class="text-blue-500 hover:text-blue-600"
            >
              {{ row }}
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="text-center font-bold p-3" v-else>No results found</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GetDocument } from "@/services";

export default defineComponent({
  name: "RecipientsList",
  props: {
    document: {
      type: Object as () => GetDocument,
      default: () => ({}),
    },
  },
});
</script>
