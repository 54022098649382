import {
  GeneralApiResponse,
  GetProjectLineResult,
  GetProjectResult,
  GetStageResults,
  GetStageTypeResults,
  useAxios,
} from "@/services";

export * from "./api.types";

export const projectService = {
  show: async (id: unknown): Promise<GetProjectResult> =>
    useAxios().get(`/api/projects/${id}`),

  store: async (params: Record<string, any>): Promise<GetProjectResult> =>
    useAxios().store(`/api/projects`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetProjectResult> =>
    useAxios().update(`/api/projects/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/projects/${id}`),

  updateProjectLine: async (
    projectId: unknown,
    lineId: unknown,
    params: Record<string, any>
  ): Promise<GetProjectLineResult> =>
    useAxios().update(`/api/projects/${projectId}/lines/${lineId}`, params),

  nextProjectNumber: async (): Promise<{ project_no: any }> => {
    const result = await useAxios()
      .instance.post(`/api/projects/next-project-number`)
      .catch((error) => error);
    return {
      project_no: _.get(result.data, "project_no", ""),
    };
  },
};

export const projectStageService = {
  fetchManualStages: async (): Promise<GetStageResults> =>
    useAxios().get(`/api/projects/manual-stages`),

  fetchBallotStages: async (): Promise<GetStageResults> =>
    useAxios().get(`/api/projects/ballot-stages`),

  fetchInitialStages: async (): Promise<GetStageTypeResults> =>
    useAxios().get(`/api/projects/stages`),

  fetchNextStages: async (projectId: unknown): Promise<GetStageTypeResults> =>
    useAxios().get(`/api/projects/stages/${projectId}`),

  updateStage: async (
    projectId: unknown,
    params: Record<string, any>
  ): Promise<GetStageTypeResults> =>
    useAxios().update(`/api/projects/stages/${projectId}`, params),
};
