import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";
import { GetDocumentType } from "@/services";

// Getters types
export type Getters = {
  documentTypes(state: LocalState): GetDocumentType[];
  documentTypeTitle(state: LocalState): (id: any) => string;
  // folders(state: LocalState): any[];
  // getFolderByType(state: LocalState): (committeeId: any, type: string) => any;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  documentTypes: (state) => {
    return state.types;
  },
  documentTypeTitle: (state) => (id: any) => {
    let title = "";
    if (id * 1 < 100) return "Folder";
    state.types.map((type) => {
      const children = type.children || [];
      children.map((child) => {
        if (id * 1 === child.value) title = child.text;
      });
    });
    return title;
  },
  // folders: (state) => {
  //   return state.folders;
  // },
  // getFolderByType: (state) => (committeeId: any, type: string): any => {
  //   const index = _.findIndex(state.folders, {
  //     committee_id: committeeId,
  //     type,
  //   });
  //   return _.get(state.folders, index, null);
  // },
};
