<template>
  <div id="comment-modal" class="modal" ref="refCommentModal">
    <div class="modal__content modal__content--lg p-5">
      <div
        class="text-3xl text-theme-1 pb-3 mb-5 border-dashed border-b-2 border-theme-1"
      >
        Comment
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <VueInputGroup :validation-errors="validationErrors.get('files', 'text')">
        <VueTextarea
          class="shadow-none bg-gray-200"
          placeholder="Write your comment here!"
          :disabled="loading"
          v-model="formData.text"
          :validation-errors="validationErrors.get('files', 'text')"
        />
      </VueInputGroup>
      <Dropzone
        v-if="!loading"
        refKey="dropzoneRef"
        class="dropzone__document mt-4 rounded-lg bg-gray-200"
        :class="{ 'pointer-events-none': loading }"
        :options="dropzoneOptions"
        :success="successUploadFile"
        :remove="removeUploadedFile"
      />
      <div class="text-center mt-5">
        <button
          class="button rounded-l-full bg-red-400 text-white font-light px-8 py-2 shadow-lg"
          @click="closeModal"
        >
          Cancel
        </button>
        <VueButton
          class="button rounded-r-full bg-gray-500 text-white font-light px-8 py-2 shadow-lg"
          @click="onSubmit"
          :disabled="loading"
          :loading="processing"
        >
          Save
        </VueButton>
      </div>
    </div>
  </div>
  <button
    v-if="canComment"
    type="button"
    class="button bg-gradient-to-b from-gray-400 to-gray-500 text-white relative pr-16 whitespace-nowrap ml-4"
    @click="openModal"
  >
    Write Comment
    <div
      class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-gray-600 to-gray-700 text-white rounded-md py-2.5 px-3"
    >
      <MessageCircleIcon class="inline w-6 h-6" />
    </div>
  </button>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  provide,
  ref,
} from "vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueTextarea from "@/global-components/form-elements/textarea/Main.vue";
import Dropzone from "@/global-components/dropzone/Main.vue";
import { DropzoneFile, DropzoneOptions } from "dropzone";
import {
  GetBallot,
  GetDocument,
  BallotComment,
  GetBallotCommentResult,
  ballotCommentService,
} from "@/services";
import { useStore } from "@/store";
import { useGeneralActions } from "@/utils/actions";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: BallotComment;
}

export default defineComponent({
  name: "WriteComment",
  components: { Dropzone, VueTextarea, VueInputGroup },
  props: {
    ballot: {
      type: Object as () => GetBallot,
      default: () => ({}),
    },
    canComment: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { fetchRecord, createOrUpdate } = useGeneralActions();

    const refCommentModal = ref<HTMLElement>();
    const dropzoneRef = ref<HTMLElement>();
    provide("bind[dropzoneRef]", (el: HTMLElement) => {
      dropzoneRef.value = el;
    });

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: ballotCommentService.show,
      successCallback: () => {
        setFormData({
          text: _.get(data.value, "0.text", ""),
          files: _.get(data.value, "0.files", []),
        });
        setTimeout(() => manualAddDocuments(formData.files), 100);
      },
    });

    const {
      processing,
      formData,
      validationErrors,
      submit,
      setFormData,
    } = createOrUpdate({
      createApiMethod: async (params) => {
        return await ballotCommentService.store(props.ballot.id, params);
      },
      successCallback(result: GetBallotCommentResult) {
        if (result.kind === "ok") {
          emit("update:created-comment", result.data);
          closeModal();
        }
      },
      params: {
        ballot_id: props.ballot.id,
        user_id: _.get(store.state.auth, "profile.id", ""),
        text: "",
        files: [],
      },
    }) as CreateOrUpdate;

    // const committeeFolder = computed<any>(() => {
    //   return store.getters["document/getFolderByType"](
    //     props.ballot.committee_id,
    //     "ballot"
    //   );
    // });

    const dropzoneOptions = computed<DropzoneOptions>(() => {
      return {
        // acceptedFiles:
        //   "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        params: {
          committee_id: props.ballot.committee_id,
          resource_type: "ballot",
          resource_id: props.ballot.id,
          // folder_id: committeeFolder.value?.id || null,
          type: 610,
        },
      };
    });

    const onSubmit = () => submit();
    const openModal = () => {
      fetch(props.ballot.id);
      cash("#comment-modal").modal("show");
    };
    const closeModal = () => {
      cash("#comment-modal").modal("hide");
    };

    const successUploadFile = (document: GetDocument) => {
      formData.files.push({
        id: document.id,
        uuid: document.uuid,
        title: document.title,
        n_number: document.n_number,
        file_name: document.file_name,
        file_size: document.file_size,
      });
    };

    const removeUploadedFile = (uuid: string) => {
      const fileIndex = _.findIndex(formData.files, { uuid });
      if (fileIndex > -1) formData.files.splice(fileIndex, 1);
    };

    const manualAddDocuments = (documents: Record<string, any>[]) => {
      documents.map((document: Record<string, any>) => {
        const mockFile: Record<string, any> = {
          accepted: true,
          name: document.file_name || "",
          size: document.file_size,
          processing: true,
          status: "success",
          documentUuid: document.uuid,
          documentId: document.id,
        };
        dropzoneRef.value?.dropzone.files.push(mockFile as DropzoneFile);
        dropzoneRef.value?.dropzone.emit("addedfile", mockFile);
        dropzoneRef.value?.dropzone.emit("complete", mockFile);
      });
    };

    onMounted(() => {
      cash("#comment-modal").modal("on.hide", () => {
        formData.text = "";
        formData.files = [];
      });
    });

    onBeforeUnmount(() => {
      cash(refCommentModal.value || "#comment-modal").modal("hide");
    });

    return {
      loading,
      processing,
      formData,
      validationErrors,
      dropzoneOptions,
      refCommentModal,
      dropzoneRef,
      onSubmit,
      openModal,
      closeModal,
      successUploadFile,
      removeUploadedFile,
    };
  },
});
</script>
