<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <MessageCircleIcon
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">FEEDBACK LIST</div>
      <div class="text-white text-sm font-light">
        Feedback for SMIIC IS application
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex justify-end transform -mt-9 sm:-mt-16">
        <router-link
          :to="{ name: 'feedbacks.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        >
          Create Feedback
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-3">
          <table class="table w-full">
            <thead>
              <tr>
                <th>Name Surname</th>
                <th>Message</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in list.data" :key="'record-' + row.id">
                <td>
                  <div class="font-semibold">
                    {{ $h.formatNameSurname($_.get(row, "user")) }}
                  </div>
                  <div class="text-xs">
                    {{
                      $h.formatDate($_.get(row, "created_at"), "DD MMMM YYYY")
                    }}
                  </div>
                </td>
                <td>
                  <div>{{ $_.get(row, "text") }}</div>
                  <router-link
                    v-for="file in $_.get(row, 'files', [])"
                    :key="file"
                    :to="{
                      name: 'documents',
                      params: { uuid: $_.get(file, 'uuid', '') },
                    }"
                    target="_blank"
                    class="block text-xs mt-1 text-blue-500 hover:text-blue-600"
                    >{{ $_.get(file, "title", "") }}</router-link
                  >
                </td>
                <td class="text-right whitespace-nowrap">
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                    @click="() => triggerDelete([row.id])"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0)"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { feedbackService } from "@/services";

export default defineComponent({
  name: "Submissions",
  components: {
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "feedback",
        config: {
          "@page": 1,
          "@select": ["id", "text", "status", "user_id", "files", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          user: {
            "@select": ["id", "title", "salutation", "name", "surname"],
          },
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: feedbackService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
    };
  },
});
</script>
