<template>
  <div class="container p-8 h-screen flex items-center">
    <!-- BEGIN: Login Form -->
    <div class="xl:w-2/5 my-auto mx-auto">
      <form
        @submit.prevent="submit"
        class="bg-gray-100 px-10 pt-10 rounded-2xl w-full"
      >
        <img
          class="h-20 mx-auto mb-8"
          :src="require(`@/assets/images/logo.svg`)"
        />
        <VueInputGroup
          class="intro-x mb-4"
          :validation-errors="validationErrors.get('email', 'token')"
        >
          <VueInput
            type="email"
            class="login__input input--lg bg-white shadow-none"
            readonly
            disabled
            :value="formData.email"
            :validation-errors="validationErrors.get('email', 'token')"
          />
        </VueInputGroup>
        <VueInputGroup
          class="intro-x mb-4"
          :validation-errors="validationErrors.get('password')"
        >
          <VueInput
            type="password"
            class="login__input input--lg bg-white shadow-none"
            placeholder="Password"
            v-model="formData.password"
            :validation-errors="validationErrors.get('password')"
          />
        </VueInputGroup>
        <VueInputGroup
          class="intro-x mb-8"
          :validation-errors="validationErrors.get('password_confirmation')"
        >
          <VueInput
            type="password"
            class="login__input input--lg bg-white shadow-none"
            placeholder="Password Confirmation"
            v-model="formData.password_confirmation"
            :validation-errors="validationErrors.get('password_confirmation')"
          />
        </VueInputGroup>
        <VueButton
          :loading="loading"
          type="submit"
          class="intro-x button button--lg w-full text-white bg-theme-4 align-top shadow-xl"
        >
          <span class="mr-3">Reset Password</span>
          <RefreshCwIcon class="inline" />
        </VueButton>
        <div class="font-light leading-tight w-3/4 pb-6 pt-10">
          The Standards and Metrology Institute for Islamic Countries SMIIC is
          an affiliated institution to Organisation of Islamic Cooperation
          (OIC).
        </div>
      </form>
    </div>
    <!-- END: Login Form -->
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { ValidationErrors } from "@/utils/form";
import { useRoute, useRouter } from "vue-router";
import { authService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "ResetPassword",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const loading = ref<boolean>(false);
    const formData = reactive({
      email: _.get(route.query, "email", ""),
      password: "",
      password_confirmation: "",
      token: _.get(route.query, "token", ""),
    });

    const validationErrors = ref(new ValidationErrors());

    const submit = () => {
      validationErrors.value.clear();
      loading.value = true;
      authService
        .resetPassword(formData)
        .then((result) => {
          if (result.kind === "ok") {
            router.push({ name: "login" });
            ToastNotify({
              text: "Your password has been reset!",
              className: "success",
            });
          } else {
            if (result.kind === "validation")
              validationErrors.value.record(result.fields);
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      loading,
      formData,
      validationErrors,
      submit,
    };
  },
});
</script>
