
import { computed, defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import _ from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { organizationService } from "@/services";

export default defineComponent({
  name: "Organizations",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "organization",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "title",
            "acronym",
            "visible_on_website",
            "is_accreditation_body",
            "is_metrology_body",
            "is_standardization_body",
            "country_code",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          country: {
            "@select": ["id", "title", "code"],
          },
        },
        filterFormat: {
          title: "@where.q",
          acronym: "@where._acronym",
          country_code: "@where._country_code",
          visible_on_website: "@where.visible_on_website",
          is_accreditation_body: "@where.is_accreditation_body",
          is_metrology_body: "@where.is_metrology_body",
          is_standardization_body: "@where.is_standardization_body",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      acronym: "",
      country_code: "",
      visible_on_website: "",
      is_accreditation_body: "",
      is_metrology_body: "",
      is_standardization_body: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: organizationService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const filterQuickSearch = computed({
      get: () => filter.query.title,
      set: (value) => {
        filter.query.title = value;
      },
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "organizations.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "organizations.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      filterQuickSearch,
      countrySelect2Options,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToView,
      goToEdit,
    };
  },
});
