<template>
  <!-- BEGIN: Modal -->
  <div id="role-modal" class="modal">
    <div class="modal__content p-5 pt-10">
      <div class="text-2xl text-theme-1">
        {{ $h.capitalizeEachWords(roleType.title) }}
      </div>
      <div class="border-t h-0.5 my-6"></div>
      <VueInputGroup
        class="mb-4"
        v-if="resourceTitle"
        :validation-errors="validationErrors.get('scope_type', 'scope_id')"
      >
        <template v-slot:label>
          <label class="block mb-1">{{ resourceTitle }}</label>
        </template>
        <Select2Input
          class="shadow-none"
          v-model="formData.scope_id"
          :options="resourceSelect2Options"
          :validation-errors="validationErrors.get('scope_type', 'scope_id')"
        />
      </VueInputGroup>
      <VueInputGroup
        v-if="visibleFunctionInput"
        class="mb-4"
        :validation-errors="validationErrors.get('function')"
      >
        <template v-slot:label>
          <label class="block mb-1">
            {{
              formData.behaviour === "behave_as_chairman"
                ? "Chair Person Term"
                : "Functions And Title"
            }}
          </label>
        </template>
        <VueInput
          class="shadow-none"
          v-model="formData.function"
          :validation-errors="validationErrors.get('function')"
        />
      </VueInputGroup>
      <VueInputGroup
        class="mb-4"
        :validation-errors="validationErrors.get('information')"
      >
        <template v-slot:label>
          <label class="block mb-1">Information</label>
        </template>
        <VueTextarea
          class="shadow-none input--sm"
          v-model="formData.information"
          :validation-errors="validationErrors.get('information')"
        />
      </VueInputGroup>
      <VueInputGroup
        v-if="visibleBallotTypes"
        class="grid grid-cols-10 gap-x-4 gap-y-2 mb-4"
        :validation-errors="validationErrors.get('ballot_types')"
        label-class="col-span-full w-full"
      >
        <template v-slot:label>
          <label class="block">Ballot Types</label>
        </template>
        <div class="col-span-2 flex items-center">
          <input
            id="bt-ds"
            class="input input--lg border"
            type="checkbox"
            value="DS"
            v-model="formData.ballot_types"
          />
          <label for="bt-ds" class="ml-2">DS</label>
        </div>
        <div class="col-span-2 flex items-center">
          <input
            id="bt-fds"
            class="input input--lg border"
            type="checkbox"
            value="FDS"
            v-model="formData.ballot_types"
          />
          <label for="bt-fds" class="ml-2">FDS</label>
        </div>
        <div class="col-span-2 flex items-center">
          <input
            id="bt-cb"
            class="input input--lg border"
            type="checkbox"
            value="CB"
            v-model="formData.ballot_types"
          />
          <label for="bt-cb" class="ml-2">CB</label>
        </div>
        <div class="col-span-2 flex items-center">
          <input
            id="bt-sr"
            class="input input--lg border"
            type="checkbox"
            value="SR"
            v-model="formData.ballot_types"
          />
          <label for="bt-sr" class="ml-2">SR</label>
        </div>
        <div class="col-span-2 flex items-center" v-if="visibleVMBallotTypes">
          <input
            id="bt-vm"
            class="input input--lg border"
            type="checkbox"
            value="VM"
            v-model="formData.ballot_types"
          />
          <label for="bt-vm" class="ml-2">VM</label>
        </div>
      </VueInputGroup>
      <div class="flex justify-end">
        <VueButton
          :loading="processing"
          @click="onSubmit"
          class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white py-2.5 px-6 font-light"
          >Save Role
        </VueButton>
      </div>
    </div>
  </div>
  <!-- END: Modal -->
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import {
  GetRole,
  GetRoleResults,
  GetRoleType,
  PostRole,
  roleService,
} from "@/services";
import { useGeneralActions } from "@/utils/actions";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostRole;
}

export default defineComponent({
  name: "UserRoleCreateOrUpdate",
  components: { VueInput, VueInputGroup },
  props: {
    definedRoles: {
      type: Array as () => GetRole[],
      default: () => [],
    },
    userId: {
      type: Number,
      default: () => 0,
    },
    administratedById: {
      type: Number,
      default: () => 0,
    },
  },
  setup(props, { emit }) {
    const { createOrUpdate } = useGeneralActions();
    const roleId = ref<number>(0);
    const roleType = ref<Record<string, any>>({});

    const {
      processing,
      formData,
      validationErrors,
      submit,
      setFormData,
    } = createOrUpdate({
      createApiMethod(params: Record<string, any>): Promise<any> {
        return roleService.store(props.userId, params);
      },
      updateApiMethod(id: any, params: Record<string, any>): Promise<any> {
        return roleService.update(props.userId, id, params);
      },
      successCallback(result: GetRoleResults) {
        if (result.kind === "ok") {
          emit("update:defined-roles", result.data);
          cash("#role-modal").modal("hide");
        }
      },
      params: {
        behaviour: "",
        scope_type: "",
        scope_id: null,
        function: "",
        information: "",
        ballot_types: [],
      } as PostRole,
    }) as CreateOrUpdate;

    const visibleFunctionInput = computed<boolean>(() => {
      return (
        [
          "behave_as_employer",
          "behave_as_user_admin",
          "behave_as_chairman",
        ].indexOf(formData.behaviour) > -1
      );
    });

    const visibleVMBallotTypes = computed<boolean>(() => {
      return (
        ["behave_as_c_ballot_monitor", "behave_as_c_voter"].indexOf(
          formData.behaviour
        ) > -1
      );
    });

    const visibleBallotTypes = computed<boolean>(() => {
      return (
        [
          "behave_as_ballot_monitor",
          "behave_as_voter",
          "behave_as_c_ballot_monitor",
          "behave_as_c_voter",
        ].indexOf(formData.behaviour) > -1
      );
    });

    const resourceTitle = computed<string>(() => {
      switch (formData.scope_type) {
        case "project":
          return "Project";
        case "committee":
          return "Committee";
        default:
          return "";
      }
    });

    const committeeTemplateSelection = (obj: any): string => {
      if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
      return obj.text;
    };

    const resourceSelect2Options = computed(() => {
      if (formData.scope_type === "committee") {
        const where = {};
        if (
          [
            "behave_as_secretary",
            "behave_as_sst",
          ].indexOf(formData.behaviour) > -1 &&
          props.administratedById
        ) {
          _.set(where, "secretariat_member_id", props.administratedById);
        }
        if (
          [
            "behave_as_convenor",
            "behave_as_wgmember",
          ].indexOf(formData.behaviour) > -1
        ) {
          _.set(where, "type", "WG");
        }
        return {
          ajaxQuery: {
            committee: {
              "@pagination": 15,
              "@select": {
                value: "id",
                text: "title",
                acronym: "acronym",
              },
              "@order": [
                "level_1_type:asc",
                "level_1_number:asc",
                "level_2_type:asc",
                "level_2_number:asc",
                "level_3_number:asc",
              ],
              "@where": where,
            },
          },
          templateSelection: committeeTemplateSelection,
        };
      }
      return {
        ajaxQuery: {
          project: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "reference",
            },
            "@order": "created_at:desc",
          },
        },
      };
    });

    const onOpen = (type: GetRoleType, role?: GetRole) => {
      roleType.value = type;
      roleId.value = _.get(role, "id", 0) * 1;
      setFormData({
        behaviour: _.get(role, "behaviour", type.behaviour),
        scope_type: _.get(role, "scope_type", type.scope),
        scope_id: _.get(role, "scope_id", null),
        function: _.get(role, "meta.function", ""),
        information: _.get(role, "meta.information", ""),
        ballot_types: _.get(role, "ballot_types", []),
      });
      cash("#role-modal").modal("show");
    };

    const onSubmit = () => submit(roleId.value || null);

    return {
      formData,
      validationErrors,
      processing,
      roleType,
      visibleFunctionInput,
      visibleVMBallotTypes,
      visibleBallotTypes,
      resourceTitle,
      resourceSelect2Options,
      onOpen,
      onSubmit,
    };
  },
});
</script>
