<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <VueInputGroup :validation-errors="validationErrors.get('text')">
        <template v-slot:label>
          <label class="block mb-1">Message</label>
        </template>
        <VueTextarea
          placeholder="Enter your message"
          v-model="formData.text"
          :validation-errors="validationErrors.get('text')"
        />
      </VueInputGroup>
      <VueInputGroup :validation-errors="validationErrors.get('files')">
        <div
          class="bg-gray-100 rounded-lg shadow-lg"
          :class="{
            'bg-red-100': !!validationErrors.get(
              'files',
              'files.0',
              'files.1',
              'files.2'
            ),
          }"
        >
          <Dropzone
            v-if="committeeId"
            refKey="dropzoneRef"
            class="dropzone__document mt-4 rounded-lg"
            :options="dropzoneOptions"
            :success="successUploadFile"
            :remove="removeUploadedFile"
            :uploading="() => (uploading = true)"
            :completed="() => (uploading = false)"
          />
        </div>
      </VueInputGroup>
      <div class="flex justify-end mt-5">
        <VueButton
          :loading="processing"
          :disabled="uploading"
          @click="onSubmit"
          class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
          >Save Feedback
        </VueButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, provide, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { feedbackService, GetDocument, PostFeedback } from "@/services";
import { DropzoneOptions } from "dropzone";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { useStore } from "vuex";
import Dropzone from "@/global-components/dropzone/Main.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostFeedback;
}

export default defineComponent({
  name: "CreateFeedback",
  components: { Dropzone },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const dropzoneRef = ref<HTMLElement>();
    provide("bind[dropzoneRef]", (el: HTMLElement) => {
      dropzoneRef.value = el;
    });

    const uploading = ref<boolean>(false);
    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT FEEDBACK (#${id.value})`;
      return "CREATE FEEDBACK";
    });

    const { createOrUpdate } = useGeneralActions();

    const {
      processing,
      formData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: feedbackService.store,
      successCallback() {
        router.go(-1);
      },
      params: {
        status: null,
        text: "",
        files: [],
      },
    }) as CreateOrUpdate;

    const committeeId = computed<any>(
      () => store.getters["committee/smiicCommittee"]?.id || null
    );
    const dropzoneOptions = computed<DropzoneOptions>(() => {
      return {
        params: {
          committee_id: committeeId.value,
          type: 103,
        },
      };
    });

    const successUploadFile = (document: GetDocument) => {
      formData.files.push({
        id: document.id,
        uuid: document.uuid,
        title: document.title,
        n_number: document.n_number,
        file_name: document.file_name,
        file_size: document.file_size,
      });
    };

    const removeUploadedFile = (uuid: string) => {
      const fileIndex = _.findIndex(formData.files, { uuid });
      if (fileIndex > -1) formData.files.splice(fileIndex, 1);
    };

    const onSubmit = () => {
      if (uploading.value) return;
      submit(id.value);
    };

    return {
      uploading,
      committeeId,
      headerTitle,
      processing,
      formData,
      validationErrors,
      dropzoneOptions,
      successUploadFile,
      removeUploadedFile,
      onSubmit,
    };
  },
});
</script>
