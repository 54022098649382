<template>
  <div class="accordion__pane py-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle relative font-medium text-2xl text-gray-600 block pr-24 whitespace-nowrap overflow-hidden overflow-ellipsis"
    >
      Reference Documents And Links ({{ filesCount }})
      <Tippy
        tag="button"
        content="Add Document"
        :options="{ placement: 'bottom' }"
        class="button p-0 absolute right-4"
        @click.stop.prevent="() => goToDocumentCreate()"
        v-if="permissions.canAddDocument"
      >
        <FilePlusIcon class="w-8 h-8" />
      </Tippy>
      <Tippy
        tag="button"
        content="Add Document Reference Link"
        :options="{ placement: 'bottom' }"
        class="button p-0 absolute right-14"
        @click.stop.prevent="() => onAddDocumentReference()"
        v-if="permissions.canAddReferenceDocument"
      >
        <LinkIcon class="w-8 h-8" />
      </Tippy>
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <BallotReferenceLinks
        :can-edit="permissions.canAddReferenceDocument"
        :ballot-id="ballotId"
        :ballot="ballot"
        @update:ballot="(val) => $emit('update:ballot', val)"
      />
      <div v-if="list.data.length > 0" class="my-6">
        <div class="section-divider mb-3">Reference Documents</div>
        <div class="overflow-x-auto text-xs">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center w-1">N Number</th>
                <th class="whitespace-nowrap">Type</th>
                <th class="whitespace-nowrap">File</th>
                <th class="whitespace-nowrap text-center">Status</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="
                  deleteProcessing && transactionIds.indexOf(row.id) > -1
                "
                :waiting="transactionIds[0] !== row.id"
              >
                <td class="text-center text-lg">
                  <span class="font-semibold">{{ row.n_number }}</span>
                  <span
                    class="ml-1 text-xs"
                    v-if="row.language !== 'english'"
                    >{{ $h.convertIdToText(row.language, "langAbbr") }}</span
                  >
                </td>
                <td>
                  {{ $store.getters["document/documentTypeTitle"](row.type) }}
                </td>
                <td>
                  <router-link
                    :to="{ name: 'documents', params: { uuid: row.uuid } }"
                    class="text-blue-500 hover:text-blue-700"
                  >
                    {{ row.title }}
                  </router-link>
                </td>
                <td
                  class="text-center"
                  :class="{
                    'text-red-500 font-semibold': row.status !== 'published',
                  }"
                >
                  {{
                    $h.convertIdToText(
                      $_.get(row, "status", ""),
                      "documentStatus"
                    )
                  }}
                </td>
                <td class="whitespace-nowrap text-right">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToView(row.uuid)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => triggerDelete([row.uuid])"
                    v-if="
                      permissions.canAddDocument &&
                      $_.get(row, 'abilities.can_delete', false)
                    "
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </div>
      <div
        v-if="ballot.files.length === 0 && list.data.length === 0"
        class="text-lg text-gray-500"
      >
        No documents found for this ballot!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { DOCUMENT_TYPES, documentService, GetBallot } from "@/services";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";
import BallotReferenceLinks from "@/views/ballots/view/documents/ReferenceDocument.vue";

export default defineComponent({
  name: "BallotDocuments",
  components: { BallotReferenceLinks, Pagination, TransactionStatement },
  props: {
    permissions: {
      type: Object,
      default: () => ({}),
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props) {
    const router = useRouter();

    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "document",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "uuid",
            "n_number",
            "version",
            "title",
            "version",
            "file_extension",
            "file_path",
            "file_size",
            "language",
            "type",
            "created_at",
            "status",
          ],
          "@where": {
            resource_type: "ballot",
            resource_id: props.ballotId,
          },
          "@whereNot": {
            type: DOCUMENT_TYPES.BALLOT_COMMENTS,
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: documentService.delete,
      successCallback(uuid) {
        if (uuid) {
          const index = _.findIndex(list.data, { uuid });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const filesCount = computed(() => {
      return (
        _.get(list.meta, "total", 0) * 1 +
        _.get(props.ballot, "files", []).length
      );
    });

    const goToDocumentCreate = () => {
      router.push({
        name: "documents.create",
        params: {
          committeeId: props.ballot.committee_id,
        },
        query: {
          resource_type: "ballot",
          resource_id: props.ballotId as any,
        },
      });
    };

    const goToView = (uuid: any) => {
      router.push({ name: "documents", params: { uuid } });
    };

    const onAddDocumentReference = () => {
      cash("#reference-document-modal").modal("show");
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      filesCount,
      changePage,
      changePerPage,
      goToDocumentCreate,
      onAddDocumentReference,
      goToView,
    };
  },
});
</script>
