
import { defineComponent, onMounted, ref } from "vue";
import { emailService, GetDocument } from "@/services";

export default defineComponent({
  name: "EmailView",
  props: {
    document: {
      type: Object as () => GetDocument,
      required: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const previewUrl = ref<string>("");
    const fetch = () => {
      loading.value = true;
      emailService
        .preview(_.get(props.document, "committee_id"), {
          subject: _.get(props.document, "title"),
          body: _.get(props.document, "note"),
          files: _.get(props.document, "files"),
          users_full_list: false
        })
        .then((result) => {
          if (result.kind === "ok") {
            previewUrl.value = URL.createObjectURL(result.data);
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => fetch());

    return {
      loading,
      previewUrl,
    };
  },
});
