
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee } from "@/services";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "CommitteeProjects",
  components: { Pagination },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const isWg = computed(() => {
      return _.get(props, "committee.type") === "WG";
    });

    const { fetch, loading, list } = fetchRecords({
      selectionQuery: () => {
        const where: any = {};
        if (isWg.value)
          _.set(
            where,
            "wg_id",
            _.get(props, "committee.id", props.committeeId)
          );
        else
          _.set(
            where,
            "committee_id",
            _.get(props, "committee.id", props.committeeId)
          );
        return {
          source: "project",
          config: {
            "@paginate": 5,
            "@page": 1,
            "@select": ["id", "committee_id", "reference", "title_en", "stage"],
            current_line: {
              "@select": [
                "id",
                "project_id",
                "start_date",
                "target_date",
                "stage",
                "version",
              ],
            },
            "@where": where,
            "@func": ["withCount"],
            "@order": "created_at:desc",
          },
        };
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      isWg,
      loading,
      list,
      filter,
      changePage,
      changePerPage,
    };
  },
});
