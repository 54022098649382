import { createStore } from "vuex";
import {
  sideMenu,
  Store as SideMenuStore,
  State as SideMenuState,
} from "./side-menu";

import { main, Store as MainStore, State as MainState } from "./main";
import { auth, Store as AuthStore, State as AuthState } from "./auth";
import {
  project,
  Store as ProjectStore,
  State as ProjectState,
} from "./project";
import { ballot, Store as BallotStore, State as BallotState } from "./ballot";
import {
  committee,
  Store as CommitteeStore,
  State as CommitteeState,
} from "./committee";
import {
  document,
  Store as DocumentStore,
  State as DocumentState,
} from "./document";
import { user, Store as UserStore, State as UserState } from "./user";

export type State = {
  main: MainState;
  auth: AuthState;
  sideMenu: SideMenuState;
  project: ProjectState;
  ballot: BallotState;
  committee: CommitteeState;
  document: DocumentState;
  user: UserState;
};

export type Store = MainStore<Pick<State, "main">> &
  AuthStore<Pick<State, "auth">> &
  SideMenuStore<Pick<State, "sideMenu">> &
  ProjectStore<Pick<State, "project">> &
  BallotStore<Pick<State, "ballot">> &
  CommitteeStore<Pick<State, "committee">> &
  DocumentStore<Pick<State, "document">> &
  UserStore<Pick<State, "user">>;

export const store = createStore({
  modules: {
    main,
    auth,
    sideMenu,
    project,
    ballot,
    committee,
    document,
    user,
  },
});

export function useStore(): Store {
  return store as Store;
}

export default store;
