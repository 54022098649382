<template>
  <div>
    <div class="text-center">
      <VueButton
        class="button bg-gradient-to-b from-red-400 to-red-500 text-white relative pr-16 whitespace-nowrap mr-2"
        @click="close"
      >
        Close Editing
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-red-800 to-red-900 text-white rounded-md py-2.5 px-3"
        >
          <XIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
      >
        Save Changes
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
    <div class="grid grid-cols-4 gap-4 py-6 items-start">
      <div class="col-span-full section-divider">English Information</div>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('english_title')"
      >
        <template v-slot:label>
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Title
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.english_title"
            :validation-errors="validationErrors.get('english_title')"
            placeholder="Enter english title"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('note')"
      >
        <template v-slot:label>
          <div
            class="col-span-full sm:col-span-1 h-full flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Note
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueTextarea
            class="bg-white m-0 shadow-none"
            v-model="formData.note"
            :validation-errors="validationErrors.get('note')"
            placeholder="Enter english description"
          />
        </div>
      </VueInputGroup>
      <div class="col-span-full section-divider">Other Information</div>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('reference')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('reference') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Reference
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.reference"
            :validation-errors="validationErrors.get('reference')"
            placeholder="Enter reference"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('start_date')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('start_date') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Start Date
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <Litepicker
            key="ballot-start-date"
            class="bg-white m-0 shadow-none"
            v-model="formData.start_date"
            :validation-errors="validationErrors.get('start_date')"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('end_date')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('end_date') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              End Date
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <Litepicker
            key="ballot-end-date"
            class="bg-white m-0 shadow-none"
            v-model="formData.end_date"
            :validation-errors="validationErrors.get('end_date')"
          />
        </div>
      </VueInputGroup>
    </div>
    <div class="text-center">
      <VueButton
        class="button bg-gradient-to-b from-red-400 to-red-500 text-white relative pr-16 whitespace-nowrap mr-2"
        @click="close"
      >
        Close Editing
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-red-800 to-red-900 text-white rounded-md py-2.5 px-3"
        >
          <XIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
      >
        Save Changes
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watch } from "vue";
import { GetBallot } from "@/services";
import Litepicker from "@/global-components/litepicker/Main.vue";
import { saveInit } from "@/views/ballots/view/save";
import dayjs from "dayjs";
import VueTextarea from "@/global-components/form-elements/textarea/Main.vue";

export default defineComponent({
  name: "BallotEditInformation",
  components: { VueTextarea, Litepicker },
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    close: {
      type: Function,
      default: () => {
        console.log("close");
      },
    },
  },
  setup(props, { emit }) {
    const { processing, formData, validationErrors, onSubmit } = saveInit(
      props,
      emit
    );

    watch(
      () => formData.start_date,
      (val) => {
        let addMount = 0;
        if (props.ballot.ballot_type === "DS") addMount = 3;
        if (props.ballot.ballot_type === "FDS") addMount = 2;
        if (addMount > 0) {
          formData.end_date = dayjs(val)
            .add(dayjs.duration({ months: addMount }))
            .format("YYYY-MM-DD");
        }
      }
    );

    return {
      processing,
      formData,
      validationErrors,
      onSubmit,
    };
  },
});
</script>
