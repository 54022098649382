import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { GetStageType } from "@/services";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_PROJECT_TYPES](
    { commit }: AugmentedActionContext,
    types: GetStageType[]
  ): void;
  [ActionTypes.SET_PROJECT_INITIAL_TYPES](
    { commit }: AugmentedActionContext,
    types: GetStageType[]
  ): void;
  [ActionTypes.SET_MANUAL_STAGES](
    { commit }: AugmentedActionContext,
    types: any[]
  ): void;
  [ActionTypes.SET_BALLOT_STAGES](
    { commit }: AugmentedActionContext,
    types: any[]
  ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_PROJECT_TYPES]({ commit }, types: GetStageType[]) {
    commit(MutationTypes.SET_PROJECT_TYPES, types);
  },
  [ActionTypes.SET_PROJECT_INITIAL_TYPES]({ commit }, types: GetStageType[]) {
    commit(MutationTypes.SET_PROJECT_INITIAL_TYPES, types);
  },
  [ActionTypes.SET_MANUAL_STAGES]({ commit }, stages: any[]) {
    commit(MutationTypes.SET_MANUAL_STAGES, stages);
  },
  [ActionTypes.SET_BALLOT_STAGES]({ commit }, stages: any[]) {
    commit(MutationTypes.SET_BALLOT_STAGES, stages);
  },
};
