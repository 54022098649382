
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  provide,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper, helper as $h } from "@/utils/helper";
import MobileMenu from "@/components/mobile-menu/Main.vue";
import SideMenuTooltip from "@/components/side-menu-tooltip/Main.vue";
import { linkTo, nestedMenu, enter, leave } from "./index";
import Confirm from "@/components/confirm/Main.vue";
import IframeViewer from "@/components/iframe-viewer/Main.vue";
import { ConfirmRef } from "@/components/confirm";
import md5 from "blueimp-md5";
import EmailToMembers from "@/components/email-to-members/Main.vue";
import { SendEmailRef } from "@/components/email-to-members";
import { NODE_ENV } from "@/services";

export default defineComponent({
  components: {
    EmailToMembers,
    Confirm,
    // TopBar,
    MobileMenu,
    SideMenuTooltip,
    IframeViewer,
  },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("login")
        .removeClass("error-page")
        .addClass("app");
    });

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const contentHTML = ref<HTMLElement>();
    const sideNavHTML = ref<HTMLElement>();
    const navbarHTML = ref<HTMLElement>();

    const confirmRef = ref<ConfirmRef>();
    provide("confirmRef", confirmRef);

    const sendEmailRef = ref<SendEmailRef>();
    provide("sendEmailRef", sendEmailRef);

    const profile = store.getters["auth/profile"];

    const nameSurname = computed(() => {
      return helper.formatNameSurname(profile)
      // return `${profile?.salutation} ${profile?.name} ${profile?.surname}`;
    });

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    );

    const isTestSystem = computed(() => NODE_ENV === "staging");

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value);
      }
    );

    onMounted(() => {
      cash("body")
        .removeClass("error-page")
        .removeClass("login")
        .addClass("app");
      formattedMenu.value = $h.toRaw(sideMenu.value);
      window.addEventListener("resize", handleWindowResize);
      handleWindowResize();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", handleWindowResize);
    });

    const handleWindowResize = () => {
      if (contentHTML.value) {
        if (navbarHTML.value) {
          contentHTML.value.style.top = `${
            navbarHTML.value.offsetTop + navbarHTML.value.offsetHeight
          }px`;
        }
        if (sideNavHTML.value) {
          contentHTML.value.style.left = `${
            sideNavHTML.value.offsetLeft + sideNavHTML.value.offsetWidth + 4
          }px`;
        }
      }
    };

    watch(() => store.state.main.simpleMenu, handleWindowResize, {
      flush: "post",
    });

    return {
      contentHTML,
      navbarHTML,
      sideNavHTML,
      nameSurname,
      gravatar,
      confirmRef,
      sendEmailRef,
      formattedMenu,
      router,
      profile,
      isTestSystem,
      linkTo,
      enter,
      leave,
    };
  },
});
