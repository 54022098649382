<template>
  <div class="section-divider mb-4">Answers</div>
  <div v-for="(row) in rows" :key="'table-' + row.uuid" class="mb-8">
    <div class="overflow-auto">
      <table class="min-w-full border border-gray-300 text-xs">
        <thead>
          <th
            class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-left font-semibold"
            colspan="2"
          >
            Answers To {{ row.sq }}: "{{ row.text }}"
          </th>
        </thead>
        <tbody>
          <tr v-for="answer in row.answers" :key="`table-vote-${answer.uuid}`">
            <td
              class="border-b border-r border-gray-300 px-3 py-1.5 text-left w-1/6"
            >
              <div class="flex items-center">
                <div class="whitespace-nowrap font-semibold">
                  {{ $_.get(answer, "members", []).length }} X
                </div>
                <div class="ml-3">{{ answer.text }}</div>
              </div>
            </td>
            <td
              class="border-b border-r border-gray-300 px-3 py-1.5 font-normal"
            >
              <span
                v-for="(member, memberIndex) in answer.members"
                :key="`${answer.uuid}-${member.id}`"
              >
                <span class="inline-block mr-2" v-if="memberIndex > 0">, </span
                >{{ $_.get(member, "country_title", "") }} ({{
                  $_.get(member, "acronym", "")
                }})</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AnswersAllVotes",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
