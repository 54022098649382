<template>
  <div class="w-full">
    <slot name="label-container">
      <div
        :class="{ 'text-red-400': !!validationErrors, [labelClass]: true }"
        v-if="$slots.label"
      >
        <slot name="label"></slot>
      </div>
    </slot>
    <slot></slot>
    <div class="col-span-full w-full mt-2 pl-2" v-if="validationErrors">
      <div
        v-for="(error, index) in validationErrors"
        :key="index"
        class="text-red-300 mt-1 text-xs"
      >
        {{ $_.get(error, "$message", error) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueInputGroup",
  props: {
    validationErrors: {
      type: Array,
      default: () => null,
    },
    labelClass: {
      type: String,
      default: () => "",
    },
  },
});
</script>
