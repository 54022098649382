
import { defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { emailBlacklistService } from "@/services";

export default defineComponent({
  name: "EmailBlacklist",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "blacklist",
        config: {
          "@page": 1,
          "@select": ["id", "email", "note", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
        filterFormat: {
          email: "@where._email",
          to: "@whereDateRange.created_at.to",
          from: "@whereDateRange.created_at.from",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      email: "",
      to: "",
      from: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: emailBlacklistService.delete,
      successCallback(email) {
        if (email) {
          const index = _.findIndex(list.data, { email });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
    };
  },
});
