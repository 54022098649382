import { get, set, forEach, cloneDeep } from "lodash";
import * as Select2 from "select2";
import { computed, watch } from "vue";
import { fetchSelection } from "@/services";

interface Select2Options {
  withoutQueue?: boolean;
  ajaxQuery?: any;
  selectFirst?: boolean;
  templateResult?(obj: any): any;
  templateSelection?(obj: any): any;
}

interface Props {
  options: Select2Options;
  modelValue: string;
}

const fetchOne = (
  el: HTMLElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
) => {
  const { ajaxQuery, withoutQueue = false, ...otherOptions } = props.options;
  fetchSelection(
    prepareQuery(ajaxQuery, null, props.modelValue, false),
    withoutQueue
  ).then((result) => {
    if (result.kind === "ok") {
      let found: any = null;
      forEach(result.data, (item) => {
        if (found === null && item) found = item;
      });
      const id = get(found, "value", get(found, "id", ""));
      if (id) {
        const newOption = new Option(
          otherOptions.templateSelection
            ? otherOptions.templateSelection(found)
            : get(found, "text", ""),
          id,
          false,
          false
        );
        jQuery(el).append(newOption);
        setValue(el, { ...props, modelValue: id }, emit, found);
      } else {
        setValue(el, { ...props, modelValue: "" }, emit);
      }
    }
  });
};

const prepareQuery = (
  ajaxQuery: any,
  q: any,
  value: any,
  firstOrPage: number | boolean = 1
) => {
  const query = cloneDeep(ajaxQuery);
  forEach(query, (item) => {
    if (typeof item["@where"] === "undefined") set(item, "@where", {});
    if (q) set(item["@where"], "q", q);
    if (value) {
      let columnKey = "id";
      if (typeof item["@select"].value !== "undefined")
        columnKey = item["@select"].value;
      set(item["@where"], columnKey, value);
      set(item, "@first", true);
    } else if (firstOrPage === false) {
      set(item, "@first", true);
    } else {
      set(item, "@page", firstOrPage);
    }
  });
  return query;
};

const setValue = (
  el: HTMLElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void,
  ctx?: Record<string, any>
): void => {
  jQuery(el).val(props.modelValue).trigger("change");
  emit("update:modelValue", props.modelValue);
  emit("update:selected-item", ctx || null);
};

const init = (
  el: HTMLElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  const {
    ajaxQuery,
    selectFirst = false,
    withoutQueue = false,
    ...otherOptions
  } = props.options;
  const options: Select2.Options = {
    width: "100%",
    data: [],
    allowClear: true,
    multiple: false,
    minimumInputLength: 0,
    maximumSelectionLength: 0,
    minimumResultsForSearch: 0,
    tokenSeparators: [","],
    placeholder: "Select one of list",
    escapeMarkup: (markup: any) => {
      return markup;
    },
    ...otherOptions,
  };

  if (otherOptions.templateSelection && !otherOptions.templateResult)
    options.templateResult = otherOptions.templateSelection;
  if (otherOptions.templateResult && !otherOptions.templateSelection)
    options.templateSelection = otherOptions.templateResult;

  if (ajaxQuery) {
    options.ajax = {
      delay: 500,
      processResults: (rsp = []) => {
        const data: any[] = [];
        let more = false;
        forEach(rsp, (item: any) => {
          get(item, "data", []).map((dataItem: any) => {
            const obj = {
              id: get(dataItem, "value", get(dataItem, "id", "")),
              ...dataItem,
              value: get(dataItem, "value", get(dataItem, "id", "")),
              text: get(dataItem, "text", ""),
              disabled: false,
            };
            data.push(obj);
          });
          if (!more)
            more = get(item, "data", []).length === get(item, "per_page", 15);
        });
        return {
          results: data,
          pagination: {
            more,
          },
        };
      },
      transport: (params, success, failure): void => {
        fetchSelection(
          prepareQuery(
            ajaxQuery,
            get(params, "data.q", ""),
            null,
            get(params, "data.page", 1)
          ),
          withoutQueue
        )
          .then((result) => {
            if (result.kind === "ok" && success) {
              success(result.data);
            }
          })
          .catch(() => {
            if (failure) {
              failure();
            }
          });
      },
    };
    if (props.modelValue || selectFirst) {
      fetchOne(el, props, emit);
    }
  }
  jQuery(el)
    .select2(options)
    .on("select2:select", (e: any) => {
      const { selected = true, ...ctx } = e.params.data;
      const newValue = selected ? ctx.value : "";
      if (newValue !== props.modelValue) {
        setValue(el, { ...props, modelValue: newValue }, emit, ctx);
      }
    })
    .on("select2:unselect", () => {
      setValue(el, { ...props, modelValue: "" }, emit);
    });

  watch(
    computed(() => props.modelValue),
    (val) => {
      const watchValue = val || "";
      if (watchValue.toString() !== (jQuery(el).val() || "").toString()) {
        if (watchValue && ajaxQuery) {
          fetchOne(el, props, emit);
        } else setValue(el, { ...props, modelValue: "" }, emit);
      }
    }
  );

  watch(
    computed(() => props.options),
    (val) => {
      reInit(el, { ...props, options: val }, emit);
    },
    { deep: true }
  );
};

const destroy = (el: HTMLElement): void => {
  jQuery(el).select2().off("select2:select").off("select2:select");
  jQuery(el).select2("destroy");
};

const reInit = (
  el: HTMLElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  destroy(el);
  init(el, props, emit);
};

export { setValue, init, reInit, destroy, Select2Options };
