
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { PostMeeting, meetingService } from "@/services";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { helper } from "@/utils/helper";
import VueTiptap from "@/components/tiptap/Main.vue";
import { useStore } from "@/store";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostMeeting;
}

export default defineComponent({
  name: "CreateMeeting",
  components: { VueTiptap },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const state = reactive<Record<string, any>>({
      nextMeetingNo: "",
    });


    const onChangeCommittee = (committee: any) => {
      fetchNextNumber(committee);
    };


    const fetchNextNumber = (committee: any) => {
      meetingService
        .nextMeetingNumber({committee_id:committee.id})
        .then((result) => {
          state.nextMeetingNo = result.meeting_no
          if (!id.value && state.nextMeetingNo) formData.meeting_no = result.meeting_no
        })
    };


    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT MEETING (#${id.value})`;
      return "CREATE MEETING";
    });

    const times = computed<string[]>(() => {
      const values = [];
      for (let i = 0; i < 24; i++) {
        const hour = i < 10 ? `0${i}` : i;
        for (let j = 0; j < 2; j++) {
          let minute: any = j * 30;
          minute = minute < 10 ? `0${minute}` : minute;
          values.push(`${hour}:${minute}`);
        }
      }
      return values;
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            acronym: "acronym",
          },
          "@where": {
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              "can_create_meeting"
            ),
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
        return obj.text;
      },
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: meetingService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        const canEdit = _.get(data.value, "abilities.can_update", false);
        if (canEdit) {
          setFormData({
            ...data.value,
            time: helper.formatDate(data.value.time, "YYYY-MM-DD HH:mm"),
          });
        } else {
          router.go(-1);
        }
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: meetingService.store,
      updateApiMethod: meetingService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        title: "",
        committee_id: _.get(route.query, "committee_id", "") || "",
        information: "",
        meeting_no: "",
        city: "",
        country_code: "",
        time: helper.formatDate(new Date(), "YYYY-MM-DD HH") + ":00",
        exact_date: "0",
        status: "",
        type: "offline",
      },
    }) as CreateOrUpdate;

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const meetingDate = computed<string>({
      get: () => {
        return formData.time.split(" ")[0] || "";
      },
      set: (val) => {
        formData.time = `${val} ${meetingTime.value}`;
      },
    });

    const meetingTime = computed<string>({
      get: () => {
        return formData.time.split(" ")[1] || "00:00";
      },
      set: (val) => {
        formData.time = `${meetingDate.value} ${val}`;
      },
    });

    const meetingHour = computed<string>({
      get: () => {
        const splitArr = formData.time.split(" ") || [];
        const time = splitArr[1] || "";
        return time.split(":")[0] || "";
      },
      set: (val) => {
        formData.time = `${meetingDate.value} ${val}:${meetingMinute.value}`;
      },
    });

    const meetingMinute = computed<string>({
      get: () => {
        const splitArr = formData.time.split(" ") || [];
        const time = splitArr[1] || "";
        return time.split(":")[1] || "";
      },
      set: (val) => {
        formData.time = `${meetingDate.value} ${meetingHour.value}:${val}`;
      },
    });

    const onSubmit = () => submit(id.value);

    return {
      state,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      committeeSelect2Options,
      countrySelect2Options,
      meetingDate,
      meetingHour,
      meetingMinute,
      times,
      meetingTime,
      meeting: data,
      onSubmit,
      onChangeCommittee,
    };
  },
});
