<template>
  <div>
    <div class="text-center mb-6">
      <VueButton
        class="button py-1.5 bg-gradient-to-b from-gray-500 to-gray-600 text-white relative pr-16 whitespace-nowrap mr-3"
        @click="addQuestion"
      >
        Add Question
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-gray-700 to-gray-800 text-white rounded-md py-1.5 px-3"
        >
          <PlusSquareIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        class="button py-1.5 bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
      >
        Save Changes
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-1.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
    <div
      class="bg-gray-100 p-4 rounded-md mb-0.5 border-none"
      v-for="(question, questionIndex) in formData.questions"
      :key="question.uuid"
    >
      <EditQuestionItem
        :question="question"
        :question-index="questionIndex"
        :delete-question="() => deleteQuestion(questionIndex)"
      />
    </div>
    <div class="text-center mt-6">
      <VueButton
        class="button py-1.5 bg-gradient-to-b from-gray-500 to-gray-600 text-white relative pr-16 whitespace-nowrap mr-3"
        @click="addQuestion"
      >
        Add Question
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-gray-700 to-gray-800 text-white rounded-md py-1.5 px-3"
        >
          <PlusSquareIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        class="button py-1.5 bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
      >
        Save Changes
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-1.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, watch } from "vue";
import { GetBallot } from "@/services";
import { helper } from "@/utils/helper";
import { saveInit } from "../save";
import EditQuestionItem from "@/views/ballots/view/questions/EditQuestionItem.vue";

export default defineComponent({
  name: "BallotEditQuestion",
  components: { EditQuestionItem },
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    close: {
      type: Function,
      default: () => {
        console.log("close");
      },
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      show: true,
    });
    const addQuestion = () => {
      formData.questions.push({
        uuid: helper.uuidv4(),
        question: "",
        answers: [
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
        ],
      });
    };
    const deleteQuestion = (index: number) => {
      formData.questions.splice(index, 1);
    };

    const { processing, formData, validationErrors, onSubmit } = saveInit(
      props,
      emit
    );

    return {
      processing,
      state,
      formData,
      validationErrors,
      onSubmit,
      addQuestion,
      deleteQuestion,
    };
  },
});
</script>
