import {
  GeneralApiResponse,
  GetMemberResult,
  GetMemberResults,
  useAxios,
} from "@/services";

export * from "./api.types";

export const memberService = {
  fetch: async (): Promise<GetMemberResults> =>
    useAxios().fetch(`/api/members`, {}),

  show: async (id: unknown): Promise<GetMemberResult> =>
    useAxios().get(`/api/members/${id}`),

  store: async (params: Record<string, any>): Promise<GetMemberResult> =>
    useAxios().store(`/api/members`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetMemberResult> =>
    useAxios().update(`/api/members/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/members/${id}`),
};
