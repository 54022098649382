<template>
  <div>
    <div class="flex">
      <div class="font-semibold">Q.{{ questionIndex + 1 }})</div>
      <VueInputGroup class="ml-3 flex-1">
        <VueTextarea
          class="bg-white input--sm shadow-none"
          placeholder="Write your question here"
          v-model="editableQuestion.question"
        />
      </VueInputGroup>
    </div>
    <div class="text-xs text-right flex items-center justify-end mt-1.5">
      <a href="javascript:;" @click="addAnswer">Add Answer</a>
      <a href="javascript:;" class="text-red-500 ml-3" @click="deleteQuestion"
        >Delete Question</a
      >
    </div>
    <div class="pl-4 sm:pl-12 divide-y">
      <div
        class="py-4"
        v-for="(answer, answerIndex) in editableQuestion.answers"
        :key="answer.uuid"
      >
        <div class="flex">
          <div class="font-semibold">A.{{ answerIndex + 1 }})</div>
          <div class="ml-3 flex-1">
            <VueInputGroup>
              <VueTextarea
                class="bg-white input--sm shadow-none"
                placeholder="Write your answer here"
                v-model="answer.answer"
              />
            </VueInputGroup>
            <VueInputGroup class="flex" v-if="answer.comment_required !== 3">
              <div
                class="bg-theme-6 border-l border-b rounded-l whitespace-nowrap px-4 flex items-center"
              >
                Comment Name
              </div>
              <VueInput
                class="bg-white shadow-none py-1.5 font-light rounded-l-none border-t-0"
                placeholder="Write your comment name here"
                v-model="answer.comment_name"
              />
            </VueInputGroup>
          </div>
        </div>
        <div class="text-xs text-right flex items-center justify-end mt-1.5">
          <a
            href="javascript:;"
            @click="() => addSubQuestion(answerIndex)"
            v-if="canAddSubQuestion"
            >Add Sub Question</a
          >
          <div class="dropdown ml-3">
            <button class="dropdown-toggle relative pr-5 whitespace-nowrap">
              <span v-if="answer.comment_required === 1">Comment Required</span>
              <span v-else-if="answer.comment_required === 2"
                >Comment Optional</span
              >
              <span v-else-if="answer.comment_required === 4"
                >Only File Required</span
              >
              <span v-else-if="answer.comment_required === 5"
                >Only File Optional</span
              >
              <span v-else>No Comment Allowed</span>
              <div class="h-full absolute right-0 top-0 pointer-events-none">
                <ArrowDownIcon class="inline w-4 h-4" />
              </div>
            </button>
            <div class="dropdown-box w-64">
              <div class="dropdown-box__content box">
                <a
                  href="javascript:;"
                  class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
                  data-dismiss="dropdown"
                  @click="() => (answer.comment_required = 3)"
                >
                  No Comment Allowed
                </a>
                <a
                  href="javascript:;"
                  class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
                  data-dismiss="dropdown"
                  @click="() => (answer.comment_required = 2)"
                >
                  Comment Optional
                </a>
                <a
                  href="javascript:;"
                  class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
                  data-dismiss="dropdown"
                  @click="() => (answer.comment_required = 1)"
                >
                  Comment Required
                </a>
                <a
                  href="javascript:;"
                  class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
                  data-dismiss="dropdown"
                  @click="() => (answer.comment_required = 4)"
                >
                  Only File Required
                </a>
                <a
                  href="javascript:;"
                  class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
                  data-dismiss="dropdown"
                  @click="() => (answer.comment_required = 5)"
                >
                  Only File Optional
                </a>
              </div>
            </div>
          </div>
          <a
            href="javascript:;"
            class="text-red-500 ml-3"
            @click="() => deleteAnswer(answerIndex)"
            >Delete Answer</a
          >
        </div>

        <div class="pl-4 sm:pl-12 divide-y" v-if="answer.questions.length > 0">
          <div
            class="py-4"
            v-for="(subQuestion, subQuestionIndex) in answer.questions"
            :key="subQuestion.uuid"
          >
            <EditQuestionItem
              :question="subQuestion"
              :question-index="subQuestionIndex"
              :delete-question="() => deleteSubQuestion(answerIndex)"
              :can-add-sub-question="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Question } from "@/services";
import { defineComponent, reactive, toRefs } from "vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "EditQuestionItem",
  props: {
    question: {
      type: Object as () => Question,
      default: () => ({}),
    },
    questionIndex: {
      type: Number,
      default: () => -1,
    },
    deleteQuestion: {
      type: Function,
      default: () => {
        console.log("deleteQuestion");
      },
    },
    canAddSubQuestion: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const editableQuestion = reactive(toRefs<Question>(props.question));
    const state = reactive({
      show: false,
    });

    const addAnswer = () => {
      editableQuestion.answers.push({
        uuid: helper.uuidv4(),
        answer: "",
        comment_name: "Comment",
        comment_required: 3,
        reporting_type: null,
        questions: [],
      });
    };

    const deleteAnswer = (index: number) => {
      editableQuestion.answers.splice(index, 1);
    };

    const addSubQuestion = (index: number) => {
      const questions = editableQuestion.answers[index].questions || [];
      questions.push({
        uuid: helper.uuidv4(),
        question: "",
        answers: [
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
        ],
      });
    };

    const deleteSubQuestion = (index: number) => {
      const questions = editableQuestion.answers[index].questions || [];
      questions.splice(index, 1);
    };

    return {
      state,
      editableQuestion,
      addAnswer,
      deleteAnswer,
      addSubQuestion,
      deleteSubQuestion,
    };
  },
});
</script>
