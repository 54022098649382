<template>
  <input
    v-cleave="{ props, emit }"
    class="input border shadow-lg block focus:bg-white focus:ring-0"
    :value="modelValue"
    @input="changeValue"
    :class="{ 'border-red-100 bg-red-50': !!validationErrors }"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { init as cleaveInit, reInit as cleaveReInit } from "./cleave-js";
import { helper } from "@/utils/helper";
import { debounce } from "debounce";

export default defineComponent({
  name: "VueInput",
  emits: ["update:modelValue"],
  directives: {
    cleave: {
      mounted(el, { value }) {
        cleaveInit(el, value.props, value.emit);
      },
      updated(el, { oldValue, value }) {
        if (oldValue.props.modelValue !== value.props.modelValue) {
          cleaveReInit(el, value.props, value.emit);
        }
      },
    },
  },
  props: {
    modelValue: {},
    modelModifiers: {
      default: () => ({}),
    },
    validationErrors: {
      type: Array,
      default: () => null,
    },
    cleaveMask: {
      default: () => null,
    },
    uppercase: {
      type: Boolean,
      default: () => false,
    },
    capitalize: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, { emit }) {
    let changeValue = (e: any) => {
      let str: string = _.cloneDeep(_.get(e, "target.value", ""));
      if (props.capitalize && _.isString(str))
        str = helper.capitalizeEachWords(str);
      if (props.uppercase && _.isString(str)) str = helper.upperCase(str);
      emit("update:modelValue", str);
    };

    if (_.has(props.modelModifiers, "lazy")) {
      changeValue = debounce(changeValue, 500);
    }

    return {
      props,
      emit,
      changeValue,
    };
  },
});
</script>
