
import { computed, defineComponent } from "vue";
import { GetCommittee } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "CommitteeStructures",
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter()
    const headerTitle = computed(() => {
      return "Structures";
    });

    const structures = computed(() => {
      const subCommittees: any[] = _.get(props.committee, "sub_committees", []);
      subCommittees.splice(0, 0, {
        id: _.get(props.committee, "id", null),
        type: _.get(props.committee, "type", null),
        acronym: _.get(props.committee, "acronym", null),
        title: _.get(props.committee, "title", null),
      });
      return subCommittees;
    });

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    return {
      headerTitle,
      structures,
      goToView
    };
  },
});
