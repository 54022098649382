<template>
  <div class="documents--view__wrapper">
    <div class="documents--view__container">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <div
          class="font-light text-center text-xl text-gray-500 h-full flex flex-col items-center justify-center px-8"
          v-if="$_.get(document, 'status', '') === 'cancelled'"
        >
          <LinkIcon class="w-16 h-16 mx-auto text-gray-400 mb-4" />
          <div>This document has been cancelled!</div>
        </div>
        <div
          class="w-full h-full tab-content fullscreen"
          id="document--screen"
          v-else
        >
          <div class="document--screen__tools">
            <a href="javascript:;" @click="onToggleFullScreen">
              <Minimize2Icon class="w-7 h-7" />
            </a>
          </div>
          <div class="w-full h-full tab-content__pane active" id="preview">
            <EmailView v-if="isEmailView" :document="document" />
            <ImageView
              v-else-if="
                ['jpg', 'jpeg', 'png', 'gif'].indexOf(document.file_extension) >
                -1
              "
              :document="document"
            />
            <PdfView v-else :document="document" />
          </div>
          <template v-if="isEmailView">
            <div
              class="w-full h-full tab-content__pane overflow-auto p-3"
              id="documents"
            >
              <DocumentsList :document="document" />
            </div>
            <div
              class="w-full h-full tab-content__pane overflow-auto p-3"
              id="recipients"
            >
              <RecipientsList :document="document" />
            </div>
          </template>
          <div
            v-if="canUpdate"
            class="w-full h-full tab-content__pane overflow-auto p-3"
            id="logs"
          >
            <DocumentLogs
              :filter-query="{ uuid: document.uuid }"
              without-route
            />
          </div>
        </div>
      </template>
    </div>
    <div class="documents--view__sidebar" v-if="!loading && document">
      <DocumentSummary
        :document="document"
        :document-uuid="documentUuid"
        :on-send-email="onSendEmail"
        :on-cancel-document="onCancelDocument"
        :on-publish-document="onPublishDocument"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, Ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { DOCUMENT_TYPES, documentService, GetDocument } from "@/services";
import PdfView from "./preview/PdfView.vue";
import EmailView from "./preview/EmailView.vue";
import ImageView from "./preview/ImageView.vue";
import DocumentSummary from "./Summary.vue";
import dayjs from "dayjs";
import { ToastNotify } from "@/utils/toast-notify";
import { ConfirmRef } from "@/components/confirm";
import RecipientsList from "./Recipients.vue";
import DocumentsList from "./Documents.vue";
import { SendEmailRef } from "@/components/email-to-members";
import DocumentLogs from "@/views/documents/logs/List.vue";
import { useStore } from "@/store";
import _ from "lodash";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetDocument>;
}

export default defineComponent({
  name: "DocumentDetailView",
  components: {
    DocumentLogs,
    RecipientsList,
    DocumentsList,
    DocumentSummary,
    PdfView,
    EmailView,
    ImageView,
  },
  props: {
    documentUuid: {
      type: String,
      default: () => "",
    },
  },
  setup(props) {
    const router = useRouter();
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const { fetchRecord } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: () => documentService.show(props.documentUuid),
      errorCallback() {
        router.go(-1);
      },
    }) as FetchRecord;

    const isEmailView = computed<boolean>(
      () => data.value.type === DOCUMENT_TYPES.MAIL
    );

    const canUpdate = computed<boolean>(() =>
      _.get(data.value, "abilities.can_update", false)
    );

    watch(
      () => props.documentUuid,
      (val) => fetch(val),
      { flush: "post" }
    );
    onMounted(() => fetch(props.documentUuid));

    const onCancelDocument = () => {
      confirmRef?.value?.show({
        title: `Do you want to cancel this document?`,
        icon: "AlertCircleIcon",
        iconClass: "text-red-400",
        confirmText: "Yes",
        cancelText: "No",
        callback: () => {
          loading.value = true;
          documentService
            .cancel(data.value.uuid)
            .then((result) => {
              if (result.kind === "ok") {
                data.value.status = "cancelled";
                data.value.cancelled_at = dayjs(new Date()).format(
                  "YYYY-MM-DD HH:mm:ss"
                );
              } else {
                ToastNotify({ text: result.message, className: "error" });
              }
            })
            .finally(() => {
              loading.value = false;
            });
        },
      });
    };

    const onPublishDocument = () => {
      confirmRef?.value?.show({
        title: `Do you want to publish this document?`,
        icon: "AlertCircleIcon",
        iconClass: "text-red-400",
        confirmText: "Yes",
        cancelText: "No",
        callback: () => {
          loading.value = true;
          documentService
            .publish(data.value.uuid)
            .then((result) => {
              if (result.kind === "ok") {
                data.value.status = "published";
                data.value.cancelled_at = "";
              } else {
                ToastNotify({ text: result.message, className: "error" });
              }
            })
            .finally(() => {
              loading.value = false;
            });
        },
      });
    };

    const onSendEmail = () => {
      sendEmailRef?.value.show({
        committee: data.value.committee,
        files: [
          {
            id: data.value.id,
            uuid: data.value.uuid,
            title: data.value.title,
            n_number: data.value.n_number,
            file_name: data.value.file_name,
            file_size: data.value.file_size,
            type: data.value.type,
          },
        ],
        success: () => {
          data.value.status = "published";
        },
      });
    };

    const onToggleFullScreen = () => {
      cash("#document--screen").fullScreen("toggle");
    };

    return {
      loading,
      isEmailView,
      canUpdate,
      document: data,
      onSendEmail,
      onCancelDocument,
      onPublishDocument,
      onToggleFullScreen,
    };
  },
});
</script>
