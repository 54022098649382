<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <div class="section-divider mb-8">Identity</div>
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-full sm:col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('title')">
            <template v-slot:label>
              <label class="block mb-1">Title</label>
            </template>
            <VueInput
              capitalize
              placeholder="Enter title"
              v-model="formData.title"
              :validation-errors="validationErrors.get('title')"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6">
          <VueInputGroup :validation-errors="validationErrors.get('code')">
            <template v-slot:label>
              <label class="block mb-1">Code</label>
            </template>
            <VueInput
              uppercase
              placeholder="Enter code"
              v-model="formData.code"
              :validation-errors="validationErrors.get('code')"
            />
          </VueInputGroup>
        </div>
      </div>
      <div class="section-divider my-8">Other</div>
      <div class="grid grid-cols-12 gap-4 items-end">
        <div class="col-span-full">
          <VueInputGroup
            :validation-errors="validationErrors.get('description')"
          >
            <template v-slot:label>
              <label class="block mb-1">Information</label>
            </template>
            <VueTiptap
              placeholder="Enter information about country"
              v-model="formData.description"
              :validation-errors="validationErrors.get('description')"
            />
            <!--            <SlateEditor-->
            <!--              placeholder="Enter information about country"-->
            <!--              v-model="formData.description"-->
            <!--              :validation-errors="validationErrors.get('description')"-->
            <!--            />-->
          </VueInputGroup>
        </div>
      </div>
      <div class="flex justify-end mt-5">
        <VueButton
          :loading="processing"
          @click="onSubmit"
          class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
          >Save Country</VueButton
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { countryService, PostCountry } from "@/services";
import VueTiptap from "@/components/tiptap/Main.vue";

export default defineComponent({
  name: "CreateCountry",
  components: { VueTiptap },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT COUNTRY (#${id.value})`;
      return "CREATE COUNTRY";
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: countryService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: countryService.store,
      updateApiMethod: countryService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        code: "",
        title: "",
        description: "",
      } as PostCountry,
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit,
    };
  },
});
</script>
