
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "@/store";
import md5 from "blueimp-md5";
import { useRoute, useRouter } from "vue-router";
import { authService, ballotService, projectStageService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { helper } from "@/utils/helper";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const profile = store.getters["auth/profile"];

    const nameSurname = computed(() => {
      return helper.formatNameSurname(profile);
      // return `${profile?.salutation} ${profile?.name} ${profile?.surname}`;
    });

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    onMounted(async () => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("welcome");

      await authService.profile().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("auth/SET_PROFILE", result.data);
        } else {
          ToastNotify({ text: result.message, className: "error" });
          if (result.kind === "unauthorized") {
            store.dispatch("auth/SET_TOKEN", "");
            store.dispatch("auth/SET_PROFILE", null);
            router.replace({ name: "login" });
          }
        }
      });

      store.dispatch("document/FETCH_DOCUMENT_TYPES", undefined);
      store.dispatch("user/FETCH_ROLE_TYPES", undefined);

      ballotService.fetchQuestions().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("ballot/SET_BALLOT_QUESTIONS", result.data);
        }
      });

      projectStageService.fetchInitialStages().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("project/SET_PROJECT_INITIAL_TYPES", result.data);
        }
      });

      projectStageService.fetchManualStages().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("project/SET_MANUAL_STAGES", result.data);
        }
      });

      projectStageService.fetchBallotStages().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("project/SET_BALLOT_STAGES", result.data);
        }
      });

      await store.dispatch("committee/FETCH_ALL_COMMITTEES", undefined);
      store.dispatch("auth/SET_SPLASH_SCREEN", false);
      if (route.redirectedFrom) {
        return router.replace({
          name: route.redirectedFrom.name as string,
          params: route.redirectedFrom.params,
          query: route.redirectedFrom.query,
        });
      }
      router.replace({ path: _.get(route.query, "redirect", "/") as string });
    });

    return {
      nameSurname,
      gravatar,
    };
  },
});
