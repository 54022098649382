
import { defineComponent, inject, onMounted, Ref } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { taskService, GetTask } from "@/services";
import { completeTask, getTaskableTitle } from "@/views/task-manager";
import { useRouter } from "vue-router";
import { ConfirmRef } from "@/components/confirm";
import { SendEmailRef } from "@/components/email-to-members";

export default defineComponent({
  name: "DashboardTaskList",
  setup() {
    const router = useRouter();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const { fetchRecords } = useGeneralActions();
    const { loading, list, fetch } = fetchRecords({
      fetchApiMethod: taskService.fetch,
    });

    onMounted(() => {
      fetch({ page: 1 });
    });

    const onCompleteTask = (task: GetTask) => {
      completeTask(task, router, confirmRef, sendEmailRef);
    };

    return {
      loading,
      list,
      getTaskableTitle,
      onCompleteTask,
    };
  },
});
