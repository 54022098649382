<template>
  <div>
    <!-- BEGIN: Detail Modal -->
    <div id="todo-detail-modal" class="modal" ref="refDetailModal">
      <div class="modal__content modal__content--sm p-4">
        <div class="text-3xl text-theme-1">Detail</div>
        <div class="border-t h-0.5 mt-3 mb-3"></div>
        <ul>
          <li
            class="py-1 px-2 mb-1 rounded bg-gray-200"
            v-for="user in $_.get(selectedRow, 'users', [])"
            :key="`user-${$_.get(user, 'id')}`"
          >
            <router-link
              class="text-blue-500 hover:text-blue-700"
              :to="{
                name: 'users.view',
                params: { id: $_.get(user, 'id') },
              }"
            >
              {{ $h.formatNameSurname(user) }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- END: Detail Modal -->
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconWorldCircle
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">TO-DO LOGS</div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Taskable Type</label>
            </template>
            <VueSelect v-model="filter.query.type">
              <option value="">All</option>
              <option value="ballot">Ballot</option>
              <option value="meetinng">Meeting</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Scope Type</label>
            </template>
            <VueSelect v-model="filter.query.scope_type">
              <option value="">All</option>
              <option value="organization">Organization</option>
              <option value="committee">Committee</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div
          v-if="filter.query.scope_type"
          class="col-span-full sm:col-span-6 md:col-span-3"
        >
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Scope</label>
            </template>
            <Select2Input
              :options="scopeSelect2Options"
              v-model="filter.query.scope_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">User</label>
            </template>
            <Select2Input
              :options="userSelect2Options"
              v-model="filter.query.user_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="">Todo</th>
                <th class="">Taskable</th>
                <th class="whitespace-nowrap">Users</th>
                <th class="whitespace-nowrap w-1 text-center">Due Date</th>
                <th class="whitespace-nowrap w-1 text-right">Created At</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :class="{ 'opacity-50 line-through': !!row.completed_at }"
              >
                <td class="whitespace-nowrap w-1">{{ row.name }}</td>
                <td>
                  <div v-html="getTaskableTitle(row)"></div>
                  <div class="text-xs mt-1">
                    <b>({{ $_.get(row, "scope.acronym") }})</b>
                    {{ $_.get(row, "scope.title") }}
                  </div>
                </td>
                <td class="whitespace-nowrap">
                  <div v-if="$_.get(row, 'users.0')">
                    <router-link
                      class="text-blue-500 hover:text-blue-700"
                      :to="{
                        name: 'users.view',
                        params: { id: $_.get(row, 'users.0.id') },
                      }"
                    >
                      {{ $h.formatNameSurname($_.get(row, "users.0")) }}
                    </router-link>
                  </div>
                  <div
                    v-if="$_.get(row, 'users', []).length > 1"
                    class="text-xs mt-1"
                  >
                    <a
                      class="hover:text-blue-700"
                      href="javascript:;"
                      @click="onSelectRow(row)"
                    >
                      {{ $_.get(row, "users", []).length - 1 }} Other Users
                    </a>
                  </div>
                </td>
                <td class="whitespace-nowrap text-center">
                  {{ $h.formatDate(row.due_at, "DD.MM.YYYY") || "-" }}
                </td>
                <td class="whitespace-nowrap text-right">
                  {{ $h.formatDate(row.created_at, "DD.MM.YYYY HH:mm") || "-" }}
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { getTaskableTitle } from "@/views/task-manager";
import { Select2Options } from "@/global-components/select2";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "TodoLogs",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const refDetailModal = ref<HTMLElement>();
    const selectedRow = ref<any>(null);

    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "task",
        config: {
          "@select": [
            "id",
            "name",
            "scope_type",
            "scope_id",
            "due_at",
            "created_at",
          ],
          "@func": ["withCount"],
          "@order": "created_at:desc",
          users: {
            "@select": [
              "id",
              "name",
              "surname",
              "salutation",
              "title",
              "email",
            ],
          },
          scope: {
            "@select": ["*"],
          },
          taskable: {
            "@select": ["*"],
          },
        },
        filterFormat: {
          type: "@where.taskable_type",
          taskable_id: "@where.taskable_id",
          scope_type: "@where.scope_type",
          scope_id: "@where.scope_id",
          user_id: "@whereHas.users.id",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
      type: "",
      taskable_id: "",
      scope_type: "",
      scope_id: "",
      user_id: "",
    });

    const scopeSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          [filter.query.scope_type]: {
            "@select": { value: "id", acronym: "acronym", text: "title" },
          },
        },
        templateResult(obj: any): any {
          if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
          return obj.text;
        },
      };
    });

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            salutation: "salutation",
            title: "title",
          },
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.name) return helper.formatNameSurname(obj);
        return obj.text;
      },
    });

    watch(
      () => filter.query.scope_type,
      () => (filter.query.scope_id = "")
    );

    const onSelectRow = (row: any) => {
      selectedRow.value = row;
      cash("#todo-detail-modal").modal("show");
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    onBeforeUnmount(() => {
      cash(refDetailModal.value || "#todo-detail-modal").modal("hide");
    });

    return {
      refDetailModal,
      loading,
      list,
      filter,
      selectedRow,
      scopeSelect2Options,
      userSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      getTaskableTitle,
      onSelectRow,
    };
  },
});
</script>
