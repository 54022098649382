<template>
  <div class="accordion__pane py-4">
    <div class="flex justify-between">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block flex-1"
      >
        Ballots
        <template v-if="!loading">({{ list.meta.total }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
    </div>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <!-- BEGIN: Table -->
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center w-1">Type</th>
                <th class="whitespace-nowrap">Reference</th>
                <th class="whitespace-nowrap text-center">Status</th>
                <th class="whitespace-nowrap text-right">End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in list.data" :key="'ballot-' + row.id">
                <td class="text-center w-1">
                  <div class="bg-yellow-300 bg-opacity-40 px-3 py-1 rounded-md">
                    {{ row.ballot_type }}
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'ballots.view', params: { id: row.id } }"
                    class="text-blue-500 hover:text-blue-700"
                  >
                    {{ row.reference }}
                  </router-link>
                  <!--                  <div class="text-xs">{{ row.english_title }}</div>-->
                </td>
                <td
                  class="whitespace-nowrap w-0 text-center"
                  :class="{
                    'text-red-400': row.status === 'CLOSED',
                    'text-theme-1': row.status === 'OPENED',
                  }"
                >
                  {{ row.status }}
                </td>
                <td class="whitespace-nowrap w-0 text-right">
                  {{ $h.formatDate(row.end_date, "DD MMMM YYYY") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Table -->
        <Pagination
          class="mt-2"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-lg text-gray-500">
        No ballots found for this project!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee, GetProject } from "@/services";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "ProjectBallots",
  components: { Pagination },
  props: {
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props) {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const committee = computed<GetCommittee | null>(() =>
      _.get(props.project, "committee", null)
    );
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "ballot",
        config: {
          // "@paginate": 5,
          "@page": 1,
          "@select": [
            "id",
            "committee_id",
            "ballot_type",
            "reference",
            "english_title",
            "status",
            "end_date",
          ],
          "@where": {
            project_id: _.get(props, "project.id", props.projectId),
            committee_id: _.get(
              committee.value,
              "id",
              props.project.committee_id
            ),
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      committee,
      changePage,
      changePerPage,
    };
  },
});
</script>
