<template>
  <div class="accordion__pane py-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
    >
      {{ title }} ({{ localList.length }})
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <!-- BEGIN: Table -->
      <div class="overflow-x-auto mt-3">
        <table v-if="localList.length > 0" class="table w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap text-center w-1">Acronym</th>
              <th class="whitespace-nowrap">Title</th>
              <th class="whitespace-nowrap">Type</th>
              <th class="whitespace-nowrap text-right">Functions</th>
            </tr>
          </thead>
          <tbody>
            <TransactionStatement
              tag="tr"
              v-for="row in list"
              :key="'record-' + row.id"
              :processing="processing && transactionIds.indexOf(row.id) > -1"
              :waiting="transactionIds[0] !== row.id"
            >
              <td class="text-center">
                <div class="bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md">
                  {{ row.acronym }}
                </div>
              </td>
              <td>
                <router-link
                  :to="{ name: 'committees.view', params: { id: row.id } }"
                  class="text-blue-500 hover:text-blue-700"
                  >{{ row.title }}
                </router-link>
              </td>
              <td>{{ $h.convertIdToText(row.type, "committeeTypes") }}</td>
              <td class="text-right">
                <div class="inline-flex">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                    @click="() => goToView(row.id)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg ml-2"
                    @click="() => triggerDelete([row.id])"
                    v-if="canEdit"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </div>
              </td>
            </TransactionStatement>
          </tbody>
        </table>
        <div v-else class="text-lg text-gray-500">
          No roles found for this member!
        </div>
      </div>
    </div>
  </div>
  <!-- END: Table -->
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { committeeMemberService } from "@/services";
import _ from "lodash";
import { useGeneralActions } from "@/utils/actions/";
import TransactionStatement from "@/components/transaction-statement/Main.vue";

export default defineComponent({
  name: "ParticipatingRoles",
  components: { TransactionStatement },
  props: {
    memberId: {
      required: true,
      default: () => null,
    },
    list: {
      type: Array,
      default: () => [],
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    title: {
      type: String,
      default: () => "Participating Roles",
    },
  },
  setup(props) {
    const router = useRouter();

    const { deleteRecords } = useGeneralActions();

    const localList = ref<any[]>(props.list);

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: async (id) => {
        return await committeeMemberService.delete(id, props.memberId);
      },
      successCallback(id) {
        if (id) {
          const index = _.findIndex(localList.value, { id });
          if (index > -1) localList.value.splice(index, 1);
        }
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    return {
      localList,
      processing,
      transactionIds,
      triggerDelete,
      goToView,
    };
  },
});
</script>
