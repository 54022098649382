<template>
  <div class="relative w-full h-full">
    <div
      class="font-light text-center text-xl text-gray-500 h-full flex flex-col items-center justify-center px-8"
      v-if="!$_.get(document, 'file_path', '')"
    >
      <LinkIcon class="w-16 h-16 mx-auto text-gray-400 mb-4" />
      <div>Link is broken!</div>
    </div>
    <img
      v-else
      :src="`${document.file_path}`"
      class="absolute left-0 top-0 w-full h-full object-contain object-center"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GetDocument } from "@/services";

export default defineComponent({
  name: "ImageView",
  props: {
    document: {
      type: Object as () => GetDocument,
      required: true,
    },
  },
});
</script>
