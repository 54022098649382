<template>
  <div>
    <div class="h-8 -mb-4 w-full bg-theme-1"></div>
    <div
      class="bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 relative rounded-t-2xl md:rounded-tr-none"
    >
      <router-link
        :to="{ name: 'tasks' }"
        class="button absolute bg-gradient-to-b from-theme-2 to-theme-9 text-theme-1 right-6 top-0 md:right-14 transform -translate-y-5"
      >
        All To-Do
      </router-link>
      <SvgIconWorldCircle
        class="w-16 h-16 absolute top-0 transform -translate-y-8"
      />
      <DashboardTaskList />
    </div>
    <div class="grid grid-cols-12 gap-6 sm:p-5">
      <div
        class="col-span-full py-10 px-8 bg-gradient-to-r from-theme-8 to-theme-9 rounded-lg"
      >
        <div class="text-theme-1 text-xl">Users</div>
        <div class="text-gray-500">
          You can create new users and you can manage roles of users.
        </div>
        <div class="flex flex-col sm:flex-row mt-4">
          <div class="w-full flex">
            <input
              placeholder="Quick Search User"
              class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
              v-model="quickSearchUser"
              @keydown.enter="
                $router.push({ name: 'users', query: searchUserQuery })
              "
            />
            <router-link
              tag="button"
              :to="{ name: 'users', query: searchUserQuery }"
              class="button bg-gray-100 border rounded-l-none shadow-lg"
            >
              <SearchIcon class="inline w-6 h-6" />
            </router-link>
          </div>
          <router-link
            :to="{ name: 'users.create' }"
            class="button flex items-center justify-center bg-theme-1 text-white w-full sm:w-64 sm:ml-3 mt-3 sm:mt-0 shadow-lg"
            v-if="$store.getters['auth/canCreateUser']"
          >
            <UserPlusIcon class="inline mr-3" /> New Users
          </router-link>
        </div>
      </div>

      <div
        class="col-span-full sm:col-span-6 py-10 px-8 bg-gradient-to-r from-theme-8 to-theme-9 rounded-lg"
      >
        <SvgIconUsers class="text-theme-4 w-16 h-16" />
        <div class="text-theme-1 text-xl mt-2">Meetings</div>
        <div class="text-gray-500 mt-1">You can view meetings.</div>
        <router-link
          :to="{ name: 'meetings' }"
          class="button flex items-center justify-center bg-theme-1 text-white w-max mt-3 shadow-xl"
        >
          <SearchIcon class="inline mr-3" /> Search Meetings
        </router-link>
      </div>

      <div
        class="col-span-full sm:col-span-6 py-10 px-8 bg-gradient-to-r from-theme-8 to-theme-9 rounded-lg"
      >
        <SvgIconFolder class="text-theme-4 w-16 h-16" />
        <div class="text-theme-1 text-xl mt-2">Ballots</div>
        <div class="text-gray-500 mt-1">You can view ballots.</div>
        <router-link
          :to="{ name: 'ballots' }"
          class="button flex items-center justify-center bg-theme-1 text-white w-max mt-3 shadow-xl"
        >
          <SearchIcon class="inline mr-3" /> Search Ballots
        </router-link>
      </div>

      <div
        class="col-span-full sm:col-span-6 lg:col-span-4 py-10 px-8 bg-gradient-to-r from-theme-8 to-theme-9 rounded-lg"
      >
        <SvgIconDocuments class="text-theme-4 w-16 h-16" />
        <div class="text-theme-1 text-xl mt-2">Documents</div>
        <div class="text-gray-500 mt-1">You can view documents.</div>
        <router-link
          :to="{ name: 'documents' }"
          class="button flex items-center justify-center bg-theme-1 text-white w-max mt-3 shadow-xl"
        >
          <SearchIcon class="inline mr-3" /> Search N-Documents
        </router-link>
      </div>

      <div
        class="col-span-full sm:col-span-6 lg:col-span-4 py-10 px-8 bg-gradient-to-r from-theme-8 to-theme-9 rounded-lg"
      >
        <SvgIconUser class="text-theme-4 w-16 h-16" />
        <div class="text-theme-1 text-xl mt-2">Committees</div>
        <div class="text-gray-500 mt-1">
          You can create new committees and you can manage them.
        </div>
        <router-link
          :to="{ name: 'committees' }"
          class="button flex items-center justify-center bg-theme-1 text-white w-max mt-3 shadow-xl"
        >
          <SearchIcon class="inline mr-3" /> Search Committees
        </router-link>
      </div>

      <div
        class="col-span-full sm:col-span-6 lg:col-span-4 py-10 px-8 bg-gradient-to-r from-theme-8 to-theme-9 rounded-lg"
      >
        <SvgIconTable class="text-theme-4 w-16 h-16" />
        <div class="text-theme-1 text-xl mt-2">Projects</div>
        <div class="text-gray-500 mt-1">
          You can create new projects and you can manage preferences.
        </div>
        <router-link
          :to="{ name: 'projects' }"
          class="button flex items-center justify-center bg-theme-1 text-white w-max mt-3 shadow-xl"
        >
          <SearchIcon class="inline mr-3" /> Search Projects
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import DashboardTaskList from "@/views/dashboard/TaskList.vue";
import SvgIconUsers from "@/global-components/svg-icon/SvgIconUsers.vue";

export default defineComponent({
  components: { SvgIconUsers, DashboardTaskList },
  setup() {
    const quickSearchUser = ref<string>("");
    const searchUserQuery = computed(() => ({
      q: quickSearchUser.value,
    }));

    return {
      quickSearchUser,
      searchUserQuery,
    };
  },
});
</script>
