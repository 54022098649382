import { reactive, ref, Ref } from "vue";
import { ToastNotify } from "@/utils/toast-notify";
import { ValidationErrors } from "@/utils/form";
import _ from "lodash";

export interface GeneralCreateOrUpdateProps {
  params: Record<string, any>;
  removeKeysIfEmptyValue?: string[];
  successMessage?: string;
  createApiMethod?(params: Record<string, any>): Promise<any>;
  updateApiMethod?(id: any, params: Record<string, any>): Promise<any>;
  successCallback?(result?: Record<string, any>): void;
  errorCallback?(): void;
}

export interface GeneralCreateOrUpdate {
  processing: Ref<boolean>;
  formData: Record<string, any>;
  validationErrors: Ref<ValidationErrors>;
  setFormData(params: Record<string, any>): void;
  resetFormData(): void;
  submit(id?: any): Promise<void>;
}

export default function createOrUpdate(
  props: GeneralCreateOrUpdateProps
): GeneralCreateOrUpdate {
  const { successMessage = "Registration success!" } = props;
  const processing = ref<boolean>(false);
  const originalFormData = _.cloneDeep(props.params);
  const formData = reactive<Record<string, any>>(props.params);
  const validationErrors = ref(new ValidationErrors());

  const submit = async (id?: any) => {
    const convertedFormData = _.cloneDeep(formData);
    if (
      props.removeKeysIfEmptyValue &&
      props.removeKeysIfEmptyValue.length > 0
    ) {
      props.removeKeysIfEmptyValue.map((k) => {
        if (!_.get(convertedFormData, k)) _.unset(convertedFormData, k);
      });
    }
    validationErrors.value.clear();
    processing.value = true;
    let result: any = null;
    if (id && props.updateApiMethod)
      result = await props.updateApiMethod(id, convertedFormData);
    else if (!id && props.createApiMethod)
      result = await props.createApiMethod(convertedFormData);
    if (result) {
      if (result.kind === "ok") {
        ToastNotify({ text: successMessage, className: "success" });
        if (props.successCallback) props.successCallback(result);
      } else {
        if (result.kind === "validation")
          validationErrors.value.record(result.fields);
        ToastNotify({ text: result.message, className: "error" });
        if (props.errorCallback) props.errorCallback();
      }
    }
    processing.value = false;
  };

  const setFormData = (params: Record<string, any>) => {
    _.forEach(formData, (val: any, key: string) => {
      if (_.has(params, key)) {
        _.set(formData, key, _.get(params, key));
      }
    });
  };

  const resetFormData = () => {
    _.forEach(formData, (val: any, key: string) => {
      _.set(formData, key, _.get(originalFormData, key, null));
    });
  };

  return {
    processing,
    formData,
    validationErrors,
    setFormData,
    submit,
    resetFormData,
  };
}
