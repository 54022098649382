import { useGeneralActions } from "@/utils/actions";
import { GetBallotResult, PostBallot, ballotService } from "@/services";
import { onMounted } from "vue";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostBallot;
}

export const saveInit = (
  props: Record<string, any>,
  emit: (event: string, ...args: unknown[]) => void
) => {
  const { createOrUpdate } = useGeneralActions();

  const {
    processing,
    formData,
    setFormData,
    validationErrors,
    submit,
  } = createOrUpdate({
    updateApiMethod: ballotService.update,
    successCallback(result: GetBallotResult) {
      if (result.kind === "ok") {
        emit("update:ballot", { ...props.ballot, ...result.data });
        props.close();
      }
    },
    params: {
      ballot_type: "",
      reference: "",
      english_title: "",
      note: "",
      start_date: "",
      end_date: "",
      questions: [],
      files: [],
    } as PostBallot,
  }) as CreateOrUpdate;

  onMounted(() => {
    setFormData(_.cloneDeep(props.ballot));
  });

  const onSubmit = () => {
    if (props.ballotId) submit(props.ballotId).finally();
    else {
      emit("update:ballot", {
        ...props.ballot,
        questions: _.cloneDeep(formData.questions),
      });
      props.close();
    }
  };

  return {
    processing,
    formData,
    validationErrors,
    setFormData,
    onSubmit,
  };
};
