
import { defineComponent, reactive, watch } from "vue";
import { GetBallot } from "@/services";
import { helper } from "@/utils/helper";
import { saveInit } from "../save";
import EditQuestionItem from "@/views/ballots/view/questions/EditQuestionItem.vue";

export default defineComponent({
  name: "BallotEditQuestion",
  components: { EditQuestionItem },
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    close: {
      type: Function,
      default: () => {
        console.log("close");
      },
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      show: true,
    });
    const addQuestion = () => {
      formData.questions.push({
        uuid: helper.uuidv4(),
        question: "",
        answers: [
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
        ],
      });
    };
    const deleteQuestion = (index: number) => {
      formData.questions.splice(index, 1);
    };

    const { processing, formData, validationErrors, onSubmit } = saveInit(
      props,
      emit
    );

    return {
      processing,
      state,
      formData,
      validationErrors,
      onSubmit,
      addQuestion,
      deleteQuestion,
    };
  },
});
