<template>
  <div>
    <div class="text-center">
      <VueButton
        class="button bg-gradient-to-b from-red-400 to-red-500 text-white relative pr-16 whitespace-nowrap mr-2"
        @click="close"
      >
        Close Editing
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-red-800 to-red-900 text-white rounded-md py-2.5 px-3"
        >
          <XIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
      >
        Save Changes
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
    <div class="grid grid-cols-4 gap-4 py-6 items-start">
      <!--      <VueInputGroup-->
      <!--        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"-->
      <!--        :validation-errors="validationErrors.get('committee_id')"-->
      <!--      >-->
      <!--        <template v-slot:label>-->
      <!--          <div-->
      <!--            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"-->
      <!--          >-->
      <!--            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">-->
      <!--              Committee-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <div-->
      <!--          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"-->
      <!--        >-->
      <!--          <Select2Input-->
      <!--            class="bg-white shadow-none"-->
      <!--            v-model="formData.committee_id"-->
      <!--            :validation-errors="validationErrors.get('committee_id')"-->
      <!--            :options="committeeSelect2Options"-->
      <!--            @update:selected-item="onChangeCommittee"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </VueInputGroup>-->
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('wg_id')"
      >
        <template v-slot:label>
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Working Group
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <Select2Input
            class="bg-white shadow-none"
            v-model="formData.wg_id"
            :validation-errors="validationErrors.get('wg_id')"
            :options="workingGroupSelect2Options"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('project_no')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('project_no') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Project No
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.project_no"
            :validation-errors="validationErrors.get('project_no')"
            placeholder="Enter reference"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('part_no')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('part_no') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Part No
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.part_no"
            :validation-errors="validationErrors.get('part_no')"
            placeholder="Enter reference"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('reference')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('reference') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Reference
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            :value="reference"
            readonly
            disabled
            :validation-errors="validationErrors.get('reference')"
            placeholder="Enter reference"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('reference_suffix')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{
              'text-red-400': validationErrors.has('reference_suffix'),
            }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Reference Suffix
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.reference_suffix"
            :validation-errors="validationErrors.get('reference_suffix')"
            placeholder="Enter reference suffix"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('edition_no')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('edition_no') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Edition No
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.edition_no"
            :validation-errors="validationErrors.get('edition_no')"
            placeholder="Enter edition no"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('price')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{ 'text-red-400': validationErrors.has('price') }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Price
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.price"
            :validation-errors="validationErrors.get('price')"
            placeholder="Enter price"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('visible_on_website')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{
              'text-red-400': validationErrors.has('visible_on_website'),
            }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Visible On Website
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueSelect
            class="bg-white m-0 shadow-none"
            v-model="formData.visible_on_website"
            :validation-errors="validationErrors.get('visible_on_website')"
          >
            <option value="0">No</option>
            <option value="1">Yes</option>
          </VueSelect>
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full sm:col-span-2 grid grid-cols-4 rounded-lg"
        :validation-errors="validationErrors.get('time_frame')"
      >
        <template v-slot:label-container>
          <div
            class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
            :class="{
              'text-red-400': validationErrors.has('time_frame'),
            }"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Time Frame
            </div>
          </div>
        </template>
        <div
          class="col-span-2 sm:col-span-2 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueSelect
            v-model="formData.time_frame"
            class="bg-white m-0 shadow-none"
            :validation-errors="validationErrors.get('time_frame')"
          >
            <option value="18">18</option>
            <option value="24">24</option>
            <option value="36">36</option>
            <option value="48">48</option>
          </VueSelect>
        </div>
      </VueInputGroup>
      <div class="col-span-full section-divider">English Information</div>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('title_en')"
      >
        <template v-slot:label>
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Title
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.title_en"
            :validation-errors="validationErrors.get('title_en')"
            placeholder="Enter english title"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('description_en')"
      >
        <template v-slot:label>
          <div
            class="col-span-full h-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Description
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueTiptap
            class="bg-white m-0 shadow-none"
            v-model="formData.description_en"
            :validation-errors="validationErrors.get('description_en')"
            placeholder="Enter english description"
          />
        </div>
      </VueInputGroup>
      <div class="col-span-full section-divider">French Information</div>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('title_fr')"
      >
        <template v-slot:label>
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Title
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.title_fr"
            :validation-errors="validationErrors.get('title_fr')"
            placeholder="Enter french title"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('description_fr')"
      >
        <template v-slot:label>
          <div
            class="col-span-full h-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Description
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueTiptap
            class="bg-white m-0 shadow-none"
            v-model="formData.description_fr"
            :validation-errors="validationErrors.get('description_fr')"
            placeholder="Enter french description"
          />
        </div>
      </VueInputGroup>
      <div class="col-span-full section-divider">Arabic Information</div>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('title_ar')"
      >
        <template v-slot:label>
          <div
            class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Title
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueInput
            class="bg-white m-0 shadow-none"
            v-model="formData.title_ar"
            :validation-errors="validationErrors.get('title_ar')"
            placeholder="Enter arabic title"
          />
        </div>
      </VueInputGroup>
      <VueInputGroup
        class="col-span-full grid grid-cols-4 sm:col-span-full rounded-lg"
        :validation-errors="validationErrors.get('description_ar')"
      >
        <template v-slot:label>
          <div
            class="col-span-full h-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
          >
            <div class="whitespace-nowrap overflow-hidden overflow-ellipsis">
              Description
            </div>
          </div>
        </template>
        <div
          class="col-span-full sm:col-span-3 flex items-center p-0 pl-0.5 font-medium"
        >
          <VueTiptap
            class="bg-white m-0 shadow-none"
            v-model="formData.description_ar"
            :validation-errors="validationErrors.get('description_ar')"
            placeholder="Enter arabic description"
          />
        </div>
      </VueInputGroup>
    </div>
    <div class="text-center">
      <VueButton
        class="button bg-gradient-to-b from-red-400 to-red-500 text-white relative pr-16 whitespace-nowrap mr-2"
        @click="close"
      >
        Close Editing
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-red-800 to-red-900 text-white rounded-md py-2.5 px-3"
        >
          <XIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
        @click="onSubmit"
        :loading="processing"
      >
        Save Changes
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <SaveIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import {
  GetProject,
  GetProjectResult,
  PostProject,
  projectService,
} from "@/services";
import { useGeneralActions } from "@/utils/actions";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { Select2Options } from "@/global-components/select2";
import VueTiptap from "@/components/tiptap/Main.vue";
import { useStore } from "@/store";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostProject;
}

export default defineComponent({
  name: "ProjectEditInformation",
  components: { VueTiptap },
  props: {
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
    close: {
      type: Function,
      default: () => {
        console.log("close");
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { createOrUpdate } = useGeneralActions();

    const stageAbbr = computed<string>(() => {
      const index = _.findIndex(store.state.project.initialTypes, {
        value: formData.stage,
      });
      return _.get(store.state.project.initialTypes, `${index}.abbr`, "");
    });

    const reference = computed<string>(() => {
      let val = `OIC/SMIIC ${stageAbbr.value} ${formData.project_no}`;
      if (formData.part_no) val += `-${formData.part_no}`;
      if (formData.reference_suffix) val += `${formData.reference_suffix}`;
      return val;
    });

    const state = reactive({
      selectedCommittee: _.get(props.project, "committee"),
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      updateApiMethod: projectService.update,
      successCallback(result: GetProjectResult) {
        if (result.kind === "ok") {
          emit("update:project", { ...props.project, ...result.data });
          props.close();
        }
      },
      params: {
        stage: "",
        project_no: "",
        part_no: "",
        reference_suffix: "",
        sequence: "",
        committee_id: "",
        wg_id: "",
        title_en: "",
        description_en: "",
        title_fr: "",
        description_fr: "",
        title_ar: "",
        description_ar: "",
        price: "",
        visible_on_website: "0",
        leader_user_id: "",
        time_frame: "",
        edition_no: "",
      } as PostProject,
    }) as CreateOrUpdate;

    onMounted(() => {
      setFormData(props.project);
    });

    const onSubmit = () => {
      submit(props.projectId);
    };

    const onChangeCommittee = (committee: any) => {
      state.selectedCommittee = committee;
      formData.wg_id = "";
    };

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
            level: "level",
            type: "type",
          },
          "@where": {
            level: [1, 2],
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              "can_create_project"
            ),
          },
          "@whereNot": {
            type: ["WG"],
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });
    const workingGroupSelect2Options = computed<Select2Options>(() => {
      const selectedCommitteeLevel = _.get(
        state.selectedCommittee,
        "level.level"
      );
      const where: any = {
        level_1_type: _.get(state.selectedCommittee, "level.level_1.type"),
        level_1_number: _.get(state.selectedCommittee, "level.level_1.number"),
      };
      if (selectedCommitteeLevel === 1) {
        _.set(where, "level", 2);
        _.set(where, "level_2_type", "WG");
      } else if (selectedCommitteeLevel === 2) {
        _.set(where, "level", 3);
        _.set(
          where,
          "level_2_type",
          _.get(state.selectedCommittee, "level.level_2.type")
        );
        _.set(
          where,
          "level_2_number",
          _.get(state.selectedCommittee, "level.level_2.number")
        );
        _.set(where, "level_3_type", "WG");
      }
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
            "@where": where,
          },
        },
        templateSelection: (obj: any): string => {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    return {
      processing,
      reference,
      formData,
      validationErrors,
      committeeSelect2Options,
      workingGroupSelect2Options,
      onChangeCommittee,
      onSubmit,
    };
  },
});
</script>
