<template>
  <div class="divide-y">
    <div class="accordion__pane py-4">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >
        {{ participantMemberTitle }}
        <template v-if="!loading">({{ participants.length }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <div class="accordion__pane__content mt-3 leading-relaxed">
        <div v-if="loading" class="text-center p-4 pt-8">
          <LoaderIcon class="spin-5 inline-block w-8 h-8" />
          <div class="mt-2 font-light">
            Please wait while the records are loading.
          </div>
        </div>
        <template v-if="participants.length > 0">
          <!-- BEGIN: Table -->
          <div class="overflow-x-auto">
            <table class="table w-full">
              <thead>
                <tr>
                  <th class="whitespace-nowrap text-center">Country</th>
                  <th class="whitespace-nowrap text-center w-0">Acronym</th>
                  <th class="whitespace-nowrap">Title</th>
                  <th class="whitespace-nowrap w-1 text-right">Functions</th>
                </tr>
              </thead>
              <tbody>
                <TransactionStatement
                  tag="tr"
                  v-for="row in participants"
                  :key="`member-p-${row.id}`"
                  :processing="
                    deleteProcessing && transactionIds.indexOf(row.id) > -1
                  "
                  :waiting="transactionIds[0] !== row.id"
                >
                  <td class="text-center whitespace-nowrap w-0">
                    {{ `${$_.get(row, "country.title", "")}` }}
                  </td>
                  <td class="text-center">{{ row.acronym }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'organizations.view',
                        params: { id: row.id },
                      }"
                      class="text-blue-500 hover:text-blue-700"
                    >
                      {{ row.title }}
                    </router-link>
                  </td>
                  <td class="text-right whitespace-nowrap">
                    <Tippy
                      tag="button"
                      content="See Details"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                      @click="() => goToView(row.id)"
                    >
                      <EyeIcon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      v-if="$_.get(committee, 'abilities.can_update', false)"
                      tag="button"
                      content="Delete"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg ml-2"
                      @click="triggerDelete([row.id])"
                    >
                      <Trash2Icon class="inline w-5 h-5" />
                    </Tippy>
                  </td>
                </TransactionStatement>
              </tbody>
            </table>
          </div>
          <!-- END: Table -->
        </template>
        <div v-else class="text-lg text-gray-500">
          No members found for this committee!
        </div>
      </div>
    </div>
    <div class="accordion__pane py-4" v-if="!isWg">
      <a
        href="javascript:;"
        class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
      >
        Observer Members
        <template v-if="!loading">({{ observers.length }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <div class="accordion__pane__content mt-3 leading-relaxed">
        <div v-if="loading" class="text-center p-4 pt-8">
          <LoaderIcon class="spin-5 inline-block w-8 h-8" />
          <div class="mt-2 font-light">
            Please wait while the records are loading.
          </div>
        </div>
        <template v-if="observers.length > 0">
          <!-- BEGIN: Table -->
          <div class="overflow-x-auto">
            <table class="table w-full">
              <thead>
                <tr>
                  <th class="whitespace-nowrap text-center">Country</th>
                  <th class="whitespace-nowrap text-center w-0">Acronym</th>
                  <th class="whitespace-nowrap">Title</th>
                  <th class="whitespace-nowrap w-1 text-right">Functions</th>
                </tr>
              </thead>
              <tbody>
                <TransactionStatement
                  tag="tr"
                  v-for="row in observers"
                  :key="`member-p-${row.id}`"
                  :processing="
                    deleteProcessing && transactionIds.indexOf(row.id) > -1
                  "
                  :waiting="transactionIds[0] !== row.id"
                >
                  <td class="text-center whitespace-nowrap w-0">
                    {{ `${$_.get(row, "country.title", "")}` }}
                  </td>
                  <td class="text-center">{{ row.acronym }}</td>
                  <td>
                    <router-link
                      :to="{
                        name: 'organizations.view',
                        params: { id: row.id },
                      }"
                      class="text-blue-500 hover:text-blue-700"
                    >
                      {{ row.title }}
                    </router-link>
                  </td>
                  <td class="text-right whitespace-nowrap">
                    <Tippy
                      tag="button"
                      content="See Details"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                      @click="() => goToView(row.id)"
                    >
                      <EyeIcon class="inline w-5 h-5" />
                    </Tippy>
                    <Tippy
                      v-if="$_.get(committee, 'abilities.can_update', false)"
                      tag="button"
                      content="Delete"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg ml-2"
                      @click="triggerDelete([row.id])"
                    >
                      <Trash2Icon class="inline w-5 h-5" />
                    </Tippy>
                  </td>
                </TransactionStatement>
              </tbody>
            </table>
          </div>
          <!-- END: Table -->
        </template>
        <div v-else class="text-lg text-gray-500">
          No members found for this committee!
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { committeeMemberService, GetCommittee } from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import _ from "lodash";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Library",
  components: { TransactionStatement },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await committeeMemberService.fetch(props.committee.id, query);
      },
    });

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) =>
        await committeeMemberService.delete(props.committeeId, id),
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const isWg = computed(() => {
      return props.committee.type === "WG";
    });

    const participantMemberTitle = computed(() => {
      if (isWg.value) return "Working Group Members";
      return "Participant Members";
    });

    const participants = computed(() =>
      list.data.filter((item) => item.committee_member_type === "P")
    );

    const observers = computed(() =>
      list.data.filter((item) => item.committee_member_type === "O")
    );

    const goToView = (value: any) => {
      router.push({ name: "organizations.view", params: { id: value } });
    };

    onMounted(() => {
      fetch({}).finally();
    });

    return {
      isWg,
      loading,
      list,
      participants,
      observers,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      participantMemberTitle,
      goToView,
    };
  },
});
</script>
