<template>
  <div
    class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
  >
    <SvgIconEye
      class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
    />
    <div class="text-white text-xl">{{ headerTitle }}</div>
  </div>
  <div class="p-3 sm:px-5 sm:py-5">
    <!-- BEGIN: Top Buttons -->
    <div
      class="flex justify-end transform -translate-y-9 sm:-translate-y-11"
      v-if="!loading && detail.id"
    >
      <WriteComment
        v-if="permissions.canComment"
        :can-comment="permissions.canComment"
        :ballot="detail"
        @update:created-comment="(val) => createdCommentEventRef(val)"
      />
      <router-link
        v-if="permissions.canVote"
        :to="{ name: 'ballots.vote', params: { id } }"
        class="button bg-gradient-to-b from-purple-400 to-purple-500 text-white relative pr-16 whitespace-nowrap ml-4"
      >
        Cast Vote
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-purple-600 to-purple-700 text-white rounded-md py-2.5 px-3"
        >
          <AwardIcon class="inline w-6 h-6" />
        </div>
      </router-link>
      <VueButton
        v-if="permissions.canOpenBallot"
        class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap ml-4"
        @click="toggleBallotStatus"
        :loading="processing"
        :disabled="disabledActionButtons"
      >
        Open Ballot
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
        >
          <CheckCircleIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
      <VueButton
        v-if="permissions.canCloseBallot"
        class="button bg-gradient-to-b from-red-400 to-red-500 text-white relative pr-16 whitespace-nowrap ml-4"
        @click="toggleBallotStatus"
        :loading="processing"
        :disabled="disabledActionButtons"
      >
        Close Ballot
        <div
          class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-red-600 to-red-700 text-white rounded-md py-2.5 px-3"
        >
          <XCircleIcon class="inline w-6 h-6" />
        </div>
      </VueButton>
    </div>
    <!-- END: Top Buttons -->
    <div v-if="loading" class="text-center p-4 pt-8">
      <LoaderIcon class="spin-5 inline-block w-8 h-8" />
      <div class="mt-2 font-light">
        Please wait while the records are loading.
      </div>
    </div>
    <template v-else>
      <div class="accordion divide-y">
        <BallotInformation
          :ballot-id="id"
          v-model:ballot="detail"
          :can-edit="permissions.canUpdate"
          @update:active-editable="(val) => (isActiveEditableInformation = val)"
        />
        <BallotQuestions
          :ballot-id="id"
          v-model:ballot="detail"
          :can-edit="permissions.canUpdate"
          @update:active-editable="(val) => (isActiveEditableQuestions = val)"
        />
        <BallotDocuments
          :ballot-id="id"
          v-model:ballot="detail"
          :permissions="permissions"
        />
        <BallotResults
          v-if="permissions.canViewResults"
          :ballot-id="id"
          v-model:ballot="detail"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  provide,
  ref,
  Ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { ballotService, GetBallot } from "@/services";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import BallotInformation from "@/views/ballots/view/information/Main.vue";
import BallotQuestions from "@/views/ballots/view/questions/Main.vue";
import BallotDocuments from "@/views/ballots/view/documents/Main.vue";
import { ConfirmRef } from "@/components/confirm";
import BallotResults from "@/views/ballots/view/results/Main.vue";
import { useStore } from "@/store";
import WriteComment from "@/views/ballots/view/WriteComment.vue";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetBallot>;
}

export default defineComponent({
  name: "BallotView",
  components: {
    WriteComment,
    BallotResults,
    BallotDocuments,
    BallotQuestions,
    BallotInformation,
  },
  setup() {
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { fetchRecord } = useGeneralActions();
    const processing = ref<boolean>(false);

    const isActiveEditableInformation = ref<boolean>(false);
    const isActiveEditableQuestions = ref<boolean>(false);
    const disabledActionButtons = computed<boolean>(
      () => isActiveEditableInformation.value
    );

    const createdCommentEventRef = ref<() => void>(() => {
      console.log("createdCommentEventRef");
    });
    provide("bind[createdCommentEventRef]", (callback: () => void) => {
      createdCommentEventRef.value = callback;
    });

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `VIEW BALLOT (#${id.value})`;
      return `${data.value.reference}`;
    });

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: ballotService.show,
      errorCallback() {
        router.go(-1);
      },
    }) as FetchRecord;

    const permissions = computed(() => {
      const status = data.value.status;
      const canUpdate = _.get(data.value, "abilities.can_update", false);
      const canVote = _.get(data.value, "abilities.can_vote", false);
      const canComment = _.get(data.value, "abilities.can_comment", false);
      const canClose = _.get(data.value, "abilities.can_close", false);
      const canOpen = _.get(data.value, "abilities.can_open", false);
      return {
        canComment: canComment && status === "OPENED",
        canVote: canVote && status === "OPENED",
        canUpdate: canUpdate && status === "CREATED",
        canAddReferenceDocument: canUpdate && ["CREATED"].indexOf(status) > -1,
        canAddDocument: canUpdate && ["CREATED", "CLOSED"].indexOf(status) > -1,
        canOpenBallot: canOpen && status === "CREATED",
        canCloseBallot: canClose && status === "OPENED",
        canViewResults:
          (status === "OPENED" && store.getters["auth/canAudit"]) ||
          status === "CLOSED",
      };
    });

    const toggleBallotStatus = () => {
      confirmRef?.value?.show({
        title: `Do you want to change status this ballot?`,
        icon: "AlertCircleIcon",
        iconClass: "text-yellow-500",
        confirmText: "Yes",
        cancelText: "No",
        callback: () => {
          if (data.value.status === "CREATED") {
            processing.value = true;
            ballotService
              .open(id.value)
              .then((result) => {
                if (result.kind === "ok") {
                  data.value = { ...data.value, ...result.data };
                }
              })
              .finally(() => {
                processing.value = false;
              });
          } else {
            processing.value = true;
            ballotService
              .close(id.value)
              .then((result) => {
                if (result.kind === "ok") {
                  data.value = { ...data.value, ...result.data };
                }
              })
              .finally(() => {
                processing.value = false;
              });
          }
        },
      });
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value).finally();
    });

    return {
      id,
      headerTitle,
      loading,
      processing,
      isActiveEditableInformation,
      isActiveEditableQuestions,
      disabledActionButtons,
      detail: data,
      permissions,
      createdCommentEventRef,
      toggleBallotStatus
    };
  },
});
</script>
