import { GetConversationResult, useAxios } from "@/services";

export * from "./api.types";

export const conversationService = {
  show: async (id: unknown): Promise<GetConversationResult> =>
    useAxios().get(`/api/conversations/${id}`),

  store: async (params: Record<string, any>): Promise<GetConversationResult> =>
    useAxios().store(`/api/conversations`, params),
};
