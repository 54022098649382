<template>
  <div class="accordion__pane active pb-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle relative font-medium text-2xl text-gray-600 block pr-16 whitespace-nowrap overflow-hidden overflow-ellipsis"
      @click="() => $emit('update:active-tab', 'info')"
    >
      Information
      <button
        class="button p-0 absolute right-4"
        @click.stop.prevent="visibleEditComponent = !visibleEditComponent"
        v-if="canEdit"
      >
        <EyeIcon v-if="visibleEditComponent" class="w-7 h-7" />
        <Edit3Icon v-else class="w-7 h-7" />
      </button>
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <ProjectEditInformation
        v-if="canEdit && visibleEditComponent"
        :project="project"
        @update:project="(val) => $emit('update:project', val)"
        :project-id="projectId"
        :close="() => (visibleEditComponent = false)"
      />
      <ProjectViewInformation
        v-else
        :project="project"
        :project-id="projectId"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import ProjectEditInformation from "@/views/projects/view/information/Edit.vue";
import ProjectViewInformation from "@/views/projects/view/information/View.vue";
import { GetProject } from "@/services";

export default defineComponent({
  name: "ProjectInformation",
  components: { ProjectViewInformation, ProjectEditInformation },
  props: {
    activeTab: { type: String, default: () => "" },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props, { emit }) {
    const visibleEditComponent = ref<boolean>(false);
    const canEdit = computed(() => {
      return _.get(props.project, "abilities.can_update", false);
    });
    watch(visibleEditComponent, (val) => emit("update:active-editable", val));
    return {
      visibleEditComponent,
      canEdit,
    };
  },
});
</script>
