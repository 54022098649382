<template>
  <div class="accordion__pane py-4">
    <div class="flex justify-between">
      <a
        href="javascript:;"
        class="accordion__pane__toggle relative font-medium text-2xl text-gray-600 block pr-24 whitespace-nowrap overflow-hidden overflow-ellipsis"
      >
        Projects
        <template v-if="!loading">({{ list.meta.total }})</template>
        <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
      </a>
      <Tippy
        v-if="!isWg && $_.get(committee, 'abilities.can_create_project', false)"
        tag="button"
        content="Add Project"
        :options="{ placement: 'bottom' }"
        class="dropdown-toggle button p-0 absolute right-4"
        @click="
          () =>
            $router.push({
              name: 'projects.create',
              query: { committee_id: committeeId },
            })
        "
      >
        <PlusSquareIcon class="w-8 h-8" />
      </Tippy>
    </div>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <!-- BEGIN: Table -->
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center w-1">Stage</th>
                <th class="whitespace-nowrap">Reference</th>
                <th class="whitespace-nowrap text-center">Start Date</th>
                <th class="whitespace-nowrap text-center">Target Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in list.data" :key="'project-' + row.id">
                <td class="text-center w-1">
                  <div
                    class="inline-block bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md"
                  >
                    {{ row.stage }}
                  </div>
                </td>
                <td>
                  <router-link
                    :to="{ name: 'projects.view', params: { id: row.id } }"
                    class="text-blue-500 hover:text-blue-700"
                  >
                    {{ row.reference }}
                  </router-link>
                  <div class="text-xs">{{ row.title_en }}</div>
                </td>
                <td class="whitespace-nowrap w-0 text-center">
                  {{
                    $h.formatDate(
                      $_.get(row, "current_line.start_date", ""),
                      "DD MMMM YYYY"
                    ) || "-"
                  }}
                </td>
                <td class="whitespace-nowrap w-0 text-center">
                  {{
                    $h.formatDate(
                      $_.get(row, "current_line.target_date", ""),
                      "DD MMMM YYYY"
                    ) || "-"
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Table -->
        <Pagination
          class="mt-2"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-lg text-gray-500">
        No projects found for this committee!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetCommittee } from "@/services";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "CommitteeProjects",
  components: { Pagination },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const isWg = computed(() => {
      return _.get(props, "committee.type") === "WG";
    });

    const { fetch, loading, list } = fetchRecords({
      selectionQuery: () => {
        const where: any = {};
        if (isWg.value)
          _.set(
            where,
            "wg_id",
            _.get(props, "committee.id", props.committeeId)
          );
        else
          _.set(
            where,
            "committee_id",
            _.get(props, "committee.id", props.committeeId)
          );
        return {
          source: "project",
          config: {
            "@paginate": 5,
            "@page": 1,
            "@select": ["id", "committee_id", "reference", "title_en", "stage"],
            current_line: {
              "@select": [
                "id",
                "project_id",
                "start_date",
                "target_date",
                "stage",
                "version",
              ],
            },
            "@where": where,
            "@func": ["withCount"],
            "@order": "created_at:desc",
          },
        };
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 5,
      },
      true
    );

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      isWg,
      loading,
      list,
      filter,
      changePage,
      changePerPage,
    };
  },
});
</script>
