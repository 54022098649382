import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  token(state: LocalState): string | null;
  profile(state: LocalState): any;
  visibleSplashScreen(state: LocalState): boolean;
  isSuperAdmin(state: LocalState): boolean;
  canCreateCommittee(state: LocalState): boolean;
  canCreateOrganization(state: LocalState): boolean;
  canCreateUser(state: LocalState): boolean;
  canManageCountry(state: LocalState): boolean;
  canAudit(state: LocalState): boolean;
  canManageSubmission(state: LocalState): boolean;
  canManageFeedback(state: LocalState): boolean;
  canCreateProject(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (committeeId?: any) => boolean;
  canCreateDocument(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (committeeId?: any) => boolean;
  canCreateFolder(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (committeeId?: any) => boolean;
  canCreateMeeting(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (committeeId?: any) => boolean;
  canCreateCommitteeBallot(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (committeeId?: any) => boolean;
  canCreateSmiicBallot(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (committeeId?: any) => boolean;
  canCreateAnyBallot(state: LocalState, getters: any): boolean;
  getCommitteeIdsFromPermission(
    state: LocalState,
    getters: any,
    rootState: RootState
  ): (permission: string) => any[];
};

const hasPermissionInCommittee = (
  rootState: RootState,
  permission: string,
  committeeId?: any
) => {
  let canCreate = false;
  rootState.committee.committees.map((committee) => {
    if (_.get(committee, `abilities.${permission}`, false)) {
      if (!committeeId || committee.id === committeeId * 1) {
        canCreate = true;
      }
    }
  });
  return canCreate;
};
// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  token: (state) => {
    return state.token;
  },
  profile: (state) => {
    return state.profile;
  },
  visibleSplashScreen: (state) => {
    return state.visibleSplashScreen;
  },
  isSuperAdmin: (state) => {
    return _.get(state.profile, "abilities.can_update_staff_roles", false);
  },
  canCreateCommittee: (state) => {
    return _.get(state.profile, "permissions.can_create_committee", false);
  },
  canCreateOrganization: (state) => {
    return _.get(state.profile, "permissions.can_create_organization", false);
  },
  canCreateUser: (state) => {
    return _.get(state.profile, "permissions.can_create_user", false);
  },
  canManageCountry: (state) => {
    return _.get(state.profile, "permissions.can_manage_country", false);
  },
  canAudit: (state) => {
    return _.get(state.profile, "permissions.can_audit", false);
  },
  canManageSubmission: (state) => {
    return _.get(state.profile, "permissions.can_manage_submission", false);
  },
  canManageFeedback: (state) => {
    return _.get(state.profile, "permissions.can_manage_feedback", false);
  },
  canCreateProject: (state, getters, rootState) => (committeeId) => {
    return hasPermissionInCommittee(
      rootState,
      "can_create_project",
      committeeId
    );
  },
  canCreateDocument: (state, getters, rootState) => (committeeId) => {
    return hasPermissionInCommittee(
      rootState,
      "can_create_document",
      committeeId
    );
  },
  canCreateFolder: (state, getters, rootState) => (committeeId) => {
    return hasPermissionInCommittee(
      rootState,
      "can_create_folder",
      committeeId
    );
  },
  canCreateMeeting: (state, getters, rootState) => (committeeId) => {
    return hasPermissionInCommittee(
      rootState,
      "can_create_meeting",
      committeeId
    );
  },
  canCreateCommitteeBallot: (state, getters, rootState) => (committeeId) => {
    return hasPermissionInCommittee(
      rootState,
      "can_create_committee_ballot",
      committeeId
    );
  },
  canCreateSmiicBallot: (state, getters, rootState) => (committeeId) => {
    return hasPermissionInCommittee(
      rootState,
      "can_create_smiic_ballot",
      committeeId
    );
  },
  canCreateAnyBallot: (state, getters) => {
    return getters.canCreateCommitteeBallot() || getters.canCreateSmiicBallot();
  },
  getCommitteeIdsFromPermission: (state, getters, rootState) => (
    permission
  ) => {
    if (getters.isSuperAdmin) return [];
    const arr: any[] = [];
    rootState.committee.committees.map((committee) => {
      if (_.get(committee, `abilities.${permission}`, false)) {
        arr.push(committee.id);
      }
    });
    return arr;
  },
};
