import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { GetStageType } from "@/services";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_PROJECT_TYPES](state: S, types: GetStageType[]): void;
  [MutationTypes.SET_PROJECT_INITIAL_TYPES](
    state: S,
    types: GetStageType[]
  ): void;
  [MutationTypes.SET_MANUAL_STAGES](state: S, types: any[]): void;
  [MutationTypes.SET_BALLOT_STAGES](state: S, types: any[]): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_PROJECT_TYPES](state: LocalState, types: GetStageType[]) {
    state.types = types;
  },
  [MutationTypes.SET_PROJECT_INITIAL_TYPES](
    state: LocalState,
    types: GetStageType[]
  ) {
    state.initialTypes = types;
  },
  [MutationTypes.SET_MANUAL_STAGES](state: LocalState, stages: any[]) {
    state.manualStages = stages;
  },
  [MutationTypes.SET_BALLOT_STAGES](state: LocalState, stages: any[]) {
    state.ballotStages = stages;
  },
};
