<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEye
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <!-- BEGIN: Top Buttons -->
      <div
        class="flex justify-end transform -translate-y-9 sm:-translate-y-11"
        v-if="canEdit"
      >
        <router-link
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          :to="{ name: 'meetings.edit', params: { id } }"
        >
          Edit Meeting
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <Edit2Icon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else-if="detail.id">
        <Information :detail="detail" :can-edit="canEdit" />
        <div class="accordion divide-y">
          <!-- BEGIN Reference -->
          <MeetingDocuments
            :can-edit="canEdit"
            :can-upload="canUpload"
            :meeting="detail"
            :meeting-id="id"
          />
          <!-- END Reference -->

          <!-- BEGIN Relations -->
          <Relations
            :meeting-id="id"
            :committee-id="detail.committee_id || null"
            :can-edit="canEdit"
          />
          <!-- END Relations -->
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import Relations from "@/views/meetings/view/Relations.vue";
import Information from "@/views/meetings/view/Information.vue";
import MeetingDocuments from "@/views/meetings/view/documents/Main.vue";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { GetMeeting } from "@/services";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetMeeting>;
}

export default defineComponent({
  name: "ViewMeeting",
  components: { MeetingDocuments, Relations, Information },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      errorCallback() {
        router.go(-1);
      },
      selectionQuery: {
        source: "meeting",
        config: {
          "@select": [
            "id",
            "title",
            "committee_id",
            "information",
            "city",
            "country_code",
            "time",
            "status",
            "type",
            "exact_date",
          ],
          country: {
            "@select": ["id", "title", "code"],
          },
          committee: {
            "@select": ["id", "title", "acronym"],
          },
        },
      },
    }) as FetchRecord;

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `VIEW MEETING (#${id.value})`;
      return `${data.value.title}`;
    });
    const canEdit = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_update", false);
    });
    const canUpload = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_upload", false);
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      headerTitle,
      canEdit,
      canUpload,
      detail: data,
    };
  },
});
</script>
