<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEye
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">
        {{ headerTitle }}
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <!-- BEGIN: Top Buttons -->
      <div
        class="flex justify-end -mt-9 sm:-mt-16 mb-4"
        v-if="$_.get(detail, 'abilities.can_update', false)"
      >
        <router-link
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          :to="{ name: 'committees.edit', params: { id } }"
        >
          Edit {{ committeeOrWorkingGroup }}
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <Edit2Icon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else-if="detail.id">
        <div class="accordion divide-y">
          <ViewLibraries :committeeId="id" :committee="detail" />
          <ViewAbout :detail="detail" />
          <ViewStructures :committeeId="id" :committee="detail" />
          <ViewProjects :committeeId="id" :committee="detail" />
          <ViewBallots :committeeId="id" :committee="detail" />
          <ViewMeetings :committeeId="id" :committee="detail" />
          <ViewMembers :committeeId="id" :committee="detail" />
          <ViewCommitteeLiaisons :committeeId="id" :committee="detail" v-if="!isWg" />
          <ViewOrganizationLiaisons :committeeId="id" :committee="detail" v-if="!isWg" />
          <ViewCommitteeUsers :committeeId="id" :committee="detail" />
          <ViewCommitteeUsersWithVoters :committee-id="id" :committee="detail" v-if="!isWg" />
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, watch } from "vue";
import ViewAbout from "./About.vue";
import ViewLibraries from "./Library.vue";
import ViewProjects from "./Projects.vue";
import ViewBallots from "./Ballots.vue";
import ViewMeetings from "./Meeting.vue";
import ViewMembers from "./Members.vue";
import ViewCommitteeLiaisons from "./CommitteeLiaisons.vue";
import ViewOrganizationLiaisons from "./OrganizationLiaisons.vue";
import ViewCommitteeUsers from "./CommitteeUsers.vue";
import ViewCommitteeUsersWithVoters from "./CommitteeUsersWithVoters.vue";
import ViewStructures from "@/views/committees/view/Structures.vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { committeeService, GetCommittee } from "@/services";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetCommittee>;
}

export default defineComponent({
  name: "ViewCommittee",
  components: {
    ViewStructures,
    ViewAbout,
    ViewLibraries,
    ViewProjects,
    ViewBallots,
    ViewMeetings,
    ViewMembers,
    ViewCommitteeLiaisons,
    ViewOrganizationLiaisons,
    ViewCommitteeUsers,
    ViewCommitteeUsersWithVoters
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: committeeService.show,
      errorCallback() {
        router.go(-1);
      }
    }) as FetchRecord;

    const id = computed(() => route.params.id);

    const isWg = computed(() => {
      return data.value.type === "WG";
    });

    const committeeOrWorkingGroup = computed(() => {
      return isWg.value ? "Working Group" : "Committee";
    });

    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `View ${committeeOrWorkingGroup.value} (#${id.value})`;
      return `(${data.value.acronym}) ${data.value.title}`;
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      isWg,
      committeeOrWorkingGroup,
      headerTitle,
      detail: data
    };
  }
});
</script>
