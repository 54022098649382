<template>
  <div class="relative w-full h-full">
    <div
      class="absolute left-0 top-0 w-full h-full flex items-center justify-center"
      v-if="loading"
    >
      <LoaderIcon class="w-8 h-8 spin-5" />
    </div>
    <template v-else>
      <div
        class="font-light text-center text-xl text-gray-500 h-full flex flex-col items-center justify-center px-8"
        v-if="!previewUrl"
      >
        <LinkIcon class="w-16 h-16 mx-auto text-gray-400 mb-4" />
        <div>Link is broken!</div>
      </div>
      <iframe
        v-else
        :src="`${previewUrl}`"
        class="absolute left-0 top-0 w-full h-full"
      >
        <p>This browser does not support PDF!</p>
      </iframe>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { emailService, GetDocument } from "@/services";

export default defineComponent({
  name: "EmailView",
  props: {
    document: {
      type: Object as () => GetDocument,
      required: true,
    },
  },
  setup(props) {
    const loading = ref<boolean>(false);
    const previewUrl = ref<string>("");
    const fetch = () => {
      loading.value = true;
      emailService
        .preview(_.get(props.document, "committee_id"), {
          subject: _.get(props.document, "title"),
          body: _.get(props.document, "note"),
          files: _.get(props.document, "files"),
          users_full_list: false
        })
        .then((result) => {
          if (result.kind === "ok") {
            previewUrl.value = URL.createObjectURL(result.data);
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    onMounted(() => fetch());

    return {
      loading,
      previewUrl,
    };
  },
});
</script>
