<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 pt-8 pb-4 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <ListIcon
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">SUBMISSION LIST</div>
      <div class="text-white text-sm font-light">
        Project submission to SMIIC General Secretariat
      </div>
    </div>
    <div class="p-3 sm:px-5">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap">Project</th>
                <th class="whitespace-nowrap">Submission Text</th>
                <th class="whitespace-nowrap">Documents</th>
                <th class="whitespace-nowrap text-right">Created At</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in list.data" :key="'record-' + row.id">
                <td>
                  <router-link
                    class="text-blue-500 hover:text-blue-600"
                    :to="{
                      name: 'projects.view',
                      params: { id: $_.get(row, 'project.id', '') },
                    }"
                  >
                    {{ $_.get(row, "project.reference", "") }}
                  </router-link>
                </td>
                <td>{{ $_.get(row, "text", "") }}</td>
                <td>
                  <router-link
                    v-for="file in $_.get(row, 'files', [])"
                    :key="file"
                    :to="{ name: 'documents', params: { uuid: file.uuid } }"
                    target="_blank"
                    class="block text-blue-500 hover:text-blue-600"
                    >{{ $_.get(file, "title", "") }}</router-link
                  >
                </td>
                <td class="text-right">
                  {{ $h.formatDate(row.created_at, "DD MMMM YYYY") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
        />
      </template>
      <div v-else class="text-center p-4">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "Submissions",
  components: {
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "submission",
        config: {
          "@page": 1,
          "@select": ["id", "text", "files", "project_id", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          project: {
            "@select": ["id", "reference"],
          },
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
    } = filterRecords(fetch, {
      page: 1,
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      applyFilter,
      clearFilter,
      changePage,
    };
  },
});
</script>
