<template>
  <div>
    <div class="section-divider my-8">N-Document Properties</div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-4">
        <VueInputGroup :validation-errors="validationErrors.get('n_number')">
          <template v-slot:label>
            <label class="block mb-1">N-Number</label>
          </template>
          <VueInput
            v-model="editableFormData.n_number"
            :validation-errors="validationErrors.get('n_number')"
          />
          <div
            class="mt-2 text-xs text-gray-500 text-gray-500"
            v-if="nextNumber.value"
          >
            Next available number will be <b>{{ nextNumber.value }}</b>
          </div>
        </VueInputGroup>
      </div>
      <div class="col-span-4">
        <VueInputGroup :validation-errors="validationErrors.get('version')">
          <template v-slot:label>
            <label class="block mb-1">Version</label>
          </template>
          <VueInput
            v-model="editableFormData.version"
            :validation-errors="validationErrors.get('version')"
          />
        </VueInputGroup>
      </div>
      <div class="col-span-4">
        <VueInputGroup
          :validation-errors="validationErrors.get('document_date')"
        >
          <template v-slot:label>
            <label class="block mb-1">Date Of Document</label>
          </template>
          <Litepicker
            v-model="editableFormData.document_date"
            :validation-errors="validationErrors.get('document_date')"
          />
        </VueInputGroup>
      </div>
      <div class="col-span-6">
        <VueInputGroup
          :validation-errors="validationErrors.get('expected_action')"
        >
          <template v-slot:label>
            <label class="block mb-1">Expected Action</label>
          </template>
          <VueSelect
            v-model="editableFormData.expected_action"
            :validation-errors="validationErrors.get('expected_action')"
          >
            <option value="">Select one of list</option>
            <option value="act">Act</option>
            <option value="info">Info</option>
            <option value="reply">Reply</option>
            <option value="vote">Vote</option>
            <option value="comment">Comment</option>
            <option value="meet">Meet</option>
          </VueSelect>
        </VueInputGroup>
      </div>
      <div class="col-span-6">
        <VueInputGroup
          :validation-errors="validationErrors.get('exp_action_due_date')"
        >
          <template v-slot:label>
            <label class="block mb-1">Due Date For Expected Action</label>
          </template>
          <Litepicker
            v-model="editableFormData.exp_action_due_date"
            :validation-errors="validationErrors.get('exp_action_due_date')"
          />
        </VueInputGroup>
      </div>
      <div class="col-span-6">
        <VueInputGroup :validation-errors="validationErrors.get('language')">
          <template v-slot:label>
            <label class="block mb-1">Language</label>
          </template>
          <VueSelect
            v-model="editableFormData.language"
            :validation-errors="validationErrors.get('language')"
          >
            <option value="english">English</option>
            <option value="french">French</option>
            <option value="arabic">Arabic</option>
          </VueSelect>
        </VueInputGroup>
      </div>
      <div class="col-span-6">
        <VueInputGroup :validation-errors="validationErrors.get('pages')">
          <template v-slot:label>
            <label class="block mb-1">Pages</label>
          </template>
          <VueInput
            v-model="editableFormData.pages"
            :validation-errors="validationErrors.get('pages')"
          />
        </VueInputGroup>
      </div>
      <div class="col-span-full">
        <VueInputGroup :validation-errors="validationErrors.get('replaces_id')">
          <template v-slot:label>
            <label class="block mb-1">Replaces</label>
          </template>
          <Select2Input
            v-model="editableFormData.replaces_id"
            :validation-errors="validationErrors.get('replaces_id')"
            :options="documentSelect2Options"
            @update:selected-item="(ctx) => onChangeReplace(ctx)"
          />
        </VueInputGroup>
      </div>
      <div class="col-span-full">
        <VueInputGroup :validation-errors="validationErrors.get('note')">
          <template v-slot:label>
            <label class="block mb-1">Description</label>
            <label class="block text-xs text-gray-500 mb-2">
              (Please enter any info on how to document is to be treated by the
              committee members. The information will appear on the cover sheet
              of the document)
            </label>
          </template>
          <VueTiptap
            v-model="editableFormData.note"
            :validation-errors="validationErrors.get('note')"
            @update:json-data="onChangeNoteJson"
          />
        </VueInputGroup>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import { documentService, PostDocument } from "@/services";
import { ValidationErrors } from "@/utils/form";
import Litepicker from "@/global-components/litepicker/Main.vue";
import { useRoute } from "vue-router";
import VueTiptap from "@/components/tiptap/Main.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "NDocumentProperties",
  components: { VueTiptap, Litepicker },
  props: {
    state: { type: Object, default: () => ({}) },
    formData: { type: Object as () => PostDocument, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const route = useRoute();
    const versionUUID = computed(() => _.get(route.query, "v", ""));

    const editableState = reactive(toRefs<Record<string, any>>(props.state));
    const editableFormData = reactive(toRefs<PostDocument>(props.formData));
    const nextNumber = reactive<Record<string, any>>({
      loading: false,
      value: 0,
    });
    const fetchNextNumber = () => {
      nextNumber.loading = true;
      documentService
        .fetchNextNumber({
          committee_id: editableFormData.committee_id,
          language: editableFormData.language,
          n_number: props.state.n_number,
        })
        .then((result) => {
          if (result.kind === "ok") {
            nextNumber.value = result.data.n_number;
            if (editableFormData.is_n_document) {
              editableFormData.n_number = result.data.n_number;
              editableFormData.version = result.data.version;
            }
          }
        })
        .finally(() => {
          nextNumber.loading = false;
        });
    };
    onMounted(() => {
      if (!versionUUID.value) {
        fetchNextNumber();
      }
      if (!editableFormData.document_date) {
        editableFormData.document_date = helper.formatDate(new Date(), "YYYY-MM-DD")
      }
    });
    watch(
      computed(() => [
        editableFormData.committee_id,
        editableFormData.language,
      ]),
      () => {
        if (editableFormData.is_n_document) {
          fetchNextNumber();
        }
      },
      { deep: true }
    );

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            n_number: "n_number",
            uuid: "uuid",
            version: "version",
          },
          "@order": ["n_number:desc", "version:desc"],
          "@where": {
            committee_id: editableFormData.committee_id,
          },
          "@whereNotIn": {
            n_number: [null, "", "null", "0"],
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const onChangeReplace = (ctx: Record<string, any>): void => {
      editableState.replace = ctx;
    };

    const onChangeNoteJson = (val: any): void => {
      editableState.noteJsonData = _.get(val, "content", []);
    };

    return {
      editableFormData,
      nextNumber,
      documentSelect2Options,
      onChangeReplace,
      onChangeNoteJson,
    };
  },
});
</script>
