import { Timeline } from "vis-timeline/peer";
import { DataSet } from "vis-data/peer";
import { TimelineOptions } from "vis-timeline";
import { unset } from "lodash";

interface Options extends TimelineOptions {
  delay?: number;
  rows?: any[];
}
interface Props {
  options?: Options;
}

const init = (
  el: HTMLElement & { timeline?: Timeline },
  props: Props
): void => {
  const options = {
    stack: true,
    editable: false,
    minHeight: "325px",
    groupEditable: false,
    clickToUse: false,
    selectable: false,
    margin: {
      axis: 20,
    },
    min: new Date(2000, 1, 1),
    max: new Date(2050, 12, 31),
    ...props.options,
  };

  const rows = options.rows || [];
  const delay = options.delay || 0;
  unset(options, "delay");
  unset(options, "rows");

  /*
  [
    {
      id: 1,
      content: "(30.20) CD study/ballot initiated",
      start: "2020-04-20",
      end: "2020-06-04",
    },
    {
      id: 2,
      content: "(30.60) Close of voting/ comment period",
      start: "2020-05-01",
      end: "2020-05-28",
    },
    {
      id: 3,
      content: "(40.20) DS ballot initiated: 3 months",
      start: "2020-05-30",
      end: "2020-08-28",
    },
    {
      id: 4,
      content: "(40.20) DS ballot initiated: 3 months",
      start: "2020-05-12",
      end: "2021-02-28",
    },
    {
      id: 5,
      content: "(40.20) DS ballot initiated: 3 months",
      start: "2020-03-24",
      end: "2020-06-28",
    },
  ]
   */
  // Create a DataSet (allows two way data-binding)
  const items = new DataSet(rows);

  // Configuration for the Timeline

  setTimeout(() => {
    el.timeline = new Timeline(el, items, options);
  }, delay);
  // Create a Timeline
};

const reInit = (
  el: HTMLElement & { timeline?: Timeline },
  props: Props
): void => {
  if (el.timeline) {
    const rows = props.options?.rows || [];
    const items = new DataSet(rows);
    el.timeline.setItems(items);
  } else {
    init(el, props);
  }
};

export { init, reInit };
