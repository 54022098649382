
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import {
  GetProject,
  GetProjectResult,
  GetStageType,
  projectStageService,
} from "@/services";
import { useGeneralActions } from "@/utils/actions";
import { launchActionForProject } from "@/views/projects/actions";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";

export default defineComponent({
  name: "ProjectActionButton",
  props: {
    canEdit: { type: Boolean, default: () => true },
    disabled: { type: Boolean, default: () => false },
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
    onFetch: { type: Function, default: () => null },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const availableNextStages = ref<GetStageType[]>([]);
    const refStageModal = ref<HTMLElement>();

    const { createOrUpdate } = useGeneralActions();

    const fetchAvailableNextStages = () => {
      projectStageService.fetchNextStages(props.projectId).then((result) => {
        if (result.kind === "ok") {
          stageFormData.stage = "";
          availableNextStages.value = result.data;
        }
      });
    };

    const {
      formData: stageFormData,
      validationErrors: stageValidationErrors,
      processing: stageProcessing,
      submit: stageSubmit,
    } = createOrUpdate({
      updateApiMethod: projectStageService.updateStage,
      successCallback(result: GetProjectResult) {
        if (result.kind === "ok") {
          props.onFetch();
          cash("#stage-modal").modal("hide");
        }
      },
      params: {
        stage: "",
      },
    });

    const visibleDecision = computed<boolean>(() => {
      return store.state.project.manualStages.indexOf(props.project.stage) > -1;
    });

    const visibleLaunchNewBallot = computed<boolean>(() => {
      return store.state.project.ballotStages.indexOf(props.project.stage) > -1;
    });

    const submitNextStage = () => stageSubmit(props.projectId);

    const onLaunchAction = () =>
      launchActionForProject({
        projectId: (props.projectId as any) * 1,
        committeeId: (props.project.committee_id as any) * 1,
        stage: props.project.stage,
        route,
        router,
        store,
      });

    onMounted(() => {
      if (visibleDecision.value) fetchAvailableNextStages();
      if (_.get(route.query, "openDecisionModal") === "1") {
        cash("#stage-modal").modal("show");
        const routeQuery = _.cloneDeep(route.query);
        _.unset(routeQuery, "openDecisionModal");
        router.replace({
          name: "projects.view",
          params: { ...route.params },
          query: routeQuery,
        });
      }
    });

    onBeforeUnmount(() => {
      cash(refStageModal.value || "#stage-modal").modal("hide");
    });

    return {
      stageProcessing,
      stageValidationErrors,
      stageFormData,
      availableNextStages,
      visibleDecision,
      visibleLaunchNewBallot,
      refStageModal,
      submitNextStage,
      onLaunchAction,
    };
  },
});
