
import { defineComponent, watch } from "vue";
import { GetBallot } from "@/services";
import Litepicker from "@/global-components/litepicker/Main.vue";
import { saveInit } from "@/views/ballots/view/save";
import dayjs from "dayjs";
import VueTextarea from "@/global-components/form-elements/textarea/Main.vue";

export default defineComponent({
  name: "BallotEditInformation",
  components: { VueTextarea, Litepicker },
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    close: {
      type: Function,
      default: () => {
        console.log("close");
      },
    },
  },
  setup(props, { emit }) {
    const { processing, formData, validationErrors, onSubmit } = saveInit(
      props,
      emit
    );

    watch(
      () => formData.start_date,
      (val) => {
        let addMount = 0;
        if (props.ballot.ballot_type === "DS") addMount = 3;
        if (props.ballot.ballot_type === "FDS") addMount = 2;
        if (addMount > 0) {
          formData.end_date = dayjs(val)
            .add(dayjs.duration({ months: addMount }))
            .format("YYYY-MM-DD");
        }
      }
    );

    return {
      processing,
      formData,
      validationErrors,
      onSubmit,
    };
  },
});
