
import { computed, defineComponent, onMounted, ref } from "vue";
import { saveInit } from "@/views/ballots/view/save";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { GetBallot } from "@/services";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "BallotReferenceLinks",
  components: { TransactionStatement },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      onSubmit,
    } = saveInit(
      {
        ...props,
        close: () => {
          cash("#reference-document-modal").modal("hide");
        },
      },
      emit
    );

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            uuid: "uuid",
            n_number: "n_number",
            type: "type",
            file_name: "file_name",
            file_size: "file_size",
          },
          "@order": ["n_number:desc", "created_at:desc"],
          "@where": {
            committee_id: props.ballot.committee_id,
            status: "published"
          },
          "@whereNot": {
            uuid: formData.files.map((file) => file.uuid),
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const documentValue = ref<string>("");
    const onAddSubmissionFile = (document: Record<string, any>) => {
      if (document) {
        formData.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.text,
          n_number: document.n_number,
          type: document.type,
          file_name: document.file_name,
          file_size: document.file_size,
        });
        setTimeout(() => {
          documentValue.value = "";
        }, 100);
      }
    };

    const onOpenModal = () => {
      cash("#reference-document-modal").modal("show");
    };

    const onDeleteReference = (uuid: any) => {
      if (uuid) {
        const index = _.findIndex(formData.files, { uuid });
        if (index > -1) {
          formData.files.splice(index, 1);
        }
      }
    };

    const goToView = (uuid: any) => {
      router.push({ name: "documents", params: { uuid } });
    };

    onMounted(() => {
      cash("#reference-document-modal").modal("on.show", () => {
        setFormData(_.cloneDeep(props.ballot));
      });
    });

    return {
      processing,
      formData,
      validationErrors,
      documentSelect2Options,
      documentValue,
      onAddSubmissionFile,
      onSubmit,
      onDeleteReference,
      onOpenModal,
      goToView,
    };
  },
});
