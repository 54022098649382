import {
  GeneralApiResponse,
  GetOrganizationResult,
  GetOrganizationResults,
  useAxios,
} from "@/services";

export * from "./api.types";

export const organizationService = {
  fetch: async (): Promise<GetOrganizationResults> =>
    useAxios().fetch(`/api/organizations`, {}),

  show: async (id: unknown): Promise<GetOrganizationResult> =>
    useAxios().get(`/api/organizations/${id}`),

  store: async (params: Record<string, any>): Promise<GetOrganizationResult> =>
    useAxios().store(`/api/organizations`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetOrganizationResult> =>
    useAxios().update(`/api/organizations/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/organizations/${id}`),
};
