<template>
  <QuestionViewItem
    v-for="(question, index) in questions"
    :key="question.uuid"
    :question="question"
    :path="['questions', index]"
    :validation-errors="validationErrors"
  />
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { GetBallot, Question } from "@/services";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "BallotViewQuestions",
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const questions = computed<Question[]>(() => {
      return _.get(props.ballot, "questions", []);
    });
    return {
      questions,
    };
  },
});
</script>
