<template>
  <div
    class="rounded-lg bg-theme-11 bg-opacity-30 pt-10 pb-24 px-3 sm:px-10 grid grid-cols-1 sm:grid-cols-2"
  >
    <div class="col-span-1">
      <div class="text-theme-3 text-2xl" v-if="$_.get(detail, 'title', '')">
        {{ $_.get(detail, "title", "") }}
      </div>
      <div class="text-theme-3 text-3xl">
        {{ $h.capitalizeFirstLetter($_.get(detail, "salutation", "")) }}
        {{ $h.capitalizeFirstLetter($_.get(detail, "name", "")) }}
        {{ $h.capitalizeFirstLetter($_.get(detail, "surname", "")) }}
      </div>
      <div class="mt-1">
        <div
          class="inline-block px-2 py-0.5 rounded bg-blue-500 text-xs text-white"
          v-if="$_.get(detail, 'status', 0) * 1 === 1"
        >
          Active User
        </div>
        <div
          class="inline-block px-2 py-0.5 rounded bg-red-400 text-xs text-white"
          v-else
        >
          Passive User
        </div>
      </div>
      <div class="mt-5">
        <div class="text-theme-1 text-lg mb-1">Administrated By Member</div>
        <div class="text-lg font-medium">
          <router-link
            v-if="$_.get(detail, 'administrated_by', '')"
            :to="{
              name: 'organizations.view',
              params: { id: $_.get(detail, 'administrated_by_id', '') },
            }"
            class="hover:text-blue-700"
          >
            {{
              `(${$_.get(detail, "administrated_by.acronym", "")}) ${$_.get(
                detail,
                "administrated_by.title",
                ""
              )}`
            }}
          </router-link>
          <template v-else>-</template>
        </div>
      </div>
    </div>
    <div
      class="col-span-1 mt-5 sm:mt-0 sm:ml-5 pt-5 sm:pt-0 sm:pl-5 border-t sm:border-t-0 sm:border-l border-theme-1"
    >
      <div class="text-theme-1 text-2xl">Contact</div>
      <div class="text-sm mt-1">
        <span class="mr-1 font-medium">Address:</span>
        <span>
          {{ $_.get(detail, "address", "") }}
        </span>
      </div>
      <div class="text-sm mt-1">
        <span class="mr-1 font-medium">Phone:</span>
        <a
          :href="'tel:' + $_.get(detail, 'phone', '')"
          class="inline-block hover:text-blue-800"
          >{{ $_.get(detail, "phone", "") }}</a
        >
      </div>
      <div class="text-sm mt-1">
        <span class="mr-1 font-medium">Email:</span>
        <a
          :href="'mailto:' + $_.get(detail, 'email', '')"
          class="inline-block hover:text-blue-800"
          >{{ $_.get(detail, "email", "") }}</a
        >
      </div>
      <div class="text-theme-1 text-2xl mt-4">Responsibilities</div>
      <div class="text-sm mt-1">
        <span class="mr-1 font-medium"> Employee Of:</span>
        <span v-if="$_.get(detail, 'administrated_by', '')">
          {{ `${$_.get(detail, "administrated_by.acronym", "")}` }}
        </span>
        <span v-else>-</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserInformation",
  props: {
    detail: {
      type: Object,
      required: true,
    },
  },
});
</script>
