<template>
  <div class="grid grid-cols-4 gap-4">
    <div
      class="col-span-full grid grid-cols-4 sm:col-span-full bg-gray-100 flex rounded-lg"
      v-if="previousCaster"
    >
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Last Vote Casted by
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        {{ previousCaster }}
      </div>
    </div>

    <div
      class="col-span-full grid grid-cols-4 sm:col-span-full bg-gray-100 flex rounded-lg"
      v-if="ballot.committee_id"
    >
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Committee
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{ name: 'committees.view', params: { id: ballot.committee_id } }"
        >
          ({{ $_.get(ballot, "committee.acronym", "") }})
          {{ $_.get(ballot, "committee.title", "") }}
        </router-link>
      </div>
    </div>
    <div
      class="col-span-full grid grid-cols-4 sm:col-span-full bg-gray-100 flex rounded-lg"
      v-if="ballot.project_id"
    >
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Project Reference
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        <router-link
          class="text-blue-500 hover:text-blue-600"
          :to="{ name: 'projects.view', params: { id: ballot.project_id } }"
          >{{ $_.get(ballot, "project.reference", "") }}
        </router-link>
      </div>
    </div>
    <div class="col-span-full section-divider">English Information</div>
    <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Title
        </div>
      </div>
      <div class="col-span-full sm:col-span-3 flex items-center px-6 py-3.5">
        {{ $_.get(ballot, "english_title", "") }}
      </div>
    </div>
    <div class="col-span-full grid grid-cols-4 bg-gray-100 flex rounded-lg">
      <div
        class="col-span-full sm:col-span-1 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Note
        </div>
      </div>
      <div
        class="col-span-full sm:col-span-3 px-6 py-3.5 ProseMirror-Viewer"
        v-html="ballot.note"
      ></div>
    </div>
    <div class="col-span-full section-divider">Other Information</div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-full sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Reference
        </div>
      </div>
      <div class="col-span-full sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(ballot, "reference", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Type
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(ballot, "ballot_type", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Version Number
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{ $_.get(ballot, "version", "") || "-" }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Created Date
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.formatDate($_.get(ballot, "created_at", ""), "DD MMMM YYYY") || "-"
        }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Start Date
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.formatDate($_.get(ballot, "start_date", ""), "DD MMMM YYYY") || "-"
        }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          End Date
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.formatDate($_.get(ballot, "end_date", ""), "DD MMMM YYYY") || "-"
        }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Opened Date
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.formatDate($_.get(ballot, "opened_date", ""), "DD MMMM YYYY") ||
          "-"
        }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Closed Date
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.formatDate($_.get(ballot, "closed_date", ""), "DD MMMM YYYY") ||
          "-"
        }}
      </div>
    </div>
    <div
      class="col-span-full sm:col-span-2 grid grid-cols-4 bg-gray-100 flex rounded-lg"
    >
      <div
        class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5 bg-gray-200 rounded-lg"
      >
        <div
          class="whitespace-nowrap overflow-hidden overflow-ellipsis font-semibold"
        >
          Status
        </div>
      </div>
      <div class="col-span-2 sm:col-span-2 flex items-center px-6 py-3.5">
        {{
          $h.convertIdToText($_.get(ballot, "status", ""), "ballotStatus") ||
          "-"
        }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { GetBallot } from "@/services";

export default defineComponent({
  name: "BallotViewInformation",
  props: {
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props) {
    const previousCaster = computed(() => {
      const user = _.get(props.ballot, "votes.0.user");

      if (!user) return null;
      const date = _.get(props.ballot, "votes.0.created_at");
      return user.name + " " + user.surname + " on " + date;
    });
    return {
      previousCaster,
    };
  },
});
</script>
