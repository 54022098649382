
import { defineComponent, reactive, ref, toRefs } from "vue";
import { PostCommittee } from "@/services";
import { ValidationErrors } from "@/utils/form";
import { Select2Options } from "@/global-components/select2";
import VueTiptap from "@/components/tiptap/Main.vue";
import CommitteeLevel from "@/views/committees/create/CommitteeLevel.vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "CommitteeIdentity",
  components: { CommitteeLevel, VueTiptap },
  props: {
    committeeType: { default: () => null },
    committeeId: { default: () => null },
    isWg: { default: () => null },
    formData: { type: Object as () => PostCommittee, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const editableFormData = reactive(toRefs<PostCommittee>(props.formData));
    const memberTemplateFormat = (obj: any): string => {
      if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
      return obj.text;
    };
    const memberSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        organization: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            acronym: "acronym",
          },
        },
      },
      templateSelection: memberTemplateFormat,
    });

    const userTemplateFormat = (obj: any): string => {
      if (obj.name) return helper.formatNameSurname(obj);
      // if (obj.name) return `${obj.salutation} ${obj.name} ${obj.surname}`;
      return obj.text;
    };

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            salutation: "salutation",
            title: "title",
          },
        },
      },
      templateSelection: userTemplateFormat,
    });

    return {
      editableFormData,
      memberSelect2Options,
      userSelect2Options,
    };
  },
});
