
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  components: {},
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("login")
        .addClass("error-page");
    });
  },
});
