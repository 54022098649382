
import { computed, defineComponent, onMounted, ref } from "vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { liaisonService, GetLiaisonResult } from "@/services";
import _ from "lodash";
import { Select2Options } from "@/global-components/select2";
import createOrUpdate from "@/utils/actions/create-or-update";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "CommitteeLiaisons",
  components: { TransactionStatement, Pagination },
  props: {
    committeeId: {
      required: true,
      default: () => null,
    },
    levelTcNumber: {
      default: () => null,
    },
  },
  setup(props) {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await liaisonService.committee.fetch(props.committeeId, query);
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) => {
        return await liaisonService.committee.delete(props.committeeId, id);
      },
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const {
      processing: submitProcessing,
      submit,
      setFormData,
      validationErrors,
    } = createOrUpdate({
      createApiMethod: async (params) => {
        return await liaisonService.committee.store(params);
      },
      successCallback(result: GetLiaisonResult) {
        newLiaisonId.value = null;
        if (result.kind === "ok") {
          list.data.splice(0, 0, result.data);
          list.meta.total++;
        }
      },
      params: {
        committeeId: "",
        liaisonId: "",
      },
    });

    const liaisonsIds = computed(() => list.data.map((item) => item.id));
    const newLiaisonId = ref(null);

    const liaisonSelect2Options = computed<Select2Options>(() => {
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@where": {
              level: [1, 2],
              type: ["TC", "SC"],
            },
            "@whereNot": {
              id: liaisonsIds.value,
              level_1_number: props.levelTcNumber,
            },
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
          },
        },
        templateSelection(obj: any): any {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    const onAddNewLiaison = (): void => {
      if (newLiaisonId.value) {
        setFormData({
          committeeId: props.committeeId,
          liaisonId: newLiaisonId.value,
        });
        submit();
      }
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      liaisonSelect2Options,
      newLiaisonId,
      submitProcessing,
      validationErrors,
      changePage,
      changePerPage,
      onAddNewLiaison,
    };
  },
});
