<template>
  <div>
    <div v-if="loading" class="text-center p-4 pt-8">
      <LoaderIcon class="spin-5 inline-block w-8 h-8" />
      <div class="mt-2 font-light">
        Please wait while the records are loading.
      </div>
    </div>
    <template v-if="list.data.length > 0">
      <div class="overflow-x-auto">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap text-center">Type</th>
              <th class="whitespace-nowrap">User</th>
              <th class="whitespace-nowrap text-right">Date & IP</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in list.data" :key="'record-' + row.id">
              <td class="text-center font-semibold w-1">
                {{ $h.snakeCaseToCamelCase(row.type) }}
              </td>
              <td>
                <div>
                  <router-link
                    class="text-blue-500 hover:text-blue-700"
                    :to="{
                      name: 'users.view',
                      params: { id: $_.get(row, 'user_id') },
                    }"
                  >
                    {{ $h.formatNameSurname($_.get(row, "user", {})) }}
                  </router-link>
                </div>
                <div class="mt-0.5">
                  <router-link
                    class="text-xs font-bold text-blue-500 hover:text-blue-700"
                    :to="{
                      name: 'documents',
                      params: { uuid: $_.get(row, 'uuid') },
                    }"
                  >
                    {{ $_.get(row, "uuid") }}
                  </router-link>
                </div>
              </td>
              <td class="text-right">
                <div>
                  {{
                    $h.formatDate($_.get(row, "created_at"), "DD.MM.YYYY HH:mm")
                  }}
                  GMT
                </div>
                <div class="text-xs font-bold">{{ $_.get(row, "ip") }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        class="mt-4"
        :current="filter.query.page"
        :per-page="filter.query.paginate * 1"
        :total="$_.get(list, 'meta.total', 0)"
        :on-refresh="onRefresh"
        @update-page="changePage"
        @update-perpage="changePerPage"
      />
    </template>
    <div v-else class="text-center p-4">No results found</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, onMounted, watch } from "vue";
import { useGeneralActions } from "@/utils/actions";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "DocumentLogs",
  components: { Pagination },
  props: {
    withoutRoute: {
      type: Boolean,
      default: () => false,
    },
    filterQuery: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "document_log",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "type",
            "user_id",
            "uuid",
            "log",
            "ip",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          user: {
            "@select": ["id", "title", "salutation", "name", "surname"],
          },
        },
        filterFormat: {
          uuid: "@where.uuid",
          user_id: "@where.user_id",
          type: "@where.type",
        },
      },
    });

    const {
      filter,
      formattedQuery,
      changePage,
      changePerPage,
      applyFilter,
      clearFilter,
    } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
        ..._.cloneDeep(props.filterQuery),
      },
      props.withoutRoute
    );

    watch(
      () => props.filterQuery,
      (fields: Record<string, any>) => {
        _.forEach(fields, (val, key) => {
          _.set(filter.query, key, val);
        });
      },
      { flush: "post", deep: true }
    );

    const bindInstance = () => {
      const applyBind = inject(`bind[applyFunction]`);
      if (typeof applyBind === "function") applyBind(applyFilter);
      const clearBind = inject(`bind[clearFunction]`);
      if (typeof clearBind === "function") clearBind(clearFilter);
    };

    const onRefresh = () => {
      fetch(formattedQuery());
    };

    onMounted(() => {
      onRefresh();
      bindInstance();
    });

    return {
      loading,
      filter,
      list,
      changePage,
      changePerPage,
      onRefresh,
    };
  },
});
</script>
