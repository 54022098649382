
import { defineComponent, onMounted } from "vue";
import { documentService, GetMeeting } from "@/services";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "MeetingDocuments",
  components: { Pagination, TransactionStatement },
  props: {
    canUpload: {
      type: Boolean,
      default: () => false,
    },
    canEdit: {
      type: Boolean,
      default: () => false,
    },
    meetingId: { default: () => null },
    meeting: { type: Object as () => GetMeeting, default: () => ({}) },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "document",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "uuid",
            "n_number",
            "version",
            "title",
            "version",
            "file_extension",
            "file_path",
            "file_size",
            "language",
            "type",
            "created_at",
          ],
          "@where": {
            resource_type: "meeting",
            resource_id: props.meetingId,
          },
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: documentService.delete,
      successCallback(uuid) {
        if (uuid) {
          const index = _.findIndex(list.data, { uuid });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const goToDocumentCreate = () => {
      router.push({
        name: "documents.create",
        params: {
          committeeId: props.meeting.committee_id,
        },
        query: {
          resource_type: "meeting",
          resource_id: props.meetingId as any,
        },
      });
    };

    const goToView = (uuid: any) => {
      router.push({ name: "documents", params: { uuid } });
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      changePage,
      changePerPage,
      goToDocumentCreate,
      goToView,
    };
  },
});
