
import { computed, defineComponent, onMounted, Ref, watch } from "vue";
import ViewAbout from "./About.vue";
import ViewLibraries from "./Library.vue";
import ViewProjects from "./Projects.vue";
import ViewBallots from "./Ballots.vue";
import ViewMeetings from "./Meeting.vue";
import ViewMembers from "./Members.vue";
import ViewCommitteeLiaisons from "./CommitteeLiaisons.vue";
import ViewOrganizationLiaisons from "./OrganizationLiaisons.vue";
import ViewCommitteeUsers from "./CommitteeUsers.vue";
import ViewCommitteeUsersWithVoters from "./CommitteeUsersWithVoters.vue";
import ViewStructures from "@/views/committees/view/Structures.vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { committeeService, GetCommittee } from "@/services";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetCommittee>;
}

export default defineComponent({
  name: "ViewCommittee",
  components: {
    ViewStructures,
    ViewAbout,
    ViewLibraries,
    ViewProjects,
    ViewBallots,
    ViewMeetings,
    ViewMembers,
    ViewCommitteeLiaisons,
    ViewOrganizationLiaisons,
    ViewCommitteeUsers,
    ViewCommitteeUsersWithVoters
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: committeeService.show,
      errorCallback() {
        router.go(-1);
      }
    }) as FetchRecord;

    const id = computed(() => route.params.id);

    const isWg = computed(() => {
      return data.value.type === "WG";
    });

    const committeeOrWorkingGroup = computed(() => {
      return isWg.value ? "Working Group" : "Committee";
    });

    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `View ${committeeOrWorkingGroup.value} (#${id.value})`;
      return `(${data.value.acronym}) ${data.value.title}`;
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      isWg,
      committeeOrWorkingGroup,
      headerTitle,
      detail: data
    };
  }
});
