<template>
  <div class="iframe--viewer" v-if="show">
    <div
      class="iframe--viewer__bar flex justify-end md:justify-between py-1 px-4"
    >
      <div class="hidden md:block">{{ iframeUrl }}</div>
      <div class="flex items-center">
        <a href="javascript:;" @click="onSave" class="flex items-center mr-4">
          Save
          <DownloadIcon class="inline w-6 h-6 ml-2" />
        </a>
        <a
          href="javascript:;"
          @click="$router.go(-1)"
          class="flex items-center"
        >
          Close
          <XIcon class="inline w-8 h-8 ml-1" />
        </a>
      </div>
    </div>
    <div class="iframe--viewer__content">
      <iframe class="absolute" ref="iframeRef" :src="iframeUrl" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import _ from "lodash";
import fileDownload from "js-file-download";

export default defineComponent({
  name: "IframeViewer",
  setup() {
    const route = useRoute();
    const show = ref<boolean>(false);
    const iframeUrl = ref<string>("");
    const iframeRef = ref<HTMLIFrameElement>();

    function getScrollbarWidth(el: HTMLElement | string) {
      return window.innerWidth - cash(el)[0].clientWidth;
    }

    watch(
      computed(() => route.query),
      () => {
        iframeUrl.value = _.get(route.query, "iUrl", "") as string;
      }
    );
    watch(iframeUrl, (val) => {
      show.value = !!val;
      if (show.value) {
        cash("body")
          .css(
            "padding-right",
            `${
              parseInt(cash("body").css("padding-right")) +
              getScrollbarWidth("html")
            }px`
          )
          .addClass("overflow-y-hidden");
      } else {
        cash("body").removeClass("overflow-y-hidden").css("padding-right", "");
      }
    });

    const onSave = async () => {
      const buffer = await fetch(iframeUrl.value).then((res) => res.arrayBuffer());
      fileDownload(buffer, new Date() + ".pdf", "application/pdf");
    };

    return {
      show,
      iframeRef,
      iframeUrl,
      onSave,
    };
  },
});
</script>
