import { State as RootState } from "@/store";
import { GetterTree } from "vuex";
import { State as LocalState } from "./state";

// Getters types
export type Getters = {
  smiicCommittee(state: LocalState): Record<string, any> | null;
  getCommittee(state: LocalState): (id: any) => Record<string, any> | null;
};

// Getters
export const getters: GetterTree<LocalState, RootState> & Getters = {
  smiicCommittee: (state) => {
    let result: Record<string, any> | null = null;
    state.committees.map((committee) => {
      if (!result && committee.level_1_type === "SMIIC") result = committee;
    });
    return result;
  },
  getCommittee: (state) => (id: any) => {
    const index = _.findIndex(state.committees, { id: id * 1 });
    return _.get(state.committees, index, null);
  },
};
