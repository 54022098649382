
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { PostDocument } from "@/services";
import { cancelFileUpload } from "@/views/documents/create/upload";
import { DocumentPDFCreation } from "@/utils/pdf-creation/document";
import { useStore } from "@/store";

export default defineComponent({
  name: "DocumentSummary",
  props: {
    formData: { type: Object as () => PostDocument, default: () => ({}) },
    state: { type: Object, default: () => ({}) },
    onUpload: { type: Function, required: true },
  },
  setup(props) {
    const store = useStore();
    const localState = reactive(toRefs(props.state));
    localState.editedFile = null;
    const iframeUrl = ref<string>("");
    const preparingDocument = ref<boolean>(false);

    const committee = computed(() =>
      store.getters["committee/getCommittee"](props.formData.committee_id)
    );

    const prepareNDocumentPdf = async () => {
      preparingDocument.value = true;
      const fileLocalUrls = [];
      if (_.get(props, "state.selectedFile")) {
        fileLocalUrls.push(
          URL.createObjectURL(_.get(props, "state.selectedFile"))
        );
      }
      new DocumentPDFCreation()
        .process({
          watermark: "PREVIEW",
          formData: props.formData,
          resource: props.state.resource,
          replace: props.state.replace,
          noteJsonData: props.state.noteJsonData,
          urls: fileLocalUrls,
          committee: committee.value,
        })
        .then((pdfCreation) => {
          pdfCreation
            .output()
            .then((url) => {
              iframeUrl.value = url;
            })
            .finally(() => {
              preparingDocument.value = false;
            });
        });
    };

    const validateFile = () => {
      if (props.formData.is_n_document) {
        prepareNDocumentPdf();
      } else {
        const type = _.get(props, "state.selectedFile.type", "");
        if (type === "application/pdf") {
          iframeUrl.value = URL.createObjectURL(
            _.get(props, "state.selectedFile")
          );
        } else {
          if (iframeUrl.value) URL.revokeObjectURL(iframeUrl.value);
          iframeUrl.value = "";
        }
      }
    };

    const onCancelUpload = () => {
      cancelFileUpload();
    };

    onBeforeUnmount(() => {
      if (iframeUrl.value) URL.revokeObjectURL(iframeUrl.value);
    });

    onMounted(() => {
      validateFile()
      if (!props.formData.is_n_document) props.onUpload();
    });
    return { iframeUrl, preparingDocument, onCancelUpload };
  },
});
