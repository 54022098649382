
import { defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "Submissions",
  components: {
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords } = useGeneralActions();
    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "submission",
        config: {
          "@page": 1,
          "@select": ["id", "text", "files", "project_id", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          project: {
            "@select": ["id", "reference"],
          },
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
    } = filterRecords(fetch, {
      page: 1,
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      applyFilter,
      clearFilter,
      changePage,
    };
  },
});
