<template>
  <!-- BEGIN: Folder Modal -->
  <div id="submission-modal" class="modal">
    <div class="modal__content p-5">
      <div class="text-3xl text-theme-1">Create Submission</div>
      <div class="border-t h-0.5 mt-3 mb-4"></div>
      <VueInputGroup :validation-errors="validationErrors.get('text')">
        <template v-slot:label>
          <label class="block mb-2">Description</label>
        </template>
        <VueTextarea
          class="input--sm"
          :validation-errors="validationErrors.get('text')"
          v-model="formData.text"
        />
      </VueInputGroup>
      <VueInputGroup
        class="mt-4"
        :validation-errors="validationErrors.get('files')"
      >
        <template v-slot:label>
          <label class="block mb-2">Select Document</label>
        </template>
        <Select2Input
          :options="documentSelect2Options"
          v-model="documentValue"
          :validation-errors="validationErrors.get('files')"
          @update:selected-item="onAddSubmissionFile"
        />
      </VueInputGroup>
      <div class="overflow-x-auto mt-4" v-if="formData.files.length > 0">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Document</th>
              <th class="whitespace-nowrap text-right">Functions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(row, index) in formData.files"
              :key="'submission-document-' + row.uuid"
            >
              <td>
                <span class="font-semibold" v-if="$_.get(row, 'n_number', '')"
                  >N{{ $_.get(row, "n_number", "") }}
                </span>
                {{ $_.get(row, "title", "") }}
              </td>
              <td class="text-right">
                <Tippy
                  tag="button"
                  content="Delete"
                  :options="{ placement: 'bottom' }"
                  class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                  @click="() => formData.files.splice(index, 1)"
                >
                  <Trash2Icon class="inline w-5 h-5" />
                </Tippy>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-end mt-6">
        <VueButton
          :loading="processing"
          @click="onSubmit"
          class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
          >Save Submission</VueButton
        >
      </div>
    </div>
  </div>
  <!-- END: Folder Modal -->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import createOrUpdate, {
  GeneralCreateOrUpdate,
} from "@/utils/actions/create-or-update";
import {
  submissionService,
  GetSubmissionResult,
  PostSubmission,
} from "@/services";
import _ from "lodash";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostSubmission;
}

export default defineComponent({
  name: "CreateOrUpdateSubmissionModal",
  props: {
    list: { type: Array as () => Record<string, any>[], default: () => [] },
    filterQuery: { type: Object, default: () => ({}) },
    projectId: { default: () => null },
    committeeId: { default: () => null },
  },
  setup(props, { emit }) {
    const localList = ref<Record<string, any>[]>(props.list);
    watch(
      () => props.list,
      (val) => (localList.value = val),
      { deep: true, flush: "post" }
    );

    const submissionId = ref<any>(null);
    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: submissionService.store,
      updateApiMethod: submissionService.update,
      successCallback(result: GetSubmissionResult) {
        if (result.kind === "ok") {
          const submissionIndex = _.findIndex(props.list, {
            id: submissionId.value,
          });
          if (submissionIndex > -1) {
            localList.value.splice(submissionIndex, 1, result.data);
          } else {
            localList.value.splice(0, 0, result.data);
            emit("update:add-item");
          }
          submissionId.value = null;
          setFormData({ name: "", description: "" });
          emit("update:list", localList.value);
          cash("#submission-modal").modal("hide");
        }
      },
      params: {
        text: "",
        files: [],
        project_id: props.projectId,
      },
    }) as CreateOrUpdate;

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            uuid: "uuid",
            n_number: "n_number",
            version: "version",
          },
          "@order": ["n_number:desc", "created_at:desc"],
          "@where": {
            committee_id: props.committeeId,
          },
          "@whereNot": {
            uuid: formData.files.map((file) => file.uuid),
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const openEditModal = (submission: Record<string, any>) => {
      submissionId.value = submission.id;
      setFormData({
        text: _.cloneDeep(_.get(submission, "text", "")),
        files: _.cloneDeep(_.get(submission, "files", [])),
      });
      cash("#submission-modal").modal("show");
    };

    const onSubmit = () => submit(submissionId.value);

    const documentValue = ref<string>("");
    const onAddSubmissionFile = (document: Record<string, any>) => {
      if (document) {
        formData.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.text,
          n_number: document.n_number,
          file_name: document.file_name,
          file_size: document.file_size,
        });
        setTimeout(() => {
          documentValue.value = "";
        }, 100);
      }
    };

    onMounted(() => {
      cash("#submission-modal").modal("on.hide", () => {
        submissionId.value = null;
        setFormData({ text: "", files: [] });
      });
    });

    return {
      documentValue,
      processing,
      formData,
      validationErrors,
      documentSelect2Options,
      openEditModal,
      onSubmit,
      onAddSubmissionFile,
    };
  },
});
</script>
