<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconFolder
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">BALLOTS</div>
      <div class="text-white text-sm font-light">
        Managing balloting process in standards development
      </div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">Committee</label>
          </template>
          <Select2Input
            :options="committeeSelect2Options"
            v-model="filter.query.committee_id"
          />
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">Type</label>
          </template>
          <VueSelect v-model="filter.query.type">
            <option value="">All</option>
            <option value="PWI">PWI</option>
            <option value="NP">NP</option>
            <option value="WD">WD</option>
            <option value="CD">CD</option>
            <option value="DS">DS</option>
            <option value="FDS">FDS</option>
            <option value="SR">SR</option>
            <option value="SMIIC">Vote to All Member</option>
          </VueSelect>
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">Reference</label>
          </template>
          <VueInput
            v-model="filter.query.reference"
            @keydown.enter="applyFilter"
          />
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">Start Date</label>
          </template>
          <Litepicker v-model="filter.query.start_date" />
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">End Date</label>
          </template>
          <Litepicker v-model="filter.query.end_date" />
        </VueInputGroup>
        <VueInputGroup class="col-span-full sm:col-span-6 md:col-span-4">
          <template v-slot:label>
            <label class="block text-white text-lg">Status</label>
          </template>
          <VueSelect v-model="filter.query.status">
            <option value="">All</option>
            <option value="CREATED">Created</option>
            <option value="OPENED">Opened</option>
            <option value="CLOSED">Closed</option>
          </VueSelect>
        </VueInputGroup>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4 z-10"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <!-- BEGIN: Top Buttons -->
      <div
        class="flex justify-end transform -mt-9 sm:-mt-16"
        v-if="$store.getters['auth/canCreateAnyBallot']"
      >
        <div class="dropdown">
          <button
            class="dropdown-toggle button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          >
            <PlusIcon class="inline w-6 h-6" />
            Create Ballot
            <div
              class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
            >
              <ArrowDownIcon class="inline w-6 h-6" />
            </div>
          </button>
          <div class="dropdown-box w-64">
            <CreateBallotDropdownList
              class="dropdown-box__content box"
              :can-create-adhoc-ballot="
                $store.getters['auth/canCreateCommitteeBallot']()
              "
              :can-create-committee-ballot="
                $store.getters['auth/canCreateCommitteeBallot']()
              "
              :can-create-smiic-ballot="
                $store.getters['auth/canCreateSmiicBallot']()
              "
            />
          </div>
        </div>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-3">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->
          <table class="table w-full">
            <thead>
              <tr>
                <Tippy
                  tag="th"
                  content="Committee / Working Group"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >Committee
                </Tippy>
                <th class="whitespace-nowrap text-center w-1">Ballot Type</th>
                <th class="whitespace-nowrap">Reference & Title</th>
                <th class="whitespace-nowrap text-center">Status</th>
                <th class="whitespace-nowrap text-right">Start Date</th>
                <th class="whitespace-nowrap text-right">End Date</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.id"
              >
                <td class="text-center">
                  <div
                    class="inline-block bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md"
                  >
                    {{ $_.get(row, "committee.acronym", "") || "SMIIC" }}
                  </div>
                </td>
                <td class="text-center">
                  <div
                    class="inline-block bg-yellow-300 bg-opacity-40 px-3 py-1 rounded-md"
                  >
                    {{ $_.get(row, "ballot_type", "") || "-" }}
                  </div>
                </td>
                <td>
                  <a
                    href="javascript:;"
                    class="text-blue-500 hover:text-blue-700"
                    @click="goToView(row.id)"
                    >{{ row.reference || "-" }}</a
                  >
                </td>
                <td
                  class="whitespace-nowrap text-center w-0"
                  :class="{
                    'text-red-400': row.status === 'CLOSED',
                    'text-theme-1': row.status === 'OPENED',
                  }"
                >
                  {{ row.status }}
                </td>
                <td class="whitespace-nowrap text-right w-0">
                  {{ $h.formatDate(row.start_date, "DD MMMM YYYY") }}
                </td>
                <td class="whitespace-nowrap text-right w-0">
                  {{ $h.formatDate(row.end_date, "DD MMMM YYYY") }}
                </td>
                <td class="whitespace-nowrap text-right w-0">
                  <div class="inline-flex">
                    <Tippy
                      tag="button"
                      content="See Details"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="() => goToView(row.id)"
                      v-if="$_.get(row, 'abilities.can_view', false)"
                    >
                      <EyeIcon class="inline w-5 h-5" />
                    </Tippy>

                    <Tippy
                      v-if="$_.get(row, 'abilities.can_delete', false)"
                      tag="button"
                      content="Delete"
                      :options="{ placement: 'bottom' }"
                      class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                      @click="() => triggerDelete([row.id])"
                    >
                      <Trash2Icon class="inline w-5 h-5" />
                    </Tippy>
                  </div>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          v-if="list.meta.total > 0"
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { ballotService } from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import CreateBallotDropdownList from "@/views/ballots/list/CreateBallotDropdownList.vue";
import { Select2Options } from "@/global-components/select2";

export default defineComponent({
  components: {
    CreateBallotDropdownList,
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "ballot",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "project_id",
            "committee_id",
            "ballot_type",
            "reference",
            "english_title",
            "status",
            "start_date",
            "end_date",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          committee: {
            "@select": ["id", "acronym"],
          },
        },
        filterFormat: {
          reference: "@where._reference",
          title: "@where._english_title",
          committee_id: "@where.committee_id",
          type: "@where.ballot_type",
          status: "@where.status",
          start_date: "@where.start_date",
          end_date: "@where.end_date",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      committee_id: "",
      reference: "",
      title: "",
      type: "",
      status: "",
      start_date: "",
      end_date: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: ballotService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.value) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "ballots.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "ballots.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      committeeSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
    };
  },
});
</script>
