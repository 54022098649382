
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "VueFileBrowser",
  emits: ["update:modelValue"],
  props: {
    modelValue: {},
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue);

    watch(inputValue, (val) => {
      emit("update:modelValue", val);
    });

    watch(
      computed(() => props.modelValue),
      (val) => {
        if (val !== inputValue.value) inputValue.value = val;
      }
    );

    const onChangeFile = (e: any): void => {
      const files = e.target.files || [];
      inputValue.value = files[0] || null;
    };

    return {
      props,
      emit,
      inputValue,
      onChangeFile,
    };
  },
});
