<template>
  <UserRoleCreateOrUpdate
    ref="refCreateOrUpdate"
    :defined-roles="definedRoles"
    @update:defined-roles="(val) => $emit('update:defined-roles', val)"
    :user-id="userId"
    :administrated-by-id="detail.administrated_by_id"
    v-if="canEdit"
  />
  <!-- BEGIN: Table -->
  <div class="transform -translate-y-16">
    <div class="grid grid-cols-12 gap-x-4 gap-y-8 px-8 text-theme-1 -intro-y">
      <a
        href="javascript:;"
        v-for="roleGroup in state.roleGroups"
        :key="roleGroup.type"
        class="col-span-full sm:col-span-6 md:col-span-3 h-32 relative zoom-in"
        @click="() => onSelectRoleGroup(roleGroup)"
      >
        <div
          class="absolute h-1/2 w-10/12 shadow-xl -bottom-1.5 left-0 right-0 mx-auto bg-gray-500 rounded-lg transition-all ease-in-out"
          :class="{
            '!bg-theme-1 !-bottom-3.5':
              roleGroup.type === state.selectedRoleGroup,
          }"
        ></div>
        <div
          class="relative h-full flex items-center bg-white rounded-lg p-3 shadow-lg"
        >
          <div
            class="text-center flex-1 text-4xl relative flex items-center justify-center"
          >
            <div
              class="bg-gray-300 w-16 h-16 rounded-2xl transform rotate-12"
            ></div>
            <div
              class="absolute bg-gray-500 text-white w-14 h-14 rounded-2xl p-2 transition-all ease-in-out"
              :class="{
                '!bg-theme-1': roleGroup.type === state.selectedRoleGroup,
              }"
            >
              {{ countScope(roleGroup.type) }}
            </div>
          </div>
          <div class="flex-1">{{ roleGroup.title }}</div>
        </div>
      </a>
    </div>

    <div class="mt-16">
      <UserRoleBehaviourList
        v-for="roleType in visibleRoleGroup.types"
        :key="roleType.behaviour"
        :role-type="roleType"
        :defined-roles="definedRoles"
        :user-id="userId"
        :user="detail"
        @update:defined-roles="(val) => $emit('update:defined-roles', val)"
        :on-add-role="onAddRole"
        :on-edit-role="onEditRole"
      />
    </div>
  </div>
  <!-- END: Table -->
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { GetRole, GetRoleType, GetUser } from "@/services";
import { useStore } from "@/store";
import { IState, IStateRoleGroup } from "./";
import UserRoleBehaviourList from "./RoleBehaviourList.vue";
import UserRoleCreateOrUpdate from "@/views/users/view/roles/CreateOrUpdate.vue";
import _ from "lodash";

export default defineComponent({
  name: "UserRoles",
  components: { UserRoleCreateOrUpdate, UserRoleBehaviourList },
  props: {
    detail: {
      type: Object as () => GetUser,
      required: true,
    },
    userId: {
      type: Number,
      default: () => 0,
    },
    definedRoles: {
      type: Array as () => GetRole[],
      default: () => [],
    },
  },
  emits: ["update:defined-components"],
  setup(props) {
    const refCreateOrUpdate = ref();

    const store = useStore();
    const allRoleTypes = computed<GetRoleType[]>(
      () => store.state.user.roleTypes
    );
    const state = reactive<IState>({
      roleGroups: [
        {
          title: "Committee Roles",
          type: "committee",
          types: [],
        },
        {
          title: "Centralized Roles",
          type: "centralized",
          types: [],
        },
        {
          title: "Project Roles",
          type: "project",
          types: [],
        },
        {
          title: "Staff Roles",
          type: "staff",
          types: [],
        },
      ],
      selectedRoleGroup: "committee",
    });

    const visibleRoleGroup = computed<IStateRoleGroup>(() => {
      const index = _.findIndex(state.roleGroups, {
        type: state.selectedRoleGroup,
      });
      const group = _.cloneDeep(
        _.get(state.roleGroups, index, {})
      ) as IStateRoleGroup;
      group.types = _.sortBy(group.types, (o: GetRoleType) => {
        return _.findIndex(props.definedRoles, { behaviour: o.behaviour }) > -1
          ? 0
          : 1;
      });
      return group;
    });

    const userDefinedRoles = computed<Record<string, any>[]>(() => []);

    const _init = () => {
      state.roleGroups.map((roleGroup) => {
        const types: GetRoleType[] = [];
        allRoleTypes.value.map((roleType) => {
          if (roleType.type === roleGroup.type) types.push(roleType);
        });
        roleGroup.types = types;
      });
    };

    onMounted(() => {
      if (allRoleTypes.value.length > 0) {
        _init();
      }
    });

    const unwatch = watch(
      allRoleTypes,
      () => {
        _init();
        unwatch();
      },
      {
        deep: true,
        flush: "post",
      }
    );

    const canEdit = computed<boolean>(() => {
      return _.get(props.detail, "abilities.can_update", false);
    });

    const onSelectRoleGroup = (roleGroup: IStateRoleGroup) => {
      state.selectedRoleGroup = roleGroup.type;
    };

    const onAddRole = (roleType: GetRoleType) => {
      if (refCreateOrUpdate.value) {
        refCreateOrUpdate.value.onOpen(roleType);
      }
    };

    const onEditRole = (roleType: GetRoleType, role: GetRole) => {
      if (refCreateOrUpdate.value) {
        refCreateOrUpdate.value.onOpen(roleType, role);
      }
    };

    const countScope = (type: string): number => {
      let count = 0;
      props.definedRoles.map((role) => {
        if (role.meta.type === type) count++;
      });
      return count;
    };

    return {
      refCreateOrUpdate,
      state,
      visibleRoleGroup,
      userDefinedRoles,
      canEdit,
      countScope,
      onSelectRoleGroup,
      onAddRole,
      onEditRole,
    };
  },
});
</script>
