
import { computed, defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { countryService } from "@/services";
import _ from "lodash";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Countries",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();

    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "country",
        config: {
          "@page": 1,
          "@select": ["id", "title", "code", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
        filterFormat: {
          title: "@where.q",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: countryService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const filterQuickSearch = computed({
      get: () => filter.query.title,
      set: (value) => {
        filter.query.title = value;
      },
    });

    const goToEdit = (value: number) => {
      router.push({ name: "countries.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      filterQuickSearch,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
    };
  },
});
