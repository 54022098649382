<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEye
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <!-- BEGIN: Top Buttons -->
      <div class="flex justify-end transform -mt-9 sm:-mt-16" v-if="canEdit">
        <router-link
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          :to="{ name: 'organizations.edit', params: { id } }"
        >
          Edit Organization
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <Edit2Icon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <div class="accordion divide-y" v-else>
        <Information :detail="detail" />
        <ViewCommitteeLiaisons
          v-if="isLiaison"
          :organization-id="id"
          :organization="detail"
        />
        <template v-else>
          <ParticipatingRoles
            :member-id="id"
            :list="participatingRoles"
            :can-edit="canEdit"
          />
          <ParticipatingRoles
            :member-id="id"
            :list="observationRoles"
            :can-edit="canEdit"
            title="Observation Roles"
          />
        </template>
        <OrganizationUsers v-if="canEdit" :organization="detail" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, watch } from "vue";
import Information from "./Information.vue";
import ParticipatingRoles from "./ParticipatingRoles.vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import ViewCommitteeLiaisons from "@/views/organizations/view/CommitteeLiaisons.vue";
import OrganizationUsers from "@/views/organizations/view/OrganizationUsers.vue";

export default defineComponent({
  name: "ViewOrganization",
  components: {
    OrganizationUsers,
    ViewCommitteeLiaisons,
    Information,
    ParticipatingRoles,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      errorCallback() {
        router.go(-1);
      },
      selectionQuery: {
        source: "organization",
        config: {
          "@select": [
            "id",
            "title",
            "acronym",
            "information",
            "contact",
            "description",
            "visible_on_website",
            "is_accreditation_body",
            "is_metrology_body",
            "is_standardization_body",
            "number_of_standards_adopted",
            "country_code",
            "created_at",
          ],
          country: {
            "@select": ["id", "title", "code"],
          },
          committees: {
            "@select": ["id", "title", "acronym", "type", "level_1_number"],
            "@order": [
              "type:asc",
              "level_1_number:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
          },
        },
      },
    });

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value)
        return `VIEW ORGANIZATION (#${id.value})`;
      return `(${data.value.acronym}) ${data.value.title}`;
    });
    const participatingRoles = computed<Record<string, any>[]>(() => {
      const committees = _.get(data.value, "committees", []);
      return committees.filter((committee: Record<string, any>) => {
        return _.get(committee, "pivot.member_type", "") === "P";
      });
    });
    const observationRoles = computed<Record<string, any>[]>(() => {
      const committees = _.get(data.value, "committees", []);
      return committees.filter((committee: Record<string, any>) => {
        return _.get(committee, "pivot.member_type", "") === "O";
      });
    });

    const canEdit = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_update", false);
    });

    const isLiaison = computed<boolean>(() => {
      return (
        !_.get(data.value, "is_accreditation_body", false) &&
        !_.get(data.value, "is_metrology_body", false) &&
        !_.get(data.value, "is_standardization_body", false)
      );
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      headerTitle,
      participatingRoles,
      observationRoles,
      detail: data,
      canEdit,
      isLiaison,
    };
  },
});
</script>
