import { computed, reactive, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { GetDocument, PostDocument, documentService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { fileUpload } from "@/views/documents/create/upload";
import { helper } from "@/utils/helper";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { DocumentPDFCreation } from "@/utils/pdf-creation/document";
import { useStore } from "@/store";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetDocument>;
}

export const init = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const state = reactive<Record<string, any>>({
    activeStep: "info",
    committeeId: _.get(route.params, "committeeId", null),
    selectedFile: null,
    editedFile: null,
    upload: {
      processing: false,
      percent: 0,
      url: "",
      uuid: "",
      hasError: false,
      error: "",
    },
    folderId: _.get(route.query, "folderId", null),
    resource: null,
    replace: null,
    noteJsonData: [],
    n_number: null,
  });

  const versionUUID = computed(() => _.get(route.query, "v", ""));

  const committee = computed(() =>
    store.getters["committee/getCommittee"](formData.committee_id)
  );

  const { createOrUpdate, fetchRecord } = useGeneralActions();

  const { loading, data, fetch } = fetchRecord({
    fetchApiMethod: documentService.show,
    errorCallback() {
      router.go(-1);
    },
    successCallback() {
      state.n_number = data.value.n_number;
      setFormData({
        committee_id: data.value.committee_id,
        folder_id: data.value.folder_id,
        n_number: data.value.n_number,
        version: data.value.version * 1 + 1,
        resource_type: data.value.resource_type,
        resource_id: data.value.resource_id,
        reference: data.value.reference,
        title: data.value.title,
        type: data.value.type,
        expected_action: data.value.expected_action,
        exp_action_due_date: data.value.exp_action_due_date,
        document_date: data.value.document_date,
        note: data.value.note,
        keywords: data.value.keywords,
        source: data.value.source,
        pages: data.value.pages,
        language: data.value.language,
        replaces_id: _.get(data.value, "replaces.id", null),
      });
    },
  }) as FetchRecord;

  if (versionUUID.value) {
    fetch(versionUUID.value).finally();
  }

  const { formData, validationErrors, setFormData } = createOrUpdate({
    params: {
      is_n_document: true,
      folder_id: state.folderId,
      committee_id: state.committeeId,
      type: _.get(route.query, "type", 101),
      uuid: helper.uuidv4(),
      n_number: "",
      keywords: "",
      source: "",
      pages: "",
      language: "english",
      title: "",
      resource_type: _.get(route.query, "resource_type", "") || "committee",
      resource_id: _.cloneDeep(state.committeeId),
      version: "1",
      expected_action: "",
      exp_action_due_date: "",
      document_date: "",
      note: "",
      replaces_id: "",
      replaced_by_id: "",
      file_extension: "",
      file_name: "",
      file_size: "",
    },
  });

  watch(
    () => state.selectedFile,
    (val, oldVal) => {
      if (!formData.title || _.get(oldVal, "name", "") === formData.title) {
        formData.title = _.get(val, "name", "").replace(/_/g, " ");
      }
      formData.file_name = _.get(val, "name", "");
      formData.file_size = _.get(val, "size", "");
    }
  );

  const onChangeStep = (val: string): void => {
    state.activeStep = val;
  };

  const onValidateAndUpload = (): void => {
    validationErrors.value.clear();
    documentService.validate(formData).then((result) => {
      if (result.kind === "ok") {
        onUpload();
      } else if (result.kind === "validation") {
        validationErrors.value.record(result.fields);
      }
    });
  };

  const onPreview = (): void => {
    validationErrors.value.clear();
    if (formData.is_n_document) {
      if (!state.selectedFile) formData.file_name = `${formData.title}.pdf`;
    }
    documentService.validate(formData).then((result) => {
      if (result.kind === "ok") {
        state.activeStep = "summary";
      } else if (result.kind === "validation") {
        validationErrors.value.record(result.fields);
      }
    });
  };

  const onUpload = (): void => {
    if (state.upload.processing) return;

    const filesLocalUrl: any = [];
    if (state.selectedFile)
      filesLocalUrl.push(URL.createObjectURL(state.selectedFile));

    documentService.generateSignedUploadUrl().then((result) => {
      if (result.kind !== "ok") {
        state.upload.processing = false;
        ToastNotify({ text: result.message, className: "error" });
      } else {
        state.upload.url = result.data.url;
        state.upload.uuid = result.data.uuid;
        formData.uuid = result.data.uuid;

        if (formData.is_n_document) {
          new DocumentPDFCreation()
            .process({
              formData,
              resource: state.resource,
              replace: state.replace,
              noteJsonData: state.noteJsonData,
              urls: filesLocalUrl,
              committee: committee.value,
            })
            .then((pdfCreation) => {
              pdfCreation.outputBlob().then((blob) => {
                if (blob) {
                  state.editedFile = new File(
                    [blob],
                    state.selectedFile
                      ? state.selectedFile.name
                      : formData.file_name,
                    blob
                  );
                }

                fileUpload({
                  router,
                  state,
                  formData: formData as PostDocument,
                });
              });
            });
        } else {
          fileUpload({
            router,
            state,
            formData: formData as PostDocument,
          });
        }
      }
    });
  };

  return {
    loading,
    state,
    formData,
    validationErrors,
    onPreview,
    onUpload,
    onValidateAndUpload,
    onChangeStep,
  };
};
