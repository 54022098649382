
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { countryService, PostCountry } from "@/services";
import VueTiptap from "@/components/tiptap/Main.vue";

export default defineComponent({
  name: "CreateCountry",
  components: { VueTiptap },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT COUNTRY (#${id.value})`;
      return "CREATE COUNTRY";
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: countryService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: countryService.store,
      updateApiMethod: countryService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        code: "",
        title: "",
        description: "",
      } as PostCountry,
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit,
    };
  },
});
