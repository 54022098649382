
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  reactive,
  Ref,
} from "vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import VueTiptap from "@/components/tiptap/Main.vue";
import createOrUpdate, {
  GeneralCreateOrUpdate,
} from "@/utils/actions/create-or-update";
import { PostEmail, emailService } from "@/services";
import Select2Input from "@/global-components/select2/Main.vue";
import VueButton from "@/global-components/form-elements/button/Main.vue";
import { SendEmailOptions } from "@/components/email-to-members/index";
import { ConfirmRef } from "@/components/confirm";
import { useStore } from "@/store";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostEmail;
}

export default defineComponent({
  name: "EmailToMembers",
  components: { VueButton, Select2Input, VueTiptap, VueInput, VueInputGroup },
  setup() {
    const store = useStore();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const state = reactive<Record<string, any>>({
      committee: null,
      documentValue: null,
      previewUrl: null,
      success: () => null,
    });

    const nameSurname = computed(() => {
      return `${_.get(store.state.auth.profile, "name", "")} ${_.get(
        store.state.auth.profile,
        "surname",
        ""
      )}`;
    });

    const {
      processing,
      formData,
      validationErrors,
      submit,
      setFormData,
    } = createOrUpdate({
      createApiMethod: (params) =>
        emailService.store(_.get(state.committee, "id"), params),
      successCallback(result: any) {
        if (result.kind === "ok") {
          if (state.success) state.success();
          hide();
        }
      },
      params: {
        users_full_list: false,
        subject: "",
        body: "",
        files: [],
      },
      successMessage: "Mail successfully sent",
    }) as CreateOrUpdate;

    const documentSelect2Options = computed(() => ({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "id",
            text: "title",
            uuid: "uuid",
            n_number: "n_number",
            version: "version",
          },
          "@order": ["created_at:desc"],
          "@where": {
            committee_id: _.get(state.committee, "id"),
          },
          "@whereNot": {
            uuid: formData.files.map((file) => file.uuid),
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    }));

    const onAddSubmissionFile = (document: Record<string, any>) => {
      if (document) {
        formData.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.text,
          n_number: document.n_number,
          file_name: document.file_name,
          file_size: document.file_size,
        });
        setTimeout(() => (state.documentValue = ""), 100);
      }
    };

    const onSubmit = () => {
      confirmRef?.value?.show({
        title: `Do you want to send email all to committee members?`,
        icon: "SendIcon",
        iconClass: "text-indigo-600",
        confirmText: "Send",
        cancelText: "Cancel",
        callback: () => submit(),
      });
    };

    const onPreview = () => {
      processing.value = true;
      emailService
        .preview(_.get(state.committee, "id"), formData)
        .then((result) => {
          if (result.kind === "ok") {
            state.previewUrl = URL.createObjectURL(result.data);
            cash("#preview-email-modal").modal("show");
          } else if (result.kind === "validation") {
            validationErrors.value.record(result.fields);
          }
        })
        .finally(() => {
          processing.value = false;
        });
    };

    const clearPreviewUrl = () => {
      if (state.previewUrl) {
        URL.revokeObjectURL(state.previewUrl);
        state.previewUrl = null;
      }
    };

    const clearFormData = () => {
      setFormData({ subject: "", body: "", files: [] });
      clearPreviewUrl();
    };

    const show = (options: SendEmailOptions) => {
      state.committee = options.committee;
      formData.users_full_list = options?.users_full_list || false;
      if (typeof options.success === "function")
        state.success = options.success;
      if (options.files) formData.files = options.files;
      formData.subject = `${_.get(state.committee, "acronym", "")} ${_.get(
        state.committee,
        "title",
        ""
      )} - `;

      formData.body =
        `<p>Dear committee member,<br>Please find below new document(s) that have been uploaded on SMIIC IS</p>` +
        `<p></p>` +
        `<p>Kind regards,<br>${nameSurname.value}</p>`;
      cash("#create-email-modal").modal("show");
    };

    const hide = () => {
      cash("#create-email-modal").modal("hide");
    };

    onMounted(() => {
      cash("#create-email-modal").modal("on.hide", () => clearFormData());
      cash("#preview-email-modal").modal("on.hide", () => clearPreviewUrl());
    });

    return {
      state,
      processing,
      formData,
      validationErrors,
      documentSelect2Options,
      onAddSubmissionFile,
      onSubmit,
      onPreview,
      show,
      hide,
    };
  },
});
