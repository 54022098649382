<template>
  <svg
    width="90"
    height="90"
    viewBox="0 0 90 90"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M87.8824 34.7525L80.9651 33.2487C80.3636 31.4072 79.6186 29.6109 78.739 27.8806L82.5684 21.9239C83.2523 20.8596 83.102 19.4623 82.2079 18.5683L71.4317 7.79205C70.5377 6.89804 69.1404 6.74767 68.0761 7.43156L62.1194 11.261C60.3891 10.3814 58.5928 9.63638 56.7513 9.03488L55.2475 2.11761C54.979 0.881653 53.8845 0 52.6197 0H37.3803C36.1155 0 35.021 0.881653 34.7525 2.11761L33.2487 9.03488C31.4072 9.63638 29.6109 10.3814 27.8806 11.261L21.9239 7.43156C20.8596 6.74767 19.4623 6.89804 18.5683 7.79205L7.79205 18.5683C6.89804 19.4623 6.74767 20.8596 7.43156 21.9239L11.261 27.8806C10.3814 29.6109 9.63638 31.4072 9.03488 33.2487L2.11761 34.7525C0.881653 35.0217 0 36.1155 0 37.3803V52.6197C0 53.8845 0.881653 54.9783 2.11761 55.2475L9.03488 56.7513C9.63638 58.5928 10.3814 60.3891 11.261 62.1194L7.43156 68.0761C6.74767 69.1404 6.89804 70.5377 7.79205 71.4317L18.5683 82.2079C19.4623 83.102 20.8596 83.2523 21.9239 82.5684L27.8806 78.739C29.6109 79.6186 31.4072 80.3636 33.2487 80.9651L34.7525 87.8824C35.021 89.1183 36.1155 90 37.3803 90H52.6197C53.8845 90 54.979 89.1183 55.2475 87.8824L56.7513 80.9651C58.5928 80.3636 60.3891 79.6186 62.1194 78.739L68.0761 82.5684C69.1404 83.2523 70.5377 83.1026 71.4317 82.2079L82.2079 71.4317C83.102 70.5377 83.2523 69.1404 82.5684 68.0761L78.739 62.1194C79.6186 60.3891 80.3636 58.5928 80.9651 56.7513L87.8824 55.2475C89.1183 54.9783 90 53.8845 90 52.6197V37.3803C90 36.1155 89.1183 35.0217 87.8824 34.7525ZM61.1355 45C61.1355 53.8969 53.8969 61.1355 45 61.1355C36.1031 61.1355 28.8645 53.8969 28.8645 45C28.8645 36.1031 36.1031 28.8645 45 28.8645C53.8969 28.8645 61.1355 36.1031 61.1355 45Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
