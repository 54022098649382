import Toastify, { Options } from "toastify-js";

export function ToastNotify(options: Options): void {
  Toastify({
    duration: 10000,
    close: true,
    gravity: "top",
    position: "right",
    stopOnFocus: true,
    ...options,
  }).showToast();
}
