
import { defineComponent, onMounted, ref } from "vue";
import {
  folderService,
  GetDocumentResult,
} from "@/services";
import { useStore } from "@/store";
import _ from "lodash";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "CreateOrUpdateFolderModal",
  props: {
    parentFolderId: { type: Number, required: true },
  },
  setup(props) {
    const store = useStore();

    const folderId = ref<any>(null);
    const localList = ref<Array<any>>([]);

    const { createOrUpdate } = useGeneralActions();

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: folderService.store,
      updateApiMethod: folderService.update,
      successCallback(result: GetDocumentResult) {
        if (result.kind === "ok") {
          const folderIndex = _.findIndex(localList.value, {
            id: folderId.value,
          });
          if (folderIndex > -1) {
            localList.value.splice(folderIndex, 1, result.data);
          } else {
            localList.value.splice(0, 0, result.data);
          }
          folderId.value = null;
          setFormData({ title: "", note: "" });
          cash("#document-folder-modal").modal("hide");
          store.dispatch("document/SET_DOCUMENTS", {
            documents: localList.value,
            offset: 0,
          });
        }
      },
      params: {
        title: "",
        note: "",
        folder_id: props.parentFolderId,
      },
    });

    const openEditModal = (folder: any) => {
      folderId.value = folder.id;
      setFormData({
        title: folder.title,
        note: folder?.note || "",
      });
      cash("#document-folder-modal").modal("show");
    };

    const onSubmit = () => submit(folderId.value);

    onMounted(() => {
      cash("#document-folder-modal").modal("on.show", () => {
        localList.value = _.cloneDeep(store.state.document.documents);
      });
      cash("#document-folder-modal").modal("on.hide", () => {
        folderId.value = null;
        setFormData({ title: "", note: "" });
      });
    });

    return {
      processing,
      formData,
      validationErrors,
      openEditModal,
      onSubmit,
    };
  },
});
