<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 pt-10 pb-4 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <GlobeIcon
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">COUNTRIES</div>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex flex-col-reverse sm:flex-row items-end sm:items-center">
        <div class="w-full flex sm:mr-3 mt-3 sm:mt-0">
          <input
            placeholder="Quick Search In Title Or Code"
            class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
            v-model="filterQuickSearch"
            @keyup.enter="applyFilter"
          />
          <button
            class="button bg-gray-100 border rounded-l-none shadow-lg"
            @click="applyFilter"
          >
            <SearchIcon class="inline w-6 h-6" />
          </button>
        </div>
        <router-link
          :to="{ name: 'countries.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-if="$store.getters['auth/canManageCountry']"
        >
          Create Country
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-5">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="
              selectedRows.length > 0 && $store.getters['auth/canManageCountry']
            "
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->

          <table class="table w-full">
            <thead>
              <th
                class="whitespace-nowrap w-1"
                v-if="$store.getters['auth/canManageCountry']"
              >
                <input
                  type="checkbox"
                  class="input input--lg border mr-2"
                  v-model="selectAll"
                />
              </th>
              <th>Code</th>
              <th>Title</th>
              <th class="text-right">Registration Date</th>
              <th
                class="w-1 whitespace-nowrap text-right"
                v-if="$store.getters['auth/canManageCountry']"
              >
                Functions
              </th>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.id"
              >
                <td v-if="$store.getters['auth/canManageCountry']">
                  <input
                    type="checkbox"
                    class="input input--lg border mr-2"
                    :value="row.id"
                    v-model="selectedRows"
                  />
                </td>
                <td>{{ row.code }}</td>
                <td>
                  <div>{{ row.title }}</div>
                </td>
                <td class="text-right">
                  <div>{{ $h.formatDate(row.created_at, "DD MMMM YYYY") }}</div>
                </td>
                <td
                  class="text-right whitespace-nowrap"
                  v-if="$store.getters['auth/canManageCountry']"
                >
                  <Tippy
                    tag="button"
                    content="Edit"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToEdit(row.id)"
                  >
                    <Edit2Icon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => triggerDelete([row.id])"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { countryService } from "@/services";
import _ from "lodash";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Countries",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();

    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "country",
        config: {
          "@page": 1,
          "@select": ["id", "title", "code", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
        },
        filterFormat: {
          title: "@where.q",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: countryService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const filterQuickSearch = computed({
      get: () => filter.query.title,
      set: (value) => {
        filter.query.title = value;
      },
    });

    const goToEdit = (value: number) => {
      router.push({ name: "countries.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      filterQuickSearch,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
    };
  },
});
</script>
