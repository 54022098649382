<template>
  <svg
    width="110"
    height="138"
    viewBox="0 0 110 138"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M84.5713 2.88814V24.6428H106.326L84.5713 2.88814Z" fill="currentColor" />
    <path
      d="M79.6429 34.5001C76.9208 34.5001 74.7142 32.2935 74.7142 29.5714V0H15.5714C7.40548 0 0.785706 6.61978 0.785706 14.7857V123.214C0.785706 131.38 7.40548 138 15.5714 138H94.4286C102.595 138 109.214 131.38 109.214 123.214V34.5001H79.6429V34.5001Z"
      fill="currentColor"
    />
  </svg>
</template>
