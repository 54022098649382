import pdfMake from "pdfmake/build/pdfmake";
import { Content, TableCell, TDocumentDefinitions } from "pdfmake/interfaces";
import { helper } from "@/utils/helper";
import { GetCommitteeMembership, FRONTEND_URL, GetRole } from "@/services";
import { PdfCreation } from "@/utils/pdf-creation/index";
import { LOGO_SVG } from "@/utils/pdf-creation/logo";

pdfMake.fonts = {
  dosis: {
    normal: `${FRONTEND_URL}/fonts/dosis/Dosis-Regular.ttf`,
    bold: `${FRONTEND_URL}/fonts/dosis/Dosis-Bold.ttf`,
  },
};

interface QueryProps {
  users: any[];
  organization: any;
}

export class OrganizationUsersPDFCreation extends PdfCreation {
  query: QueryProps = {
    users: [],
    organization: {},
  };

  options: TDocumentDefinitions = {
    header: {
      margin: [40, 40, 40, 0],
      columns: [
        [
          {
            svg: LOGO_SVG,
            fit: [200, 35],
          },
        ],
        [
          {
            style: "headerLight",
            alignment: "right",
            text: "ORGANIZATION USERS",
          },
          {
            style: "headerLight",
            alignment: "right",
            text: helper.formatDate(new Date(), "DD.MM.YYYY"),
          },
        ],
      ],
    },
    footer: (currentPage, pageCount) => {
      return {
        margin: [40, 10],
        fontSize: 8,
        columns: [
          "Copyright 2021 SMIIC - All rights reserved",
          {
            text: `Page ${currentPage}/${pageCount}`,
            alignment: "right",
          },
        ],
      };
    },
    pageMargins: [40, 80, 40, 30],
    styles: {
      header: {
        fontSize: 13,
        bold: true,
      },
      headerLight: {
        fontSize: 13,
      },
      tableHeader: {
        bold: true,
        fillColor: "#CCCCCC",
      },
    },
    defaultStyle: {
      fontSize: 10,
      lineHeight: 1.2,
      font: "dosis",
    },
    content: [
      {
        margin: [0, 30, 0, 0],
        columns: [
          [
            {
              style: "header",
              fontSize: 15,
              text: "({{organization.acronym}}) {{organization.title}}",
            },
          ],
        ],
      },
      "membership-table",
      "users-table",
    ],
  };

  prepareUsersTable(): Array<Content> {
    const users = _.get(this.query, "users", []);
    if (users.length === 0) return [];
    const body: Array<Content> = [];
    users.map((user: Record<string, any>) => {
      body.push([
        {
          margin: [0, 15, 0, 0],
          style: "header",
          fontSize: 11,
          text: `${_.get(user, "salutation")} ${_.get(user, "name")} ${_.get(
            user,
            "surname"
          )}`,
        },
      ]);
      body.push(this.prepareRolesTable(_.get(user, "roles", [])));
    });
    return body;
  }

  prepareRolesTable(roles: GetRole[]): Array<Content> {
    if (roles.length === 0) {
      return [
        {
          text: "No roles found for this user!",
        },
      ];
    }

    const body: TableCell[][] = [];
    roles.map((role: GetRole) => {
      let title = role.title;
      if (role.ballot_types.length > 0)
        title += ` (${role.ballot_types.join(", ")})`;
      body.push([
        title,
        `${_.get(role, "scope.acronym", "")} ${_.get(role, "scope.title", "")}`,
      ]);
    });
    return [
      {
        margin: [0, 5, 0, 0],
        table: {
          headerRows: 1,
          // dontBreakRows: true,
          keepWithHeaderRows: 1,
          widths: ["*", "*"],
          body: [
            [
              { text: "Title", style: "tableHeader" },
              { text: "Resource", style: "tableHeader" },
            ],
            ...body,
          ],
        },
        layout: {
          hLineColor: "#999999",
          vLineColor: "#999999",
        },
      },
    ];
  }

  replaceLines(lines: Array<Content>): Array<Content> {
    return super.replaceLines(lines).map((line) => {
      if (line === "users-table") {
        line = this.prepareUsersTable();
      }
      if (line === "membership-table") {
        line = this.prepareMembershipTable();
      }
      return line;
    });
  }

  prepareCoverPages(): Promise<any> {
    return new Promise((resolve) => {
      const content = this.replaceLines(
        _.cloneDeep(this.options.content) as Array<Content>
      );
      const pdfDoc = pdfMake.createPdf({ ...this.options, content });
      pdfDoc.getBuffer((result) => resolve(result));
    });
  }

  prepareMembershipTable(): Array<Content> {
    const committees = _.get(this.query, "organization.committees", []);
    if (committees.length === 0) return [];

    const body: TableCell[][] = [];
    committees.map((committee: GetCommitteeMembership) => {
      const title = `${committee.title} (${committee.acronym})`;
      const userCount = this.getUserCountForCommittee(committee.id);

      const membershipText =
        ["WG", "BOD", "GA"].indexOf(committee.type) === -1
          ? committee.pivot.member_type + "-Member"
          : "Member";

      if (["WG", "BOD", "GA", "SMIIC"].indexOf(committee.type) === -1)
        body.push([
          title,
          {
            text: membershipText,
            alignment: "center",
          },
          { ...userCount, alignment: "center" },
        ]);
    });
    return [
      {
        margin: [0, 5, 0, 0],
        table: {
          headerRows: 1,
          // dontBreakRows: true,
          keepWithHeaderRows: 1,
          widths: ["*", 100, 80],
          body: [
            [
              { text: "Committee", style: "tableHeader" },
              {
                text: "Membership Type",
                style: "tableHeader",
                alignment: "center",
              },
              { text: "User Count", style: "tableHeader", alignment: "center" },
            ],
            ...body,
          ],
        },
        layout: {
          hLineColor: "#999999",
          vLineColor: "#999999",
        },
      },
    ];
  }

  private getUserCountForCommittee(id: number) {
    let number = 0;
    const users = _.get(this.query, "users", []);
    users.map((user) => {
      _.get(user, "roles", []).map((role: GetRole) => {
        if (role.scope_type === "committee" && role.scope_id === id) {
          number++;
        }
      });
    });

    if (number === 0) return { text: "Absent", bold: true };

    return { text: number };
  }
}
