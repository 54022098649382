import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State as LocalState } from "./state";
import { GetRoleType } from "@/services";

// Mutation contracts
export type Mutations<S = LocalState> = {
  [MutationTypes.SET_ROLE_TYPES](state: S, types: GetRoleType[]): void;
};

// Define mutations
export const mutations: MutationTree<LocalState> & Mutations = {
  [MutationTypes.SET_ROLE_TYPES](state: LocalState, types: GetRoleType[]) {
    state.roleTypes = types;
  },
};
