<template>
  <textarea
    class="input w-full border block focus:bg-white focus:ring-0 shadow-lg"
    v-model="inputValue"
    :class="{ 'border-red-100 bg-red-50': !!validationErrors }"
  >
  </textarea>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "VueTextarea",
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      default: () => "",
    },
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue);

    watch(inputValue, (val) => {
      emit("update:modelValue", val);
    });

    watch(
      computed(() => props.modelValue),
      (val) => {
        if (val !== inputValue.value) inputValue.value = val;
      }
    );

    return {
      inputValue,
    };
  },
});
</script>
