import { inject, ref, Ref } from "vue";
import { ToastNotify } from "@/utils/toast-notify";
import { GeneralApiProblem } from "@/services";
import { ConfirmRef } from "@/components/confirm";

export interface GeneralDeleteRecordsProps {
  text?: string;
  deleteApiMethod(...args: any): Promise<any>;
  successCallback?(id?: any): void;
  errorCallback?(id?: any, error?: GeneralApiProblem): void;
}

export interface GeneralDeleteRecords {
  processing: Ref<boolean>;
  transactionIds: Ref<any[]>;
  destroyNextRecord(): Promise<void>;
  triggerDelete(ids: any[]): void;
}

export default function deleteRecords(
  props: GeneralDeleteRecordsProps
): GeneralDeleteRecords {
  const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
  const processing = ref<boolean>(false);
  const transactionIds = ref<any[]>([]);

  const destroyNextRecord = async (): Promise<void> => {
    const id = transactionIds.value[0] || null;
    if (id) {
      processing.value = true;
      const result = await props.deleteApiMethod(id);
      if (result.kind !== "ok") {
        ToastNotify({ text: result.message, className: "error" });
        if (props.errorCallback) props.errorCallback(id, result);
      } else {
        if (props.successCallback) props.successCallback(id);
      }
      processing.value = false;
      transactionIds.value.splice(0, 1);
      destroyNextRecord().finally();
    }
  };

  const triggerDelete = (ids: any[]): void => {
    confirmRef?.value?.show({
      title:
        props.text || `Do you want to delete the ${ids.length} selected rows?`,
      icon: "Trash2Icon",
      iconClass: "text-red-400",
      confirmText: "Yes",
      cancelText: "No",
      callback: () => {
        transactionIds.value = ids;
        destroyNextRecord().finally();
      },
    });
  };

  return {
    processing,
    transactionIds,
    destroyNextRecord,
    triggerDelete,
  };
}
