
import { defineComponent, reactive, ref } from "vue";
import { ValidationErrors } from "@/utils/form";
import { useRoute, useRouter } from "vue-router";
import { authService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  name: "ResetPassword",
  setup() {
    const route = useRoute();
    const router = useRouter();

    const loading = ref<boolean>(false);
    const formData = reactive({
      email: _.get(route.query, "email", ""),
      password: "",
      password_confirmation: "",
      token: _.get(route.query, "token", ""),
    });

    const validationErrors = ref(new ValidationErrors());

    const submit = () => {
      validationErrors.value.clear();
      loading.value = true;
      authService
        .resetPassword(formData)
        .then((result) => {
          if (result.kind === "ok") {
            router.push({ name: "login" });
            ToastNotify({
              text: "Your password has been reset!",
              className: "success",
            });
          } else {
            if (result.kind === "validation")
              validationErrors.value.record(result.fields);
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      loading,
      formData,
      validationErrors,
      submit,
    };
  },
});
