<template>
  <!-- BEGIN: Table -->
  <div class="accordion__pane py-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
    >
      Committee Liaisons
      <template v-if="!loading">({{ list.meta.total }})</template>
      <LoaderIcon v-else class="spin-5 inline-block w-8 h-8" />
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-3">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center w-1">Acronym</th>
                <th class="whitespace-nowrap">Title</th>
                <th class="whitespace-nowrap">Type</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="
                  deleteProcessing && transactionIds.indexOf(row.id) > -1
                "
                :waiting="transactionIds[0] !== row.id"
              >
                <td class="text-center">
                  <div class="bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md">
                    {{ row.acronym }}
                  </div>
                </td>
                <td>{{ row.title }}</td>
                <td>{{ $h.convertIdToText(row.type, "committeeTypes") }}</td>
                <td class="whitespace-nowrap text-right">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg"
                    @click="() => goToView(row.id)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    v-if="$_.get(committee, 'abilities.can_update', false)"
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg ml-2"
                    @click="triggerDelete([row.id])"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-lg text-gray-500">
        No liaisons found for this committee!
      </div>
    </div>
  </div>
  <!-- END: Table -->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { liaisonService, GetCommittee } from "@/services";
import _ from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "CommitteeLiaisons",
  components: { Pagination, TransactionStatement },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await liaisonService.committee.fetch(props.committee.id, query);
      },
    });

    const { filter, formattedQuery, changePage, changePerPage } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
      },
      true
    );

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) =>
        await liaisonService.committee.delete(props.committeeId, id),
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) {
            list.data.splice(index, 1);
            list.meta.total--;
          }
        }
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "committees.view", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery()).finally();
    });

    return {
      loading,
      list,
      filter,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      changePage,
      changePerPage,
      goToView,
    };
  },
});
</script>
