
import { defineComponent } from "vue";
import { init } from "./index";
import VueFileBrowser from "@/global-components/form-elements/file-browser/Main.vue";
import DocumentInfo from "@/views/documents/create/info/Main.vue";
import DocumentActionButtons from "@/views/documents/create/Buttons.vue";
import DocumentSummary from "@/views/documents/create/summary/Main.vue";

export default defineComponent({
  name: "DocumentCreate",
  components: {
    DocumentSummary,
    DocumentActionButtons,
    DocumentInfo,
    VueFileBrowser,
  },
  setup() {
    const documentProperties = init();
    return {
      ...documentProperties,
    };
  },
});
