
import { computed, defineComponent, onMounted, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import Relations from "@/views/meetings/view/Relations.vue";
import Information from "@/views/meetings/view/Information.vue";
import MeetingDocuments from "@/views/meetings/view/documents/Main.vue";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { GetMeeting } from "@/services";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetMeeting>;
}

export default defineComponent({
  name: "ViewMeeting",
  components: { MeetingDocuments, Relations, Information },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      errorCallback() {
        router.go(-1);
      },
      selectionQuery: {
        source: "meeting",
        config: {
          "@select": [
            "id",
            "title",
            "committee_id",
            "information",
            "city",
            "country_code",
            "time",
            "status",
            "type",
            "exact_date",
          ],
          country: {
            "@select": ["id", "title", "code"],
          },
          committee: {
            "@select": ["id", "title", "acronym"],
          },
        },
      },
    }) as FetchRecord;

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `VIEW MEETING (#${id.value})`;
      return `${data.value.title}`;
    });
    const canEdit = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_update", false);
    });
    const canUpload = computed<boolean>(() => {
      return _.get(data.value, "abilities.can_upload", false);
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value);
    });

    return {
      id,
      loading,
      headerTitle,
      canEdit,
      canUpload,
      detail: data,
    };
  },
});
