<template>
  <div v-timeline="{ props, emit }" style="min-height: 325px"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { init, reInit } from "@/global-components/timeline";

export default defineComponent({
  name: "TimelineVisJS",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  directives: {
    timeline: {
      mounted(el, { value }) {
        init(el, value.props);
      },
      updated(el, { value }) {
        reInit(el, value.props);
      },
      beforeUnmount(el) {
        if (el.timeline) el.timeline.destroy();
      },
    },
  },
  setup(props, context) {
    return {
      props,
      ...context,
    };
  },
});
</script>
