import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { fetchSelection } from "@/services";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_COMMITTEES](
    { commit }: AugmentedActionContext,
    committees: Record<string, any>[]
  ): void;
  [ActionTypes.FETCH_ALL_COMMITTEES]({
    commit,
  }: AugmentedActionContext): Promise<void>;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_COMMITTEES]({ commit }, committees: Record<string, any>[]) {
    commit(MutationTypes.SET_COMMITTEES, committees);
  },
  async [ActionTypes.FETCH_ALL_COMMITTEES]({ commit }) {
    await fetchSelection({
      committee: {
        "@get": true,
        "@select": [
          "id",
          "type",
          "acronym",
          "title",
          "level_1_type",
          "level_1_number",
          "level_2_type",
          "level_2_number",
          "level_3_type",
          "level_3_number",
          "secretary_user_id",
          "secretariat_member_id",
        ],
        secretaryUser: {
          "@select": ["id", "salutation", "name", "surname", "email"],
        },
        secretariat: {
          "@select": ["id", "title", "acronym"],
        },
        folder: {
          "@select": ["id", "committee_id", "type", "title"],
        },
        "@func": ["withSMIIC"],
        "@order": [
          "level_1_type:asc",
          "level_1_number:asc",
          "level_2_type:asc",
          "level_2_number:asc",
          "level_3_number:asc",
        ],
      },
    }).then((result) => {
      if (result.kind === "ok") {
        commit(
          MutationTypes.SET_COMMITTEES,
          _.get(result.data, "committee.data", [])
        );
      }
    });
  },
};
