<template>
  <div class="accordion__pane py-4">
    <a
      href="javascript:;"
      class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
    >
      Results
      <Tippy
        tag="button"
        content="PDF Document"
        :options="{ placement: 'bottom' }"
        class="button p-0 float-right mr-4"
        @click.stop.prevent="pdfCreation"
        v-if="state.questions.length > 0 && ballot.status === 'CLOSED'"
      >
        <FileIcon class="w-8 h-8" />
      </Tippy>
    </a>
    <div class="accordion__pane__content mt-3 leading-relaxed">
      <template v-if="state.detail">
        <Questions v-if="state.questions.length > 0" :rows="state.questions" />
        <CastedVotes v-if="state.table.rows.length > 0" :table="state.table" />
        <BallotResult
          v-if="state.detail.result && ballot.ballot_type !== 'SR'"
          :result="state.detail.result"
          :ballot-type="ballot.ballot_type"
        />
        <NotCastedVotes
          v-if="membersNotCastedVote.length > 0"
          :rows="membersNotCastedVote"
        />
        <AnswersAllVotes
          v-if="state.allAnswersToQuestions.length > 0"
          :rows="state.allAnswersToQuestions"
        />
        <CommentsFromVoters
          v-if="state.commentsFromVoters.length > 0"
          :rows="state.commentsFromVoters"
        />
        <CommentsFromCommenters
          v-if="state.commentsFromCommenters.length > 0"
          :rows="state.commentsFromCommenters"
        />
      </template>
      <div v-else class="text-lg text-gray-500">
        No results found for this ballot!
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, onMounted, reactive } from "vue";
import { ballotService, GetBallot, GetMember } from "@/services";
import { VoteResults } from "@/views/ballots/view/results";
import CastedVotes from "@/views/ballots/view/results/CastedVotes.vue";
import NotCastedVotes from "@/views/ballots/view/results/NotCastedVotes.vue";
import CommentsFromVoters from "@/views/ballots/view/results/CommentsFromVoters.vue";
import Questions from "@/views/ballots/view/results/Questions.vue";
import AnswersAllVotes from "@/views/ballots/view/results/Answers.vue";
import { BallotResultPDFCreation } from "@/utils/pdf-creation/ballot-result";
import { useRoute, useRouter } from "vue-router";
import CommentsFromCommenters from "@/views/ballots/view/results/CommentsFromCommenters.vue";
import BallotResult from "@/views/ballots/view/results/Result.vue";

export default defineComponent({
  name: "BallotResults",
  components: {
    BallotResult,
    CommentsFromCommenters,
    AnswersAllVotes,
    Questions,
    CommentsFromVoters,
    NotCastedVotes,
    CastedVotes,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: () => true,
    },
    ballotId: { default: () => null },
    ballot: { type: Object as () => GetBallot, default: () => ({}) },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const instanceVoteResults = new VoteResults();

    const bindInstance = () => {
      const bind = inject(`bind[createdCommentEventRef]`);
      if (typeof bind === "function") {
        bind((comment: any): void => {
          if (typeof state.detail.comments !== "undefined") {
            state.detail.comments.push(comment);
          }
        });
      }
    };

    const state = reactive<Record<string, any>>({
      detail: null,
      table: {
        headers: [],
        footers: [],
        rows: [],
        voteCastedMembers: [],
      },
      questions: [],
      commentsFromVoters: [],
      commentsFromCommenters: [],
      allAnswersToQuestions: [],
    });

    const membersNotCastedVote = computed(() => {
      const members: GetMember[] = _.get(state.detail, "members", []);
      return members.filter(
        (member: GetMember) =>
          state.table.voteCastedMembers.indexOf(member.id) === -1
      );
    });

    const fetch = async () => {
      state.detail = _.get(props.ballot, "result", null);
      if (props.ballot.status === "OPENED") {
        const response = await ballotService.fetchResults(props.ballotId);
        state.detail = response.kind === "ok" ? response.data : null;
      }
      if (state.detail) {
        // Work for all
        const secretaryId =
          _.get(state.detail, "committee.secretariat_member_id", "0") * 1;
        state.detail.members.map((member: Record<string, any>) => {
          let type: any = member.committee_member_type;
          if (secretaryId === member.id) type = "S";
          _.set(member, "member_type", type);
        });

        instanceVoteResults.setup(state.detail);
        state.table.headers = instanceVoteResults.table.headers;
        state.table.rows = instanceVoteResults.table.rows;
        state.table.footers = instanceVoteResults.table.footers;
        state.table.voteCastedMembers =
          instanceVoteResults.table.voteCastedMembers;

        state.commentsFromVoters = instanceVoteResults.commentsFromVoters;
        state.commentsFromCommenters =
          instanceVoteResults.commentsFromCommenters;
        state.allAnswersToQuestions = instanceVoteResults.allAnswersToQuestions;
        state.questions = instanceVoteResults.flattenQuestions;
      }
    };

    const pdfCreation = () => {
      new BallotResultPDFCreation()
        .process({
          ballot: props.ballot,
          result: state.detail,
        })
        .then((result) => {
          result.output().then((url) => {
            router.push({
              query: { ...route.query, iUrl: url },
            });
          });
        });
    };

    onMounted(() => {
      fetch();
      bindInstance();
    });

    return {
      state,
      membersNotCastedVote,
      pdfCreation,
    };
  },
});
</script>
