
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import {
  GetProject,
  GetProjectResult,
  PostProject,
  projectService,
} from "@/services";
import { useGeneralActions } from "@/utils/actions";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { Select2Options } from "@/global-components/select2";
import VueTiptap from "@/components/tiptap/Main.vue";
import { useStore } from "@/store";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostProject;
}

export default defineComponent({
  name: "ProjectEditInformation",
  components: { VueTiptap },
  props: {
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
    close: {
      type: Function,
      default: () => {
        console.log("close");
      },
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { createOrUpdate } = useGeneralActions();

    const stageAbbr = computed<string>(() => {
      const index = _.findIndex(store.state.project.initialTypes, {
        value: formData.stage,
      });
      return _.get(store.state.project.initialTypes, `${index}.abbr`, "");
    });

    const reference = computed<string>(() => {
      let val = `OIC/SMIIC ${stageAbbr.value} ${formData.project_no}`;
      if (formData.part_no) val += `-${formData.part_no}`;
      if (formData.reference_suffix) val += `${formData.reference_suffix}`;
      return val;
    });

    const state = reactive({
      selectedCommittee: _.get(props.project, "committee"),
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      updateApiMethod: projectService.update,
      successCallback(result: GetProjectResult) {
        if (result.kind === "ok") {
          emit("update:project", { ...props.project, ...result.data });
          props.close();
        }
      },
      params: {
        stage: "",
        project_no: "",
        part_no: "",
        reference_suffix: "",
        sequence: "",
        committee_id: "",
        wg_id: "",
        title_en: "",
        description_en: "",
        title_fr: "",
        description_fr: "",
        title_ar: "",
        description_ar: "",
        price: "",
        visible_on_website: "0",
        leader_user_id: "",
        time_frame: "",
        edition_no: "",
      } as PostProject,
    }) as CreateOrUpdate;

    onMounted(() => {
      setFormData(props.project);
    });

    const onSubmit = () => {
      submit(props.projectId);
    };

    const onChangeCommittee = (committee: any) => {
      state.selectedCommittee = committee;
      formData.wg_id = "";
    };

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
            level: "level",
            type: "type",
          },
          "@where": {
            level: [1, 2],
            id: store.getters["auth/getCommitteeIdsFromPermission"](
              "can_create_project"
            ),
          },
          "@whereNot": {
            type: ["WG"],
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });
    const workingGroupSelect2Options = computed<Select2Options>(() => {
      const selectedCommitteeLevel = _.get(
        state.selectedCommittee,
        "level.level"
      );
      const where: any = {
        level_1_type: _.get(state.selectedCommittee, "level.level_1.type"),
        level_1_number: _.get(state.selectedCommittee, "level.level_1.number"),
      };
      if (selectedCommitteeLevel === 1) {
        _.set(where, "level", 2);
        _.set(where, "level_2_type", "WG");
      } else if (selectedCommitteeLevel === 2) {
        _.set(where, "level", 3);
        _.set(
          where,
          "level_2_type",
          _.get(state.selectedCommittee, "level.level_2.type")
        );
        _.set(
          where,
          "level_2_number",
          _.get(state.selectedCommittee, "level.level_2.number")
        );
        _.set(where, "level_3_type", "WG");
      }
      return {
        ajaxQuery: {
          committee: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@order": [
              "level_1_type:asc",
              "level_1_number:asc",
              "level_2_type:asc",
              "level_2_number:asc",
              "level_3_number:asc",
            ],
            "@where": where,
          },
        },
        templateSelection: (obj: any): string => {
          if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    return {
      processing,
      reference,
      formData,
      validationErrors,
      committeeSelect2Options,
      workingGroupSelect2Options,
      onChangeCommittee,
      onSubmit,
    };
  },
});
