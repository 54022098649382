<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconUsers
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">MEETINGS LIST</div>
      <div class="text-white text-sm font-light">
        Storing meeting records of SMIIC Organs and Committees
      </div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-6">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Title</label>
            </template>
            <VueInput
              v-model="filter.query.title"
              @keydown.enter="applyFilter"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Committee</label>
            </template>
            <Select2Input
              :options="committeeSelect2Options"
              v-model="filter.query.committee_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Status</label>
            </template>
            <VueSelect v-model="filter.query.status">
              <option value="">All</option>
              <option value="Proposed">Proposed</option>
              <option value="Definite">Definite</option>
              <option value="Convened">Convened</option>
              <option value="Ongoing">Ongoing</option>
              <option value="Closed">Closed</option>
              <option value="Postponed">Postponed</option>
              <option value="Canseled">Canseled</option>
              <option value="Archived">Archived</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Meeting Type</label>
            </template>
            <VueSelect v-model="filter.query.type">
              <option value="">All</option>
              <option value="offline">Physical</option>
              <option value="online">Virtual</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">From Time</label>
            </template>
            <Litepicker v-model="filter.query.from" />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">To Time</label>
            </template>
            <Litepicker v-model="filter.query.to" />
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex flex-col-reverse sm:flex-row items-end sm:items-center">
        <div class="w-full flex sm:mr-3 mt-3 sm:mt-0">
          <input
            v-model="filter.query.title"
            placeholder="Quick Search In Title"
            class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
            @keydown.enter="applyFilter"
          />
          <button
            class="button bg-gray-100 border rounded-l-none shadow-lg"
            @click="applyFilter"
          >
            <SearchIcon class="inline w-6 h-6" />
          </button>
        </div>
        <router-link
          :to="{ name: 'meetings.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-if="$store.getters['auth/canCreateMeeting']()"
        >
          Create Meeting
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-6">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->

          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap w-1"></th>
                <th class="whitespace-nowrap">Committee</th>
                <th class="whitespace-nowrap">Title</th>
                <th class="whitespace-nowrap">Meeting Place</th>
                <th class="whitespace-nowrap text-center">Status</th>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.id"
              >
                <td>
                  <input
                    type="checkbox"
                    class="input input--lg border mr-2"
                    :value="row.id"
                    v-model="selectedRows"
                    v-if="$_.get(row, 'abilities.can_delete', false)"
                  />
                </td>
                <td>
                  <div
                    class="inline-block bg-theme-11 bg-opacity-40 px-3 py-1 rounded-md"
                  >
                    {{ $_.get(row, "committee.acronym", "") }}
                  </div>
                </td>
                <td>{{ $_.get(row, "title") }}</td>
                <td class="whitespace-nowrap">
                  <div v-if="$_.get(row, 'type', '') === 'offline'">
                    {{
                      $_.get(
                        row,
                        "country.title",
                        $_.get(row, "country_code", "")
                      )
                    }}
                    /
                    {{ $_.get(row, "city") }}
                  </div>
                  <div v-else>Virtual</div>
                  <div class="text-theme-1 text-xs">
                    {{
                      $h.formatDate($_.get(row, "time"), "DD MMMM YYYY HH:mm")
                    }}
                    GMT
                  </div>
                </td>
                <td class="text-center">{{ $_.get(row, "status") }}</td>
                <td class="text-right whitespace-nowrap">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToView(row.id)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Edit"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToEdit(row.id)"
                    v-if="$_.get(row, 'abilities.can_update', false)"
                  >
                    <Edit2Icon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => triggerDelete([row.id])"
                    v-if="$_.get(row, 'abilities.can_delete', false)"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0)"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { meetingService } from "@/services";
import { Select2Options } from "@/global-components/select2";

export default defineComponent({
  name: "Meetings",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "meeting",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "title",
            "committee_id",
            "city",
            "country_code",
            "time",
            "status",
            "type",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          country: {
            "@select": ["id", "title", "code"],
          },
          committee: {
            "@select": ["id", "title", "acronym"],
          },
        },
        filterFormat: {
          title: "@where.q",
          committee_id: "@where.committee_id",
          type: "@where.type",
          status: "@where.status",
          to: "@whereDateRange.time.to",
          from: "@whereDateRange.time.from",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      committee_id: "",
      type: "",
      status: "",
      to: "",
      from: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: meetingService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.value) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "meetings.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "meetings.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      committeeSelect2Options,
      countrySelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToEdit,
      goToView,
    };
  },
});
</script>
