<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <div class="section-divider mb-8">Identity</div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-full sm:col-span-6">
            <VueInputGroup :validation-errors="validationErrors.get('title')">
              <template v-slot:label>
                <label class="block mb-1">Title</label>
              </template>
              <VueInput
                capitalize
                placeholder="Enter title"
                v-model="formData.title"
                :validation-errors="validationErrors.get('title')"
              />
            </VueInputGroup>
          </div>
          <div class="col-span-full sm:col-span-6">
            <VueInputGroup :validation-errors="validationErrors.get('acronym')">
              <template v-slot:label>
                <label class="block mb-1">Acronym</label>
              </template>
              <VueInput
                uppercase
                placeholder="Enter acronym"
                v-model="formData.acronym"
                :validation-errors="validationErrors.get('acronym')"
              />
            </VueInputGroup>
          </div>
          <div class="col-span-full sm:col-span-6 lg:col-span-4 sm:mt-4">
            <div class="flex items-center text-lg">
              <input
                id="is_accreditation_body"
                class="input input--switch input--lg bg-gray-300"
                type="checkbox"
                v-model="formData.is_accreditation_body"
                :true-value="1"
                :false-value="0"
              />
              <label for="is_accreditation_body" class="ml-2"
                >Is Accreditation Body?</label
              >
            </div>
          </div>
          <div class="col-span-full sm:col-span-6 lg:col-span-4 sm:mt-4">
            <div class="flex items-center text-lg">
              <input
                id="is_metrology_body"
                class="input input--switch input--lg bg-gray-300"
                type="checkbox"
                v-model="formData.is_metrology_body"
                :true-value="1"
                :false-value="0"
              />
              <label for="is_metrology_body" class="ml-2"
                >Is Metrology Body?</label
              >
            </div>
          </div>
          <div class="col-span-full sm:col-span-6 lg:col-span-4 sm:mt-4">
            <div class="flex items-center text-lg">
              <input
                id="is_standardization_body"
                class="input input--switch input--lg bg-gray-300"
                type="checkbox"
                v-model="formData.is_standardization_body"
                :true-value="1"
                :false-value="0"
              />
              <label for="is_standardization_body" class="ml-2"
                >Is Standardization Body?</label
              >
            </div>
          </div>
        </div>
        <div class="section-divider my-8">Other</div>
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-full">
            <VueInputGroup
              :validation-errors="validationErrors.get('description')"
            >
              <template v-slot:label>
                <label class="block mb-1">Description</label>
              </template>
              <VueTiptap
                placeholder="Enter description about organization"
                v-model="formData.description"
                :validation-errors="validationErrors.get('description')"
              />
            </VueInputGroup>
          </div>
          <div class="col-span-full">
            <VueInputGroup
              :validation-errors="validationErrors.get('information')"
            >
              <template v-slot:label>
                <label class="block mb-1">Information</label>
              </template>
              <VueTiptap
                placeholder="Enter information about organization"
                v-model="formData.information"
                :validation-errors="validationErrors.get('information')"
              />
            </VueInputGroup>
          </div>
          <div class="col-span-full">
            <VueInputGroup :validation-errors="validationErrors.get('contact')">
              <template v-slot:label>
                <label class="block mb-1">Contact</label>
              </template>
              <VueTiptap
                placeholder="Enter information about organization"
                v-model="formData.contact"
                :validation-errors="validationErrors.get('contact')"
              />
            </VueInputGroup>
          </div>
          <div class="col-span-full sm:col-span-4">
            <VueInputGroup
              :validation-errors="validationErrors.get('country_code')"
            >
              <template v-slot:label>
                <label class="block mb-1">Country</label>
              </template>
              <Select2Input
                :options="countrySelect2Options"
                v-model="formData.country_code"
                :validation-errors="validationErrors.get('country_code')"
              />
            </VueInputGroup>
          </div>
          <VueInputGroup
            class="col-span-full sm:col-span-4"
            :validation-errors="validationErrors.get('visible_on_website')"
          >
            <template v-slot:label>
              <label class="block mb-1">Visible On Website</label>
            </template>
            <VueSelect
              v-model="formData.visible_on_website"
              :validation-errors="validationErrors.get('visible_on_website')"
            >
              <option value="0">No</option>
              <option value="1">Yes</option>
            </VueSelect>
          </VueInputGroup>
          <VueInputGroup
            class="col-span-full sm:col-span-4"
            :validation-errors="
              validationErrors.get('number_of_standards_adopted')
            "
          >
            <template v-slot:label>
              <label class="block mb-1"
                >Adopted and/or Implemented # of Standards</label
              >
            </template>
            <VueInput
              uppercase
              placeholder="# of Standards"
              v-model="formData.number_of_standards_adopted"
              :validation-errors="
                validationErrors.get('number_of_standards_adopted')
              "
            />
          </VueInputGroup>
        </div>
        <div class="flex justify-end mt-5">
          <VueButton
            :loading="processing"
            @click="onSubmit"
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white shadow-lg px-10"
            >Save Organization</VueButton
          >
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { PostOrganization, organizationService } from "@/services";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import VueTiptap from "@/components/tiptap/Main.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostOrganization;
}

export default defineComponent({
  name: "CreateOrganization",
  components: { VueTiptap },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (id.value) return `EDIT ORGANIZATION (#${id.value})`;
      return "CREATE ORGANIZATION";
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: organizationService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData(data.value);
      },
    });

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit,
    } = createOrUpdate({
      createApiMethod: organizationService.store,
      updateApiMethod: organizationService.update,
      successCallback() {
        router.go(-1);
      },
      params: {
        visible_on_website: 0,
        acronym: "",
        title: "",
        information: "",
        description: "",
        contact: "",
        country_code: "",
        is_accreditation_body: 0,
        is_metrology_body: 0,
        is_standardization_body: 0,
        number_of_standards_adopted: 0,
      },
    }) as CreateOrUpdate;

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      countrySelect2Options,
      onSubmit,
    };
  },
});
</script>
