import pdfMake from "pdfmake/build/pdfmake";
import {
  Content,
  Column,
  TDocumentDefinitions,
  ContentColumns,
  ContentStack,
  ContentText,
  ContentUnorderedList,
} from "pdfmake/interfaces";
import { FRONTEND_URL, PostDocument } from "@/services";
import { PdfCreation } from "@/utils/pdf-creation/index";
import { LOGO_SVG } from "@/utils/pdf-creation/logo";
import { helper } from "@/utils/helper";
import { useStore } from "@/store";

console.log("buraya gellll", FRONTEND_URL);

// pdfMake.vfs = pdfMakeFonts.pdfMake.vfs;
pdfMake.fonts = {
  dosis: {
    normal: `${FRONTEND_URL}/fonts/dosis/Dosis-Regular.ttf`,
    bold: `${FRONTEND_URL}/fonts/dosis/Dosis-Bold.ttf`,
  },
};

interface QueryProps {
  watermark?: string;
  formData?: PostDocument;
  resource?: Record<string, any>;
  replace?: Record<string, any>;
  noteJsonData?: any[];
  committee?: any[];
}

interface DocumentDefinitions extends TDocumentDefinitions {
  header: ContentColumns;
  content: Array<Content>;
}

export class DocumentPDFCreation extends PdfCreation {
  query: QueryProps = {};

  protected options: DocumentDefinitions = {
    header: {
      margin: [40, 20, 40, 0],
      columns: [
        [
          {
            svg: LOGO_SVG,
            fit: [200, 35],
          },
        ],
      ],
    },
    footer: (currentPage, pageCount) => {
      return {
        margin: [20, 10],
        fontSize: 8,
        columns: [
          "Copyright 2021 SMIIC - All rights reserved",
          {
            text: `Page ${currentPage}/${pageCount}`,
            alignment: "right",
          },
        ],
      };
    },
    pageMargins: [40, 60, 40, 30],
    styles: {
      header: {
        fontSize: 13,
        bold: true,
      },
      headerLight: {
        fontSize: 13,
      },
      tableHeader: {
        bold: true,
        fillColor: "#EEEEEE",
      },
    },
    defaultStyle: {
      fontSize: 11,
      lineHeight: 1.2,
      font: "dosis",
    },
    content: [],
  };

  prepareCoverPages(): Promise<any> {
    return new Promise((resolve) => {
      if (this.query.watermark) {
        this.options.watermark = {
          text: this.query.watermark,
          color: "#0059A6",
          opacity: 0.1,
          bold: true,
        };
      }
      this.prepareData();
      const content = this.replaceLines(
        _.cloneDeep(this.options.content) as Array<Content>
      );
      const pdfDoc = pdfMake.createPdf({ ...this.options, content });
      pdfDoc.getBuffer((result) => resolve(result));
    });
  }

  protected prepareData(): void {
    this.prepareHeaderColumns();
    this.prepareInformation();
    this.prepareTitle();
    this.prepareOtherInformation();
    this.prepareDescription();
  }

  protected prepareInformation(): void {
    const committee = _.get(this.query, "committee", null);
    const leftColumns: Column = [
      {
        text: `${_.get(committee, "acronym", null)} "${_.get(
          committee,
          "title",
          null
        )}"`,
        bold: true,
        link: `${FRONTEND_URL}/committees/view/${_.get(committee, "id", "")}`,
      },
      {
        text: [
          "Secretariat:   ",
          {
            text: `${_.get(committee, "secretariat.acronym", "")}`,
            bold: true,
            link: `${FRONTEND_URL}/organizations/view/${_.get(
              committee,
              "secretariat_member_id",
              ""
            )}`,
          },
        ],
        margin: [0, 10, 0, 0],
      },
      {
        text: [
          "Secretary:   ",
          {
            text: helper.formatNameSurname(_.get(committee, "secretary_user")),
            bold: true,
            link: `${FRONTEND_URL}/users/view/${_.get(
              committee,
              "secretary_user_id",
              ""
            )}`,
          },
        ],
      },
      {
        text: [
          "Email of Secretary:   ",
          {
            text: `${_.get(committee, "secretary_user.email", "")}`,
            link: `mailto:${_.get(committee, "secretary_user.email", "")}`,
            bold: true,
          },
        ],
      },
    ];

    this.options.content.push({
      columns: [
        leftColumns,
        [
          {
            qr: `${FRONTEND_URL}/documents/${this.query.formData?.uuid || ""}`,
            fit: 100,
            alignment: "right",
            eccLevel: "M",
          },
        ],
      ],
      margin: [0, 25, 0, 0],
    });
  }

  protected prepareOtherInformation(): void {
    const leftColumns: Column = [];
    const rightColumns: Column = [];

    const type = _.get(this.query.formData, "type", 0);
    if (type) {
      leftColumns.push({
        text: [
          "Document Type:   ",
          {
            text: useStore().getters["document/documentTypeTitle"](type),
            bold: true,
          },
        ],
      });
    }

    const page = _.get(this.query.formData, "pages", 0) * 1;
    if (page > 0) {
      leftColumns.push({
        text: ["Pages:   ", { text: page.toString(), bold: true }],
      });
    }

    const resource = _.get(this.query.resource, "text", "");
    const resourceType = helper.capitalizeEachWords(
      _.get(this.query.formData, "resource_type", "")
    );
    if (resource) {
      let resourceLink: any = null;
      switch (_.get(this.query.formData, "resource_type", "")) {
        case "project":
          resourceLink = `${FRONTEND_URL}/projects/view/${_.get(
            this.query.resource,
            "id",
            ""
          )}`;
          break;
        case "ballot":
          resourceLink = `${FRONTEND_URL}/ballots/view/${_.get(
            this.query.resource,
            "id",
            ""
          )}`;
          break;
        case "meeting":
          resourceLink = `${FRONTEND_URL}/meetings/view/${_.get(
            this.query.resource,
            "id",
            ""
          )}`;
          break;
      }
      leftColumns.push({
        text: [
          "Resource:   ",
          {
            text: `${resourceType || "-"} - ${resource || "-"}`,
            bold: true,
            link: resourceLink,
          },
        ],
      });
    }

    if (this.query.replace) {
      leftColumns.push({
        text: [
          "Replaces:   ",
          {
            text: `N${_.get(this.query.replace, "n_number", "")}`,
            bold: true,
            link: `${FRONTEND_URL}/documents/${_.get(
              this.query.replace,
              "uuid",
              ""
            )}`,
          },
        ],
      });
    }

    const date = _.get(this.query.formData, "document_date", "");
    if (date) {
      rightColumns.push({
        text: [
          "Document Date:   ",
          { text: helper.formatDate(date, "DD MMMM YYYY"), bold: true },
        ],
        alignment: "right",
      });
    }

    const dueDate = _.get(this.query.formData, "exp_action_due_date", "");
    if (dueDate) {
      rightColumns.push({
        text: [
          "Due Date:   ",
          { text: helper.formatDate(dueDate, "DD MMMM YYYY"), bold: true },
        ],
        alignment: "right",
      });
    }

    const expected = _.get(this.query.formData, "expected_action", "");
    if (expected) {
      rightColumns.push({
        text: [
          "Expected Action:   ",
          { text: helper.capitalizeEachWords(expected), bold: true },
        ],
        alignment: "right",
      });
    }

    this.options.content.push({
      columns: [leftColumns, rightColumns],
    });
  }

  protected prepareHeaderColumns(): void {
    const acronym = _.get(this.query, "committee.acronym", null);
    this.options.header.columns.push([
      {
        text: `N${this.query.formData?.n_number || ""}`,
        bold: true,
        fontSize: 28,
        alignment: "right",
        color: "#0059A6",
        lineHeight: 0.85,
        margin: [0, -5, 0, 0],
      },
      {
        text: acronym === "SMIIC" ? "SMIIC" : `SMIIC/${acronym}`,
        fontSize: 13,
        alignment: "right",
        lineHeight: 0.85,
      },
    ]);
  }

  protected prepareTitle(): void {
    this.options.content.push({
      text: _.get(this.query.formData, "title", ""),
      bold: true,
      fontSize: 16,
      margin: [0, 20, 0, 25],
      alignment: "justify",
      lineHeight: 1,
    });
  }

  protected prepareDescription(): void {
    const items = this.query.noteJsonData || [];
    if (items.length > 0) {
      this.options.content.push({
        text: "Description",
        bold: true,
        margin: [0, 15, 0, 5],
        fontSize: 13,
      });

      const contents = this.convertJsonContents(items);
      this.options.content.push(contents);
    }
  }

  protected convertJsonContents(
    contents: any[],
    marginTop = 7
  ): Array<Content> {
    const rsp: Array<Content> = [];
    contents.map((content) => {
      const type = _.get(content, "type", "text");
      switch (type) {
        case "text":
          rsp.push(this.convertToText(content));
          break;
        case "paragraph":
          rsp.push(this.convertToParagraph(content, marginTop));
          break;
        case "bulletList":
          rsp.push(this.convertToBulletList(content));
          break;
        case "listItem":
          rsp.push(this.convertToListItem(content));
          break;
        case "heading":
          rsp.push(this.convertToHeading(content));
          break;
      }
    });
    return rsp;
  }

  protected convertToText(content: any): ContentText {
    const rsp: ContentText = {
      text: content.text || "",
    };
    const marks = _.get(content, "marks", []);
    marks.map((mark: any) => {
      switch (mark.type) {
        case "link":
          rsp.link = _.get(mark, "attrs.href", "");
          rsp.decoration = "underline";
          rsp.color = "#0059A6";
          break;
        case "bold":
          rsp.bold = true;
          break;
        case "italic":
          // rsp.italics = true;
          break;
      }
    });
    return rsp;
  }

  protected convertToParagraph(content: any, marginTop: number): ContentText {
    return {
      text: this.convertJsonContents(_.get(content, "content", [])),
      alignment: _.get(content, "attrs.textAlign", "left"),
      margin: [0, marginTop, 0, 0],
    };
  }

  protected convertToHeading(content: any): ContentText {
    let fontSize = 16;
    switch (_.get(content, "attrs.level", 0)) {
      case 2:
        fontSize = 13;
        break;
    }
    return {
      text: this.convertJsonContents(_.get(content, "content", [])),
      alignment: _.get(content, "attrs.textAlign", "left"),
      bold: true,
      fontSize,
      margin: [0, 3, 0, 7],
    };
  }

  protected convertToBulletList(content: any): ContentUnorderedList {
    return {
      ul: this.convertJsonContents(_.get(content, "content", []), 0),
      margin: [0, 7, 0, 0],
    };
  }

  protected convertToListItem(content: any): ContentStack {
    return {
      stack: this.convertJsonContents(_.get(content, "content", []), 0),
    };
  }
}
