<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconOrganization
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">ORGANIZATIONS</div>
      <div class="text-white text-sm font-light">Members and Liaisons</div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Acronym</label>
            </template>
            <VueInput
              v-model="filter.query.acronym"
              @keydown.enter="applyFilter"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Title</label>
            </template>
            <VueInput
              v-model="filter.query.title"
              @keydown.enter="applyFilter"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Country</label>
            </template>
            <Select2Input
              :options="countrySelect2Options"
              v-model="filter.query.country_code"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Visible On Website</label>
            </template>
            <VueSelect v-model="filter.query.visible_on_website">
              <option value="">All</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Accreditation Body</label>
            </template>
            <VueSelect v-model="filter.query.is_accreditation_body">
              <option value="">All</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Metrology Body</label>
            </template>
            <VueSelect v-model="filter.query.is_metrology_body">
              <option value="">All</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg"
                >Standardization Body</label
              >
            </template>
            <VueSelect v-model="filter.query.is_standardization_body">
              <option value="">All</option>
              <option value="0">No</option>
              <option value="1">Yes</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex flex-col-reverse sm:flex-row items-end sm:items-center">
        <div class="w-full flex sm:mr-3 mt-3 sm:mt-0">
          <input
            v-model="filter.query.title"
            placeholder="Quick Search In Title"
            class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
            @keydown.enter="applyFilter"
          />
          <button
            class="button bg-gray-100 border rounded-l-none shadow-lg"
            @click="applyFilter"
          >
            <SearchIcon class="inline w-6 h-6" />
          </button>
        </div>
        <router-link
          :to="{ name: 'organizations.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-if="$store.getters['auth/canCreateOrganization']"
        >
          Create Organization
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-5">
          <!-- BEGIN: Action Buttons -->
          <div
            class="flex items-center divide-x mb-3"
            v-if="selectedRows.length > 0"
          >
            <div class="mr-2">Actions for the selected rows.</div>
            <Tippy
              tag="button"
              content="Delete"
              :options="{ placement: 'bottom' }"
              class="button button--sm rounded-none text-red-400 text-center"
              @click="() => triggerDelete(selectedRows)"
            >
              <Trash2Icon class="inline" />
            </Tippy>
          </div>
          <!-- END: Action Buttons -->

          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center">Country</th>
                <th class="whitespace-nowrap text-center">Acronym</th>
                <th class="whitespace-nowrap">Title</th>
                <Tippy
                  tag="th"
                  content="Visible On Website"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >VW
                </Tippy>
                <Tippy
                  tag="th"
                  content="Accreditation Body"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >AB
                </Tippy>
                <Tippy
                  tag="th"
                  content="Metrology Body"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >MB
                </Tippy>
                <Tippy
                  tag="th"
                  content="Standardization Body"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >SB
                </Tippy>
                <th class="whitespace-nowrap w-1 text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <TransactionStatement
                tag="tr"
                v-for="row in list.data"
                :key="'record-' + row.id"
                :processing="processing && transactionIds.indexOf(row.id) > -1"
                :waiting="transactionIds[0] !== row.id"
              >
                <td class="text-center">
                  {{ `${$_.get(row, "country.title", "")}` }}
                </td>
                <td class="text-center font-semibold">
                  {{ row.acronym }}
                </td>
                <td>
                  {{ row.title }}
                </td>
                <td class="text-center whitespace-nowrap w-1">
                  <CheckCircleIcon
                    class="w-6 h-6 text-theme-1"
                    v-if="$_.get(row, 'visible_on_website', 0) * 1 === 1"
                  />
                  <XCircleIcon class="w-7 h-7 text-red-400" v-else />
                </td>
                <td class="text-center whitespace-nowrap w-1">
                  <CheckCircleIcon
                    class="w-6 h-6 text-theme-1"
                    v-if="$_.get(row, 'is_accreditation_body', 0) * 1 === 1"
                  />
                  <XCircleIcon class="w-7 h-7 text-red-400" v-else />
                </td>
                <td class="text-center whitespace-nowrap w-1">
                  <CheckCircleIcon
                    class="w-6 h-6 text-theme-1"
                    v-if="$_.get(row, 'is_metrology_body', 0) * 1 === 1"
                  />
                  <XCircleIcon class="w-7 h-7 text-red-400" v-else />
                </td>
                <td class="text-center whitespace-nowrap w-1">
                  <CheckCircleIcon
                    class="w-6 h-6 text-theme-1"
                    v-if="$_.get(row, 'is_standardization_body', 0) * 1 === 1"
                  />
                  <XCircleIcon class="w-7 h-7 text-red-400" v-else />
                </td>
                <td class="whitespace-nowrap text-right">
                  <Tippy
                    tag="button"
                    content="See Details"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToView(row.id)"
                  >
                    <EyeIcon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Edit"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => goToEdit(row.id)"
                    v-if="$_.get(row, 'abilities.can_update', false)"
                  >
                    <Edit2Icon class="inline w-5 h-5" />
                  </Tippy>
                  <Tippy
                    tag="button"
                    content="Delete"
                    :options="{ placement: 'bottom' }"
                    class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                    @click="() => triggerDelete([row.id])"
                    v-if="$_.get(row, 'abilities.can_delete', false)"
                  >
                    <Trash2Icon class="inline w-5 h-5" />
                  </Tippy>
                </td>
              </TransactionStatement>
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useRouter } from "vue-router";
import _ from "lodash";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { Select2Options } from "@/global-components/select2";
import { useGeneralActions } from "@/utils/actions";
import { organizationService } from "@/services";

export default defineComponent({
  name: "Organizations",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "organization",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "title",
            "acronym",
            "visible_on_website",
            "is_accreditation_body",
            "is_metrology_body",
            "is_standardization_body",
            "country_code",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          country: {
            "@select": ["id", "title", "code"],
          },
        },
        filterFormat: {
          title: "@where.q",
          acronym: "@where._acronym",
          country_code: "@where._country_code",
          visible_on_website: "@where.visible_on_website",
          is_accreditation_body: "@where.is_accreditation_body",
          is_metrology_body: "@where.is_metrology_body",
          is_standardization_body: "@where.is_standardization_body",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title: "",
      acronym: "",
      country_code: "",
      visible_on_website: "",
      is_accreditation_body: "",
      is_metrology_body: "",
      is_standardization_body: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: organizationService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const filterQuickSearch = computed({
      get: () => filter.query.title,
      set: (value) => {
        filter.query.title = value;
      },
    });

    const countrySelect2Options = ref<Select2Options>({
      ajaxQuery: {
        country: {
          "@pagination": 15,
          "@select": {
            value: "code",
            text: "title",
          },
        },
      },
    });

    const goToView = (value: any) => {
      router.push({ name: "organizations.view", params: { id: value } });
    };

    const goToEdit = (value: any) => {
      router.push({ name: "organizations.edit", params: { id: value } });
    };

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      filterQuickSearch,
      countrySelect2Options,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      goToView,
      goToEdit,
    };
  },
});
</script>
