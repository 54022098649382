
import { defineComponent } from "vue";
import { init, reInit } from "./index";

export default defineComponent({
  name: "Litepicker",
  directives: {
    picker: {
      mounted(el, { value }) {
        init(el, value.props, value.emit);
      },
      updated(el, { oldValue, value }) {
        if (oldValue.props.modelValue !== value.props.modelValue) {
          reInit(el, value.props, value.emit);
        }
      },
    },
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      type: String,
      default: "",
    },
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, context) {
    const onClear = () => {
      context.emit("update:modelValue", "");
    };

    return {
      props,
      ...context,
      onClear,
    };
  },
});
