
import { computed, defineComponent, onMounted, ref } from "vue";
import {
  DOCUMENT_TYPES,
  documentService,
  fetchSelection,
  GetDocument,
} from "@/services";
import { useRouter } from "vue-router";
import _ from "lodash";
import { useStore } from "@/store";
import { useGeneralActions } from "@/utils/actions";

export default defineComponent({
  name: "DocumentSummary",
  props: {
    documentUuid: {
      type: String,
      default: () => "",
    },
    document: {
      type: Object as () => GetDocument,
      default: () => ({}),
    },
    onSendEmail: {
      type: Function,
      default: () => null,
    },
    onCancelDocument: {
      type: Function,
      default: () => null,
    },
    onPublishDocument: {
      type: Function,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { deleteRecords } = useGeneralActions();

    const versions = ref<any[]>([]);

    const isEmailView = computed<boolean>(
      () => props.document.type === DOCUMENT_TYPES.MAIL
    );
    const selectedVersionId = computed({
      get: () => props.documentUuid,
      set: (uuid: any) =>
        router.push({
          name: "documents",
          params: { uuid },
        }),
    });
    const lastVersion = computed(() => {
      return _.get(
        _.orderBy(versions.value, ["version"], ["desc"]),
        "0",
        props.document
      );
    });

    const isToBePublished = computed<boolean>(
      () => _.get(lastVersion.value, "status", "") === "to_be_published"
    );

    const canDelete = computed<boolean>(() => {
      return (
        isToBePublished.value &&
        _.get(props.document, "abilities.can_delete", false) &&
        !_.get(props.document, "versioned_at", "")
      );
    });

    const canUpdate = computed<boolean>(() => {
      return _.get(props.document, "abilities.can_update", false);
    });

    const canCreateVersion = computed<boolean>(() => {
      return (
        isToBePublished.value &&
        store.getters["auth/canCreateDocument"]() &&
        canUpdate.value &&
        !_.get(props.document, "versioned_at", "")
      );
    });

    const { triggerDelete } = deleteRecords({
      text: `Do you want to delete this document?`,
      deleteApiMethod: documentService.delete,
      successCallback() {
        router.go(-1);
      },
    });

    const fetchVersionDocuments = () => {

      if(!props.document.committee_id){
        return null
      }

      fetchSelection({
        document: {
          "@get": true,
          "@select": [
            "uuid",
            "title",
            "n_number",
            "version",
            "status",
            "folder_id",
            "committee_id",
          ],
          "@where": {
            committee_id: props.document.committee_id,
            language: props.document.language,
            n_number: props.document.n_number,
          },
          "@func": ["withVersioned"],
        },
      }).then((result) => {
        if (result.kind === "ok") {
          versions.value = _.get(result.data, "document.data", []);
        }
      });
    };

    const addNewVersion = () => {
      if (isToBePublished.value) {
        const folderId = _.get(lastVersion.value, "folder_id", null);
        const routeQuery = {
          name: "documents.create",
          params: {
            committeeId: _.get(lastVersion.value, "committee_id", ""),
          },
          query: {
            v: _.get(lastVersion.value, "uuid", ""),
          },
        };
        if (folderId) {
          _.set(routeQuery, "query.folderId", folderId);
        }
        router.push(routeQuery);
      }
    };

    const onDelete = () => triggerDelete([props.documentUuid]);

    const onToggleFullScreen = () => {

      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // mobile
        window.open(_.get(props.document, 'file_path', ''), "_blank");
      }else{
        // not mobile
        cash("#document--screen").fullScreen("toggle");
      }

    };

    onMounted(() => {
      fetchVersionDocuments();
    });

    return {
      versions,
      isEmailView,
      selectedVersionId,
      canUpdate,
      canDelete,
      canCreateVersion,
      addNewVersion,
      onDelete,
      onToggleFullScreen,
      tabs: [
        {
          value: "preview",
          text: "Preview",
          icon: "book-open-icon",
          show: true,
        },
        {
          value: "documents",
          text: "Documents",
          icon: "layers-icon",
          show: isEmailView.value,
        },
        {
          value: "recipients",
          text: "Recipients",
          icon: "users-icon",
          show: isEmailView.value,
        },
        {
          value: "logs",
          text: "Logs",
          icon: "activity-icon",
          show: canUpdate.value,
        },
      ],
    };
  },
});
