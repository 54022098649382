<template>
  <div>
    <VueInputGroup :validation-errors="validationErrors.get('committee')">
      <template v-slot:label>
        <label class="block mb-1">Add New Member</label>
      </template>
      <div class="flex">
        <Select2Input
          class="rounded-r-none"
          v-model="newMemberId"
          :options="memberSelect2Options"
        />
        <VueButton
          :disabled="!newMemberId"
          class="rounded-l-none bg-gray-300 hover:bg-gray-200 shadow-lg px-6"
          @click="onAddNewMember"
          :loading="submitProcessing"
        >
          <PlusCircleIcon class="w-5 h-5" />
        </VueButton>
      </div>
    </VueInputGroup>

    <template v-if="members.length > 0">
      <div class="section-divider mb-2 mt-8">List Of Selected Members</div>

      <!-- BEGIN: Table -->
      <div class="overflow-x-auto">
        <table class="table w-full">
          <thead>
            <tr>
              <th class="whitespace-nowrap text-center">Country</th>
              <th class="whitespace-nowrap text-center w-0">Acronym</th>
              <th class="whitespace-nowrap">Title</th>
              <th class="whitespace-nowrap w-1 text-right">Functions</th>
            </tr>
          </thead>
          <tbody>
            <TransactionStatement
              tag="tr"
              v-for="row in members"
              :key="`member-${type}-${row.id}`"
              :processing="
                deleteProcessing && transactionIds.indexOf(row.id) > -1
              "
              :waiting="transactionIds[0] !== row.id"
            >
              <td class="text-center whitespace-nowrap w-0">
                {{ `${$_.get(row, "country.title", row.country_code || "")}` }}
              </td>
              <td class="text-center">{{ row.acronym }}</td>
              <td>
                <router-link
                  class="text-blue-500 hover:text-blue-600"
                  :to="{
                    name: 'organizations.view',
                    params: { id: row.id },
                  }"
                >
                  {{ row.title }}
                </router-link>
              </td>
              <td class="text-right">
                <Tippy
                  tag="button"
                  content="Delete"
                  :options="{ placement: 'bottom' }"
                  class="button w-10 h-10 p-0 bg-white text-red-400 rounded-full shadow-lg"
                  @click="triggerDelete([row.id])"
                >
                  <Trash2Icon class="inline w-5 h-5" />
                </Tippy>
              </td>
            </TransactionStatement>
          </tbody>
        </table>
      </div>
      <!-- END: Table -->
    </template>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { Select2Options } from "@/global-components/select2";
import {
  committeeMemberService,
  GetCommitteeResult,
  GetMember,
} from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import _ from "lodash";

export default defineComponent({
  name: "CommitteeMembers",
  components: { TransactionStatement },
  emits: ["update:members"],
  props: {
    type: { default: () => "P" },
    committeeId: {
      required: true,
      default: () => null,
    },
    committeeType: {
      required: true,
      type: String,
      default: () => "TC",
    },
    committeeMembers: {
      type: Array as () => GetMember[],
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { createOrUpdate, deleteRecords } = useGeneralActions();

    const setCommitteeMembers = (members: GetMember[]) => {
      emit("update:members", members);
    };

    watch(
      () => props.committeeMembers,
      (val) => setCommitteeMembers(val || []),
      { deep: true, flush: "post" }
    );
    const committeeMembersIds = computed(() =>
      props.committeeMembers.map((item) => item.id)
    );
    const members = computed<GetMember[]>(() => {
      return props.committeeMembers.filter((item) => {
        return item?.committee_member_type === props.type;
      });
    });

    // const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const newMemberId = ref(null);
    const memberSelect2Options = computed<Select2Options>(() => {
      const where = {};
      const func = [];
      switch (props.committeeType) {
        case "AC":
          _.set(where, "is_accreditation_body", 1);
          break;
        case "MC":
          _.set(where, "is_metrology_body", 1);
          break;
        case "SMIIC":
          func.push("getBodies");
          break;
        default:
          _.set(where, "is_standardization_body", 1);
          break;
      }
      return {
        ajaxQuery: {
          organization: {
            "@pagination": 15,
            "@select": {
              value: "id",
              title: "title",
              acronym: "acronym",
            },
            "@where": {
              ...where,
            },
            "@whereNot": {
              id: committeeMembersIds.value,
            },
            "@order": "created_at:desc",
            "@func": func,
          },
        },
        templateResult(obj: any): any {
          if (obj.id) return `(${obj.acronym}) ${obj.title}`;
          return obj.text;
        },
      };
    });

    const {
      processing: submitProcessing,
      submit,
      setFormData,
      validationErrors,
    } = createOrUpdate({
      createApiMethod: committeeMemberService.store,
      successCallback(result: GetCommitteeResult) {
        newMemberId.value = null;
        if (result.kind === "ok" && result.data.members) {
          setCommitteeMembers(result.data.members);
        }
      },
      params: {
        committeeId: props.committeeId,
        memberId: "",
        member_type: props.type,
      },
    });

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) =>
        await committeeMemberService.delete(props.committeeId, id),
      successCallback(id) {
        if (id) {
          setCommitteeMembers(
            props.committeeMembers.filter((item) => item.id !== id)
          );
        }
      },
    });

    const onAddNewMember = (): void => {
      setFormData({
        committeeId: props.committeeId,
        memberId: newMemberId.value,
      });
      submit();
    };

    return {
      submitProcessing,
      deleteProcessing,
      members,
      newMemberId,
      memberSelect2Options,
      validationErrors,
      onAddNewMember,
      transactionIds,
      triggerDelete,
    };
  },
});
</script>
