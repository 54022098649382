<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <router-link :to="{ name: 'dashboard' }" class="flex mr-auto">
        <img class="w-16" src="@/assets/images/logo-white.svg" />
      </router-link>

      <div class="dropdown mr-3">
        <a
          class="dropdown-toggle relative z-10"
          href="javascript:;"
        >
          <img class="w-8 h-8 object-contain object-cover rounded-full" :src="gravatar" />
        </a>
        <div class="dropdown-box w-40">
          <div class="dropdown-box__content box dark:bg-dark-1 p-2">
            <router-link
              :to="{ name: 'users.view', params: { id: profile.id } }"
              class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              data-dismiss="dropdown"
            >
              <UserIcon class="w-4 h-4 mr-2" /> My Profile
            </router-link>
            <router-link
              :to="{ name: 'organizations.view', params: { id: profile.administrated_by_id } }"
              class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              data-dismiss="dropdown"
            >
              <SvgIconOrganization class="w-4 h-4 mr-2" /> My Organization
            </router-link>
            <router-link
              :to="{ name: 'feedbacks.create' }"
              class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              data-dismiss="dropdown"
            >
              <MessageCircleIcon class="w-4 h-4 mr-2" /> Send Feedback
            </router-link>
            <a
              href="javascript:;"
              class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
              data-dismiss="dropdown"
              @click="$store.dispatch('auth/LOGOUT')"
            >
              <TrashIcon class="w-4 h-4 mr-2" /> Logout
            </a>
          </div>
        </div>
      </div>
      <BarChart2Icon
        class="w-8 h-8 text-white transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu">
        <!-- BEGIN: First Child -->
        <template v-for="(menu, menuKey) in formattedMenu">
          <li
            v-if="menu == 'devider'"
            :key="menu + menuKey"
            class="menu__devider my-6"
          ></li>
          <li v-else :key="menu + menuKey">
            <a
              href="javascript:;"
              class="menu"
              :class="{
                'menu--active': menu.active,
                'menu--open': menu.activeDropdown,
              }"
              @click="linkTo(menu, router)"
            >
              <div class="menu__icon">
                <component :is="menu.icon" />
              </div>
              <div class="menu__title">
                {{ menu.title }}
                <div
                  v-if="menu.subMenu"
                  class="menu__sub-icon"
                  :class="{ 'transform rotate-180': menu.activeDropdown }"
                >
                  <ChevronDownIcon />
                </div>
              </div>
            </a>
            <!-- BEGIN: Second Child -->
            <transition @enter="enter" @leave="leave">
              <ul v-if="menu.subMenu && menu.activeDropdown">
                <li
                  v-for="(subMenu, subMenuKey) in menu.subMenu"
                  :key="subMenuKey"
                >
                  <a
                    href="javascript:;"
                    class="menu"
                    :class="{ 'menu--active': subMenu.active }"
                    @click="linkTo(subMenu, router)"
                  >
                    <div class="menu__icon">
                      <ActivityIcon />
                    </div>
                    <div class="menu__title">
                      {{ subMenu.title }}
                      <div
                        v-if="subMenu.subMenu"
                        class="menu__sub-icon"
                        :class="{
                          'transform rotate-180': subMenu.activeDropdown,
                        }"
                      >
                        <ChevronDownIcon />
                      </div>
                    </div>
                  </a>
                  <!-- BEGIN: Third Child -->
                  <transition @enter="enter" @leave="leave">
                    <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                      <li
                        v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                        :key="lastSubMenuKey"
                      >
                        <a
                          href="javascript:;"
                          class="menu"
                          :class="{ 'menu--active': lastSubMenu.active }"
                          @click="linkTo(lastSubMenu, router)"
                        >
                          <div class="menu__icon">
                            <ZapIcon />
                          </div>
                          <div class="menu__title">
                            {{ lastSubMenu.title }}
                          </div>
                        </a>
                      </li>
                    </ul>
                  </transition>
                  <!-- END: Third Child -->
                </li>
              </ul>
            </transition>
            <!-- END: Second Child -->
          </li>
        </template>
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { helper as $h } from "@/utils/helper";
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave,
} from "./index";
import { nestedMenu } from "@/layouts/side-menu";
import md5 from "blueimp-md5";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formattedMenu = ref([]);
    const mobileMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    );

    const profile = store.getters["auth/profile"];

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value);
      }
    );

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value);
    });

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      gravatar,
      profile,
      linkTo,
      enter,
      leave,
    };
  },
});
</script>
