<template>
  <div ref="fileUploadRef" v-file-upload="{ props, emit }" class="dropzone">
    <div class="dz-message">
      <slot>
        <div class="text-lg font-medium">
          Drop files here or click to upload.
        </div>
        <div>You can upload documents by clicking or dropping files here.</div>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ref, onMounted } from "vue";
import { init } from "./index";

export default defineComponent({
  name: "Dropzone",
  directives: {
    fileUpload: {
      mounted(el, { value }) {
        init(el, value.props);
      },
    },
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    refKey: {
      type: String,
      default: null,
    },
    success: {
      type: Function,
      default: () => {
        console.log("upload success");
      },
    },
    failure: {
      type: Function,
      default: () => {
        console.log("upload failure");
      },
    },
    remove: {
      type: Function,
      default: () => {
        console.log("uploaded remove");
      },
    },
    uploading: {
      type: Function,
      default: () => {
        console.log("uploading");
      },
    },
    completed: {
      type: Function,
      default: () => {
        console.log("completed");
      },
    },
  },
  setup(props, context) {
    const fileUploadRef = ref<HTMLElement>();
    const bindInstance = () => {
      if (props.refKey) {
        const bind = inject<(el: HTMLElement | undefined) => void>(
          `bind[${props.refKey}]`
        );
        if (bind) {
          bind(fileUploadRef.value);
        }
      }
    };

    onMounted(() => {
      bindInstance();
    });

    return {
      props,
      ...context,
      fileUploadRef,
    };
  },
});
</script>
