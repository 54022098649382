import {
  GeneralApiResponse,
  GetDocument,
  PostDocument,
  submissionService,
  documentService,
} from "@/services";
import axios, { CancelTokenSource } from "axios";
import { Router } from "vue-router";
import { ToastNotify } from "@/utils/toast-notify";

interface Props {
  router: Router;
  formData: PostDocument;
  state: Record<string, any>;
}
let fileCancelSource: CancelTokenSource | null = null;

export const cancelFileUpload = () => {
  if (fileCancelSource) fileCancelSource.cancel("File upload cancelled!");
};

const successFunction = (props: Props, document: GetDocument) => {
  ToastNotify({
    className: "success",
    text: "The file has been successfully uploaded to the server.",
  });
  props.state.upload.percent = 100;
  props.router.replace({
    name: "documents",
    params: {
      uuid: document.uuid,
    },
  });
};

const errorFunction = (props: Props, result: GeneralApiResponse) => {
  props.state.upload.hasError = true;
  let errorMessage = "";
  if (result.kind === "validation") {
    _.forEach(result.fields, (fields: string[]) => {
      fields.map((field) => {
        errorMessage += `${field}<br>`;
      });
    });
    props.state.upload.error = errorMessage;
  }
  ToastNotify({
    className: "error",
    text: _.get(result, "message", ""),
  });
};

const processAfterUpload = (props: Props, document: GetDocument) => {
  if (document.type * 1 === 210) {
    submissionService
      .store({
        submission: {
          text: document.title,
          files: [document.uuid],
        },
        project_id: document.resource_id,
      })
      .then((result) => {
        if (result.kind === "ok") {
          successFunction(props, document);
        } else {
          errorFunction(props, result);
          documentService.delete(document.uuid).finally();
        }
      });
  } else {
    successFunction(props, document);
  }
};

export const fileUpload = async (props: Props): Promise<void> => {
  const { state, formData } = props;
  state.upload.processing = true;
  state.upload.percent = 0;
  state.upload.hasError = false;
  state.upload.error = null;
  const file = state.editedFile ? state.editedFile : state.selectedFile;
  fileCancelSource = axios.CancelToken.source();
  await axios
    .put(state.upload.url, file, {
      cancelToken: fileCancelSource.token,
      onUploadProgress: (progressEvent) => {
        let percent = (progressEvent.loaded / progressEvent.total) * 100;
        if (percent > 99) percent = 99;
        state.upload.percent = Math.floor(percent);
      },
    })
    .then((result) => {
      if (result.status === 200) {
        documentService
          .store(formData)
          .then((documentResult) => {
            if (documentResult.kind === "ok") {
              processAfterUpload(props, documentResult.data);
            } else {
              errorFunction(props, documentResult);
            }
          })
          .finally(() => {
            state.upload.processing = false;
          });
      }
    })
    .catch((err: Error) => {
      state.upload.processing = false;
      state.upload.hasError = true;
      state.upload.error = "";
      ToastNotify({
        className: "error",
        text: err.message,
      });
    });
};
