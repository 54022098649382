
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  provide,
  ref,
  Ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { ballotService, GetBallot } from "@/services";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import BallotInformation from "@/views/ballots/view/information/Main.vue";
import BallotQuestions from "@/views/ballots/view/questions/Main.vue";
import BallotDocuments from "@/views/ballots/view/documents/Main.vue";
import { ConfirmRef } from "@/components/confirm";
import BallotResults from "@/views/ballots/view/results/Main.vue";
import { useStore } from "@/store";
import WriteComment from "@/views/ballots/view/WriteComment.vue";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetBallot>;
}

export default defineComponent({
  name: "BallotView",
  components: {
    WriteComment,
    BallotResults,
    BallotDocuments,
    BallotQuestions,
    BallotInformation,
  },
  setup() {
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { fetchRecord } = useGeneralActions();
    const processing = ref<boolean>(false);

    const isActiveEditableInformation = ref<boolean>(false);
    const isActiveEditableQuestions = ref<boolean>(false);
    const disabledActionButtons = computed<boolean>(
      () => isActiveEditableInformation.value
    );

    const createdCommentEventRef = ref<() => void>(() => {
      console.log("createdCommentEventRef");
    });
    provide("bind[createdCommentEventRef]", (callback: () => void) => {
      createdCommentEventRef.value = callback;
    });

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `VIEW BALLOT (#${id.value})`;
      return `${data.value.reference}`;
    });

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: ballotService.show,
      errorCallback() {
        router.go(-1);
      },
    }) as FetchRecord;

    const permissions = computed(() => {
      const status = data.value.status;
      const canUpdate = _.get(data.value, "abilities.can_update", false);
      const canVote = _.get(data.value, "abilities.can_vote", false);
      const canComment = _.get(data.value, "abilities.can_comment", false);
      const canClose = _.get(data.value, "abilities.can_close", false);
      const canOpen = _.get(data.value, "abilities.can_open", false);
      return {
        canComment: canComment && status === "OPENED",
        canVote: canVote && status === "OPENED",
        canUpdate: canUpdate && status === "CREATED",
        canAddReferenceDocument: canUpdate && ["CREATED"].indexOf(status) > -1,
        canAddDocument: canUpdate && ["CREATED", "CLOSED"].indexOf(status) > -1,
        canOpenBallot: canOpen && status === "CREATED",
        canCloseBallot: canClose && status === "OPENED",
        canViewResults:
          (status === "OPENED" && store.getters["auth/canAudit"]) ||
          status === "CLOSED",
      };
    });

    const toggleBallotStatus = () => {
      confirmRef?.value?.show({
        title: `Do you want to change status this ballot?`,
        icon: "AlertCircleIcon",
        iconClass: "text-yellow-500",
        confirmText: "Yes",
        cancelText: "No",
        callback: () => {
          if (data.value.status === "CREATED") {
            processing.value = true;
            ballotService
              .open(id.value)
              .then((result) => {
                if (result.kind === "ok") {
                  data.value = { ...data.value, ...result.data };
                }
              })
              .finally(() => {
                processing.value = false;
              });
          } else {
            processing.value = true;
            ballotService
              .close(id.value)
              .then((result) => {
                if (result.kind === "ok") {
                  data.value = { ...data.value, ...result.data };
                }
              })
              .finally(() => {
                processing.value = false;
              });
          }
        },
      });
    };

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      fetch(id.value).finally();
    });

    return {
      id,
      headerTitle,
      loading,
      processing,
      isActiveEditableInformation,
      isActiveEditableQuestions,
      disabledActionButtons,
      detail: data,
      permissions,
      createdCommentEventRef,
      toggleBallotStatus
    };
  },
});
