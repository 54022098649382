
import { defineComponent } from "vue";
import { GetDocument } from "@/services";

export default defineComponent({
  name: "RecipientsList",
  props: {
    document: {
      type: Object as () => GetDocument,
      default: () => ({}),
    },
  },
});
