// Action enums
export enum ActionTypes {
  SET_DOCUMENT_TYPES = "SET_DOCUMENT_TYPES",
  FETCH_DOCUMENTS = "FETCH_DOCUMENTS",
  FETCH_DOCUMENT_TYPES = "FETCH_DOCUMENT_TYPES",
  FETCH_ALL_FOLDERS = "FETCH_ALL_FOLDERS",
  SET_AGGREGATION = "SET_AGGREGATION",
  SET_DOCUMENTS = "SET_DOCUMENTS",
  SET_FORCE_DOCUMENTS = "SET_FORCE_DOCUMENTS",
  SET_FOLDERS = "SET_FOLDERS",
}
