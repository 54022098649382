
import { computed, defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { projectService } from "@/services";
import _ from "lodash";
import { Select2Options } from "@/global-components/select2";
import ProjectListItem from "@/views/projects/list/ListItem.vue";

export default defineComponent({
  components: {
    ProjectListItem,
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "project",
        config: {
          "@page": 1,
          "@select": ["id", "reference", "stage", "title_en", "committee_id"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          current_line: {
            "@select": [
              "id",
              "project_id",
              "start_date",
              "target_date",
              "stage",
              "version",
            ],
          },
          committee: {
            "@select": ["id", "acronym", "title"],
          },
        },
        filterFormat: {
          reference: "@where._reference",
          title_en: "@where._title_en",
          committee_id: "@where.committee_id",
          stage: "@where._stage",
          vow: "@where.visible_on_website",
          dw: "@where.document_waiting",
          tf: "@where.time_frame",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title_en: "",
      reference: "",
      committee_id: "",
      stage: "",
      vow: "",
      dw: "",
      tf: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: projectService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const filterQuickSearch = computed({
      get: () => filter.query.reference,
      set: (value) => {
        filter.query.reference = value;
      },
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      filterQuickSearch,
      processing,
      transactionIds,
      triggerDelete,
      committeeSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
    };
  },
});
