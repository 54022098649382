<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconTable
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">PROJECTS</div>
      <div class="text-white text-sm font-light">
        Managing SMIIC Committees’ projects
      </div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Reference</label>
            </template>
            <VueInput
              v-model="filter.query.reference"
              @keydown.enter="applyFilter"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Committee</label>
            </template>
            <Select2Input
              :options="committeeSelect2Options"
              v-model="filter.query.committee_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Stage Code</label>
            </template>
            <VueInput
              v-model="filter.query.stage"
              @keydown.enter="applyFilter"
              placeholder="eg: 10.20"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Time Frame</label>
            </template>
            <VueSelect v-model="filter.query.tf">
              <option value="">All</option>
              <option value="18">18</option>
              <option value="24">24</option>
              <option value="36">36</option>
              <option value="48">48</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Visible On Website</label>
            </template>
            <VueSelect v-model="filter.query.vow">
              <option value="">All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-3">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Document Waiting</label>
            </template>
            <VueSelect v-model="filter.query.dw">
              <option value="">All</option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="applyFilter"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="clearFilter"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <!--      <div class="grid grid-cols-12 gap-4 pt-4 -intro-y intro-fast" v-else>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-3 flex flex-col items-center text-center text-theme-13"-->
      <!--        >-->
      <!--          <DiscIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Under Development</div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-3 flex flex-col items-center text-center"-->
      <!--        >-->
      <!--          <TargetIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Active / Preliminary / Published</div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-3 flex flex-col items-center text-center"-->
      <!--        >-->
      <!--          <AwardIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Published</div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-3 flex flex-col items-center text-center"-->
      <!--        >-->
      <!--          <PackageIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Open / Closed Ballots</div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-4 flex flex-col items-center text-center"-->
      <!--        >-->
      <!--          <ThumbsUpIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Planned Ballots</div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-4 flex flex-col items-center text-center"-->
      <!--        >-->
      <!--          <XCircleIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Canceled / Withdrawn</div>-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="bg-white rounded-lg p-3 col-span-6 sm:col-span-4 lg:col-span-4 flex flex-col items-center text-center"-->
      <!--        >-->
      <!--          <CheckCircleIcon class="w-8 h-8" />-->
      <!--          <div class="mt-2">Action Required</div>-->
      <!--        </div>-->
      <!--      </div>-->
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 sm:px-5 sm:py-10">
      <div class="flex flex-col-reverse sm:flex-row items-end sm:items-center">
        <div class="w-full sm:w-auto sm:flex-grow flex sm:mr-3 mt-3 sm:mt-0">
          <input
            v-model="filterQuickSearch"
            placeholder="Quick Search In Reference"
            class="input w-full border shadow-lg focus:bg-white flex-grow rounded-r-none border-r-0"
            @keydown.enter="applyFilter"
          />
          <button
            class="button bg-gray-100 border rounded-l-none shadow-lg"
            @click="applyFilter"
          >
            <SearchIcon class="inline w-6 h-6" />
          </button>
        </div>
        <router-link
          :to="{ name: 'projects.create' }"
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          v-if="$store.getters['auth/canCreateProject']()"
        >
          New Project
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <PlusIcon class="inline w-6 h-6" />
          </div>
        </router-link>
      </div>
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-if="list.data.length > 0">
        <div class="overflow-x-auto mt-3">
          <table class="table w-full">
            <thead>
              <tr>
                <th class="whitespace-nowrap text-center">Stage</th>
                <Tippy
                  tag="th"
                  content="Committee / Working Group"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >Committee
                </Tippy>
                <th class="whitespace-nowrap">Reference & Title</th>
                <Tippy
                  tag="th"
                  content="Stage Start Date"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >Start Date
                </Tippy>
                <Tippy
                  tag="th"
                  content="Stage Target Date"
                  :options="{ placement: 'bottom' }"
                  class="whitespace-nowrap text-center"
                  >Target Date
                </Tippy>
                <th class="whitespace-nowrap text-right">Functions</th>
              </tr>
            </thead>
            <tbody>
              <ProjectListItem
                v-for="row in list.data"
                :key="'record-' + row.id"
                :item="row"
                :processing="processing"
                :transaction-ids="transactionIds"
                :trigger-delete="triggerDelete"
              />
            </tbody>
          </table>
        </div>
        <Pagination
          class="mt-4"
          :current="filter.query.page * 1"
          :per-page="filter.query.paginate * 1"
          :total="$_.get(list, 'meta.total', 0) * 1"
          @update-page="changePage"
          @update-perpage="changePerPage"
        />
      </template>
      <div v-else class="text-center p-4 pt-8">No results found</div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { projectService } from "@/services";
import _ from "lodash";
import { Select2Options } from "@/global-components/select2";
import ProjectListItem from "@/views/projects/list/ListItem.vue";

export default defineComponent({
  components: {
    ProjectListItem,
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "project",
        config: {
          "@page": 1,
          "@select": ["id", "reference", "stage", "title_en", "committee_id"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          current_line: {
            "@select": [
              "id",
              "project_id",
              "start_date",
              "target_date",
              "stage",
              "version",
            ],
          },
          committee: {
            "@select": ["id", "acronym", "title"],
          },
        },
        filterFormat: {
          reference: "@where._reference",
          title_en: "@where._title_en",
          committee_id: "@where.committee_id",
          stage: "@where._stage",
          vow: "@where.visible_on_website",
          dw: "@where.document_waiting",
          tf: "@where.time_frame",
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      title_en: "",
      reference: "",
      committee_id: "",
      stage: "",
      vow: "",
      dw: "",
      tf: "",
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: projectService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const filterQuickSearch = computed({
      get: () => filter.query.reference,
      set: (value) => {
        filter.query.reference = value;
      },
    });

    const committeeSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        committee: {
          "@pagination": 15,
          "@select": {
            value: "id",
            title: "title",
            acronym: "acronym",
          },
          "@order": [
            "level_1_type:asc",
            "level_1_number:asc",
            "level_2_type:asc",
            "level_2_number:asc",
            "level_3_number:asc",
          ],
        },
      },
      templateSelection: (obj: any): string => {
        if (obj.acronym) return `(${obj.acronym}) ${obj.title}`;
        return obj.text;
      },
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      filterQuickSearch,
      processing,
      transactionIds,
      triggerDelete,
      committeeSelect2Options,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
    };
  },
});
</script>
