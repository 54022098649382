import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { roleService, GetRoleType } from "@/services";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_ROLE_TYPES](
    { commit }: AugmentedActionContext,
    types: GetRoleType[]
  ): void;
  [ActionTypes.FETCH_ROLE_TYPES]({ dispatch }: AugmentedActionContext): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_ROLE_TYPES]({ commit }, types: GetRoleType[]) {
    commit(MutationTypes.SET_ROLE_TYPES, types);
  },
  [ActionTypes.FETCH_ROLE_TYPES]({ dispatch }) {
    roleService.fetchRoleTypes().then((result) => {
      if (result.kind === "ok") {
        dispatch(ActionTypes.SET_ROLE_TYPES, result.data);
      }
    });
  },
};
