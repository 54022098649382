<template>
  <div class="container sm:px-10">
    <div class="block lg:grid grid-cols-2 gap-4">
      <!-- BEGIN: Login Info -->
      <div class="hidden lg:flex flex-col min-h-screen">
        <div class="my-auto">
          <img
            class="-intro-x w-full -mt-16"
            :src="require(`@/assets/images/users.png`)"
          />
          <div
            class="-intro-x text-white font-extralight text-4xl xl:text-6xl leading-tight"
          >
            Welcome to <br />
            SMIIC Information <br />
            System.
          </div>
          <div class="divide-y divide-white divide-opacity-20 mt-20">
            <img
              class="-intro-x w-12 mb-2.5"
              :src="require(`@/assets/images/logo-white.svg`)"
            />
            <div class="-intro-x text-white font-extralight leading-tight pt-2">
              The Standards and Metrology Institute for Islamic Countries SMIIC
              is an affiliated institution to Organisation of Islamic
              Cooperation (OIC).
            </div>
          </div>
        </div>
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div class="h-screen xl:h-auto flex py-5 xl:py-0">
        <div
          class="my-auto mx-auto xl:ml-20 bg-white px-5 py-10 rounded-2xl w-full sm:w-3/4"
        >
          <img
            class="w-1/2 mx-auto"
            :src="require(`@/assets/images/logo.svg`)"
          />
          <Login
            v-if="!forgotPassword.show"
            @update-email="(val) => (forgotPassword.email = val)"
          />
          <ForgotPassword v-else :email="forgotPassword.email" />
          <div class="intro-x mt-8 text-blue-400 text-center">
            <a
              href="javascript:;"
              @click="forgotPassword.show = false"
              v-if="forgotPassword.show"
            >
              Back To Login
            </a>
            <a href="javascript:;" @click="forgotPassword.show = true" v-else>
              Reset Password
            </a>
          </div>
        </div>
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import Login from "./Login.vue";
import ForgotPassword from "./ForgotPassword.vue";

export default defineComponent({
  components: { ForgotPassword, Login },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("login");
    });

    const forgotPassword = reactive({
      show: false,
      email: "",
    });

    return {
      forgotPassword,
    };
  },
});
</script>
