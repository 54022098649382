<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <ActivityIcon
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">Document Logs</div>
      <div
        class="grid grid-cols-12 gap-4 mt-5 -intro-y intro-fast"
        v-if="filter.show"
      >
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Document</label>
            </template>
            <Select2Input
              :options="documentSelect2Options"
              v-model="filter.query.uuid"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">User</label>
            </template>
            <Select2Input
              :options="userSelect2Options"
              v-model="filter.query.user_id"
            />
          </VueInputGroup>
        </div>
        <div class="col-span-full sm:col-span-6 md:col-span-4">
          <VueInputGroup>
            <template v-slot:label>
              <label class="block text-white text-lg">Type</label>
            </template>
            <VueSelect v-model="filter.query.type">
              <option value="">All</option>
              <option value="document_opened">Document Opened</option>
              <option value="mail_opened">Mail Opened</option>
            </VueSelect>
          </VueInputGroup>
        </div>
        <div class="col-span-full flex justify-end">
          <button
            class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white font-light px-8 mr-2"
            @click="filter.apply"
          >
            Apply Filter
          </button>
          <button
            class="button bg-gradient-to-b from-red-400 to-red-500 text-white font-light px-8"
            @click="filter.clear"
          >
            Clear Filter
          </button>
        </div>
      </div>
      <button
        class="button rounded-full bg-gradient-to-l from-theme-11 to-theme-13 shadow-lg py-2 absolute -bottom-4"
        @click="filter.show = !filter.show"
      >
        <ArrowDownIcon class="inline w-5 h-5 -intro-y" v-if="!filter.show" />
        <ArrowUpIcon class="inline w-5 h-5 intro-y" v-else />
        Filter
      </button>
    </div>
    <div class="p-3 pt-6">
      <DocumentLogs :filter-query="filter.query" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, provide, ref } from "vue";
import DocumentLogs from "@/views/documents/logs/List.vue";
import { Select2Options } from "@/global-components/select2";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "DocumentLogsContainer",
  components: { DocumentLogs },
  setup() {
    const filter = ref<any>({
      show: false,
      query: {
        uuid: "",
        user_id: "",
        type: "",
      },
      apply: () => null,
      clear: () => null,
    })

    provide("bind[applyFunction]", (callback: () => void) => {
      filter.value.apply = callback;
    });
    provide("bind[clearFunction]", (callback: () => void) => {
      filter.value.clear = callback;
    });

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            title: "title",
            salutation: "salutation",
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.value) return helper.formatNameSurname(obj);
        return obj.text || "";
      },
    });

    const documentSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "uuid",
            text: "title",
            n_number: "n_number",
          },
          "@order": ["created_at:desc"],
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    });

    return {
      filter,
      userSelect2Options,
      documentSelect2Options
    };
  },
});
</script>
