import { App } from "vue";
import faker from "./faker";
import helper from "./helper";
import lodash from "./lodash";
import { devtools } from "./devtools";

export default (app: App): void => {
  app.use(faker);
  app.use(helper);
  app.use(lodash);
  app.use(devtools);
};
