<template>
  <div
    v-if="
      canCreateSmiicBallot || canCreateCommitteeBallot || canCreateAdhocBallot
    "
  >
    <template v-if="canCreateSmiicBallot">
      <router-link
        :to="{
          name: 'ballots.create',
          params: { type: 'DS' },
          query: { committee_id: committeeId || undefined },
        }"
        class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
        data-dismiss="dropdown"
      >
        DS Ballot
      </router-link>
      <router-link
        :to="{
          name: 'ballots.create',
          params: { type: 'FDS' },
          query: { committee_id: committeeId || undefined },
        }"
        class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
        data-dismiss="dropdown"
      >
        FDS Ballot
      </router-link>
      <router-link
        :to="{ name: 'ballots.create', params: { type: 'SMIIC' } }"
        class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
        data-dismiss="dropdown"
      >
        Vote To All Members
      </router-link>
    </template>
    <template v-if="canCreateCommitteeBallot || canCreateAdhocBallot">
      <div class="py-3 px-4 bg-gray-200 border-b border-gray-200 font-semibold">
        Committee Internal Ballot
      </div>
      <template v-if="canCreateCommitteeBallot">
        <!--        <router-link-->
        <!--          :to="{-->
        <!--            name: 'ballots.create',-->
        <!--            params: { type: 'WD' },-->
        <!--            query: { committee_id: committeeId || undefined },-->
        <!--          }"-->
        <!--          class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"-->
        <!--          data-dismiss="dropdown"-->
        <!--        >-->
        <!--          Working Draft Ballot-->
        <!--        </router-link>-->
        <!--        <router-link-->
        <!--          :to="{-->
        <!--            name: 'ballots.create',-->
        <!--            params: { type: 'PWI' },-->
        <!--            query: { committee_id: committeeId || undefined },-->
        <!--          }"-->
        <!--          class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"-->
        <!--          data-dismiss="dropdown"-->
        <!--        >-->
        <!--          PWI Ballot-->
        <!--        </router-link>-->
        <router-link
          :to="{
            name: 'ballots.create',
            params: { type: 'NP' },
            query: { committee_id: committeeId || undefined },
          }"
          class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
          data-dismiss="dropdown"
        >
          New Work Item Proposal
        </router-link>
        <router-link
          :to="{
            name: 'ballots.create',
            params: { type: 'CD' },
            query: { committee_id: committeeId || undefined },
          }"
          class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100 border-b"
          data-dismiss="dropdown"
        >
          Committee Draft
        </router-link>
      </template>
      <router-link
        v-if="canCreateAdhocBallot"
        :to="{
          name: 'ballots.create',
          params: { type: 'ADHOC' },
          query: { committee_id: committeeId || undefined },
        }"
        class="block px-4 py-2 transition duration-300 ease-in-out bg-white hover:bg-gray-100"
        data-dismiss="dropdown"
      >
        ADHOC Ballot
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "CreateBallotDropdownList",
  props: {
    committeeId: {
      default: () => null,
    },
    canCreateAdhocBallot: {
      type: Boolean,
      default: () => false,
    },
    canCreateCommitteeBallot: {
      type: Boolean,
      default: () => false,
    },
    canCreateSmiicBallot: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>
