<template>
  <CreateOrUpdateFolderModal
    :parent-folder-id="folderId"
    ref="refCreateOrUpdateModal"
  />
  <div class="h-full recycle--table" ref="wrapperRef">
    <div class="text-xs" v-if="documents.length > 0">
      <div class="recycle--table__head pb-1" ref="headerRef">
        <div
          class="recycle--table__cell"
          style="flex-basis: 100px; flex-grow: 1"
        >
          N
        </div>
        <div
          class="recycle--table__cell"
          style="flex-basis: 250px; flex-grow: 100"
        >
          Title
        </div>
        <div
          class="recycle--table__cell text-center"
          style="flex-basis: 100px; flex-grow: 25"
        >
          Type
        </div>
        <div
          class="recycle--table__cell text-center"
          style="flex-basis: 125px; flex-grow: 1"
        >
          Status
        </div>
        <div
          class="recycle--table__cell text-right"
          style="flex-basis: 125px; flex-grow: 1"
        >
          Modified
        </div>
      </div>
      <RecycleScroller
        :items="documents"
        :item-size="45"
        :style="{ height: `${contentHeight}px` }"
        :emit-update="true"
        key-field="uuid"
        @update="changeActiveList"
      >
        <template v-slot="{ item }">
          <div
            class="recycle--table__cell"
            style="flex-basis: 100px; flex-grow: 1"
            :class="{
              'flex items-center': !item.id,
              '!bg-blue-50': item.type * 1 < 100,
            }"
          >
            <template v-if="item.id">
              <template v-if="item.type * 1 < 100">
                <Tippy
                  tag="a"
                  href="javascript:;"
                  content="Edit"
                  :options="{ placement: 'bottom' }"
                  class="text-theme-1 mr-2"
                  v-if="$_.get(item, 'abilities.can_update', false)"
                  @click="onEditFolder(item)"
                >
                  <Edit2Icon class="inline w-4 h-4" />
                </Tippy>
                <Tippy
                  tag="a"
                  href="javascript:;"
                  content="Delete"
                  :options="{ placement: 'bottom' }"
                  class="text-theme-1 mr-2"
                  v-if="$_.get(item, 'abilities.can_delete', false)"
                  @click="onDeleteFolder(item.id)"
                >
                  <Trash2Icon class="inline w-4 h-4" />
                </Tippy>
              </template>
              <template v-else>
                <span class="font-semibold">{{
                  $_.get(item, "n_number", "")
                }}</span>
                <span class="ml-1" v-if="item.language !== 'english'">{{
                  $h.convertIdToText(item.language, "langAbbr")
                }}</span>
                <Tippy
                  tag="a"
                  href="javascript:;"
                  v-if="$_.get(item, 'replaced_by_id')"
                  :content="`Replaced: N${$_.get(
                    item,
                    'replaced_by.n_number',
                    ''
                  )}`"
                  :options="{ placement: 'bottom' }"
                  class="inline text-blue-500 hover:text-blue-600"
                  @click="openItem($_.get(item, 'replaced_by'))"
                >
                  <CopyIcon class="w-4 h-4 inline ml-2" />
                </Tippy>
              </template>
            </template>
            <div
              v-else
              class="rounded-lg w-full h-1.5 bg-blue-200 animate-pulse"
            ></div>
          </div>
          <div
            class="recycle--table__cell whitespace-nowrap overflow-hidden overflow-ellipsis"
            style="flex-basis: 250px; flex-grow: 100"
            :class="{
              'flex items-center': !item.id,
              '!bg-blue-50': item.type * 1 < 100,
            }"
          >
            <template v-if="item.id">
              <a
                href="javascript:;"
                class="text-blue-500 hover:text-blue-600"
                @click="openItem(item)"
              >
                {{ $_.get(item, "title", "") || "-" }}
              </a>
            </template>
            <div
              v-else
              class="rounded-lg w-full h-1.5 bg-blue-200 animate-pulse"
            ></div>
          </div>
          <div
            class="recycle--table__cell text-center whitespace-nowrap overflow-hidden overflow-ellipsis"
            style="flex-basis: 100px; flex-grow: 25"
            :class="{
              'flex items-center': !item.id,
              '!bg-blue-50': item.type * 1 < 100,
            }"
          >
            <template v-if="item.id">
              {{
                $store.getters["document/documentTypeTitle"](
                  $_.get(item, "type", "")
                )
              }}
            </template>
            <div
              v-else
              class="rounded-lg w-full h-1.5 bg-blue-200 animate-pulse"
            ></div>
          </div>
          <div
            class="recycle--table__cell text-center whitespace-nowrap overflow-hidden overflow-ellipsis"
            style="flex-basis: 125px; flex-grow: 1"
            :class="{
              'flex items-center': !item.id,
              '!bg-blue-50': item.type * 1 < 100,
            }"
          >
            <div
              v-if="!item.id"
              class="rounded-lg w-full h-1.5 bg-blue-200 animate-pulse"
            ></div>
            <template v-else-if="item.type * 1 > 100">
              {{
                $h.convertIdToText($_.get(item, "status", ""), "documentStatus")
              }}
            </template>
          </div>
          <div
            class="recycle--table__cell text-right whitespace-nowrap overflow-hidden overflow-ellipsis"
            style="flex-basis: 125px; flex-grow: 1"
            :class="{
              'flex items-center': !item.id,
              '!bg-blue-50': item.type * 1 < 100,
            }"
          >
            <template v-if="item.id">
              {{ $h.formatDate($_.get(item, "updated_at", ""), "DD MMM YYYY") }}
            </template>
            <div
              v-else
              class="rounded-lg w-full h-1.5 bg-blue-200 animate-pulse"
            ></div>
          </div>
        </template>
      </RecycleScroller>
    </div>
    <div v-else class="text-center p-4 pt-8">No results found</div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
} from "vue";
import { useRouter } from "vue-router";
import { helper } from "@/utils/helper";
import { useStore } from "@/store";
import { RecycleScroller } from "vue3-virtual-scroller";
import { useGeneralActions } from "@/utils/actions";
import { folderService } from "@/services";
import CreateOrUpdateFolderModal from "../CreateOrUpdateModal.vue";

export default defineComponent({
  name: "DocumentList",
  components: { CreateOrUpdateFolderModal, RecycleScroller },
  props: {
    folderId: {
      default: () => null,
    },
    changeActiveList: {
      type: Function,
      default: () => {
        console.log("changeActiveList");
      },
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const wrapperRef = ref<HTMLElement>();
    const headerRef = ref<HTMLElement>();
    const contentHeight = ref<number>(0);
    const refCreateOrUpdateModal = ref();

    const documents = computed(() =>
      store.state.document.documents.map((document) => {
        return {
          uuid: document.uuid || helper.uuidv4(),
          ...document,
        };
      })
    );

    const { deleteRecords } = useGeneralActions();

    const { triggerDelete } = deleteRecords({
      deleteApiMethod: folderService.delete,
      successCallback(id) {
        if (id) {
          const list = documents.value.filter(
            (document: any) => document.id !== id
          );
          store.dispatch("document/SET_FORCE_DOCUMENTS", list);
        }
      },
    });

    const onDeleteFolder = (id: any) => triggerDelete([id]);

    const calculateHeights = () => {
      const wrapperHeight = wrapperRef.value?.clientHeight || 0;
      const headerHeight = headerRef.value?.offsetHeight || 0;
      contentHeight.value = wrapperHeight - headerHeight;
    };

    const openItem = (item: any) => {
      if (item.type * 1 < 100) {
        router.push({
          name: "documents",
          params: {},
          query: { folderId: item.id },
        });
      } else {
        router.push({
          name: "documents",
          params: { uuid: item.uuid },
        });
      }
    };

    const onEditFolder = (folder: any) => {
      refCreateOrUpdateModal?.value.openEditModal(folder);
    };

    onMounted(() => {
      window.addEventListener("resize", calculateHeights);
      setTimeout(() => {
        calculateHeights();
      }, 100);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", calculateHeights);
    });

    return {
      documents,
      wrapperRef,
      headerRef,
      contentHeight,
      refCreateOrUpdateModal,
      openItem,
      onEditFolder,
      onDeleteFolder,
    };
  },
});
</script>
