
import { defineComponent, reactive, ref, watch } from "vue";
import { getBrowser } from "@/utils/browser";
import { ValidationErrors } from "@/utils/form";
import VueButton from "@/global-components/form-elements/button/Main.vue";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store";
import { authService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";

export default defineComponent({
  components: { VueInput, VueInputGroup, VueButton },
  emits: ["update-email"],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const loading = ref<boolean>(false);

    const formData = reactive({
      email: "",
      password: "",
    });

    const validationErrors = ref(new ValidationErrors());

    watch(
      () => formData.email,
      (email) => {
        emit("update-email", email);
      }
    );

    const submit = () => {
      validationErrors.value.clear();
      loading.value = true;
      authService
        .login({
          ...formData,
          device_name: getBrowser(),
        })
        .then((result) => {
          if (result.kind === "ok") {
            store.dispatch("auth/SET_TOKEN", result.meta.token || "");
            store.dispatch("auth/SET_PROFILE", result.data);
            const query = {};
            if (route.query.redirect)
              _.set(query, "redirect", route.query.redirect);
            router.push({ name: "welcome", query });
          } else {
            if (result.kind === "validation")
              validationErrors.value.record(result.fields);
            ToastNotify({ text: result.message, className: "error" });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      loading,
      formData,
      validationErrors,
      submit,
    };
  },
});
