import pdfMake from "pdfmake/build/pdfmake";
// import pdfMakeFonts from "pdfmake/build/vfs_fonts";
import {
  Content,
  Table,
  TableCell,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import {
  BallotVoteResult,
  FRONTEND_URL,
  GetBallot,
  GetMember,
  GetRole,
} from "@/services";
import { PdfCreation } from "@/utils/pdf-creation/index";
import { LOGO_SVG } from "@/utils/pdf-creation/logo";
import { VoteResults } from "@/views/ballots/view/results";
import { helper } from "@/utils/helper";
import { ballot } from "@/store/ballot";

// pdfMake.vfs = pdfMakeFonts.pdfMake.vfs;
pdfMake.fonts = {
  dosis: {
    normal: `${FRONTEND_URL}/fonts/dosis/Dosis-Regular.ttf`,
    bold: `${FRONTEND_URL}/fonts/dosis/Dosis-Bold.ttf`,
  },
};

interface QueryProps {
  ballot?: GetBallot;
  result?: BallotVoteResult;
}

interface DocumentDefinitions extends TDocumentDefinitions {
  content: Array<Content>;
}

export class BallotResultPDFCreation extends PdfCreation {
  query: QueryProps = {};
  protected voteResults: VoteResults = new VoteResults();

  protected options: DocumentDefinitions = {
    header: {
      margin: [20, 20, 20, 0],
      columns: [
        [
          {
            svg: LOGO_SVG,
            fit: [200, 35],
          },
        ],
      ],
    },
    footer: (currentPage, pageCount) => {
      return {
        margin: [20, 10],
        fontSize: 8,
        columns: [
          "Copyright SMIIC - All rights reserved",
          {
            text: `Page ${currentPage}/${pageCount}`,
            alignment: "right",
          },
        ],
      };
    },
    pageMargins: [20, 60, 20, 15],
    styles: {
      header: {
        fontSize: 13,
        bold: true,
      },
      headerLight: {
        fontSize: 13,
      },
      tableHeader: {
        bold: true,
        fillColor: "#EEEEEE",
      },
    },
    defaultStyle: {
      fontSize: 10,
      lineHeight: 1.2,
      font: "dosis",
    },
    content: [
      {
        margin: [0, 20, 0, 0],
        table: {
          widths: [100, "*", 100, "*"],
          body: [
            [
              { text: "Committee: ", bold: true, fillColor: "#EFEFEF" },
              {
                text: "{{ballot.committee.acronym}} {{ballot.committee.title}}",
                link: `${FRONTEND_URL}/committees/view/{{ballot.committee_id}}`,
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              { text: "Reference: ", bold: true, fillColor: "#EFEFEF" },
              {
                text: "{{ballot.reference}}",
                link: `${FRONTEND_URL}/ballots/view/{{ballot.id}}`,
                colSpan: 3,
              },
              {},
              {},
            ],
            [
              { text: "Title: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.english_title}}", colSpan: 3 },
              {},
              {},
            ],
            [
              { text: "Start Date: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.start_date}}" },
              { text: "End Date: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.end_date}}" },
            ],
            [
              { text: "Opened Date: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.opened_date}}" },
              { text: "Closed Date: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.closed_date}}" },
            ],
            [
              { text: "Status: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.status}}" },
              { text: "Version Number: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.version}}" },
            ],
            [
              { text: "Note: ", bold: true, fillColor: "#EFEFEF" },
              { text: "{{ballot.note}}", colSpan: 3 },
              {},
              {},
            ],
          ],
        },
        layout: {
          hLineColor: "#999999",
          vLineColor: "#999999",
        },
      },
    ],
  };

  prepareCoverPages(): Promise<any> {
    return new Promise((resolve) => {
      this.prepareData();
      const content = this.replaceLines(
        _.cloneDeep(this.options.content) as Array<Content>
      );

      const pageOrientation =
        this.query.ballot?.ballot_type === "SR" ? "landscape" : "portrait";

      const pdfDoc = pdfMake.createPdf({
        ...this.options,
        pageOrientation,
        content,
      });
      pdfDoc.getBuffer((result) => resolve(result));
    });
  }

  protected prepareData(): void {
    if (this.query.result && this.query.ballot) {
      this.query.ballot.start_date = helper.formatDate(
        this.query.ballot.start_date,
        "DD MMMM YYYY"
      );
      this.query.ballot.end_date = helper.formatDate(
        this.query.ballot.end_date,
        "DD MMMM YYYY"
      );
      this.query.ballot.opened_date = helper.formatDate(
        this.query.ballot.opened_date,
        "DD MMMM YYYY"
      );
      this.query.ballot.closed_date = helper.formatDate(
        this.query.ballot.closed_date,
        "DD MMMM YYYY"
      );
      this.voteResults.setup(this.query.result);
      this.prepareQuestionsTable();
      this.prepareCastedVoteTable();
      this.prepareResultTable();
      // this.prepareNotCastedVoteTable();
      this.prepareAnswersTable();
      this.prepareCommentsFromVotersTable();
      this.prepareCommentsFromCommentersTable();
    }
  }

  protected prepareQuestionsTable() {
    this.options.content.push({
      style: "header",
      margin: [0, 15, 0, 5],
      text: "QUESTIONS",
    });
    const table = {
      headerRows: 1,
      // dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: ["auto", "*"],
      body: [
        [
          { text: "ID", style: "tableHeader" },
          { text: "Question", style: "tableHeader" },
        ],
      ],
    };
    this.voteResults.flattenQuestions.map((question) => {
      table.body.push([question.sq, question.text]);
    });
    this.options.content.push({
      table,
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }

  protected prepareResultTable() {
    const currentBallotType = _.get(this.query.ballot, "ballot_type", "");

    if (currentBallotType === "SR") return;

    this.options.content.push({
      style: "header",
      margin: [0, 15, 0, 5],
      text: "RESULT",
    });
    const result = _.get(this.query.result, "result", null);
    const table: Table = {
      headerRows: 1,
      // dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: ["*"],
      body: [
        [
          {
            text: _.get(result, "committee_result", ""),
            alignment: "center",
          },
        ],
        [
          {
            text: _.get(result, "result", ""),
            style: "tableHeader",
            alignment: "center",
          },
        ],
      ],
    };

    if (["SMIIC", "DS", "FDS"].indexOf(currentBallotType) > -1) {
      table.body.splice(0, 0, [
        {
          text: _.get(result, "smiic_result", ""),
          alignment: "center",
        },
      ]);
    }

    this.options.content.push({
      table,
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }

  protected prepareCastedVoteTable() {
    this.options.content.push({
      style: "header",
      margin: [0, 15, 0, 5],
      text: "MEMBERS CASTED VOTE",
    });

    const table: Table & { widths: any[] } = {
      headerRows: 4,
      // dontBreakRows: true,
      keepWithHeaderRows: 4,
      widths: [],
      body: [],
    };

    const mergeGroups = (groups: any[]): any[] => {
      groups = groups.map((cells: any[], index: number) => {
        const mergeCells: any[] = [];
        cells.map((cell) => {
          mergeCells.push(cell);
          if (cell.colspan * 1 > 1) {
            for (let i = 0; i < cell.colspan - 1; i++) {
              mergeCells.push({ rowspan: cell.rowspan });
            }
          }
        });
        mergeCells.map((cell, cellIndex) => {
          if (cell.rowspan * 1 > 1) {
            for (let i = 0; i < cell.rowspan; i++) {
              groups[index + i].splice(cellIndex, 0, {});
            }
          }
        });
        return mergeCells;
      });
      return groups.map((cells: any[]) => {
        const tableCells: TableCell[] = [];
        cells.map((cell) => {
          if (cell.text) {
            tableCells.push({
              text: cell.text,
              colSpan: cell.colspan,
              rowSpan: cell.rowspan,
              style: "tableHeader",
              fontSize: 9,
              alignment: "center",
            });
          } else {
            tableCells.push({});
          }
        });
        table.body.push(tableCells);
        return tableCells;
      });
    };

    const headers = mergeGroups(this.voteResults.table.headers);

    this.voteResults.table.rows.map((cells: any[]) => {
      table.body.push(
        cells.map((cell) => ({
          text: cell,
          alignment: "center",
        }))
      );
    });

    mergeGroups(this.voteResults.table.footers);

    for (let i = 0; i < headers[0].length; i++) {
      table.widths.push("*");
    }

    this.options.content.push({
      table,
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }

  protected prepareNotCastedVoteTable() {
    const members: GetMember[] = _.cloneDeep(
      _.get(this.query, "result.members", [])
    ).filter(
      (member: GetMember) =>
        this.voteResults.table.voteCastedMembers.indexOf(member.id) === -1
    );
    if (members.length === 0) return;
    this.options.content.push({
      style: "header",
      margin: [0, 15, 0, 5],
      text: "MEMBERS NOT CASTED VOTE",
    });
    const table: Table = {
      headerRows: 1,
      // dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: ["auto", "*"],
      body: [
        [
          { text: "Member", style: "tableHeader" },
          { text: "Title", style: "tableHeader" },
        ],
      ],
    };

    members.map((member) => {
      table.body.push([
        `${_.get(member, "country_title", "")} (${member.acronym})`,
        member.title,
      ]);
    });
    this.options.content.push({
      table,
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }

  protected prepareCommentsFromVotersTable() {
    if (this.voteResults.commentsFromVoters.length === 0) return;
    this.options.content.push({
      pageBreak: "before",
      style: "header",
      margin: [0, 15, 0, 5],
      text: "COMMENTS FROM VOTERS",
    });
    const table: Table = {
      headerRows: 1,
      // dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: [75, "*"],
      body: [
        [
          { text: "Member", style: "tableHeader" },
          { text: "Comments", style: "tableHeader" },
        ],
      ],
    };
    this.voteResults.commentsFromVoters.map((comment) => {
      const title = `${_.get(comment, "member.country_title", "")} (${_.get(
        comment,
        "member.acronym",
        ""
      )})`;

      const commentsArr: Content[] = [];
      _.get(comment, "comments", []).map((item: any) => {
        commentsArr.push({
          text: `Comment To ${item.question}`,
          bold: true,
          margin: [0, 2],
        });
        if (item.comment) commentsArr.push({ text: item.comment, fontSize: 9 });
        _.get(item, "files", []).map((file: any) => {
          commentsArr.push({
            text: file.title,
            fontSize: 9,
            link: `${FRONTEND_URL}/documents/${file.uuid}`,
            color: "#0059A6",
            margin: [0, 1, 0, 0],
          });
        });
      });
      table.body.push([title, commentsArr]);
    });
    this.options.content.push({
      table,
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }

  protected prepareCommentsFromCommentersTable() {
    if (this.voteResults.commentsFromCommenters.length === 0) return;
    this.options.content.push({
      pageBreak: "before",
      style: "header",
      margin: [0, 15, 0, 5],
      text: "COMMENTS FROM COMMENTERS",
    });
    const table: Table = {
      headerRows: 1,
      // dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: [75, "*"],
      body: [
        [
          { text: "Member", style: "tableHeader" },
          { text: "Comment", style: "tableHeader" },
        ],
      ],
    };
    this.voteResults.commentsFromCommenters.map((comment) => {
      const title = `${_.get(comment, "member.country_title", "")} (${_.get(
        comment,
        "member.acronym",
        ""
      )})`;

      const commentsArr: Content[] = [];
      _.get(comment, "comments", []).map((item: any) => {
        commentsArr.push({
          text: `Commenter (${item.user_name})`,
          bold: true,
          margin: [0, 2],
        });
        if (item.text) commentsArr.push({ text: item.text, fontSize: 9 });
        _.get(item, "files", []).map((file: any) => {
          commentsArr.push({
            text: file.title,
            fontSize: 9,
            link: `${FRONTEND_URL}/documents/${file.uuid}`,
            color: "#0059A6",
            margin: [0, 1, 0, 0],
          });
        });
      });
      table.body.push([title, commentsArr]);
    });
    this.options.content.push({
      table,
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }

  protected prepareAnswersTable() {
    if (this.voteResults.allAnswersToQuestions.length === 0) return;
    this.options.content.push({
      pageBreak: "before",
      style: "header",
      margin: [0, 20, 0, 0],
      text: "ANSWERS OF ALL MEMBERS",
    });

    this.voteResults.allAnswersToQuestions.map((value, index) =>
      this.eachQuestionInAnswers(value, index)
    );
  }

  protected eachQuestionInAnswers(
    question: Record<string, any>,
    index: number
  ) {
    const table: Table = {
      headerRows: 1,
      // dontBreakRows: true,
      keepWithHeaderRows: 1,
      widths: [75, "*"],
      body: [
        [
          {
            text: `Answers To ${question.sq}: "${question.text}"`,
            colSpan: 2,
            style: "tableHeader",
          },
          {},
        ],
      ],
    };
    _.get(question, "answers", []).map((answer: Record<string, any>) => {
      const members = answer.members.map((m: Record<string, any>) => {
        return `${_.get(m, "country_title", "")} (${_.get(m, "acronym", "")})`;
      });
      table.body.push([
        {
          fontSize: 9,
          text: [
            { text: `${_.get(answer, "members", []).length} X  `, bold: true },
            answer.text,
          ],
        },
        { text: members.join(",  "), colSpan: 1, fontSize: 9 },
      ]);
    });
    this.options.content.push({
      table,
      margin: [0, 20, 0, 0],
      layout: {
        hLineColor: "#999999",
        vLineColor: "#999999",
      },
    });
  }
}
