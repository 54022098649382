import {
  computed,
  reactive,
  Ref,
  ref,
  WritableComputedRef,
  ComputedRef,
} from "vue";
import { fetchSelection, PaginationMeta } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";

interface SelectionQuery {
  source: string;
  config: Record<string, any> | ComputedRef<Record<string, any>>;
  filterFormat?: Record<string, string>;
}

export interface GeneralFetchRecordsProps {
  fetchApiMethod?(query: Record<string, any>): Promise<any>;
  successCallback?(): void;
  errorCallback?(): void;
  selectionQuery?: (() => SelectionQuery) | SelectionQuery;
}

export interface GeneralFetchRecords {
  loading: Ref<boolean>;
  selectedRows: Ref<number[]>;
  list: { data: Record<string, any>[]; meta: PaginationMeta };
  selectAll: WritableComputedRef<boolean>;
  selectionQuery?: (() => SelectionQuery) | SelectionQuery;
  fetch(query: Record<string, any>): Promise<void>;
}

export default function fetchRecords(
  props: GeneralFetchRecordsProps
): GeneralFetchRecords {
  const selectedRows = ref<number[]>([]);
  const loading = ref<boolean>(true);

  const list = reactive<{ data: Record<string, any>[]; meta: PaginationMeta }>({
    data: [],
    meta: { current_page: 1, total: 0 },
  });

  const selectAll = computed<boolean>({
    get: () => selectedRows.value?.length === list.data.length,
    set: (value) => {
      let selected: any = [];
      if (value) {
        selected = list.data.map((item) => item.id);
      }
      selectedRows.value = selected;
    },
  });

  const fetch = async (query?: Record<string, any>) => {
    if (!props.fetchApiMethod) {
      await fetchThroughSelection(query);
    } else {
      loading.value = true;
      const result = await props.fetchApiMethod(query || {});
      if (result.kind !== "ok") {
        ToastNotify({ text: result.message, className: "error" });
        if (props.errorCallback) props.errorCallback();
      } else {
        list.data = result.data;
        list.meta = result.meta;
        if (props.successCallback) props.successCallback();
      }
      loading.value = false;
    }
  };

  const fetchThroughSelection = async (query?: Record<string, any>) => {
    if (props.selectionQuery) {
      const selectionQuery =
        typeof props.selectionQuery === "function"
          ? props.selectionQuery()
          : props.selectionQuery;
      let config = _.cloneDeep(selectionQuery.config);
      if (_.has(selectionQuery.config, "effect")) {
        config = _.cloneDeep(selectionQuery.config.value);
      }
      _.unset(config, "@get");
      _.unset(config, "@first");
      const params = {
        [selectionQuery.source]: config,
      };
      if (!_.has(config, "@offset")) {
        _.set(params, [selectionQuery.source, "@page"], query?.page || 1);
      }
      if (_.has(query, "paginate")) {
        _.set(
          params,
          [selectionQuery.source, "@paginate"],
          query?.paginate || 15
        );
      }
      if (selectionQuery.filterFormat && query) {
        Object.keys(query).map((key) => {
          const value = _.get(props.selectionQuery, `filterFormat.${key}`, "");
          if (value) {
            _.set(params, `${selectionQuery.source}.${value}`, query[key]);
          }
        });
      }
      loading.value = true;
      const result = await fetchSelection(params);
      try {
        if (result.kind !== "ok") {
          ToastNotify({ text: result.message, className: "error" });
          if (props.errorCallback) props.errorCallback();
        } else {
          const { data = [], ...meta } = result.data[selectionQuery.source];
          list.data = data;
          list.meta = meta as PaginationMeta;
          if (props.successCallback) props.successCallback();
        }
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    }
  };

  return {
    selectedRows,
    selectAll,
    loading,
    list,
    selectionQuery: props?.selectionQuery,
    fetch,
  };
}
