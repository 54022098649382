<template>
  <button class="button" :disabled="loading">
    <slot v-if="!loading"></slot>
    <div class="flex items-center justify-center" v-else>
      <span class="mr-2 font-light">{{loadingText}}</span>
      <LoaderIcon class="inline spin-5" />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueButton",
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    loadingText: {
      type: String,
      default: () => "Please Waiting"
    }
  }
});
</script>
