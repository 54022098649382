
import { defineComponent, provide, ref } from "vue";
import DocumentLogs from "@/views/documents/logs/List.vue";
import { Select2Options } from "@/global-components/select2";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "DocumentLogsContainer",
  components: { DocumentLogs },
  setup() {
    const filter = ref<any>({
      show: false,
      query: {
        uuid: "",
        user_id: "",
        type: "",
      },
      apply: () => null,
      clear: () => null,
    })

    provide("bind[applyFunction]", (callback: () => void) => {
      filter.value.apply = callback;
    });
    provide("bind[clearFunction]", (callback: () => void) => {
      filter.value.clear = callback;
    });

    const userSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        user: {
          "@pagination": 15,
          "@select": {
            value: "id",
            name: "name",
            surname: "surname",
            title: "title",
            salutation: "salutation",
          },
        },
      },
      templateSelection(obj: any): any {
        if (obj.value) return helper.formatNameSurname(obj);
        return obj.text || "";
      },
    });

    const documentSelect2Options = ref<Select2Options>({
      ajaxQuery: {
        document: {
          "@pagination": 15,
          "@select": {
            value: "uuid",
            text: "title",
            n_number: "n_number",
          },
          "@order": ["created_at:desc"],
        },
      },
      templateSelection(obj: any): any {
        if (obj.n_number) return `(N${obj.n_number || ""}) ${obj.text}`;
        return obj.text;
      },
    });

    return {
      filter,
      userSelect2Options,
      documentSelect2Options
    };
  },
});
