<template>
  <div>
    <div class="flex items-center text-lg mt-4 mb-8" v-if="canAddNDocument">
      <input
        class="input input--switch input--lg bg-gray-300"
        type="checkbox"
        v-model="editableFormData.is_n_document"
      />
      <label class="ml-2"
        >Do you want to add n-number to the selected file?</label
      >
    </div>
    <DocumentGeneralProperties
      :state="state"
      :form-data="editableFormData"
      :validation-errors="validationErrors"
    />
    <NDocumentProperties
      :state="state"
      v-if="editableFormData.is_n_document"
      :form-data="editableFormData"
      :validation-errors="validationErrors"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, toRefs, watch } from "vue";
import {
  DOCUMENT_GROUP_TYPES,
  GetDocumentType,
  PostDocument,
} from "@/services";
import { ValidationErrors } from "@/utils/form";
import DocumentGeneralProperties from "./GeneralProperties.vue";
import NDocumentProperties from "./NDocumentProperties.vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "DocumentInfo",
  components: { NDocumentProperties, DocumentGeneralProperties },
  props: {
    state: { type: Object, default: () => ({}) },
    formData: { type: Object as () => PostDocument, default: () => ({}) },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const store = useStore();
    const editableFormData = reactive(toRefs<PostDocument>(props.formData));
    watch(
      () => editableFormData.is_n_document,
      (val) => {
        if (!val) {
          editableFormData.n_number = "";
          editableFormData.keywords = "";
          editableFormData.pages = "";
          editableFormData.language = "english";
          editableFormData.version = "";
          editableFormData.expected_action = "";
          editableFormData.exp_action_due_date = "";
          editableFormData.document_date = "";
          editableFormData.note = "";
          editableFormData.replaces_id = "";
          editableFormData.replaced_by_id = "";
        }
      },
      { flush: "post" }
    );

    const canAddNDocument = computed<boolean>(() => {
      let canAddNDocument = false;
      store.getters["document/documentTypes"].map((type) => {
        if (type.children) {
          type.children.map((subType) => {
            if (editableFormData.type * 1 === subType.value) {
              canAddNDocument = type.n_document;
            }
          });
        }
      });
      return canAddNDocument;
    });

    watch(canAddNDocument, (val) => {
      editableFormData.is_n_document = val;
    });

    editableFormData.is_n_document = canAddNDocument.value;

    return {
      editableFormData,
      canAddNDocument,
    };
  },
});
</script>
