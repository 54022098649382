<template>
  <svg
    width="77"
    height="61"
    viewBox="0 0 77 61"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.2059 8.7326H38.9378L32.7099 1.32701C32.524 1.10356 32.2454 0.978248 31.955 0.987306H6.79412C3.02792 1.02467 -0.00433603 4.09052 4.65447e-06 7.85692V53.151C-0.000184071 56.9144 3.03093 59.9759 6.79412 60.0131H70.2059C73.9691 59.9759 77.0002 56.9144 77 53.151V15.5947C77.0002 11.8313 73.9691 8.76997 70.2059 8.7326Z"
      fill="currentColor"
    />
  </svg>
</template>
