<template>
  <div class="container relative sm:px-10">
    <div class="grid grid-cols-5 h-screen">
      <!-- BEGIN: Login Info -->
      <div
        class="flex justify-center items-end lg:items-center lg:min-h-screen col-span-5 lg:col-span-2"
      >
        <div class="lg:my-auto -intro-x">
          <div
            class="rounded-full h-48 w-48 sm:h-96 sm:w-96 border border-white border-opacity-30 flex items-center justify-center"
          >
            <div
              class="rounded-full h-32 w-32 sm:h-48 sm:w-48 border border-white border-opacity-30 flex items-center justify-center"
            >
              <img
                class="rounded-full h-24 w-24 sm:h-36 sm:w-36"
                :src="gravatar"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- END: Login Info -->
      <!-- BEGIN: Login Form -->
      <div
        class="lg:h-screen xl:h-auto flex py-5 xl:py-0 col-span-5 lg:col-span-3"
      >
        <div class="lg:my-auto px-5 py-10 w-full intro-x">
          <div
            class="text-center lg:text-left text-blue-400 text-2xl sm:text-3xl font-extralight"
          >
            Welcome
          </div>
          <div
            class="text-center lg:text-left text-white text-4xl sm:text-7xl font-extralight mt-3"
          >
            {{ nameSurname }}
          </div>
        </div>
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "@/store";
import md5 from "blueimp-md5";
import { useRoute, useRouter } from "vue-router";
import { authService, ballotService, projectStageService } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { helper } from "@/utils/helper";

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const profile = store.getters["auth/profile"];

    const nameSurname = computed(() => {
      return helper.formatNameSurname(profile);
      // return `${profile?.salutation} ${profile?.name} ${profile?.surname}`;
    });

    const gravatar = computed(() => {
      return `https://secure.gravatar.com/avatar/${md5(
        profile?.email
      )}?size=256`;
    });

    onMounted(async () => {
      cash("body")
        .removeClass("app")
        .removeClass("error-page")
        .addClass("welcome");

      await authService.profile().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("auth/SET_PROFILE", result.data);
        } else {
          ToastNotify({ text: result.message, className: "error" });
          if (result.kind === "unauthorized") {
            store.dispatch("auth/SET_TOKEN", "");
            store.dispatch("auth/SET_PROFILE", null);
            router.replace({ name: "login" });
          }
        }
      });

      store.dispatch("document/FETCH_DOCUMENT_TYPES", undefined);
      store.dispatch("user/FETCH_ROLE_TYPES", undefined);

      ballotService.fetchQuestions().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("ballot/SET_BALLOT_QUESTIONS", result.data);
        }
      });

      projectStageService.fetchInitialStages().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("project/SET_PROJECT_INITIAL_TYPES", result.data);
        }
      });

      projectStageService.fetchManualStages().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("project/SET_MANUAL_STAGES", result.data);
        }
      });

      projectStageService.fetchBallotStages().then((result) => {
        if (result.kind === "ok") {
          store.dispatch("project/SET_BALLOT_STAGES", result.data);
        }
      });

      await store.dispatch("committee/FETCH_ALL_COMMITTEES", undefined);
      store.dispatch("auth/SET_SPLASH_SCREEN", false);
      if (route.redirectedFrom) {
        return router.replace({
          name: route.redirectedFrom.name as string,
          params: route.redirectedFrom.params,
          query: route.redirectedFrom.query,
        });
      }
      router.replace({ path: _.get(route.query, "redirect", "/") as string });
    });

    return {
      nameSurname,
      gravatar,
    };
  },
});
</script>
