<template>
  <div class="px-4 py-4 mt-1.5 bg-white border border-dashed">
    <div>
      <span class="text-theme-1 font-medium mr-1.5"
        >{{ label }}{{ questionIndex + 1 }})</span
      >
      <span
        :class="{
          'text-red-400': validationErrors.get(
            `${path}.uuid`,
            `${path}.answer`,
            `${path}.answer.uuid`,
            `${path}.answer.comment`,
            `${path}.answer.files`
          ),
        }"
        >{{ question.question }}</span
      >
    </div>
    <div>
      <div
        v-for="(error, index) in validationErrors.get(
          `${path}.uuid`,
          `${path}.answer`,
          `${path}.answer.uuid`
        )"
        :key="`validation-` + index"
        class="text-red-300 mt-1 text-xs"
      >
        {{ error }}
      </div>
    </div>
    <div class="px-4 py-0.5 mt-4 bg-gray-100">
      <div
        v-for="answer in question.answers"
        :key="answer.uuid"
        class="border-b border-dashed border-gray-300 last:border-0"
      >
        <div class="flex items-center my-3">
          <input
            class="input !bg-gray-300"
            type="radio"
            v-model="editableQuestion.vote.answer"
            :value="answer.uuid"
            :name="question.uuid"
            :id="answer.uuid"
          />
          <label :for="answer.uuid" class="flex-1 ml-2">{{
            answer.answer
          }}</label>
        </div>
      </div>
    </div>
    <div
      class="p-4 mt-1.5 bg-gray-100"
      v-show="selectedAnswer && selectedAnswer.comment_required !== 3"
    >
      <VueInputGroup
        class="relative mb-4"
        v-if="selectedAnswer"
        :validation-errors="
          validationErrors.get(`${path}.answer.comment`, `${path}.answer.files`)
        "
      >
        <template v-slot:label>
          <label class="block font-medium mb-1">
            {{ selectedAnswer.comment_name }}
          </label>
        </template>
        <VueTextarea
          v-if="[4, 5].indexOf(selectedAnswer.comment_required) === -1"
          class="input--sm bg-white shadow-none rounded"
          v-model="editableQuestion.vote.comment"
          :validation-errors="
            validationErrors.get(
              `${path}.answer.comment`,
              `${path}.answer.files`
            )
          "
        />
      </VueInputGroup>
      <Dropzone
        v-if="committeeId"
        refKey="dropzoneRef"
        class="dropzone__document rounded-lg bg-gray-200"
        :options="dropzoneOptions"
        :success="successUploadFile"
        :remove="removeUploadedFile"
        :uploading="startUpload"
        :completed="endUpload"
      />
    </div>
    <template v-if="subQuestions.length > 0">
      <div class="section-divider my-6">Sub Questions</div>
      <VoteQuestion
        v-for="(subQuestion, subQuestionIndex) in subQuestions"
        :key="subQuestion.uuid"
        :question="subQuestion"
        :question-index="subQuestionIndex"
        label="S.Q."
        class="bg-gray-50"
        :validation-errors="validationErrors"
        :path="`${path}.answer.questions.${subQuestionIndex}`"
        :start-upload="startUpload"
        :end-upload="endUpload"
        :committee-id="committeeId"
        :ballot-id="ballotId"
      />
    </template>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  provide,
  reactive,
  ref,
  toRefs,
} from "vue";
import { GetDocument, Question, QuestionAnswer } from "@/services";
import Dropzone from "@/global-components/dropzone/Main.vue";
import { DropzoneFile, DropzoneOptions } from "dropzone";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "VoteQuestion",
  components: { Dropzone },
  props: {
    question: {
      type: Object as () => Question,
      default: () => ({}),
    },
    questionIndex: {
      type: Number,
      default: () => -1,
    },
    label: {
      type: String,
      default: () => "Q.",
    },
    committeeId: {
      default: () => null,
    },
    ballotId: {
      default: () => null,
    },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
    path: {
      type: String,
      default: () => "",
    },
    startUpload: {
      type: Function,
      default: () => null,
    },
    endUpload: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const dropzoneRef = ref<HTMLElement>();
    provide("bind[dropzoneRef]", (el: HTMLElement) => {
      dropzoneRef.value = el;
    });

    const dropzoneOptions = computed<DropzoneOptions>(() => {
      return {
        acceptedFiles:
          "application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        params: {
          committee_id: props.committeeId,
          resource_type: "ballot",
          resource_id: props.ballotId,
          // folder_id: _.get(props.committeeFolder, "id", null),
          type: 610,
        },
      };
    });
    const editableQuestion = reactive(toRefs<Question>(props.question));
    const selectedAnswerIndex = computed(() => {
      return _.findIndex(editableQuestion.answers, {
        uuid: editableQuestion.vote?.answer,
      });
    });
    const selectedAnswer = computed<QuestionAnswer>(() => {
      return (
        _.get(editableQuestion.answers, `${selectedAnswerIndex.value}`) || null
      );
    });
    const subQuestions = computed<Question[]>(() => {
      return _.get(selectedAnswer.value, `questions`, []);
    });

    const successUploadFile = (document: GetDocument) => {
      if (editableQuestion.vote) {
        editableQuestion.vote.files.push({
          id: document.id,
          uuid: document.uuid,
          title: document.title,
          n_number: document.n_number,
          file_name: document.file_name,
          file_size: document.file_size,
        });
      }
    };

    const removeUploadedFile = (uuid: string) => {
      if (editableQuestion.vote) {
        const fileIndex = _.findIndex(editableQuestion.vote.files, { uuid });
        if (fileIndex > -1) editableQuestion.vote.files.splice(fileIndex, 1);
      }
    };

    const manualAddDocuments = (documents: Record<string, any>[]) => {
      documents.map((document: Record<string, any>) => {
        const mockFile: Record<string, any> = {
          accepted: true,
          name: document.file_name,
          size: document.file_size,
          processing: true,
          status: "success",
          documentUuid: document.uuid,
          documentId: document.id,
        };
        dropzoneRef.value?.dropzone.files.push(mockFile as DropzoneFile);
        dropzoneRef.value?.dropzone.emit("addedfile", mockFile);
        dropzoneRef.value?.dropzone.emit("complete", mockFile);
      });
    };

    onMounted(() => {
      const files = editableQuestion.vote?.files || [];
      if (files.length > 0) {
        setTimeout(() => manualAddDocuments(files), 100);
      }
    });

    return {
      dropzoneRef,
      subQuestions,
      editableQuestion,
      selectedAnswerIndex,
      selectedAnswer,
      dropzoneOptions,
      successUploadFile,
      removeUploadedFile,
    };
  },
});
</script>
