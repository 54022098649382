<template>
  <svg
    width="63"
    height="49"
    viewBox="0 0 63 49"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.3767 0.4375H2.62671C2.31071 0.437491 1.99781 0.499724 1.70587 0.620646C1.41392 0.741568 1.14866 0.918811 0.925213 1.14225C0.70177 1.3657 0.524527 1.63096 0.403605 1.92291C0.282683 2.21485 0.22045 2.52775 0.220459 2.84375V43.75C0.221918 45.0259 0.729417 46.2491 1.63162 47.1513C2.53382 48.0535 3.75705 48.561 5.03296 48.5625H57.9705C59.2464 48.561 60.4696 48.0535 61.3718 47.1513C62.274 46.2491 62.7815 45.0259 62.783 43.75V2.84375C62.783 2.52775 62.7207 2.21485 62.5998 1.92291C62.4789 1.63096 62.3016 1.3657 62.0782 1.14225C61.8548 0.918811 61.5895 0.741568 61.2976 0.620646C61.0056 0.499724 60.6927 0.437491 60.3767 0.4375ZM5.03296 19.6875H17.0642V29.3125H5.03296V19.6875ZM21.8767 19.6875H57.9717L57.9724 29.3125H21.8767V19.6875ZM5.03296 34.125H17.0642V43.75H5.03296V34.125ZM57.9705 43.75H21.8767V34.125H57.9728L57.9735 43.7499L57.9705 43.75Z"
      fill="currentColor"
    />
  </svg>
</template>
