import { reactive, watch } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

interface Filter {
  show: boolean;
  query: Record<string, any>;
}

export default function filterRecords(
  fetch: (query: Record<string, any>) => Promise<void>,
  query: Record<string, any>,
  withoutRoute?: boolean
) {
  const route = useRoute();
  const router = useRouter();
  const defaultFilterQuery = _.cloneDeep(query);

  const filter = reactive<Filter>({
    show: false,
    query,
  });

  const changePage = (page: number) => {
    filter.query.page = page;
    if (!withoutRoute) router.push({ query: formattedQuery() }).finally();
    else fetch(formattedQuery()).finally();
  };

  const changePerPage = (perPage: number) => {
    console.log({ title: "Catch PerPage", perPage });
    filter.query.page = 1;
    filter.query.paginate = perPage;
    if (!withoutRoute) router.push({ query: formattedQuery() }).finally();
    else fetch(formattedQuery()).finally();
  };

  const applyFilter = () => {
    if (_.has(filter.query, "page")) filter.query.page = 1;
    if (!withoutRoute) router.push({ query: formattedQuery() }).finally();
    else fetch(formattedQuery()).finally();
  };

  const clearFilter = () => {
    filter.query = _.cloneDeep({ ...defaultFilterQuery });
    if (_.has(filter.query, "page")) filter.query.page = 1;
    if (!withoutRoute) router.push({ query: formattedQuery() }).finally();
    else fetch(formattedQuery()).finally();
  };

  const setRouteToFilter = () => {
    Object.keys(filter.query).map((key) => {
      if (!route.query[key]) {
        filter.query[key] = defaultFilterQuery[key];
      } else {
        if (
          _.isArray(defaultFilterQuery[key]) &&
          !_.isArray(route.query[key])
        ) {
          filter.query[key] = [route.query[key]];
        } else {
          filter.query[key] = route.query[key];
        }
      }
    });
  };

  const formattedQuery = (): Record<string, any> => {
    const obj: Record<string, any> = {};
    Object.keys(filter.query).map((key: string) => {
      if (
        (_.isArray(filter.query[key]) && filter.query[key].length > 0) ||
        (!_.isArray(filter.query[key]) && filter.query[key])
      )
        _.set(obj, key, filter.query[key]);
    });
    return obj;
  };

  if (!withoutRoute) {
    setRouteToFilter();
    watch(
      () => route.query,
      () => {
        setRouteToFilter();
        fetch(formattedQuery()).finally();
      },
      {
        flush: "post",
      }
    );
  }

  return {
    filter,
    applyFilter,
    clearFilter,
    changePage,
    changePerPage,
    formattedQuery,
  };
}
