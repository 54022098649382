<template>
  <div class="mt-2 pt-2 pl-4 border-t border-dashed border-gray-300">
    <VueInputGroup
      :validation-errors="
        validationErrors?.get(
          `${path.join('.')}`,
          `${[...path, 'answer'].join('.')}`,
          `${[...path, 'questions'].join('.')}`
        ) || []
      "
    >
      <div>
        <span class="font-semibold mr-1.5">{{ label }}</span>
        {{ answer.answer || "-" }}
      </div>
      <div
        class="text-xs mt-1"
        v-if="$_.get(answer, 'comment_required', '0') * 1 !== 3"
      >
        <span class="rounded bg-indigo-100 px-2.5 py-0.5 mr-1">{{
          $_.get(answer, "comment_name", "")
        }}</span>
        <span class="rounded bg-red-100 px-2.5 py-0.5">
          <template v-if="$_.get(answer, 'comment_required', '0') * 1 === 1"
            >Comment Required</template
          >
          <template
            v-else-if="$_.get(answer, 'comment_required', '0') * 1 === 2"
            >Comment Optional</template
          >
          <template
            v-else-if="$_.get(answer, 'comment_required', '0') * 1 === 4"
            >Only File Required</template
          >
          <template
            v-else-if="$_.get(answer, 'comment_required', '0') * 1 === 5"
            >Only File Optional</template
          >
          <template v-else>No Comment Allowed</template>
        </span>
      </div>
    </VueInputGroup>
    <div class="pl-4" v-if="answer.questions && answer.questions.length > 0">
      <QuestionViewItem
        v-for="(question, questionIndex) in $_.get(answer, 'questions', [])"
        :key="question.uuid"
        :question="question"
        :path="[...path, 'questions', questionIndex]"
        :validation-errors="validationErrors"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { ValidationErrors } from "@/utils/form";

export default defineComponent({
  name: "AnswerViewItem",
  props: {
    answer: { type: Object, required: true },
    path: { type: Array, required: true },
    validationErrors: {
      type: Object as () => ValidationErrors,
      default: () => ({}),
    },
  },
  setup(props) {
    const label = computed(() => {
      const sq: any[] = [];
      props.path.map((val: any, index) => {
        if (_.get(props.path, index - 1, "") === "answers")
          sq.push(val * 1 + 1);
      });
      return `A.${sq.join(".")})`;
    });

    return {
      label,
    };
  },
});
</script>
