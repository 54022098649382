<template>
  <div class="mb-6" v-if="canEdit || filterDefinedRoles.length > 0">
    <div class="section-divider relative mb-3">
      <small>{{ $_.get(roleType, "title", "") }}</small>
      <Tippy
        tag="button"
        content="Add Role"
        :options="{ placement: 'bottom' }"
        class="button p-0 absolute right-4 bg-white shadow-lg rounded-full"
        @click="() => onAddRole(roleType)"
        v-if="canEdit"
      >
        <PlusCircleIcon class="w-8 h-8 m-0.5" />
      </Tippy>
    </div>
    <div
      class="bg-yellow-100 px-3 py-2 rounded-lg font-light"
      v-if="filterDefinedRoles.length === 0"
    >
      No roles were found for this user!
    </div>
    <div class="overflow-x-auto" v-else>
      <table class="table w-full text-xs">
        <thead>
          <tr>
            <th class="whitespace-nowrap">Resource & Appointed By</th>
            <th class="whitespace-nowrap" v-if="visibleBallotTypes">
              Ballot Types
            </th>
            <th>Information</th>
            <th class="text-right" v-if="canEdit">Functions</th>
          </tr>
        </thead>
        <tbody>
          <TransactionStatement
            v-for="row in filterDefinedRoles"
            :key="`defined-${row.id}`"
            tag="tr"
            :processing="processing && transactionIds.indexOf(row.id) > -1"
            :waiting="transactionIds[0] !== row.id"
          >
            <td>
              <span v-if="$_.get(row, 'scope.acronym', '')" class="mr-1">
                {{ $_.get(row, "scope.acronym", "") }}
              </span>
              {{ $_.get(row, "scope.title", "") }}
            </td>
            <td v-if="visibleBallotTypes">
              <span
                v-for="ballotType in $_.get(row, 'ballot_types', [])"
                :key="ballotType"
                class="mr-2 bg-yellow-200 px-2.5 py-1 rounded-xl"
                >{{ ballotType }}</span
              >
            </td>
            <td>
              <div v-if="$_.get(row, 'meta.information', '')">
                {{ $_.get(row, "meta.information", "") }}
              </div>
              <div class="mt-3" v-if="$_.get(row, 'meta.function', '')">
                <div
                  class="font-medium pb-1 mb-1 border-b"
                  v-if="$_.get(row, 'meta.information', '')"
                >
                  Function And Title
                </div>
                <div>{{ $_.get(row, "meta.function", "") }}</div>
              </div>
            </td>
            <td class="whitespace-nowrap text-right" v-if="canEdit">
              <template v-if="canEdit">
                <Tippy
                  tag="button"
                  content="Edit"
                  :options="{ placement: 'bottom' }"
                  class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                  @click="() => onEditRole(roleType, row)"
                >
                  <Edit2Icon class="inline w-4 h-4" />
                </Tippy>
                <Tippy
                  tag="button"
                  content="Delete"
                  :options="{ placement: 'bottom' }"
                  class="button py-2 px-2 bg-white text-theme-1 rounded-full shadow-lg mr-2"
                  @click="() => triggerDelete([row.id])"
                >
                  <Trash2Icon class="inline w-4 h-4" />
                </Tippy>
              </template>
            </td>
          </TransactionStatement>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { GetRole, GetRoleType, GetUser, roleService } from "@/services";
import { useGeneralActions } from "@/utils/actions";
import TransactionStatement from "@/components/transaction-statement/Main.vue";

export default defineComponent({
  name: "UserRoleBehaviourList",
  components: { TransactionStatement },
  props: {
    definedRoles: {
      type: Array as () => GetRole[],
      default: () => [],
    },
    roleType: {
      type: Object as () => GetRoleType,
      default: () => ({}),
    },
    onAddRole: {
      type: Function,
      default: () => null,
    },
    onEditRole: {
      type: Function,
      default: () => null,
    },
    userId: {
      type: Number,
      default: () => 0,
    },
    user: {
      type: Object as () => GetUser,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { deleteRecords } = useGeneralActions();

    const filterDefinedRoles = computed(() =>
      props.definedRoles.filter(
        (role) => props.roleType.behaviour === role.behaviour
      )
    );

    const canEdit = computed<boolean>(() => {
      let can = _.get(props.user, "abilities.can_update_roles", false);
      if (props.roleType.type === "staff") {
        can = _.get(props.user, "abilities.can_update_staff_roles", false);
      }
      return can;
    });

    const visibleBallotTypes = computed<boolean>(() => {
      return (
        [
          "behave_as_ballot_monitor",
          "behave_as_voter",
          "behave_as_c_ballot_monitor",
          "behave_as_c_voter",
        ].indexOf(props.roleType.behaviour) > -1
      );
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: async (id) => {
        return await roleService.delete(props.userId, id);
      },
      successCallback(id) {
        if (id) {
          const list = _.cloneDeep(props.definedRoles);
          const index = _.findIndex(list, { id });
          if (index > -1) list.splice(index, 1);
          emit("update:defined-roles", list);
        }
      },
    });

    return {
      processing,
      transactionIds,
      triggerDelete,
      filterDefinedRoles,
      visibleBallotTypes,
      canEdit,
    };
  },
});
</script>
