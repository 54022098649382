
import { computed, defineComponent, onMounted } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { committeeMemberService, GetCommittee } from "@/services";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import _ from "lodash";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Library",
  components: { TransactionStatement },
  props: {
    committeeId: {
      default: () => null,
    },
    committee: {
      type: Object as () => GetCommittee,
      default: () => ({}),
    },
  },
  setup(props) {
    const router = useRouter();
    const { fetchRecords, deleteRecords } = useGeneralActions();
    const { fetch, loading, list } = fetchRecords({
      fetchApiMethod: async (query: Record<string, any>) => {
        return await committeeMemberService.fetch(props.committee.id, query);
      },
    });

    const {
      processing: deleteProcessing,
      transactionIds,
      triggerDelete,
    } = deleteRecords({
      deleteApiMethod: async (id) =>
        await committeeMemberService.delete(props.committeeId, id),
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    const isWg = computed(() => {
      return props.committee.type === "WG";
    });

    const participantMemberTitle = computed(() => {
      if (isWg.value) return "Working Group Members";
      return "Participant Members";
    });

    const participants = computed(() =>
      list.data.filter((item) => item.committee_member_type === "P")
    );

    const observers = computed(() =>
      list.data.filter((item) => item.committee_member_type === "O")
    );

    const goToView = (value: any) => {
      router.push({ name: "organizations.view", params: { id: value } });
    };

    onMounted(() => {
      fetch({}).finally();
    });

    return {
      isWg,
      loading,
      list,
      participants,
      observers,
      deleteProcessing,
      transactionIds,
      triggerDelete,
      participantMemberTitle,
      goToView,
    };
  },
});
