
import { defineComponent, reactive } from "vue";
import { ConfirmOptions } from "./index";
import { set, get, cloneDeep } from "lodash";

export default defineComponent({
  name: "Confirm",
  setup() {
    // region Reactive
    const defaultOptions = {
      title: "",
      text: "",
      icon: "",
      iconClass: "",
      confirmText: "Continue",
      confirmClass: "",
      cancelText: "Cancel",
      cancelClass: "",
      callback: () => null,
      cancelCallback: () => null,
    };

    const options = reactive(cloneDeep(defaultOptions)) as ConfirmOptions;
    // endregion Reactive

    // region Methods
    const show = (ctx: ConfirmOptions): void => {
      cash("#alert-modal").modal("show");
      Object.keys(defaultOptions).map((item) => {
        set(options, item, get(defaultOptions, item, null));
      });
      Object.keys(ctx).map((item) => {
        set(options, item, get(ctx, item, null));
      });
    };

    const hide = (): void => {
      cash("#alert-modal").modal("hide");
    };

    const onConfirm = (): void => {
      if (typeof options.callback === "function") options.callback()
      hide()
    }

    const onCancel = (): void => {
      if (typeof options.cancelCallback === "function") options.cancelCallback()
      hide()
    }
    //

    return {
      options,
      show,
      hide,
      onConfirm,
      onCancel
    };
  },
});
