
import { defineComponent, inject, onMounted, watch } from "vue";
import { useGeneralActions } from "@/utils/actions";
import Pagination from "@/components/pagination/Main.vue";

export default defineComponent({
  name: "DocumentLogs",
  components: { Pagination },
  props: {
    withoutRoute: {
      type: Boolean,
      default: () => false,
    },
    filterQuery: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { fetchRecords, filterRecords } = useGeneralActions();

    const { fetch, loading, list } = fetchRecords({
      selectionQuery: {
        source: "document_log",
        config: {
          "@page": 1,
          "@select": [
            "id",
            "type",
            "user_id",
            "uuid",
            "log",
            "ip",
            "created_at",
          ],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          user: {
            "@select": ["id", "title", "salutation", "name", "surname"],
          },
        },
        filterFormat: {
          uuid: "@where.uuid",
          user_id: "@where.user_id",
          type: "@where.type",
        },
      },
    });

    const {
      filter,
      formattedQuery,
      changePage,
      changePerPage,
      applyFilter,
      clearFilter,
    } = filterRecords(
      fetch,
      {
        page: 1,
        paginate: 15,
        ..._.cloneDeep(props.filterQuery),
      },
      props.withoutRoute
    );

    watch(
      () => props.filterQuery,
      (fields: Record<string, any>) => {
        _.forEach(fields, (val, key) => {
          _.set(filter.query, key, val);
        });
      },
      { flush: "post", deep: true }
    );

    const bindInstance = () => {
      const applyBind = inject(`bind[applyFunction]`);
      if (typeof applyBind === "function") applyBind(applyFilter);
      const clearBind = inject(`bind[clearFunction]`);
      if (typeof clearBind === "function") clearBind(clearFilter);
    };

    const onRefresh = () => {
      fetch(formattedQuery());
    };

    onMounted(() => {
      onRefresh();
      bindInstance();
    });

    return {
      loading,
      filter,
      list,
      changePage,
      changePerPage,
      onRefresh,
    };
  },
});
