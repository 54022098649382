import filterRecords from "./filter-records";
import fetchRecords from "./fetch-records";
import fetchRecord from "./fetch-record";
import createOrUpdate from "./create-or-update";
import deleteRecords from "./delete-records";

export function useGeneralActions() {
  return {
    filterRecords,
    fetchRecords,
    fetchRecord,
    createOrUpdate,
    deleteRecords,
  };
}
