<template>
  <div
    class="input border shadow-lg block focus:bg-white focus:ring-0 relative"
    :class="{ 'border-red-100 bg-red-50': !!validationErrors }"
  >
    <div v-if="!inputValue">
      <div>Please click following button in order to select a document from your computer.</div>
      <VueButton
        class="button bg-gradient-to-b from-theme-14 to-theme-15 text-white relative px-16 mt-4 whitespace-nowrap"
      >
        Browse
      </VueButton>
    </div>
    <div v-else class="flex">
      <div></div>
      <div class="flex-1">
        <div>{{ inputValue.name }}</div>
        <div>{{ inputValue.type }}</div>
        <div>{{ inputValue.size }}</div>
      </div>
    </div>
    <input
      type="file"
      class="absolute left-0 top-0 w-full h-full opacity-0 cursor-pointer"
      @change="onChangeFile"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "VueFileBrowser",
  emits: ["update:modelValue"],
  props: {
    modelValue: {},
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref(props.modelValue);

    watch(inputValue, (val) => {
      emit("update:modelValue", val);
    });

    watch(
      computed(() => props.modelValue),
      (val) => {
        if (val !== inputValue.value) inputValue.value = val;
      }
    );

    const onChangeFile = (e: any): void => {
      const files = e.target.files || [];
      inputValue.value = files[0] || null;
    };

    return {
      props,
      emit,
      inputValue,
      onChangeFile,
    };
  },
});
</script>
