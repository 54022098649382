// Create initial state

import { GetDocumentType } from "@/services";

export type State = {
  // folders: Record<string, any>[];
  documents: Record<string, any>[];
  aggregation: Record<string, any>[];
  types: GetDocumentType[];
};
export const state: State = {
  // folders: [],
  documents: [],
  aggregation: [],
  types: [],
};
