
import { defineComponent, onMounted, ref } from "vue";
import { DOCUMENT_TYPES, GetDocument } from "@/services";
import { StandardDocumentPDFCreation } from "@/utils/pdf-creation/standard-document";
import { useStore } from "@/store";

export default defineComponent({
  name: "PdfView",
  props: {
    document: {
      type: Object as () => GetDocument,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const path = ref<string>("");
    const loading = ref<boolean>(false);

    const prepareStandardDocument = () => {
      loading.value = true
      new StandardDocumentPDFCreation()
        .process({
          user: store.state.auth.profile,
          urls: [props.document.file_path],
        })
        .then((pdfCreation) => {
          pdfCreation.output().then((url) => {
            path.value = url;
          }).finally(() => {
            loading.value = false
          })
        });
    };

    onMounted(() => {
      if (props.document.type === DOCUMENT_TYPES.STANDARD_DOCUMENT) {
        prepareStandardDocument();
      } else {
        path.value = props.document.file_path;
      }
    });

    return {
      path,
      loading
    };
  },
});
