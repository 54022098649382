import { App } from "vue";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { get } from "lodash";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const idToText = require("./id-to-text.json");

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

const helpers = {
  cutText(text: string, length: number): string {
    if (text.split(" ").length > 1) {
      const string = text.substring(0, length);
      const splitText = string.split(" ");
      splitText.pop();
      return splitText.join(" ") + "...";
    } else {
      return text;
    }
  },
  formatDate(date: string | Date, format: string): string {
    if (!date) return "";
    if (typeof date !== "string") return dayjs(date).format(format);

    const parsedDate =
      typeof date === "string" && date.includes("T")
        ? dayjs(date)
        : dayjs.tz(date); // Consider timezone

    return parsedDate.tz("UTC").format(format);
  },
  capitalizeFirstLetter(string: string): string {
    if (string) {
      return helpers.upperCase(string.charAt(0)) + string.slice(1);
    } else {
      return "";
    }
  },
  capitalizeEachWords(string: string): string {
    if (string) {
      return string.replace(/(^|\s)\S/g, (l) => helpers.upperCase(l));
    } else {
      return "";
    }
  },
  convertFileSize(fileSizeInBytes: number): string {
    let i = -1;
    const byteUnits = [" KB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
    do {
      fileSizeInBytes /= 1024;
      i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
  },
  upperCase(string: string): string {
    if (string) {
      const letters = {
        i: "I",
        ş: "Ş",
        ğ: "Ğ",
        ü: "Ü",
        ö: "Ö",
        ç: "Ç",
        ı: "I",
      };
      const newString: string = string.replace(
        /(([iışğüçö]))+/g,
        function (letter: string): string {
          return get(letters, letter, "");
        }
      );
      return newString.toUpperCase();
    } else {
      return "";
    }
  },
  lowerCase(string: string): string {
    if (string) {
      const letters = {
        İ: "i",
        I: "ı",
        Ş: "ş",
        Ğ: "ğ",
        Ü: "ü",
        Ö: "ö",
        Ç: "ç",
      };
      const newString: string = string.replace(
        /(([İIŞĞÜÇÖ]))+/g,
        function (letter: string): string {
          return get(letters, letter, "");
        }
      );
      return newString.toLowerCase();
    } else {
      return "";
    }
  },
  onlyNumber(string: string): string {
    if (string) {
      return string.replace(/\D/g, "");
    } else {
      return "";
    }
  },
  formatCurrency(number: number): string {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, "");
      const rest = formattedNumber.length % 3;
      let currency = formattedNumber.substr(0, rest);
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g);
      let separator;

      if (thousand) {
        separator = rest ? "." : "";
        currency += separator + thousand.join(".");
      }

      return currency;
    } else {
      return "";
    }
  },
  timeAgo(time: string): string | false {
    const date = new Date(
      (time || "").replace(/-/g, "/").replace(/[TZ]/g, " ")
    );
    const diff = (new Date().getTime() - date.getTime()) / 1000;
    const dayDiff = Math.floor(diff / 86400);

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format("MMMM DD, YYYY");
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && "just now") ||
          (diff < 120 && "1 minute ago") ||
          (diff < 3600 && Math.floor(diff / 60) + " minutes ago") ||
          (diff < 7200 && "1 hour ago") ||
          (diff < 86400 && Math.floor(diff / 3600) + " hours ago"))) ||
      (dayDiff === 1 && "Yesterday") ||
      (dayDiff < 7 && dayDiff + " days ago") ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + " weeks ago")
    );
  },
  diffTimeByNow(
    time: string
  ): {
    days: string | number;
    hours: string | number;
    minutes: string | number;
    seconds: string | number;
  } {
    const startDate = dayjs(dayjs().format("YYYY-MM-DD HH:mm:ss").toString());
    const endDate = dayjs(dayjs(time).format("YYYY-MM-DD HH:mm:ss").toString());

    const duration = dayjs.duration(endDate.diff(startDate));
    const milliseconds = Math.floor(duration.asMilliseconds());

    const days = Math.round(milliseconds / 86400000);
    const hours = Math.round((milliseconds % 86400000) / 3600000);
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000);
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    );

    if (seconds < 30 && seconds >= 0) {
      minutes += 1;
    }

    return {
      days: days.toString().length < 2 ? "0" + days : days,
      hours: hours.toString().length < 2 ? "0" + hours : hours,
      minutes: minutes.toString().length < 2 ? "0" + minutes : minutes,
      seconds: seconds.toString().length < 2 ? "0" + seconds : seconds,
    };
  },
  isset(obj: Record<string, unknown> | number | string): boolean | number {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === "object" || Array.isArray(obj)) {
        return Object.keys(obj).length;
      } else {
        return obj.toString().length;
      }
    }

    return false;
  },
  toRaw(obj: Record<string, any>): any {
    return JSON.parse(JSON.stringify(obj));
  },
  randomNumbers(from: number, to: number, length: number): Array<number> {
    const numbers = [0];
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to));
    }

    return numbers;
  },
  convertIdToText(id: any, type: string): any {
    if (id === null || id === undefined) return null;
    return _.get(idToText, [type, id.toString()], id);
  },
  getIdToText(type: string) {
    return _.get(idToText, [type], {});
  },
  uuidv4() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  base64Encode(str: string) {
    // eslint-disable-next-line node/no-deprecated-api
    return new Buffer(str, "utf-8").toString("base64");
  },
  base64Decode(str: string) {
    // eslint-disable-next-line node/no-deprecated-api
    return new Buffer(str, "base64").toString("utf-8");
  },
  formatNameSurname(data: Record<string, any>): string {
    const arr: any[] = [];

    const title = _.get(data, "title", "");
    const salutation = _.get(data, "salutation", "");
    const name = _.get(data, "name", "");
    const surname = _.get(data, "surname", "");

    if (title && _.isString(title) && title.length < 10) arr.push(title);
    else if (salutation) arr.push(salutation);

    if (name) arr.push(name);
    if (surname) arr.push(surname);

    return arr.join(" ");
  },
  snakeCaseToCamelCase(str: string): string {
    return helpers.capitalizeEachWords(
      helpers.lowerCase(str).replace(/_/g, " ")
    );
  },
};

const install = (app: App): void => {
  app.config.globalProperties.$h = helpers;
};

export { install as default, helpers as helper };
