import { computed, ref, Ref } from "vue";
import { fetchSelection, GetCommittee } from "@/services";
import { ToastNotify } from "@/utils/toast-notify";
import { useRoute } from "vue-router";

interface SelectionQuery {
  source: string;
  config: Record<string, any>;
}

export interface GeneralFetchRecordProps {
  fetchApiMethod?(query: Record<string, any>): Promise<any>;
  successCallback?(): void;
  errorCallback?(): void;
  selectionQuery?: SelectionQuery;
}

export interface GeneralFetchRecord {
  loading: Ref<boolean>;
  data: Ref<Record<string, any>>;
  fetch(id: any): Promise<void>;
}

export default function fetchRecord(
  props: GeneralFetchRecordProps
): GeneralFetchRecord {
  const route = useRoute();
  const id = computed(() => route.params.id);
  const loading = ref<boolean>(!!id.value);
  const data = ref<Record<string, any>>({});

  const fetch = async (id: any) => {
    if (id) {
      if (!props.fetchApiMethod) {
        fetchThroughSelection(id).finally();
      } else {
        loading.value = true;
        const result = await props.fetchApiMethod(id);
        if (result.kind !== "ok") {
          ToastNotify({ text: result.message, className: "error" });
          if (props.errorCallback) props.errorCallback();
        } else {
          data.value = result.data;
          if (props.successCallback) props.successCallback();
          loading.value = false;
        }
      }
    }
  };

  const fetchThroughSelection = async (id: any) => {
    if (props.selectionQuery) {
      const config = _.cloneDeep(props.selectionQuery.config);
      _.unset(config, "@paginate");
      _.unset(config, "@page");
      _.set(config, "@where.id", id);
      if (_.has(config, "@get")) {
        _.unset(config, "@first");
      } else {
        _.set(config, "@first", true);
      }
      const params = {
        [props.selectionQuery.source]: config,
      };
      loading.value = true;
      const result = await fetchSelection(params);
      if (result.kind !== "ok") {
        ToastNotify({ text: result.message, className: "error" });
        if (props.errorCallback) props.errorCallback();
      } else {
        data.value = result.data[props.selectionQuery.source];
        if (props.successCallback) props.successCallback();
        loading.value = false;
      }
    }
  };

  return {
    loading,
    data,
    fetch,
  };
}
