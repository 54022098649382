<template>
  <div id="alert-modal" class="modal">
    <div class="modal__content modal__content--lg">
      <div class="py-6 px-10 sm:px-20 text-center">
        <component
          :is="options.icon"
          v-if="options.icon"
          class="w-14 h-14 text-gray-600 mx-auto mt-3"
          :class="options.iconClass"
        />
        <div class="text-3xl text-theme-3 mt-5" v-if="options.title">
          {{ options.title }}
        </div>
        <div class="text-gray-500 font-light mt-2" v-if="options.text">
          {{ options.text }}
        </div>
      </div>
      <div class="px-5 pb-8 text-center">
        <button
          class="button rounded-full bg-gradient-to-l from-theme-12 to-theme-13 text-white font-light px-8 shadow-lg mr-2"
          :class="options.confirmClass"
          @click="onConfirm"
        >
          {{ options.confirmText }}
        </button>
        <button
          class="button rounded-full bg-gradient-to-l from-red-400 to-red-500 text-white font-light px-8 shadow-lg"
          :class="options.cancelClass"
          @click="onCancel"
        >
          {{ options.cancelText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { ConfirmOptions } from "./index";
import { set, get, cloneDeep } from "lodash";

export default defineComponent({
  name: "Confirm",
  setup() {
    // region Reactive
    const defaultOptions = {
      title: "",
      text: "",
      icon: "",
      iconClass: "",
      confirmText: "Continue",
      confirmClass: "",
      cancelText: "Cancel",
      cancelClass: "",
      callback: () => null,
      cancelCallback: () => null,
    };

    const options = reactive(cloneDeep(defaultOptions)) as ConfirmOptions;
    // endregion Reactive

    // region Methods
    const show = (ctx: ConfirmOptions): void => {
      cash("#alert-modal").modal("show");
      Object.keys(defaultOptions).map((item) => {
        set(options, item, get(defaultOptions, item, null));
      });
      Object.keys(ctx).map((item) => {
        set(options, item, get(ctx, item, null));
      });
    };

    const hide = (): void => {
      cash("#alert-modal").modal("hide");
    };

    const onConfirm = (): void => {
      if (typeof options.callback === "function") options.callback()
      hide()
    }

    const onCancel = (): void => {
      if (typeof options.cancelCallback === "function") options.cancelCallback()
      hide()
    }
    //

    return {
      options,
      show,
      hide,
      onConfirm,
      onCancel
    };
  },
});
</script>
