import {
  useAxios,
  GeneralApiResponse,
  GetCommitteeResult,
  GetCommitteeResults,
  GetCommitteeCreationResults,
  GetCommitteeUserResults,
} from "@/services";

export * from "./members";
export * from "./liaisons";
export * from "./api.types";

export const committeeService = {
  fetch: async (): Promise<GetCommitteeResults> =>
    useAxios().fetch(`/api/committees`),

  show: async (id: unknown): Promise<GetCommitteeResult> =>
    useAxios().get(`/api/committees/${id}`),

  store: async (params: Record<string, any>): Promise<GetCommitteeResult> =>
    useAxios().store(`/api/committees`, params),

  update: async (
    id: unknown,
    params: Record<string, any>
  ): Promise<GetCommitteeResult> =>
    useAxios().update(`/api/committees/${id}`, params),

  delete: async (id: unknown): Promise<GeneralApiResponse> =>
    useAxios().delete(`/api/committees/${id}`),

  creation: async (
    params: Record<string, any>
  ): Promise<GetCommitteeCreationResults> =>
    useAxios().store(`/api/committees/creation`, params),

  fetchCommitteeUsers: async (
    committeeId: unknown,
    params?: Record<string, any>
  ): Promise<GetCommitteeUserResults> =>
    useAxios().fetch(`/api/committees/${committeeId}/users`, params),

  fetchCommitteeUsersFullList: async (
    committeeId: unknown,
    params?: Record<string, any>
  ): Promise<GetCommitteeUserResults> =>
    useAxios().fetch(`/api/committees/${committeeId}/users-full-list`, params),

  nextCommitteeNumber: async (
    params: Record<string, any>
  ): Promise<{ number: any }> => {
    const result = await useAxios()
      .instance.post(`/api/committees/next-committee-number`, params)
      .catch((error) => error);
    return {
      number: _.get(result.data, "number", ""),
    };
  },
};
