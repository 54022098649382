<template>
  <div class="w-full flex">
    <input
      v-picker="{ props, emit }"
      type="text"
      :value="modelValue"
      class="input w-full border shadow-lg block focus:bg-white focus:ring-0"
      :class="{
        'border-red-100 bg-red-50': !!validationErrors,
        '!rounded-r-none': !!modelValue,
      }"
    />
    <button
      v-if="!!modelValue"
      class="button bg-gray-300 rounded-l-none"
      @click="onClear"
    >
      <XIcon class="w-5 h-5" />
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { init, reInit } from "./index";

export default defineComponent({
  name: "Litepicker",
  directives: {
    picker: {
      mounted(el, { value }) {
        init(el, value.props, value.emit);
      },
      updated(el, { oldValue, value }) {
        if (oldValue.props.modelValue !== value.props.modelValue) {
          reInit(el, value.props, value.emit);
        }
      },
    },
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      type: String,
      default: "",
    },
    validationErrors: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, context) {
    const onClear = () => {
      context.emit("update:modelValue", "");
    };

    return {
      props,
      ...context,
      onClear,
    };
  },
});
</script>
