<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEye
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else-if="project.id">
        <!-- BEGIN: Top Buttons -->
        <ProjectActionButton
          :project-id="id"
          :project="project"
          :on-fetch="onFetch"
          :disabled="disabledActionButtons"
        />
        <!-- END: Top Buttons -->
        <div class="accordion divide-y">
          <!-- BEGIN Information -->
          <ProjectInformation
            :project-id="id"
            v-model:project="project"
            v-model:active-tab="activeTab"
            @update:active-editable="
              (val) => (isActiveEditableInformation = val)
            "
          />
          <!-- END Information -->

          <!-- BEGIN Ballot -->
          <ProjectBallots
            :project-id="id"
            v-model:project="project"
            v-model:active-tab="activeTab"
          />
          <!-- END Ballot -->

          <!-- BEGIN Timeline & History -->
          <ProjectTimeline
            :project-id="id"
            v-model:project="project"
            v-model:active-tab="activeTab"
          />
          <!-- END Timeline & History -->

          <!-- BEGIN Documents -->
          <ProjectDocuments
            :project-id="id"
            v-model:project="project"
            v-model:active-tab="activeTab"
          />
          <!-- END Documents -->

          <!-- BEGIN Submissions -->
          <ProductSubmissions
            :project-id="id"
            v-model:project="project"
            v-model:active-tab="activeTab"
            v-if="$_.get(project, 'abilities.can_update')"
          />
          <!-- END Submissions -->
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, Ref, watch } from "vue";
import { useGeneralActions } from "@/utils/actions";
import { GetProject, projectService } from "@/services";
import { useRoute, useRouter } from "vue-router";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import ProjectInformation from "./information/Main.vue";
import ProjectTimeline from "./timeline/Main.vue";
import ProjectDocuments from "./documents/Main.vue";
import ProductSubmissions from "@/views/projects/view/submissions/Main.vue";
import ProjectActionButton from "@/views/projects/view/ActionButton.vue";
import ProjectBallots from "@/views/projects/view/Ballots.vue";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetProject>;
}

export default defineComponent({
  name: "ViewProject",
  components: {
    ProjectBallots,
    ProjectActionButton,
    ProductSubmissions,
    ProjectTimeline,
    ProjectInformation,
    ProjectDocuments,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const activeTab = ref<string>("info");
    const isActiveEditableInformation = ref<boolean>(false);
    const { fetchRecord } = useGeneralActions();

    const { loading, data, fetch } = fetchRecord({
      fetchApiMethod: projectService.show,
      errorCallback() {
        router.go(-1);
      },
    }) as FetchRecord;

    const id = computed(() => route.params.id);
    const headerTitle = computed<string>(() => {
      if (loading.value || !data.value) return `VIEW PROJECT (#${id.value})`;
      return `${data.value.reference}`;
    });
    const disabledActionButtons = computed<boolean>(
      () => isActiveEditableInformation.value
    );

    watch(id, () => onFetch(), { flush: "post" });
    onMounted(() => {
      onFetch();
    });

    const onFetch = () => fetch(id.value).finally();

    return {
      id,
      loading,
      headerTitle,
      project: data,
      activeTab,
      disabledActionButtons,
      isActiveEditableInformation,
      onFetch,
    };
  },
});
</script>
