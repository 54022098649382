
import { defineComponent, inject, onMounted, Ref } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { taskService, GetTask } from "@/services";
import { completeTask, getTaskableTitle } from "@/views/task-manager";
import { useRouter } from "vue-router";
import { ConfirmRef } from "@/components/confirm";
import { SendEmailRef } from "@/components/email-to-members";

export default defineComponent({
  name: "TodoList",
  components: {
    TransactionStatement,
    Pagination,
  },
  setup() {
    const router = useRouter();
    const confirmRef = inject<Ref<ConfirmRef>>(`confirmRef`);
    const sendEmailRef = inject<Ref<SendEmailRef>>(`sendEmailRef`);
    const { fetchRecords, filterRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      fetchApiMethod: taskService.fetch,
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    const onCompleteTask = (task: GetTask) => {
      completeTask(task, router, confirmRef, sendEmailRef);
    };

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
      getTaskableTitle,
      onCompleteTask,
    };
  },
});
