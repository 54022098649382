
import { defineComponent, onMounted } from "vue";
import Pagination from "@/components/pagination/Main.vue";
import { useGeneralActions } from "@/utils/actions";
import { feedbackService } from "@/services";

export default defineComponent({
  name: "Submissions",
  components: {
    Pagination,
  },
  setup() {
    const { fetchRecords, filterRecords, deleteRecords } = useGeneralActions();

    const { fetch, loading, list, selectAll, selectedRows } = fetchRecords({
      selectionQuery: {
        source: "feedback",
        config: {
          "@page": 1,
          "@select": ["id", "text", "status", "user_id", "files", "created_at"],
          "@where": {},
          "@func": ["withCount"],
          "@order": "created_at:desc",
          user: {
            "@select": ["id", "title", "salutation", "name", "surname"],
          },
        },
      },
    });

    const {
      filter,
      applyFilter,
      clearFilter,
      formattedQuery,
      changePage,
      changePerPage,
    } = filterRecords(fetch, {
      page: 1,
      paginate: 15,
    });

    const { processing, transactionIds, triggerDelete } = deleteRecords({
      deleteApiMethod: feedbackService.delete,
      successCallback(id) {
        if (id) {
          const index = _.findIndex(list.data, { id });
          if (index > -1) list.data.splice(index, 1);
        }
      },
    });

    onMounted(() => {
      fetch(formattedQuery());
    });

    return {
      loading,
      list,
      selectAll,
      selectedRows,
      filter,
      processing,
      transactionIds,
      triggerDelete,
      applyFilter,
      clearFilter,
      changePage,
      changePerPage,
    };
  },
});
