
import { Question } from "@/services";
import { defineComponent, reactive, toRefs } from "vue";
import { helper } from "@/utils/helper";

export default defineComponent({
  name: "EditQuestionItem",
  props: {
    question: {
      type: Object as () => Question,
      default: () => ({}),
    },
    questionIndex: {
      type: Number,
      default: () => -1,
    },
    deleteQuestion: {
      type: Function,
      default: () => {
        console.log("deleteQuestion");
      },
    },
    canAddSubQuestion: {
      type: Boolean,
      default: () => true,
    },
  },
  setup(props) {
    const editableQuestion = reactive(toRefs<Question>(props.question));
    const state = reactive({
      show: false,
    });

    const addAnswer = () => {
      editableQuestion.answers.push({
        uuid: helper.uuidv4(),
        answer: "",
        comment_name: "Comment",
        comment_required: 3,
        reporting_type: null,
        questions: [],
      });
    };

    const deleteAnswer = (index: number) => {
      editableQuestion.answers.splice(index, 1);
    };

    const addSubQuestion = (index: number) => {
      const questions = editableQuestion.answers[index].questions || [];
      questions.push({
        uuid: helper.uuidv4(),
        question: "",
        answers: [
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
          {
            uuid: helper.uuidv4(),
            answer: "",
            comment_name: "Comment",
            comment_required: 3,
            reporting_type: null,
            questions: [],
          },
        ],
      });
    };

    const deleteSubQuestion = (index: number) => {
      const questions = editableQuestion.answers[index].questions || [];
      questions.splice(index, 1);
    };

    return {
      state,
      editableQuestion,
      addAnswer,
      deleteAnswer,
      addSubQuestion,
      deleteSubQuestion,
    };
  },
});
