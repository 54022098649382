// Create initial state
import { GetStageType } from "@/services";

export type State = {
  initialTypes: GetStageType[];
  types: GetStageType[];
  manualStages: any[];
  ballotStages: any[];
};
export const state: State = {
  initialTypes: [],
  types: [],
  manualStages: [],
  ballotStages: [],
};
