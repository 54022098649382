
import { computed, defineComponent, ref } from "vue";
import {
  GetRole,
  GetRoleResults,
  GetRoleType,
  PostRole,
  roleService,
} from "@/services";
import { useGeneralActions } from "@/utils/actions";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import VueInputGroup from "@/global-components/form-elements/input-group/Main.vue";
import VueInput from "@/global-components/form-elements/input/Main.vue";

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostRole;
}

export default defineComponent({
  name: "UserRoleCreateOrUpdate",
  components: { VueInput, VueInputGroup },
  props: {
    definedRoles: {
      type: Array as () => GetRole[],
      default: () => [],
    },
    userId: {
      type: Number,
      default: () => 0,
    },
    administratedById: {
      type: Number,
      default: () => 0,
    },
  },
  setup(props, { emit }) {
    const { createOrUpdate } = useGeneralActions();
    const roleId = ref<number>(0);
    const roleType = ref<Record<string, any>>({});

    const {
      processing,
      formData,
      validationErrors,
      submit,
      setFormData,
    } = createOrUpdate({
      createApiMethod(params: Record<string, any>): Promise<any> {
        return roleService.store(props.userId, params);
      },
      updateApiMethod(id: any, params: Record<string, any>): Promise<any> {
        return roleService.update(props.userId, id, params);
      },
      successCallback(result: GetRoleResults) {
        if (result.kind === "ok") {
          emit("update:defined-roles", result.data);
          cash("#role-modal").modal("hide");
        }
      },
      params: {
        behaviour: "",
        scope_type: "",
        scope_id: null,
        function: "",
        information: "",
        ballot_types: [],
      } as PostRole,
    }) as CreateOrUpdate;

    const visibleFunctionInput = computed<boolean>(() => {
      return (
        [
          "behave_as_employer",
          "behave_as_user_admin",
          "behave_as_chairman",
        ].indexOf(formData.behaviour) > -1
      );
    });

    const visibleVMBallotTypes = computed<boolean>(() => {
      return (
        ["behave_as_c_ballot_monitor", "behave_as_c_voter"].indexOf(
          formData.behaviour
        ) > -1
      );
    });

    const visibleBallotTypes = computed<boolean>(() => {
      return (
        [
          "behave_as_ballot_monitor",
          "behave_as_voter",
          "behave_as_c_ballot_monitor",
          "behave_as_c_voter",
        ].indexOf(formData.behaviour) > -1
      );
    });

    const resourceTitle = computed<string>(() => {
      switch (formData.scope_type) {
        case "project":
          return "Project";
        case "committee":
          return "Committee";
        default:
          return "";
      }
    });

    const committeeTemplateSelection = (obj: any): string => {
      if (obj.acronym) return `(${obj.acronym}) ${obj.text}`;
      return obj.text;
    };

    const resourceSelect2Options = computed(() => {
      if (formData.scope_type === "committee") {
        const where = {};
        if (
          [
            "behave_as_secretary",
            "behave_as_sst",
          ].indexOf(formData.behaviour) > -1 &&
          props.administratedById
        ) {
          _.set(where, "secretariat_member_id", props.administratedById);
        }
        if (
          [
            "behave_as_convenor",
            "behave_as_wgmember",
          ].indexOf(formData.behaviour) > -1
        ) {
          _.set(where, "type", "WG");
        }
        return {
          ajaxQuery: {
            committee: {
              "@pagination": 15,
              "@select": {
                value: "id",
                text: "title",
                acronym: "acronym",
              },
              "@order": [
                "level_1_type:asc",
                "level_1_number:asc",
                "level_2_type:asc",
                "level_2_number:asc",
                "level_3_number:asc",
              ],
              "@where": where,
            },
          },
          templateSelection: committeeTemplateSelection,
        };
      }
      return {
        ajaxQuery: {
          project: {
            "@pagination": 15,
            "@select": {
              value: "id",
              text: "reference",
            },
            "@order": "created_at:desc",
          },
        },
      };
    });

    const onOpen = (type: GetRoleType, role?: GetRole) => {
      roleType.value = type;
      roleId.value = _.get(role, "id", 0) * 1;
      setFormData({
        behaviour: _.get(role, "behaviour", type.behaviour),
        scope_type: _.get(role, "scope_type", type.scope),
        scope_id: _.get(role, "scope_id", null),
        function: _.get(role, "meta.function", ""),
        information: _.get(role, "meta.information", ""),
        ballot_types: _.get(role, "ballot_types", []),
      });
      cash("#role-modal").modal("show");
    };

    const onSubmit = () => submit(roleId.value || null);

    return {
      formData,
      validationErrors,
      processing,
      roleType,
      visibleFunctionInput,
      visibleVMBallotTypes,
      visibleBallotTypes,
      resourceTitle,
      resourceSelect2Options,
      onOpen,
      onSubmit,
    };
  },
});
