import { ActionTree, ActionContext } from "vuex";
import { State as RootState } from "@/store";
import { State as LocalState } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { documentService, fetchSelection, GetDocumentType } from "@/services";
import { useGeneralActions } from "@/utils/actions";

// Actions context
type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<LocalState, RootState>, "commit">;

// Actions contracts
export interface Actions {
  [ActionTypes.SET_DOCUMENT_TYPES](
    { commit }: AugmentedActionContext,
    types: GetDocumentType[]
  ): void;
  [ActionTypes.FETCH_DOCUMENTS](
    { commit, dispatch }: AugmentedActionContext,
    payload: Record<string, any>
  ): void;
  [ActionTypes.FETCH_DOCUMENT_TYPES]({ commit }: AugmentedActionContext): void;
  [ActionTypes.SET_AGGREGATION](
    { commit }: AugmentedActionContext,
    aggregation: Record<string, any>[]
  ): void;
  [ActionTypes.SET_DOCUMENTS](
    { commit, state }: AugmentedActionContext,
    payload: {
      documents: Record<string, any>[];
      offset: number;
    }
  ): void;
  [ActionTypes.SET_FORCE_DOCUMENTS](
    { commit, state }: AugmentedActionContext,
    payload: Record<string, any>[]
  ): void;
  // [ActionTypes.FETCH_ALL_FOLDERS]({ dispatch }: AugmentedActionContext): void;
  // [ActionTypes.SET_FOLDERS](
  //   { commit, state }: AugmentedActionContext,
  //   payloads: any[]
  // ): void;
}

// Define actions
export const actions: ActionTree<LocalState, RootState> & Actions = {
  [ActionTypes.SET_DOCUMENT_TYPES]({ commit }, types: GetDocumentType[]) {
    commit(MutationTypes.SET_DOCUMENT_TYPES, types);
  },
  [ActionTypes.FETCH_DOCUMENTS]({ dispatch }, payload) {
    const { config = {}, formattedQuery = {}, ...other } = payload;
    const configFunc = _.get(config, "@func", []);
    const withAggregation: boolean = configFunc.indexOf("aggregation") > -1;
    const { fetchRecords } = useGeneralActions();
    const { list, fetch } = fetchRecords({
      selectionQuery: {
        source: "document",
        config: {
          "@offset": 0,
          "@paginate": 20,
          "@select": [
            "id",
            "n_number",
            "version",
            "uuid",
            "title",
            "reference",
            "committee_id",
            "folder_id",
            "type",
            "status",
            "expected_action",
            "created_at",
            "updated_at",
            "file_extension",
            "language",
            "replaced_by_id",
          ],
          "@order": "id:desc",
          replaced_by: {
            "@select": ["id", "n_number", "uuid", "type"],
          },
          ...config,
        },
        ...other,
      },
    });
    fetch(formattedQuery).then(() => {
      if (withAggregation) {
        const aggregation = _.cloneDeep(_.get(list.meta, "aggregation", []));
        dispatch(ActionTypes.SET_AGGREGATION, aggregation);
      }
      dispatch(ActionTypes.SET_DOCUMENTS, {
        documents: _.cloneDeep(list.data),
        offset: _.get(formattedQuery, "offset", 0),
      });
    });
  },
  [ActionTypes.FETCH_DOCUMENT_TYPES]({ commit }) {
    documentService.fetchDocumentTypes().then((result) => {
      if (result.kind === "ok") {
        commit(MutationTypes.SET_DOCUMENT_TYPES, result.data);
      }
    });
  },
  // [ActionTypes.FETCH_ALL_FOLDERS]({ dispatch }) {
  //   fetchSelection({
  //     folder: {
  //       "@get": true,
  //       "@select": ["id", "parent_id", "name", "type", "committee_id"],
  //       "@order": "name:asc",
  //     },
  //   }).then((result) => {
  //     if (result.kind === "ok") {
  //       dispatch(
  //         MutationTypes.SET_FOLDERS,
  //         _.get(result.data, "folder.data", [])
  //       );
  //     }
  //   });
  // },
  [ActionTypes.SET_AGGREGATION](
    { commit },
    aggregation: Record<string, any>[]
  ) {
    commit(MutationTypes.SET_AGGREGATION, aggregation);
    let total = 0;
    aggregation.map((item) => {
      if (item.type === "total") total = item.count;
    });

    const documents: Record<string, any>[] = [];
    for (let i = 0; i < total; i++) documents.push({});
    commit(MutationTypes.SET_DOCUMENTS, documents);
  },
  [ActionTypes.SET_DOCUMENTS]({ commit, state }, payload) {
    const allDocuments: Record<string, any>[] = _.cloneDeep(state.documents);
    let localOffset: number = _.cloneDeep(payload.offset || 0);
    payload.documents.map((item) => {
      allDocuments[localOffset++] = item;
    });
    commit(MutationTypes.SET_DOCUMENTS, allDocuments);
  },
  [ActionTypes.SET_FORCE_DOCUMENTS]({ commit }, payload) {
    commit(MutationTypes.SET_DOCUMENTS, payload);
  },
  // [ActionTypes.SET_FOLDERS]({ commit }, payloads) {
  //   commit(MutationTypes.SET_FOLDERS, payloads);
  // },
};
