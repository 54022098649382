
import { defineComponent, onMounted, Ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";
import { OrganizationUsersPDFCreation } from "@/utils/pdf-creation/organization-users";
import { GetOrganization } from "@/services";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<{ data: Record<string, any>[] }>;
}

export default defineComponent({
  name: "OrganizationUsers",
  props: {
    organization: {
      type: Object as () => GetOrganization,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord } = useGeneralActions();

    const { fetch, loading, data: list } = fetchRecord({
      selectionQuery: {
        source: "user",
        config: {
          "@get": true,
          "@select": ["id", "salutation", "name", "surname", "email"],
          "@where": {
            administrated_by_id: props.organization.id,
          },
          "@order": "created_at:desc",
          "@func": ["withRoles"],
        },
      },
    }) as FetchRecord;

    const goToView = (value: any) => {
      router.push({ name: "users.view", params: { id: value } });
    };

    const exportFile = () => {
      console.log("exportFile");

      new OrganizationUsersPDFCreation()
        .process({
          organization: props.organization,
          users: _.get(list.value, "data", []),
        })
        .then((pdfCreation) => {
          pdfCreation.output().then((url) => {
            router.push({
              query: { ...route.query, iUrl: url },
            });
          });
        });
    };

    onMounted(() => {
      fetch({});
    });

    return {
      loading,
      list,
      goToView,
      exportFile,
    };
  },
});
