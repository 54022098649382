<template>
  <div>
    <div
      class="relative bg-gradient-to-r from-theme-5 to-theme-4 py-10 px-6 md:px-14 rounded-t-2xl md:rounded-tr-none"
    >
      <SvgIconEdit
        class="w-16 h-16 absolute top-0 transform -translate-y-8 text-white"
      />
      <div class="text-white text-xl">{{ headerTitle }}</div>
      <div class="text-white text-sm font-light">
        All mandatory fields are indicated with red asterisks.
      </div>
    </div>
    <div class="p-3 sm:px-5 sm:py-5">
      <!-- BEGIN: Top Buttons -->
      <div class="flex justify-end transform -translate-y-9 sm:-translate-y-11">
        <VueButton
          class="button bg-gradient-to-b from-theme-11 to-theme-13 text-white relative pr-16 whitespace-nowrap"
          @click="onSubmit"
          :loading="processing"
        >
          Save {{ committeeOrWorkingGroup }}
          <div
            class="h-full absolute right-0 top-0 pointer-events-none bg-gradient-to-b from-theme-14 to-theme-15 text-white rounded-md py-2.5 px-3"
          >
            <SaveIcon class="inline w-6 h-6" />
          </div>
        </VueButton>
      </div>
      <!-- END: Top Buttons -->
      <div v-if="loading" class="text-center p-4 pt-8">
        <LoaderIcon class="spin-5 inline-block w-8 h-8" />
        <div class="mt-2 font-light">
          Please wait while the records are loading.
        </div>
      </div>
      <template v-else>
        <div class="accordion divide-y">
          <div class="accordion__pane active pb-6">
            <!-- BEGIN ABOUT -->
            <a
              href="javascript:;"
              class="accordion__pane__toggle font-medium text-2xl text-gray-600 block"
              :class="{
                'text-red-500': validationErrors.get(
                  'title',
                  'acronym',
                  'scope',
                  'level',
                  'level_1_code',
                  'level_1_number',
                  'secretariat_member_id',
                  'assistant_user_id'
                ),
              }"
            >Identity</a
            >
            <div class="accordion__pane__content mt-3 pb-6 leading-relaxed">
              <CommitteeIdentity
                :committee-id="id"
                :form-data="formData"
                :validation-errors="validationErrors"
                :committee-type="committeeType"
                :is-wg="isWg"
              />
            </div>
          </div>
          <template v-if="id">
            <CommitteeMemberContainer
              :committee-id="id"
              :committee-type="formData.type"
            />
            <template v-if="!isWg">
              <CommitteeLiaisons
                :committee-id="id"
                :level-tc-number="formData.level_1_number"
              />
              <OrganizationLiaisons :committee-id="id" />
            </template>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useGeneralActions } from "@/utils/actions";
import {
  committeeService,
  GetCommittee,
  GetCommitteeResult,
  PostCommittee
} from "@/services";
import CommitteeIdentity from "@/views/committees/create/Identity.vue";
import CommitteeLiaisons from "@/views/committees/create/CommitteeLiaisons.vue";
import OrganizationLiaisons from "@/views/committees/create/OrganizationLiaisons.vue";
import CommitteeMemberContainer from "@/views/committees/create/MemberContainer.vue";
import { GeneralCreateOrUpdate } from "@/utils/actions/create-or-update";
import { GeneralFetchRecord } from "@/utils/actions/fetch-record";

interface FetchRecord extends GeneralFetchRecord {
  data: Ref<GetCommittee>;
}

interface CreateOrUpdate extends GeneralCreateOrUpdate {
  formData: PostCommittee;
}

export default defineComponent({
  name: "CreateCommittee",
  components: {
    CommitteeMemberContainer,
    OrganizationLiaisons,
    CommitteeLiaisons,
    CommitteeIdentity
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    const { fetchRecord, createOrUpdate } = useGeneralActions();
    const { loading, data: committee, fetch } = fetchRecord({
      fetchApiMethod: committeeService.show,
      errorCallback() {
        router.go(-1);
      },
      successCallback() {
        setFormData({
          ...committee.value,
          level: committee.value.level.level,
          level_1_type: committee.value.level.level_1.type,
          level_2_type: committee.value.level.level_2.type,
          level_3_type: committee.value.level.level_3.type,
          level_1_number: committee.value.level.level_1.number,
          level_2_number: committee.value.level.level_2.number,
          level_3_number: committee.value.level.level_3.number
        });
      }
    }) as FetchRecord;

    const {
      processing,
      formData,
      setFormData,
      validationErrors,
      submit
    } = createOrUpdate({
      createApiMethod: committeeService.store,
      updateApiMethod: committeeService.update,
      successCallback(result: GetCommitteeResult) {
        if (result.kind === "ok") {
          if (!id.value) {
            router.replace({
              name: "committees.edit",
              params: {
                id: result.data.id
              }
            });
          } else {
            router.go(-1);
          }
        }
      },
      params: {
        title: "",
        acronym: "",
        scope: "",
        creation_date: "",
        type: "",
        level: 1,
        level_1_type: _.get(route.params, "type", "TC"),
        level_1_number: null,
        level_2_type: "",
        level_2_number: null,
        level_3_type: "",
        level_3_number: null,
        assistant_user_id: "",
        chairperson_user_id: "",
        secretary_user_id: "",
        secretariat_member_id: "",
        chairperson_info: "",
        chairperson_user_term: ""
      }
    }) as CreateOrUpdate;

    const id = computed(() => route.params.id);

    const committeeType = computed(() => {
      if (formData.level_3_type) return formData.level_3_type;
      if (formData.level_2_type) return formData.level_2_type;
      return formData.level_1_type;
    });

    const isWg = computed(() => {
      return committeeType.value === "WG";
    });

    const committeeOrWorkingGroup = computed(() => {
      return isWg.value ? "Working Group" : "Committee";
    });

    const headerTitle = computed<string>(() => {
      if (id.value) return `Edit ${committeeOrWorkingGroup.value} ${formData.acronym || id.value}`;
      return "CREATE";
    });

    watch(id, (val) => fetch(val).finally(), { flush: "post" });
    onMounted(() => {
      if (id.value) {
        fetch(id.value).finally();
      }
    });

    const onSubmit = () => submit(id.value);

    return {
      id,
      committee,
      committeeType,
      isWg,
      committeeOrWorkingGroup,
      headerTitle,
      loading,
      processing,
      formData,
      validationErrors,
      onSubmit
    };
  }
});
</script>
