import { reactive } from "vue";
import Cleave from "cleave.js";

interface Props {
  cleaveMask: any;
  modelValue: string;
}

let cleaveInstance = reactive({}) as Cleave;

const init = (
  el: HTMLInputElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  if (props.cleaveMask) {
    const options = {
      ...props.cleaveMask,
      initValue: props.modelValue,
      onValueChanged: (e: any) => {
        const event = new Event("input", { bubbles: true });
        el.value = e.target.value;
        el.dispatchEvent(event);
      },
    };
    cleaveInstance = new Cleave(el, options);
  }
};

const reInit = (
  el: HTMLInputElement,
  props: Props,
  emit: (event: string, ...args: unknown[]) => void
): void => {
  if (cleaveInstance instanceof Cleave) {
    cleaveInstance.destroy();
    init(el, props, emit);
  }
};

export { init, reInit };
