
import TransactionStatement from "@/components/transaction-statement/Main.vue";
import { computed, defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
import { launchActionForProject } from "@/views/projects/actions";
import { useStore } from "@/store";
export default defineComponent({
  name: "ProjectListItem",
  components: { TransactionStatement },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    transactionIds: {
      type: Array,
      default: () => [],
    },
    processing: {
      type: Boolean,
      default: () => false,
    },
    triggerDelete: {
      type: Function,
      default: () => null,
    },
  },
  setup(props) {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const goToView = (value: any) => {
      router.push({ name: "projects.view", params: { id: value } });
    };

    const visibleDecision = computed<boolean>(() => {
      return store.state.project.manualStages.indexOf(props.item.stage) > -1;
    });

    const visibleLaunchNewBallot = computed<boolean>(() => {
      return (
        store.getters["auth/canCreateCommitteeBallot"](
          _.get(props.item, "committee_id", 0)
        ) && store.state.project.ballotStages.indexOf(props.item.stage) > -1
      );
    });

    const onLaunchAction = () =>
      launchActionForProject({
        projectId: _.get(props.item, "id", 0) * 1,
        committeeId: _.get(props.item, "committee_id", 0) * 1,
        stage: _.get(props.item, "stage", ""),
        route,
        router,
        store,
      });

    return {
      visibleDecision,
      visibleLaunchNewBallot,
      goToView,
      onLaunchAction,
    };
  },
});
