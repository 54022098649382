<template>
  <svg
    width="58"
    height="44"
    viewBox="0 0 58 44"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.125 6.5625H55.125C55.7549 6.5625 56.359 6.31228 56.8044 5.86688C57.2498 5.42148 57.5 4.81739 57.5 4.1875C57.5 3.55761 57.2498 2.95352 56.8044 2.50812C56.359 2.06272 55.7549 1.8125 55.125 1.8125H17.125C16.4951 1.8125 15.891 2.06272 15.4456 2.50812C15.0002 2.95352 14.75 3.55761 14.75 4.1875C14.75 4.81739 15.0002 5.42148 15.4456 5.86688C15.891 6.31228 16.4951 6.5625 17.125 6.5625V6.5625Z"
      fill="currentColor"
    />
    <path
      d="M55.125 19.625H17.1267C16.4968 19.625 15.8927 19.8752 15.4473 20.3206C15.0019 20.766 14.7517 21.3701 14.7517 22C14.7517 22.6299 15.0019 23.234 15.4473 23.6794C15.8927 24.1248 16.4968 24.375 17.1267 24.375H55.125C55.7549 24.375 56.359 24.1248 56.8043 23.6794C57.2497 23.234 57.5 22.6299 57.5 22C57.5 21.3701 57.2497 20.766 56.8043 20.3206C56.359 19.8752 55.7549 19.625 55.125 19.625Z"
      fill="currentColor"
    />
    <path
      d="M55.125 37.4375H17.1267C16.4968 37.4375 15.8927 37.6877 15.4473 38.1331C15.0019 38.5785 14.7517 39.1826 14.7517 39.8125C14.7517 40.4424 15.0019 41.0465 15.4473 41.4919C15.8927 41.9373 16.4968 42.1875 17.1267 42.1875H55.125C55.7549 42.1875 56.359 41.9373 56.8043 41.4919C57.2497 41.0465 57.5 40.4424 57.5 39.8125C57.5 39.1826 57.2497 38.5785 56.8043 38.1331C56.359 37.6877 55.7549 37.4375 55.125 37.4375Z"
      fill="currentColor"
    />
    <path
      d="M4.0625 7.75C6.03001 7.75 7.625 6.15501 7.625 4.1875C7.625 2.21999 6.03001 0.625 4.0625 0.625C2.09499 0.625 0.5 2.21999 0.5 4.1875C0.5 6.15501 2.09499 7.75 4.0625 7.75Z"
      fill="currentColor"
    />
    <path
      d="M4.0625 25.5625C6.03001 25.5625 7.625 23.9675 7.625 22C7.625 20.0325 6.03001 18.4375 4.0625 18.4375C2.09499 18.4375 0.5 20.0325 0.5 22C0.5 23.9675 2.09499 25.5625 4.0625 25.5625Z"
      fill="currentColor"
    />
    <path
      d="M4.0625 43.3767C6.03001 43.3767 7.625 41.7818 7.625 39.8142C7.625 37.8467 6.03001 36.2517 4.0625 36.2517C2.09499 36.2517 0.5 37.8467 0.5 39.8142C0.5 41.7818 2.09499 43.3767 4.0625 43.3767Z"
      fill="currentColor"
    />
  </svg>
</template>
