
import { defineComponent, onMounted, ref, watch } from "vue";
import { GetProject, GetProjectLine } from "@/services";

export default defineComponent({
  name: "ProjectViewInformation",
  props: {
    projectId: { default: () => null },
    project: { type: Object as () => GetProject, default: () => ({}) },
  },
  setup(props) {
    const currentStage = ref<GetProjectLine>({});
    const findCurrentStage = () => {
      props.project.lines.map((line: GetProjectLine) => {
        if (line.status === "CURRENT") currentStage.value = line;
      });
    };
    watch(() => props.project.lines, findCurrentStage, {
      deep: true,
      flush: "post",
    });
    onMounted(() => {
      findCurrentStage();
    });
    return {
      currentStage,
    };
  },
});
