import { GetTaskResult, GetTaskResults, useAxios } from "@/services";

export * from "./api.types";

export const taskService = {
  fetch: async (): Promise<GetTaskResults> =>
    useAxios().fetch(`/api/tasks`, {}),
  complete: async (id: unknown): Promise<GetTaskResult> =>
    useAxios().update(`/api/tasks/${id}/complete`, {}),
};
