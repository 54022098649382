<template>
  <div class="section-divider mb-4">Casted Votes</div>
  <div class="overflow-auto mb-8">
    <table class="min-w-full border border-gray-300">
      <thead>
        <tr
          v-for="(cells, rowIndex) in table.headers"
          :key="`header-row-${rowIndex}`"
        >
          <th
            v-for="(cell, cellIndex) in cells"
            :key="`header-cell-${cellIndex}`"
            :colspan="cell.colspan"
            :rowspan="cell.rowspan"
            class="bg-blue-50 border-b border-r border-gray-300 px-3 py-2 text-xs font-normal"
          >
            {{ cell.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(cells, rowIndex) in table.rows"
          :key="`data-row-${rowIndex}`"
        >
          <td
            v-for="(cell, cellIndex) in cells"
            :key="`data-cell-${cellIndex}`"
            class="border-b border-r border-gray-300 px-3 py-1.5 text-xs text-center font-normal"
          >
            {{ cell }}
          </td>
        </tr>
        <tr
          v-for="(cells, rowIndex) in table.footers"
          :key="`data-footer-${rowIndex}`"
        >
          <td
            v-for="(cell, cellIndex) in cells"
            :key="`data-footer-cell-${cellIndex}`"
            :colspan="cell.colspan"
            :rowspan="cell.rowspan"
            class="border-b border-r border-gray-300 px-3 py-2 text-xs text-center font-semibold"
            :class="{
              'bg-gray-200': cell.text === '',
              'bg-blue-50': cell.text !== '',
            }"
          >
            {{ cell.text }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

interface Table {
  rows: [];
  headers: [];
  footers: [];
}

export default defineComponent({
  name: "CastedVotes",
  props: {
    table: {
      type: Object as () => Table,
    },
  },
});
</script>
